/* GLOBAL */
:root {
  /* default */
  --primaryColor: #FF7A00;
  --secondaryColor: #FFF500;
  --titleColor: var(--primaryColor);
  --textColor: #878787;
  --textLowOpacity: #727272E6;
  --focusColor: #FFF0E3;
  --borderColor: #DCDCDC;
  --sloganTextColor: #FFFFFF;
  --page2RightBackMob: var(--primaryColor);
  --page2RightBackDesk: var(--focusColor);
  /* timer */
  --backgroundTimer: var(--primaryColor);
  --titleTimerColor: #FFFFFF;
  --textTimerColor: #FFFFFF;
  --numberTimerColor: var(--titleTimerColor);
  /* uncomplicate button */
  --uncomplicatedButtonColor: var(--focusColor);
  --uncomplicatedButtonText: var(--primaryColor);
  --uncomplicatedButtonIcon: var(--primaryColor);
  /* select plan */
  --planButtonActiveBack: var(--primaryColor);
  --planButtonActive: #FFFFFF;
  --planButtonText: #000000;
  --borderButtonColor: var(--borderColor);
  /* activated benefits */
  --detailBackColor: var(--primaryColor);
  --detailTextColor: #FFFFFF;
  /* select plan details */
  --primarySwitchColor: var(--primaryColor);
  --secondarySwitchColor: #FFFFFF;
  --monthlyBackColor: var(--focusColor);
  --monthlyColorBase: var(--primaryColor);
  --monthlyDetailsBackColor: var(--monthlyColorBase);
  --monthlyDetailsColor: #FFFFFF;
  --annualTextColor: var(--textColor);
  --annualBorderColor: #D7D7D780;
  --counterBorder: #cccccc;
  --counterTextBorder: #000000;
  /* stepper */
  --stepperBorderColor: #FFF4EA;
  --stepperDesabledCircleColor: #FFF4EA;
  /* payment method */
  --paymetCardBorder: #DCDCDC;
  /* select chip info */
  --dualSwitchColor: var(--primaryColor);
  /* config page */
  --spanLabel: var(--primaryColor);
  /* app link */
  --wppStepColor: #FFFFFF;
  --appLinkBackColor: var(--primaryColor);
}

* {
  margin: 0;
}

.container {
  .content {
    max-width: 1920px;
    max-height: 993px;
  }

  /********** PAGE 1 **********/
  &.home, &.vip-id {
    background-color: var(--primaryColor);
  
    .content {
      background-color: var(--primaryColor);
    
      .container-left {
        background-color: var(--primaryColor);
        padding: 50px 20px 0 20px;
        max-height: 80vh;
        overflow: hidden;
      }
    
      .container-right {
        padding: 50px 20px;
        background-color: #FFFFFF;
        border-radius: 20px 20px 0 0;
      }
    
      .left {
        /* HEADING */
        .content-left-heading {
          width: 100vw;
    
          .heading {
            display: flex;
            justify-content: center;
            width: 100%;
    
            h3 {
              display: none;
            }
    
            img {
              width: 55%;
            }
          }
        }
    
        /* SLOGAN */
        .content-left-slogan {
          justify-content: center;
          text-align: center;
          padding: 25px 0;
          color: var(--sloganTextColor);
    
          .slogan {
            max-width: 300px;
          }
    
          h3 {
            font-size: 31px;
            font-weight: 600;
    
            span {
              color: var(--secondaryColor);
            }
          }
        }
    
        /* BACK IMAGE */
        .content-left-back-image {
          width: calc(100vw + 80px);
          overflow: hidden;
          margin: -55px -20px 0 -20px;
    
          .back-image {
            width: 100%;
          }
    
          img {
            object-fit: cover;
            object-position: center;
            width: 100%;
          }
        }
    
        /* DETAILS */
        .content-left-details {
          .details {
            display: none;
            justify-content: center;
            flex-direction: row;
          }
    
          img {
            width: 15%;
          }
    
          .detailsTextArea {
            max-width: 500px;
    
            h3 {
              font-size: 18px;
              font-weight: 500;
              color: var(--titleColor);
            }
    
            p {
              font-size: 12px;
              font-weight: 400;
              color: var(--textColor);
            }
          }
        }
    
        /* UNCOMPLICATE BUTTON */
        .content-left-uncomplicate-button {
          justify-content: center;
          margin-bottom: 50px;
    
          button {
            padding: 15px 35px;
            font-size: 14px;
            font-weight: 600;
            border-radius: 100px;
            background-color: var(--uncomplicatedButtonColor);
            color: var(--uncomplicatedButtonText);
            gap: 10px;
    
            svg path {
              stroke: var(--uncomplicatedButtonIcon);
            }
          }
        }
      }
    
      .right {
        /* TIMER */
        .content-right-timer {
          .timer {
            flex-direction: column;
            align-items: end;
            position: absolute;
            top: 60px;
            right: 20px;
            gap: 5px;
          }
    
          .timerArea {
            display: flex;
            align-items: center;
            font-size: 14px;
            font-weight: 400;
            color: var(--titleTimerColor);
            gap: 3px;
    
            h3 {
              font-size: 12px;
              font-weight: 500;
            }
    
            p {
              display: none;
              color: var(--textTimerColor);
            }
    
            .squareTime {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 35px;
              height: 35px;
              border-radius: 8px;
              background-color: var(--borderColor);
              color: var(--numberTimerColor);
              font-size: 15px;
              font-weight: 400;
            }
          }
    
          .timerTextArea {
            flex-direction: column;
            justify-content: center;
          }
        }
    
        /* CHOOSE PLAN TEXT */
        .content-right-choose-plan-text {
          margin-bottom: 25px;
    
          h3 {
            font-size: 20px;
            font-weight: 500;
            color: var(--titleColor);
          }
    
          p {
            font-size: 14px;
            font-weight: 400;
            color: var(--textColor);
            opacity: 0.75;
          }
        }
    
        /* SELECT PLAN */
        .content-right-select-plan {
          margin-bottom: 30px;
    
          .selectArea {
            display: flex;
            margin: 0px 0 5px -20px;
            color: var(--primaryColor);
    
            .buttonLabel {
              align-items: center;
              background-color: var(--primaryColor);
              color: #FFF;
              padding: 0 8px;
              border: solid 2px #FFF;
              border-radius: 38px;
              font-size: 12px;
              font-weight: 500;
              height: 20px;
              transform: translate(0, 46px);
              margin-left: -74px;
            }
          }
    
          .containerCarousel {
            width: 100vw;
            padding: 15px 20px;
            margin-right: -40px;
            gap: 15px;
            overflow-x: auto;
            flex-wrap: nowrap;
          }
  
          .containerCarousel::-webkit-scrollbar-track {
            background: var(--primaryColor); /* cor de fundo da barra de rolagem */
          }
    
          .select-plan {
            .skltn-button {
              min-width: 76px;
              min-height: 58px;
              max-width: 76px;
              max-height: 58px;
              border-radius: 38px;
            }

            button {
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              min-width: 76px;
              min-height: 58px;
              max-width: 76px;
              max-height: 58px;
              border-radius: 38px;
              border: solid 1.5px var(--borderButtonColor);
              background-color: #FFFFFF;
              color: var(--planButtonText);
              cursor: pointer;
              gap: 4px;

              svg path {
                stroke: var(--planButtonText);
              }
          
              h3 {
                font-size: 23px;
                font-weight: 400;
              }
          
              p {
                font-size: 12px;
                font-weight: 500;
              }
            }
          
            button.active-button, button:hover {
              background-color: var(--planButtonActiveBack);
              color: var(--planButtonActive);
              border: none;
              transition: 0.3s;

              svg path {
                stroke: var(--planButtonActive);
              }
            }
    
            button.disabledButton:disabled {
              display: none;
              opacity: 35%;
            }
    
            button.disabledButton:hover {
              background-color: var(--planButtonActive);
              border: solid 1.5px var(--borderButtonColor);
              color: var(--borderButtonColor);
              cursor: auto;
            }

            .plansDivider {
              min-height: 50%;
              max-height: 50%;
              min-width: 9px;
              border-radius: 25px;
              background-color: var(--planButtonActiveBack);
              align-self: center;
            }
    
            .planTextArea {
              display: flex;
              gap: 15px;
              color: var(--primaryColor);

              .textAreaChipOnly {
                max-width: 90vw;
              }
    
              h1, h3, .skltn-labelPlan {
                font-weight: 500;
              }
    
              .labelPlan, .skltn-labelPlan {
                font-size: clamp(20px, 13vw, 60px);
              }

              .labelChipOnly {
                width: fit-content;
              }

              p {
                word-wrap: break-word;
                overflow-wrap: break-word;
                white-space: normal;
                max-width: 100%;
                color: var(--textColor);
              }
            }
          }
        }

        /* PLAN BENEFITS APPS */
        .content-right-plan-bnfts-apps {
          .plan-bnfts-apps {
            gap: 10px;
            margin-bottom: 20px;

            .planBnftsAppsTitle {
              font-size: 20px;
              font-weight: 500;
              color: var(--titleColor);
            }

            .bnftsApps {
              .bnftsApp {
                display: flex;
                align-items: center;
                gap: 8px;
                padding: 0 5px;
  
                p {
                  font-size: clamp(13px, 1.25vw, 16px);
                  color: var(--textColor);
                }
              }
            }
          }
        }

        /* ACTIVATED BENEFITS */
        .content-right-activated-bnfts, .content-right-activated-bnfts-flw {
          .activated-bnfts, .activated-bnfts-flw {
            display: flex;
            gap: 20px;
            margin-bottom: 20px;
            color: var(--planButtonText);
  
            .actvBnftsTitle {
              font-size: 20px;
              font-weight: 500;
              color: var(--titleColor);
            }
  
            .actvPortability {
              display: flex;
              align-items: center;

              .actvBnftsIcon {
                display: flex;
                align-items: center;
                justify-content: center;

                svg {
                  width: 35px;
                  height: 35px;

                  rect {
                    fill: var(--detailBackColor);
                  }
  
                  path {
                    stroke: var(--detailTextColor);
                  }
                }
              }
  
              .actvBnftsText {
                display: flex;
                justify-content: center;
                flex-direction: column;
                flex: 1;
                margin: 0 15px;
  
                h3 {
                  font-size: 14px;
                  font-weight: 500;
                  color: var(--titleColor);
                }

                p {
                  font-size: 12px;
                  color: var(--annualTextColor);
                }
              }

              .actvBnftsDetail {
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: var(--detailBackColor);
                border-radius: 27px;
                padding: 0 10px;
                height: 31px;

                p {
                  font-size: 14px;
                  font-weight: 600;
                  color: var(--detailTextColor);
                }
              }
            }

            .divider {
              width: 100%;
              height: 2px;
              border-color: var(--annualTextColor);
              opacity: 0.4;
            }
          }
        }
    
        /* PLAN DETAILS TEXT */
        .content-right-plan-details-text {
          width: 100%;
          margin-bottom: 42px;
    
          .plan-details-text {
            display: flex;
            flex-direction: column;
            text-align: center;
            gap: 20px;
    
            h3 {
              font-size: 20px;
              font-weight: 500;
              text-align: start;
              color: var(--primaryColor);
            }
    
            ul {
              list-style: none;
              padding: 0;
              display: flex;
              justify-content: space-between;
              max-width: 480px;
    
              li {
                text-align: start;
              }
    
              li.plan-details-text-bonus {
                display: none;
              }
    
              h3 {
                font-size: 14px;
                font-weight: 500;
              }
    
              p {
                font-size: 12px;
                font-weight: 400;
                color: var(--textColor);
                opacity: 1;
              }
            }
          }
        }
    
        /* SELECT PLAN DETAILS */
        .choosePortability {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 35px;
          white-space: nowrap;
          flex-wrap: nowrap;
          max-width: 100%;
    
          .choosePortabilitySwitch {
            display: flex;
            align-items: center;

            .MuiSwitch-root {
              width: 48px;
              height: 27px;
              padding: 2px;

              .MuiSwitch-thumb {
                box-sizing: border-box;
                width: 18px;
                height: 18px;
                margin-top: 3px;
                margin-left: 4px;
                transition: background-color 300ms;
                background-color: var(--primarySwitchColor);
              }

              .MuiSwitch-track {
                background-color: transparent;
                border-radius: 13px;
                border: solid 1px var(--primarySwitchColor);
                opacity: 1;
                transition: background-color 300ms, border-color 300ms;
              }

              .MuiSwitch-switchBase {
                padding: 0;
                margin: 2px;
                transition-duration: 300ms;
              
                &.Mui-checked {
                  transform: translateX(19px);
              
                  & + .MuiSwitch-track {
                    opacity: 1;
                    background-color: var(--primarySwitchColor);
                    border: solid 1px var(--primarySwitchColor);
                  }

                  .MuiSwitch-thumb {
                    background-color: var(--secondarySwitchColor);
                  }
                }
              
                &.Mui-disabled {
                  & + .MuiSwitch-track {
                    opacity: 1;
                  }
                }
              }
            }
          }
    
          .choosePortabilityText {
            flex-direction: column;
            display: flex;
            justify-content: center;
            flex: 1;
            margin: 0 15px;
    
            h3 {
              font-size: 14px;
              font-weight: 500;
              color: var(--titleColor);
              white-space: normal;
            }
    
            p {
              font-size: 12px;
              font-weight: 400;
              color: var(--annualTextColor);
              opacity: 0.9;
              white-space: normal;
            }
          }
    
          .choosePortabilityDetail {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: var(--primaryColor);
            border-radius: 27px;
            padding: 0 10px;
            height: 31px;
    
            p {
              font-size: 14px;
              font-weight: 600;
              color: #FFFFFF;
            }
    
            span {
              display: none;
            }
          }
        }
    
        .monthlyAnnualCards {
          gap: 15px;
  
          .skltn-monthlyAnnualCard {
            height: 94px;
            border-radius: 10px;
          }
    
          .monthlyAnnualCard {
            padding: 22px 25px;
            border: solid 1px var(--annualBorderColor);
            border-radius: 10px;
            cursor: pointer;
            user-select: none;
    
            .monthlyAnnualTitle {
              align-items: center;
              gap: 10px;
              position: relative;
    
              h3 {
                font-size: 16px;
                font-weight: 600;
                color: var(--annualTextColor);
              }
    
              .monthlyAnnualDetails {
                padding: 3px 5px 1px 5px;
                background-color: var(--primaryColor);
                color: #FFFFFF;
                border-radius: 4px;
                font-size: 8px;
                font-weight: 600;
                position: absolute;
                transform: translate(65px);
              }
    
              .monthlyAnnualDetails.annualDetails {
                display: flex;
                transform: translate(54px);
              }
            }
    
            .monthlyAnnualTextArea {
              gap: 5px;
    
              p {
                font-size: 12px;
                font-weight: 400;
                color: var(--annualTextColor);
              }
            }
    
            .monthlyAnnualPriceArea {
              display: flex;
              align-items: center;
              justify-content: right;
              flex-wrap: nowrap;
              gap: 5px;
    
              h3 {
                font-size: 17px;
                font-weight: 500;
                color: var(--annualTextColor);
              }
    
              h3.oldPrice {
                font-size: 14px;
                font-weight: 500;
                color: var(--annualTextColor);
                opacity: 0.75;
                text-decoration: line-through;
              }
    
              svg {
                width: 18px;

                path {
                  stroke: var(--monthlyColorBase);
                }
              }
            }
          }
    
          .monthlyAnnualCard.monthly {
            background-color: var(--monthlyBackColor);
            border: none;
    
            .monthlyAnnualPriceArea .annualPriceArea {
              display: flex;
              flex-direction: column;
              align-items: flex-end;
            }
    
            .monthlyAnnualTitle h3 {
              color: var(--monthlyColorBase);
            }
    
            .monthlyAnnualTitle .monthlyAnnualDetails {
              background-color: var(--monthlyDetailsBackColor);
              color: var(--monthlyDetailsColor);
            }
    
            .monthlyAnnualTextArea p {
              font-size: 13px;
              color: var(--monthlyColorBase);
            }
    
            .monthlyAnnualPriceArea h3 {
              color: var(--monthlyColorBase);
              font-weight: 600;
            }
          }

          .monthlyAnnualCard.chipOnly {
            background-color: var(--monthlyBackColor);
            border: none;

            .monthlyAnnualChipCounter {
              .numberCounter {
                display: flex;
                align-items: center;
                justify-content: space-between;
                border: 1px solid var(--monthlyColorBase);
                border-radius: 10px;
                width: 100%;
                height: 100%;
                overflow: hidden;
                color: var(--monthlyColorBase);

                .counterIcon {
                  display: flex;
                  flex: 1 1 auto;
                  align-items: center;
                  justify-content: center;
                  max-width: 100%;
                  height: 100%;
                  padding: 3px;

                  svg {
                    width: 80%;
                    height: 80%;

                    path {
                      fill: var(--monthlyColorBase);
                    }
                  }
                }

                .counterText {
                  display: flex;
                  flex: 1 1 auto;
                  align-items: center;
                  justify-content: center;
                  text-align: center;
                  min-width: 0;
                }
              }
            }
    
            .monthlyAnnualTitle {
              h3 {
                color: var(--monthlyColorBase);
              }

              .monthlyAnnualDetails {
                transform: translate(90px);
              }
            }
    
            .monthlyAnnualTextArea {
              padding: 0 4% 0 4%;

              p {
                font-size: 13px;
                color: var(--monthlyColorBase);
              }
            }
    
            .monthlyAnnualPriceArea h3 {
              color: var(--monthlyColorBase);
              font-weight: 600;
            }
          }
        }
      }
    }
    
    .footer {
      display: flex;
      height: 32vh;
      background-color: white;
      padding: 60px 20px;
    }
    
    /******************** MEDIA QUERY FOR GALAXY FOLD ********************/
    
    @media (max-width: 325px) {
      .content {
        .right {
          /* SELECT PLAN */
          .content-right-select-plan .select-plan .planTextArea {
            h1, h3, .skltn-labelPlan {
              font-size: 45px;
            }
          }
        }
      }
    }
    
    
    /******************** MDIEA QUERY 900 PIXELS ********************/
    
    @media (min-width: 900px) {
      display: flex;
      align-items: center;
      height: 100vh;
      --titleColor: #686868;
    
      .content {
        display: flex;
        flex-wrap: nowrap;
        height: 100%;
        max-height: 605px;
        padding: 1.5rem 2rem;
        overflow: hidden;
    
        .container-left {
          display: flex;
          width: 170%;
          padding: 0;
          background-color: var(--primaryColor);
          max-height: unset;
          overflow: unset;
        }
    
        .container-right {
          display: flex;
          padding: 0 0 0 1rem;
          background-color: var(--primaryColor);
        }
    
        .left {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-self: flex-start;
          height: calc(100vh - 2.5rem);
          padding: 1.25rem 0.75rem;
          position: relative;
    
          /* HEADING */
          .content-left-heading {
            align-self: flex-start;
            width: auto;
    
            .heading img {
              width: 130px;
            }
          }
    
          /* SLOGAN */
          .content-left-slogan {
            justify-content: start;
            text-align: initial;
            padding: 0;
            margin-top: -190px;
    
            .slogan {
              max-width: 170px;
    
              h3 {
                font-size: 18px;
              }
            }
          }
    
          /* BACK IMAGE */
          .content-left-back-image {
            display: flex;
            margin: 0;
            width: 100vw;
            height: 100vh;
            top: -1.5rem;
            left: -2rem;
            position: absolute;
    
            .back-image {
              height: 100%;
              position: relative;
            }
    
            img {
              object-fit: unset;
              object-position: unset;
              width: 65%;
              position: absolute;
              top: -28px;
              left: 0px;
            }
          }
    
          /* DETAILS */
          .content-left-details .details {
            display: none;
          }
    
          /* UNCOMPLICATE BUTTON */
        }
        
        .right {
          flex-direction: column;
          justify-content: space-between;
          padding: 1.5rem;
          background-color: #FFF;
          border-radius: 1.75rem;
          z-index: 100;
    
          /* TIMER */
      
          /* CHOOSE PLAN TEXT */
          .content-right-choose-plan-text {
            margin-bottom: 0;
    
            h3 {
              font-size: 11px;
            }
    
            p {
              font-size: 9px;
            }
          }
    
          /* SELECT PLAN */
          .content-right-select-plan {
            margin-bottom: 0;
    
            .selectArea {
              margin: 0;
    
              .buttonLabel {
                padding: 1px 6px 0 6px;
                border: solid 1px #FFF;
                font-size: 7px;
                height: 13px;
                position: absolute;
                transform: translate(0, 13px);
                margin-left: 0;
                right: 5px;
              }
            }
    
            .containerCarousel {
              width: 100%;
              padding: 5px 0 0 2px;
              margin-right: 0;
              gap: 0;
              justify-content: space-between;
              align-items: center;
              overflow-x: unset;
              position: relative;
            }
    
            .select-plan {
              .skltn-button {
                min-width: 38px;
                min-height: 28px;
                max-width: 76px;
                max-height: 58px;
              }
    
              button {
                min-width: 38px;
                min-height: 28px;
                gap: 2px;
    
                h3 {
                  font-size: 12px;
                }
    
                p {
                  font-size: 6px;
                }
              }
    
              .planTextArea {
                gap: 8px;
    
                h1, h3, .skltn-labelPlan, .labelChipOnly, .labelPlan {
                  font-size: 32px;
                }

                p {
                  font-size: 10px;
                }
              }
            }
          }

          /* PLAN BENEFITS APPS */
          .content-right-plan-bnfts-apps {
            .plan-bnfts-apps {
              .planBnftsAppsTitle {
                font-size: 11px;
              }
  
              .bnftsApps .bnftsApp p {
                font-size: clamp(9px, 1.15vw, 16px);
              }
            }
          }

          /* ACTIVATED BENEFITS */
          .content-right-activated-bnfts, .content-right-activated-bnfts-flw {
            .activated-bnfts, .activated-bnfts-flw {
              gap: 10px;
              margin-bottom: 10px;

              .actvBnftsTitle {
                font-size: 11px;
              }

              .actvPortability {
                .actvBnftsIcon svg {
                  width: 25px;
                  height: 25px;
                }

                .actvBnftsText {
                  h3 {
                    font-size: 10px;
                  }

                  p {
                    font-size: 8px;
                  }
                }

                .actvBnftsDetail {
                  width: auto;
                  height: auto;
                  padding: 4px 11px 3px 11px;
  
                  p {
                    font-size: 8px;
                  }
                }
              }
            }
          }
    
          /* PLAN DETAILS TEXT */
          .content-right-plan-details-text {
            margin-bottom: 0;
            align-items: center;
            padding: 0px 0 2px 0;
    
            .plan-details-text {
              flex-direction: row;
              gap: 0;
    
              .planDetailsTextColumn {
                display: flex;
                flex-direction: column;
                justify-content: initial;
                gap: 2px;
              }
    
              .planDetailsTextDetail {
                display: flex;
                align-items: center;
                gap: 5px;
    
                p {
                  font-size: 8px;
                  font-weight: 500;
                  color: var(--textColor);
                  opacity: 80%;
                }
    
                svg {
                  width: 8px;
                }
              }
    
              .planDetailsTextDetail.icon svg path {
                fill: var(--primaryColor);
              }
    
              .planDetailsTextDetail.checkIcon svg path {
                stroke: var(--primaryColor);
              }
  
              .planDetailsTextDetail.cellTowerIcon svg path {
                fill: var(--primaryColor);
              }
            }
          }
    
          /* SELECT PLAN DETAILS */
          .choosePortability {
            margin-bottom: 5px;
    
            .choosePortabilitySwitch {
              .MuiSwitch-root {
                width: 24px;
                height: 14px;
  
                .MuiSwitch-thumb {
                  width: 8px;
                  height: 8px;
                  margin-left: 2px;
                  margin-top: 2px;
                }
  
                .MuiSwitch-switchBase.Mui-checked {
                  transform: translate(8px);
                }
              }
            }
    
            .choosePortabilityText {
              margin: 0 10px;
    
              h3 {
                color: var(--primaryColor);
                font-size: 9px;
              }
    
              p {
                font-size: 8px;
              }
            }
    
            .choosePortabilityDetail {
              width: auto;
              height: auto;
              padding: 4px 11px 3px 11px;
              background-color: var(--focusColor);
    
              p {
                display: flex;
                gap: 2px;
                font-size: 8px;
                color: var(--primaryColor);
              }
            }
          }
    
          .monthlyAnnualCards {
            gap: 8px;
  
            .skltn-monthlyAnnualCard {
              height: 55px;
            }
    
            .monthlyAnnualCard {
              padding: 12px 18px;
    
              .monthlyAnnualTitle {
                gap: 8px;
    
                h3 {
                  font-size: 10px;
                }
    
                .monthlyAnnualDetails {
                  padding: 2px 5px 1px 5px;
                  font-size: 6px;
                  transform: translate(42px, -1px);
                }
    
                .monthlyAnnualDetails.annualDetails {
                  transform: translate(35px, -1px);
                }
              }
    
              .monthlyAnnualTextArea {
                transition: 0.5s;
                gap: 3px;
    
                p {
                  font-size: 8px;
                }
              }
    
              .monthlyAnnualPriceArea {
                h3 {
                  font-size: 10px;
                }
    
                h3.oldPrice {
                  display: none;
                }
    
                svg {
                  width: 12px;
                }
              }
            }
    
            .monthlyAnnualCard:hover .monthlyAnnualTextArea {
              padding-left: 12px;
              transition: 0.5s;
            }
    
            .monthlyAnnualCard.monthly .monthlyAnnualTextArea p {
              font-size: 8px;
              color: var(--monthlyColorBase);
            }
          }
        }
      }
    }
    
    
    /******************** MEDIA QUERY 1200 PIXELS ********************/
    
    @media (min-width: 1200px) {
      .content {
        padding: 1.75rem 3.25rem;
        max-height: 695px;
    
        .left {
          padding: 1.25rem 0;
  
          /* HEADING */
          .content-left-heading .heading img {
            width: 170px;
          }
        
          /* SLOGAN */
          .content-left-slogan {
            margin-top: -250px;
        
            .slogan {
              max-width: 240px;
        
              h3 {
                font-size: 26px;
              }
            }
          }
        
          /* BACK IMAGE */
          .content-left-back-image {
            top: 0;
            left: -3.5rem;
        
            img {
              width: 65%;
              top: -59px;
              left: 5px;
            }
          }
        
          /* DETAILS */
        
          /* UNCOMPLICATE BUTTON */
        }
    
        .right {
          padding: 1.5rem 2rem;
  
          /* CHOOSE PLAN TEXT */
          .content-right-choose-plan-text {
            margin-bottom: 0;
        
            h3 {
              font-size: 12px;
            }
        
            p {
              font-size: 11px;
            }
          }
  
          /* SELECT PLAN */
          .content-right-select-plan {
            margin-bottom: 0;
  
            .selectArea {
              .buttonLabel {
                padding: 0px 7px;
                font-size: 9px;
                height: 16px;
                transform: translate(0, 18px);
                right: 8px;
              }
            }
        
            .containerCarousel {
              padding: 5px 0;
            }
        
            .select-plan {
              .skltn-button {
                min-width: 52px;
                min-height: 38px;
                max-width: 76px;
                max-height: 58px;
              }
    
              button {
                min-width: 50px;
                min-height: 34px;
                gap: 4px;
        
                h3 {
                  font-size: 14px;
                }
          
                p {
                  font-size: 10px;
                }
              }
        
              .planTextArea {
                h1, h3, .skltn-labelPlan, .labelChipOnly, .labelPlan {
                  font-size: 34px;
                }

                p {
                  font-size: 12px;
                }
              }
            }
          }

          /* PLAN BENEFITS APPS */
          .content-right-plan-bnfts-apps {
            .plan-bnfts-apps {
              .planBnftsAppsTitle {
                font-size: 12px;
              }
  
              .bnftsApps .bnftsApp {
                padding: 0 12px;

                p {
                  font-size: clamp(9px, 0.9vw, 16px);
                }
              }
            }
          }

          /* ACTIVATED BENEFITS */
          .content-right-activated-bnfts, .content-right-activated-bnfts-flw {
            .activated-bnfts, .activated-bnfts-flw {
              gap: 10px;
              margin-bottom: 10px;

              .actvBnftsTitle {
                font-size: 12px;
              }

              .actvPortability {
                .actvBnftsIcon svg {
                  width: 30px;
                  height: 30px;
                }

                .actvBnftsText {
                  h3 {
                    font-size: 11px;
                  }

                  p {
                    font-size: 10px;
                  }
                }

                .actvBnftsDetail {
                  padding: 6px 11px 5px 11px;

                  p {
                    font-size: 11px;
                  }
                }
              }
            }
          }
        
          /* PLAN DETAILS TEXT */
          .content-right-plan-details-text {
            padding: 5px 0 10px 0;
        
            .plan-details-text {
              .planDetailsTextColumn {
                gap: 5px;
              }
        
              .planDetailsTextDetail {
                p {
                  font-size: 11px;
                }
          
                svg {
                  width: 13px;
                }
              }
            }
          }
        
          /* SELECT PLAN DETAILS */
          .choosePortability {
            .choosePortabilityText {
              margin: 0 12px;
        
              h3 {
                font-size: 11px;
              }
        
              p {
                font-size: 10px;
              }
            }
        
            .choosePortabilityDetail {
              padding: 6px 11px 5px 11px;
        
              p {
                gap: 3px;
                font-size: 11px;
              }
        
              span {
                display: block;
              }
            }
          }
        
          .monthlyAnnualCards {
            .skltn-monthlyAnnualCard {
              height: 75px;
            }
  
            .monthlyAnnualCard {
              padding: 18px;
        
              .monthlyAnnualTitle {
                h3 {
                  font-size: 13px;
                }
        
                .monthlyAnnualDetails {
                  font-size: 8px;
                  transform: translate(54px, -1px);
                }
        
                .monthlyAnnualDetails.annualDetails {
                  transform: translate(45px, -1px);
                }
              }
        
              .monthlyAnnualTextArea p {
                font-size: 10px;
              }
        
              .monthlyAnnualPriceArea {
                h3 {
                  font-size: 14px;
                }
        
                svg {
                  width: 17px;
                }
              }
            }
        
            .monthlyAnnualCard.monthly .monthlyAnnualTextArea p {
              font-size: 10px;
            }
          }
        }
      }
    }
    
    
    /******************** MEDIA QUERY 1440 PIXELS ********************/
    
    @media (min-width: 1440px) {
      .content {
        padding: 2rem 3.5rem;
        max-height: 920px;
    
        .container-right {
          padding: 0 0 0 2rem;
        }
  
        .left {
          padding: 1.25rem 0 0 0;
          height: calc(100vh - 5.25rem);
  
          /* HEADING */
          .content-left-heading .heading img {
            width: 200px;
          }
        
          /* SLOGAN */
          .content-left-slogan {
            margin-top: -270px;
        
            .slogan {
              max-width: 275px;
        
              h3 {
                font-size: 29px;
              }
            }
          }
        
          /* BACK IMAGE */
          .content-left-back-image img {
            width: 63%;
            top: -105px;
            left: 0px;
          }
          
          /* DETAILS */
          .content-left-details {
            z-index: 1;
            align-items: end;
        
            .details {
              display: flex;
              align-self: auto;
              width: auto;
              justify-content: flex-start;
              gap: 9px;
            }
        
            img {
              width: 15%;
            }
        
            .detailsTextArea h3 {
              font-size: 12px;
              color: #FFFFFF;
            }
        
            .detailsTextArea p {
              font-size: 11px;
              color: #FFFFFF;
            }
          }
        
          /* UNCOMPLICATE BUTTON */
        }
    
        .right {
          padding: 2rem 2.75rem;
  
          /* TIMER */
          
          /* CHOOSE PLAN TEXT */
          .content-right-choose-plan-text {
            margin-bottom: 0;
        
            h3 {
              font-size: 15px;
            }
        
            p {
              font-size: 12px;
            }
          }
        
          /* SELECT PLAN */
          .content-right-select-plan {
            margin-bottom: 0;
        
            .selectArea .buttonLabel {
              font-size: 10px;
              height: 18px;
              transform: translate(0, 22px);
              right: 14px;
            }
        
            .select-plan {
              .skltn-button {
                min-width: 64px;
                min-height: 47px;
                max-width: 76px;
                max-height: 58px;
              }
    
              button {
                min-width: 60px;
                min-height: 43px;
        
                h3 {
                  font-size: 20px;
                }
              }
        
              .planTextArea {
                h1, h3, .skltn-labelPlan, .labelChipOnly, .labelPlan {
                  font-size: 44px;
                }

                p {
                  font-size: 14px;
                }
              }
            }
          }

          /* PLAN BENEFITS APPS */
          .content-right-plan-bnfts-apps {
            .plan-bnfts-apps {
              .planBnftsAppsTitle {
                font-size: 15px;
              }
  
              .bnftsApps .bnftsApp p {
                font-size: clamp(9px, 0.95vw, 16px);
              }
            }
          }

          /* ACTIVATED BENEFITS */
          .content-right-activated-bnfts, .content-right-activated-bnfts-flw {
            .activated-bnfts, .activated-bnfts-flw {
              gap: 14px;
              margin-bottom: 14px;

              .actvBnftsTitle {
                font-size: 15px;
              }

              .actvPortability {
                .actvBnftsIcon svg {
                  width: 30px;
                  height: 30px;
                }
  
                .actvBnftsText {
                  h3 {
                    font-size: 13px;
                  }
  
                  p {
                    font-size: 11px;
                  }
                }

                .actvBnftsDetail p {
                  font-size: 12px;
                }
              }
            }
          }
        
          /* PLAN DETAILS TEXT */
          .content-right-plan-details-text {
            padding: 0 0 10px 0;
        
            .plan-details-text .planDetailsTextColumn {
              gap: 6px;
            }
        
            .plan-details-text .planDetailsTextDetail p {
              font-size: 12px;
            }
        
            .plan-details-text .planDetailsTextDetail svg {
              width: 14px;
            }
          }
        
          /* SELECT PLAN DETAILS */
          .choosePortability {
            margin-bottom: 8px;
        
            .choosePortabilitySwitch {
              .MuiSwitch-root {
                width: 38px;
                height: 22px;
  
                .MuiSwitch-thumb {
                  width: 13px;
                  height: 13px;
                  margin-left: 4px;
                  margin-top: 3px;
                }
  
                .MuiSwitch-switchBase.Mui-checked {
                  transform: translate(13px);
                }
              }
            }
        
            .choosePortabilityText h3 {
              font-size: 13px;
            }
        
            .choosePortabilityText p {
              font-size: 11px;
            }
        
            .choosePortabilityDetail p {
              gap: 4px;
              font-size: 12px;
            }
          }
        
          .monthlyAnnualCards {
            .skltn-monthlyAnnualCard {
              height: 86px;
            }
    
            .monthlyAnnualCard {
              padding: 22px;
        
              .monthlyAnnualTitle {
                h3 {
                  font-size: 14px;
                }
        
                .monthlyAnnualDetails {
                  font-size: 9px;
                  transform: translate(58px, -1px);
                }
        
                .monthlyAnnualDetails.annualDetails {
                  transform: translate(48px, -1px);
                }
              }
        
              .monthlyAnnualTextArea p {
                font-size: 12px;
              }
        
              .monthlyAnnualPriceArea h3 {
                font-size: 14px;
              }
        
              .monthlyAnnualPriceArea svg {
                width: 16px;
              }
            }
        
            .monthlyAnnualCard.monthly .monthlyAnnualTextArea p {
              font-size: 12px;
            }
          }
        }
      }
    }
    
    
    /******************** MEDIA QUERY 1860 PIXELS ********************/
    
    @media (min-width: 1860px) {
      .content {
        padding: 2.5rem 6.25rem;
        max-height: 1080px;
  
        .container-right {
          padding: 0 0 0 3rem;
        }
  
        .left {
          /* HEADING */
          .content-left-heading .heading img {
            width: 245px;
          }
        
          /* SLOGAN */
          .content-left-slogan {
            margin-top: -420px;
        
            .slogan {
              max-width: 310px;
        
              h3 {
                font-size: 34px;
              }
            }
          }
        
          /* BACK IMAGE */
          .content-left-back-image {
            left: -6.5rem;
            
            img {
              width: auto;
              top: -155px;
            }
          }
          
          /* DETAILS */
          .content-left-details {
            .details {
              gap: 10px;
            }
        
            img {
              width: 17%;
            }
        
            .detailsTextArea h3 {
              font-size: 13px;
            }
        
            .detailsTextArea p {
              font-size: 12px;
            }
          }
        }
    
        .right {
          padding: 4.5rem 4.25rem;
  
          /* CHOOSE PLAN TEXT */
          .content-right-choose-plan-text {
            margin-bottom: 0;
        
            h3 {
              font-size: 18px;
            }
        
            p {
              font-size: 16px;
            }
          }
        
          /* SELECT PLAN */
          .content-right-select-plan {
            margin-bottom: 0;
        
            .selectArea .buttonLabel {
              right: 12px;
            }
        
            .select-plan {
              .skltn-button {
                min-width: 62px;
                min-height: 47px;
              }
    
              button {
                min-width: 62px;
                min-height: 47px;
              }
        
              .planTextArea {
                h1, h3, .skltn-labelPlan, .labelChipOnly, .labelPlan {
                  font-size: 58px;
                }

                p {
                  font-size: 16px;
                }
              }
            }
          }

          /* PLAN BENEFITS APPS */
          .content-right-plan-bnfts-apps {
            .plan-bnfts-apps {
              .planBnftsAppsTitle {
                font-size: 18px;
              }
  
              .bnftsApps .bnftsApp {
                padding: 0 8px;

                p {
                  font-size: clamp(9px, 1vw, 17px);
                }
              }
            }
          }

          /* ACTIVATED BENEFITS */
          .content-right-activated-bnfts, .content-right-activated-bnfts-flw {
            .activated-bnfts, .activated-bnfts-flw {
              .actvBnftsTitle {
                font-size: 18px;
              }

              .actvPortability {
                .actvBnftsText {
                  h3 {
                    font-size: 15px;
                  }
  
                  p {
                    font-size: 12px;
                  }
                }

                .actvBnftsDetail p {
                  font-size: 14px;
                }
              }
            }
          }
        
          /* PLAN DETAILS TEXT */
          .content-right-plan-details-text {
            padding: 0 0 10px 0;
        
            .plan-details-text .planDetailsTextColumn {
              gap: 20px;
            }
        
            .plan-details-text .planDetailsTextDetail p {
              font-size: 14px;
            }
          }
        
          /* SELECT PLAN DETAILS */
          .choosePortability {
            margin-bottom: 30px;
        
            .choosePortabilityText h3 {
              font-size: 14px;
            }
        
            .choosePortabilityText p {
              font-size: 12px;
            }
        
            .choosePortabilityDetail p {
              gap: 4px;
              font-size: 12px;
            }
          }
        
          .monthlyAnnualCards {
            .skltn-monthlyAnnualCard {
              height: 100px;
            }
    
            .monthlyAnnualCard {
              padding: 28px;
        
              .monthlyAnnualTitle {
                h3 {
                  font-size: 15px;
                }
        
                .monthlyAnnualDetails {
                  transform: translate(64px, 0px);
                }
        
                .monthlyAnnualDetails.annualDetails {
                  transform: translate(54px, 0px);
                }
              }
        
              .monthlyAnnualPriceArea h3 {
                font-size: 16px;
              }
        
              .monthlyAnnualPriceArea {
                gap: 8px;
  
                svg {
                  width: 18px;
                }
              }
            }
  
            .monthlyAnnualCard:hover .monthlyAnnualTextArea {
              padding-left: 18px;
            }
          }
        }
      }
    }
  }
  
  /********** PAGE 2 **********/
  &.configure-seu-plano {
    --secondaryColor: #FFF4EA;
  
    .content {
      .container-left {
        padding: 80px 20px 224px 20px;
      }
  
      .container-right {
        padding: 20px 25px 0 25px;
        background-color: var(--page2RightBackMob);
        position: fixed;
        bottom: 0;
        z-index: 1;
      }
  
      .left {
        display: flex;
        gap: 30px;
        padding-bottom: 60px;
        
        /* HEADING */
        .content-left-heading {
          width: 100vw;
    
          .heading {
            display: flex;
            justify-content: center;
            width: 100%;
    
            h3 {
              display: none;
            }
    
            img {
              width: 55%;
            }
          }
        }
  
        /* PLAN CONFIG TEXT */
        .content-left-plan-config-text {
          .plan-config-text {
            display: flex;
            align-items: center;
            gap: 10px;
            
            .iconsArea {
              display: flex;
              align-items: center;
            }
          
            h3 {
              font-size: 14px;
              font-weight: 600;
            }
      
            p {
              font-size: 12px;
              font-weight: 400;
            }
          }
        }
  
        /* STEPPER PLAN */
        .content-left-stepper-plan {
          width: 100%;
          overflow: hidden;
  
          .stepper-plan {
            width: 100%;
  
            .css-10mg1vw-MuiStepper-root .css-n7tliy-MuiStep-root {
              padding-left: 0;
              padding-right: 0;
            }
            
            .Mui-completed .Mui-completed .QontoStepIcon-circle {
              background-color: var(--primaryColor);
            }
  
            .Mui-active .QontoStepIcon-circle {
              background-color: var(--primaryColor);
            }
            
            .Mui-active .QontoStepIcon-circle label {
              color: white;
              font-size: 10px;
              font-weight: 600;
            }
  
            .Mui-active .QontoStepIcon-circle::after {
              content: "";
              display: block;
              width: 30%;
              height: 6px;
              background-color: var(--primaryColor);
              position: absolute;
              left: 58%;
              z-index: 1;
              border-radius: 10px;
            }
  
            .Mui-disabled .Mui-disabled .QontoStepIcon-circle {
              color: var(--stepperDesabledCircleColor);
            }
            
            .Mui-disabled .Mui-disabled .QontoStepIcon-circle label {
              color: var(--primaryColor);
              font-size: 10px;
              font-weight: 600;
            }
            
            .MuiStepConnector-root.MuiStepConnector-horizontal.MuiStepConnector-alternativeLabel {
              top: 10px;
              left: calc(-50% + 8px);
              right: calc(50% + 8px);
            
              .MuiStepConnector-line.MuiStepConnector-lineHorizontal {
                border-top-width: 6px;
                border-color: var(--stepperBorderColor);
                width: 93%;
                margin-left: 4%;
              }
            }
            
            .MuiStepConnector-root.MuiStepConnector-horizontal.MuiStepConnector-alternativeLabel.Mui-completed .MuiStepConnector-line.MuiStepConnector-lineHorizontal {
              border-color: var(--primaryColor);
            }
            
            .MuiStepConnector-root.MuiStepConnector-horizontal.MuiStepConnector-alternativeLabel.Mui-active .MuiStepConnector-line.MuiStepConnector-lineHorizontal {
              border-color: var(--primaryColor);
            }
            
            .css-vnkopk-MuiStepLabel-iconContainer.MuiStepLabel-alternativeLabel {
              z-index: 1;
            }
              
            .css-1vyamtt-MuiStepLabel-labelContainer {
              .css-1hv8oq8-MuiStepLabel-label.MuiStepLabel-alternativeLabel {
                margin-top: 5px;
                color: var(--primaryColor);
                font-size: 14px;
              }
  
              .css-1hv8oq8-MuiStepLabel-label.MuiStepLabel-alternativeLabel.Mui-disabled {
                display: none;
              }
  
              .css-1hv8oq8-MuiStepLabel-label.MuiStepLabel-alternativeLabel.Mui-completed {
                display: none;
              }
            }
          }
  
          .stepper-plan.step-5 {
            .Mui-active .QontoStepIcon-circle::after {
              opacity: 0;
            }
          }
        }
  
        /* STEPS */
        .content-left-steps {
          .steps {
            .step {
              gap: 30px;
  
              .choose-plan-text {
                h3 {
                  font-size: 14px;
                  font-weight: 600;
                }
          
                p {
                  font-size: 12px;
                  font-weight: 400;
                }
              }
            }
  
            .step-1 {
              /* BONUS TEXT */
              .bonus-text {
                margin-bottom: -15px;
  
                h3 {
                  font-size: 14px;
                  font-weight: 600;
                }
          
                p {
                  font-size: 12px;
                  font-weight: 400;
                }
              }
              
              /* SELECT PORTABILITY */
              .select-portability {
                .choosePortability {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  white-space: nowrap;
                  flex-wrap: nowrap;
                  max-width: 100%;
            
                  .choosePortabilitySwitch {
                    display: flex;
                    align-items: center;

                    .MuiSwitch-root {
                      width: 34px;
                      height: 20px;
                      padding: 2px;
        
                      .MuiSwitch-thumb {
                        box-sizing: border-box;
                        width: 12px;
                        height: 12px;
                        margin-left: 3px;
                        margin-top: 3px;
                        transition: background-color 300ms;
                        background-color: var(--primarySwitchColor);
                      }
        
                      .MuiSwitch-track {
                        background-color: transparent;
                        border-radius: 13px;
                        border: solid 1px var(--primarySwitchColor);
                        opacity: 1;
                        transition: background-color 300ms, border-color 300ms;
                      }
        
                      .MuiSwitch-switchBase {
                        padding: 0;
                        margin: 2px;
                        transition-duration: 300ms;
                      
                        &.Mui-checked {
                          transform: translate(12px);
                      
                          & + .MuiSwitch-track {
                            opacity: 1;
                            background-color: var(--primarySwitchColor);
                            border: solid 1px var(--primarySwitchColor);
                          }
        
                          .MuiSwitch-thumb {
                            background-color: var(--secondarySwitchColor);
                          }
                        }
                      
                        &.Mui-disabled {
                          & + .MuiSwitch-track {
                            opacity: 1;
                          }
                        }
                      }
                    }
                  }
            
                  .choosePortabilityText {
                    flex-direction: column;
                    display: flex;
                    justify-content: center;
                    flex: 1;
                    margin: 0 15px;
            
                    h3 {
                      font-size: 12px;
                      font-weight: 500;
                      color: #282828;
                      white-space: normal;
                    }
            
                    p {
                      font-size: 10px;
                      font-weight: 400;
                      color: var(--annualTextColor);
                      opacity: 0.9;
                      white-space: normal;
                    }
                  }
            
                  .choosePortabilityDetail {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: var(--primaryColor);
                    border-radius: 27px;
                    padding: 0 14px;
                    height: 28px;
            
                    p {
                      font-size: 12px;
                      font-weight: 600;
                      color: #FFFFFF;
                    }
            
                    span {
                      display: none;
                    }
                  }
                }
              }
  
              /* SELECT SMART CONTROL */
              .select-smart-control {
                .choosePortability {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  white-space: nowrap;
                  flex-wrap: nowrap;
                  max-width: 100%;
            
                  .choosePortabilitySwitch {
                    display: flex;
                    align-items: center;

                    .MuiSwitch-root {
                      width: 34px;
                      height: 20px;
                      padding: 2px;
        
                      .MuiSwitch-thumb {
                        box-sizing: border-box;
                        width: 12px;
                        height: 12px;
                        margin-left: 3px;
                        margin-top: 3px;
                        transition: background-color 300ms;
                        background-color: var(--primarySwitchColor);
                      }
        
                      .MuiSwitch-track {
                        background-color: transparent;
                        border-radius: 13px;
                        border: solid 1px var(--primarySwitchColor);
                        opacity: 1;
                        transition: background-color 300ms, border-color 300ms;
                      }
        
                      .MuiSwitch-switchBase {
                        padding: 0;
                        margin: 2px;
                        transition-duration: 300ms;
                      
                        &.Mui-checked {
                          transform: translate(12px);
                      
                          & + .MuiSwitch-track {
                            opacity: 1;
                            background-color: var(--primarySwitchColor);
                            border: solid 1px var(--primarySwitchColor);
                          }
        
                          .MuiSwitch-thumb {
                            background-color: var(--secondarySwitchColor);
                          }
                        }
                      
                        &.Mui-disabled {
                          & + .MuiSwitch-track {
                            opacity: 1;
                          }
                        }
                      }
                    }
                  }
            
                  .choosePortabilityText {
                    flex-direction: column;
                    display: flex;
                    justify-content: center;
                    flex: 1;
                    margin: 0 15px;
            
                    h3 {
                      font-size: 12px;
                      font-weight: 500;
                      color: #282828;
                    }
            
                    p {
                      font-size: 10px;
                      font-weight: 400;
                      color: var(--annualTextColor);
                      opacity: 0.9;
                      white-space: normal;
                    }
                  }
            
                  .choosePortabilityDetail {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: var(--focusColor);
                    border-radius: 27px;
                    padding: 0 14px;
                    height: 28px;
            
                    p {
                      font-size: 12px;
                      font-weight: 600;
                      color: var(--primaryColor);
                    }
            
                    span {
                      display: none;
                    }
                  }
                }
              }
  
              /* ACCESS TECHNOLOGY TEXT */
              .access-technology-text {
                margin: 10px 0 -15px 0;
                
                h3 {
                  font-size: 14px;
                  font-weight: 600;
                }
          
                p {
                  font-size: 12px;
                  font-weight: 400;
                }
              }
  
              /* SELECT PICK UP CHIP */
              .chip-only-pick-up {
                width: 100%;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                padding-bottom: 20px;
                gap: 14px;
  
                .pickUpChoose {
                  gap: 10px;
                  margin-bottom: 10px;

                  .pickUpChoice {
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    flex-wrap: nowrap;
                    width: 100%;
  
                    .checkArea {
                      gap: 5px;
                      align-items: center;

                      .textAreaChipInfo {
                        h3 {
                          font-size: 12px;
                          font-weight: 500;
                          color: #282828;
                        }
    
                        p {
                          font-size: 10px;
                          font-weight: 400;
                          color: var(--textLowOpacity);
                        }

                        a {
                          text-decoration-color: var(--primaryColor);
  
                          p {
                            color: var(--primaryColor);
                          }
                        }
                      }

                      svg {
                        path {
                          fill: var(--dualSwitchColor);
                        }
                      }
                      
                      .Mui-checked svg rect {
                        fill: var(--dualSwitchColor);
                      }
                    }

                    .priceAreaChipInfo {
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      white-space: nowrap;
    
                      h3 {
                        font-size: 12px;
                        font-weight: 500;
                        color: #6D6D6D;
                      }
                    }
                  }
                }
              }
  
              /* SELECT CHIP INFO */
              .select-chip-info {
                width: 100%;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                padding-bottom: 20px;
                gap: 14px;
  
                .chip, .pickUpChoice {
                  flex-direction: row;
                  align-items: center;
                  justify-content: center;
                  flex-wrap: nowrap;
                  width: 100%;
  
                  .checkArea {
                    gap: 5px;
                    align-items: center;
  
                    .textAreaChipInfo {
                      h3 {
                        font-size: 12px;
                        font-weight: 500;
                        color: #282828;
                      }
  
                      p {
                        font-size: 10px;
                        font-weight: 400;
                        color: var(--textLowOpacity);
                      }
                    }
  
                    svg path {
                      fill: var(--dualSwitchColor);
                    }
                    
                    .Mui-checked {
                      svg rect {
                        fill: var(--dualSwitchColor);
                      }
                    }
                  }
        
                  .priceAreaChipInfo {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    white-space: nowrap;
  
                    h3 {
                      font-size: 12px;
                      font-weight: 500;
                      color: #6D6D6D;
                    }
                  }
                }
  
                .pickUpChoose {
                  gap: 10px;
                  margin-bottom: 10px;

                  .pickUpChoice {
                    padding: 0 7%;
  
                    .checkArea {
                      .textAreaChipInfo a {
                        text-decoration-color: var(--primaryColor);

                        p {
                          color: var(--primaryColor);
                        }
                      }

                      svg {
                        width: 85% !important;
    
                        path {
                          fill: var(--dualSwitchColor);
                        }
                      }
                      
                      .Mui-checked {
                        svg rect {
                          fill: var(--dualSwitchColor);
                        }
                      }
                    }
                  }
                }
              }
            }
  
            .step-4, .step-5 {
              .payment-step {
                .paymentCard {
                  border: 1px solid var(--paymetCardBorder);

                  &.activated {
                    background-color: #F4F4F4;

                    .cardCheckbox svg {
                      circle:nth-of-type(1) {
                        stroke: var(--primaryColor);
                      }

                      circle:nth-of-type(2) {
                        fill: var(--primaryColor);
                      }
                    }
                  }

                  .cardDetail {
                    background-color: var(--primaryColor);
                    color: white
                  }

                  .keepCard svg path {
                    fill: var(--primaryColor);
                  }
                }

                .collapses {
                  .pix-collapse {
                    .pixQrCodeBox {
                      border: 2px solid var(--paymetCardBorder);
                    }

                    .stepByStep {
                      .stepNumber {
                        background-color: var(--stepperDesabledCircleColor);
                        color: var(--primaryColor);
                      }

                      .stepText {
                        p {
                          color: var(--textLowOpacity);
                        }
                      }
                    }
                  }

                  .boleto-collapse {
                    .boletoLinkBox {
                      border: 1px solid var(--paymetCardBorder);
                    }

                    .stepByStep {
                      .stepNumber {
                        background-color: var(--stepperDesabledCircleColor);
                        color: var(--primaryColor);
                      }

                      .stepText {
                        p {
                          color: var(--textLowOpacity);
                        }
                      }
                    }

                    .downloadPDFBtn {
                      border: 1px solid var(--textLowOpacity);
                      color: var(--textLowOpacity);
                    }
                  }
                }
              }
            }
          }
        }
      }
  
      .right {
        /* Theme */
        --textColor: #FFFFFF;
  
        background-color: var(--page2RightBackMob);
        display: flex;
        flex-direction: column;
        gap: 20px;
  
        /* ORDER SUMMARY TEXT */
        .content-right-order-summary-text {
          .order-summary-text {
            display: flex;
            flex-wrap: nowrap;
            justify-content: space-between;
            align-items: center;
            padding-top: 5px;
            margin-bottom: 5px;
            color: var(--textColor);
  
            h3 {
              font-size: 14px;
              font-weight: 500;
            }
  
            p {
              font-size: 12px;
              font-weight: 400;
              opacity: 0.75;
            }
  
            .iconsArea {
              display: flex;
            }
          }
  
          .order-summary-text.closed {
            padding-top: 0;
            margin-bottom: 0;
          }
        }
        
        /* FIRST SUMMARY */
        .content-right-first-summary {
          margin: 5px 0 20px 0;
  
          .first-summary {
            gap: 15px;
  
            .titleArea {
              display: flex;
              flex-wrap: nowrap;
              justify-content: flex-start;
              align-items: center;
              gap: 15px;
  
              .iconsArea {
                display: flex;
                align-items: center;
              }
  
              .textArea {
                color: var(--textColor);
  
                h3 {
                  font-size: 14px;
                  font-weight: 500;
                }
      
                p {
                  font-size: 12px;
                  font-weight: 400;
                  opacity: 0.75;
                }
              }
            }
  
            .ItemsArea {
              gap: 25px;
  
              .item {
                width: 100%;
  
                .choosePortability {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  white-space: nowrap;
                  flex-wrap: nowrap;
                  max-width: 100%;
                  gap: 15px;
            
                  .choosePortabilityText {
                    flex-direction: column;
                    display: flex;
                    justify-content: center;
                    flex: 1;
                    color: var(--textColor);
            
                    h3 {
                      font-size: 14px;
                      font-weight: 500;
                    }
        
                    p {
                      font-size: 10px;
                      font-weight: 400;
                      opacity: 0.75;
                      white-space: normal;
                    }
                  }
            
                  .choosePortabilityDetail {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: var(--focusColor);
                    border-radius: 27px;
                    padding: 0 14px;
                    height: 28px;
            
                    p {
                      font-size: 12px;
                      font-weight: 600;
                      color: var(--primaryColor);
                    }
            
                    span {
                      display: none;
                    }
                  }
                }
              }
            }
          }
        }
        
        /* SECOND SUMMARY */
        .content-right-second-summary {
          display: none;
          margin: 5px 0 -10px 0;
  
          .second-summary {
            .titleArea {
              display: flex;
              flex-wrap: nowrap;
              justify-content: flex-start;
              align-items: center;
              gap: 15px;
  
              .iconsArea {
                display: flex;
                align-items: center;
              }
  
              .textArea {
                color: var(--textColor);
  
                h3 {
                  font-size: 14px;
                  font-weight: 500;
                }
      
                p {
                  font-size: 12px;
                  font-weight: 400;
                  opacity: 0.75;
                }
              }
            }
          }
        }
        
        /* CHOSEN PLAN CHIP */
        .content-right-chosen-plan-chip {
          display: none;
  
          .chosen-plan-chip {
            display: flex;
            flex-direction: column;
            margin-bottom: 10px;
            gap: 25px;
  
            .chosenPlanChip {
              display: flex;
              flex-wrap: nowrap;
              justify-content: space-between;
              align-items: center;
              gap: 15px;
              color: var(--textColor);
              
              .textArea {
  
                h3 {
                  font-size: 14px;
                  font-weight: 500;
                }
  
                p {            
                  font-size: 12px;
                  font-weight: 400;
                  opacity: 0.75;
                }
              }
  
              .priceArea {
                h3 {
                  font-size: 14px;
                  font-weight: 500;
                }
              }
  
              .chip {
                display: flex;
                flex-wrap: nowrap;
                justify-content: space-between;
                align-items: center;
              }
            }
          }
        }
  
        /* PURCHASE SUMMARY */
        .content-right-purchase-summary {
          border-top: solid 1px #FFFFFF;
          width: 100vw;
          margin-left: -25px;
  
          .purchase-summary {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 3vh 20px;
  
            .priceArea {
              color: var(--textColor);  
  
              h3 {
                font-size: 20px;
                font-weight: 500;
              }
  
              p {
                font-size: 12px;
                font-weight: 400;
              }
            }
  
            .buttonArea {
              .MuiCircularProgress-root svg circle {
                stroke: var(--textColor);
              }
  
              button {
                padding: 13px 20px;
                font-size: 12px;
                font-weight: 600;
                border-radius: 100px;
                background-color: var(--uncomplicatedButtonColor);
                color: var(--uncomplicatedButtonText);
                gap: 8px;
        
                svg {
                  width: 16px;
  
                  path {
                    stroke: var(--uncomplicatedButtonIcon);
                  }
                }
              }
            }
          }
        }
      }
    }
    
    .footer {
      display: none;
      height: 32vh;
      background-color: blue;
      padding: 60px 20px;
    }
    
    /******************** MEDIA QUERY 900 PIXELS ********************/
    
    @media (min-width: 900px) {
      display: flex;
      align-items: center;
      height: 100vh;
      
      .content {
        display: flex;
        flex-wrap: nowrap;
        height: 100vh;
  
        .container-left {
          display: flex;
          width: 170%;
          padding: 1.75rem 2.75rem;
        }
    
        .container-right {
          display: flex;
          padding: 0;
          background-color: var(--page2RightBackDesk);
          position: unset;
          bottom: unset;
          padding: 1.75rem 2.5rem;
          overflow: hidden;
          z-index: unset;
        }
    
        .left {
          display: flex;
          flex-direction: column;
          gap: 15px;
        
          /* HEADING */
          .content-left-heading {
            align-self: flex-start;
            width: auto;
      
            .heading {
              justify-content: unset;
      
              img {
                width: 120px;
              }
            }
          }
  
          /* PLAN CONFIG TEXT */
          .content-left-plan-config-text {
            padding-left: 14%;
            margin-bottom: -5px;
  
            .plan-config-text {
              gap: 8px;
              
              .iconsArea svg {
                width: 14px;
              }
            
              h3 {
                font-size: 9px;
              }
        
              p {
                font-size: 8px;
              }
            }
          }
  
          /* STEPPER PLAN */
          .content-left-stepper-plan {
            justify-content: center;
            width: 100%;
            overflow: hidden;
    
            .stepper-plan {
              width: 85%;
  
              .MuiStepLabel-root .MuiStepLabel-iconContainer .QontoStepIcon-completedIcon {
                width: 10px;
                height: 10px;
              }
              
              .MuiStepLabel-root .MuiStepLabel-iconContainer .QontoStepIcon-circle {
                width: 15px;
                height: 15px;
              }
              
              .Mui-active .QontoStepIcon-circle label {
                font-size: 7px;
              }
    
              .Mui-active .QontoStepIcon-circle::after {
                width: 33%;
                height: 4px;
                left: 52%;
                transform: translateY(0px);
              }
              
              .Mui-disabled .Mui-disabled .QontoStepIcon-circle label {
                font-size: 7px;
              }
              
              .MuiStepConnector-root.MuiStepConnector-horizontal.MuiStepConnector-alternativeLabel {
                top: 6px;
                left: calc(-50% + 6px);
                right: calc(50% + 6px);
              
                .MuiStepConnector-line.MuiStepConnector-lineHorizontal {
                  border-top-width: 4px;
                }
              }
              
              .css-1vyamtt-MuiStepLabel-labelContainer {
                .css-1hv8oq8-MuiStepLabel-label.MuiStepLabel-alternativeLabel {
                  font-size: 7px;
                }
  
                .css-1hv8oq8-MuiStepLabel-label.MuiStepLabel-alternativeLabel.Mui-disabled {
                  display: block;
                  color: var(--textColor);
                }
  
                .css-1hv8oq8-MuiStepLabel-label.MuiStepLabel-alternativeLabel.Mui-completed {
                  display: block;
                }
              }
            }
          }
  
          /* STEPS */
          .content-left-steps {
            .steps {
              .step {
                gap: 15px;
    
                .textArea {
                  width: 100%;
                }
  
                .choose-plan-text {
                  padding-left: 15%;
  
                  h3 {
                    font-size: 9px;
                  }
            
                  p {
                    font-size: 8px;
                  }
                }
  
                .form {
                  padding: 0 15%;
                  gap: 5px;
  
                  .choose-plan-text {
                    padding-left: 0;
  
                    h3 {
                      font-size: 8px;
                    }
              
                    p {
                      font-size: 7px;
                    }
                  }
  
                  .formArea {
                    display: flex;
                    flex-direction: column;
                    gap: 8px;
  
                    .fractArea {
                      display: flex;
                      width: 100%;
                      gap: 8px;
                    }
  
                    .MuiFormControl-root .MuiInputLabel-root.MuiInputLabel-sizeSmall {
                      font-size: 10px;
                    }
  
                    .MuiFormControl-root .MuiInputBase-sizeSmall input.MuiInputBase-inputSizeSmall {
                      font-size: 10px;
                    }
  
                    .MuiFormLabel-root.MuiInputLabel-shrink.MuiInputLabel-sizeSmall.MuiInputLabel-outlined {
                      transform: translate(13px, -4px) scale(0.75);
                    }
  
                    .MuiInputBase-root.MuiInputBase-sizeSmall span {
                      font-size: 7px;
                    }
  
                    .MuiFormHelperText-root.MuiFormHelperText-sizeSmall {
                      font-size: 8px;
                      margin-top: 2px;
                    }
  
                    .MuiButtonBase-root.MuiAutocomplete-clearIndicator svg {
                      font-size: 0.8rem;
                    }
  
                    .MuiButtonBase-root.MuiAutocomplete-popupIndicator {
                      padding-top: 3px;
  
                      svg {
                        font-size: 1.3rem;
                      }
                    }
  
                    .MuiButtonBase-root.clearButton svg {
                      font-size: 0.8rem;
                    }
  
                    .MuiAutocomplete-popper .MuiAutocomplete-option {
                      font-size: 10px;
                    }
                  }
                }
              }
  
              .step-1 {
                /* BONUS TEXT */
                .bonus-text {
                  margin-bottom: -5px;
                }
                
                /* SELECT PORTABILITY */
                .select-portability {
                  padding: 0 15%;
  
                  .choosePortability {
                    .choosePortabilitySwitch {
                      .MuiSwitch-root {
                        width: 24px;
                        height: 14px;
          
                        .MuiSwitch-thumb {
                          width: 8px;
                          height: 8px;
                          margin-left: 2px;
                          margin-top: 2px;
                        }
          
                        .MuiSwitch-switchBase.Mui-checked {
                          transform: translate(8px);
                        }
                      }
                    }
              
                    .choosePortabilityText {
                      margin: 0 10px;
              
                      h3 {
                        font-size: 8px;
                      }
              
                      p {
                        font-size: 7px;
                      }
                    }
              
                    .choosePortabilityDetail {
                      padding: 3px 8px;
                      width: unset;
                      height: unset;
              
                      p {
                        display: flex;
                        gap: 3px;
                        font-size: 8px;
                      }
              
                      span {
                        display: block;
                      }
                    }
                  }
                }
    
                /* SELECT SMART CONTROL */
                .select-smart-control {
                  padding: 0 15%;
  
                  .choosePortability {
                    .choosePortabilitySwitch {
                      .MuiSwitch-root {
                        width: 24px;
                        height: 14px;
          
                        .MuiSwitch-thumb {
                          width: 8px;
                          height: 8px;
                          margin-left: 2px;
                          margin-top: 2px;
                        }
          
                        .MuiSwitch-switchBase.Mui-checked {
                          transform: translate(8px);
                        }
                      }
                    }
              
                    .choosePortabilityText {
                      margin: 0 10px;
              
                      h3 {
                        font-size: 8px;
                      }
              
                      p {
                        font-size: 7px;
                      }
                    }
              
                    .choosePortabilityDetail {
                      padding: 3px 8px;
                      width: unset;
                      height: unset;
              
                      p {
                        display: flex;
                        gap: 3px;
                        font-size: 8px;
                      }
              
                      span {
                        display: block;
                      }
                    }
                  }
                }
    
                /* ACCESS TECHNOLOGY TEXT */
                .access-technology-text {
                  margin: 15px 0 -5px 0;
                }
  
                /* SELECT PICK UP CHIP */
                .chip-only-pick-up {
                  padding: 0 15% 30px;
                  gap: 10px;

                  .pickUpChoose {
                    .pickUpChoice {
                      .checkArea {
                        display: flex;
                        
                        .MuiButtonBase-root.MuiCheckbox-root {
                          padding: 0 8px;
                        }
    
                        .MuiButtonBase-root.MuiCheckbox-root svg {
                          width: 12px;
                          height: 12px;
                        }
    
                        .textAreaChipInfo {
                          h3 {
                            font-size: 8px;
                          }
      
                          p {
                            font-size: 7px;
                          }
                        }
                      }
            
                      .priceAreaChipInfo {
                        h3 {
                          font-size: 9px;
                        }
                      }
                    }
                  }

                  hr {
                    margin-top: -10px;
                  }
                }
    
                /* SELECT CHIP INFO */
                .select-chip-info {
                  padding: 0 15% 30px;
                  gap: 10px;
    
                  .chip, .pickUpChoice {
                    .checkArea {
                      display: flex;
                      
                      .MuiButtonBase-root.MuiCheckbox-root {
                        padding: 0 8px;
                      }
  
                      .MuiButtonBase-root.MuiCheckbox-root svg {
                        width: 12px;
                        height: 12px;
                      }
  
                      .textAreaChipInfo {
                        h3 {
                          font-size: 8px;
                        }
    
                        p {
                          font-size: 7px;
                        }
                      }
                    }
          
                    .priceAreaChipInfo {
                      h3 {
                        font-size: 9px;
                      }
                    }
                  }

                  hr {
                    margin-top: -10px;
                  }
                }
              }
  
              .step-4 .step-5 {
                .payment-step {
                  padding: 10px 15% 0 15%;
                  gap: 20px;
                  
                  .textArea {
                    gap: 5px;
    
                    h3 {
                      font-size: 15px;
                    }
    
                    h4 {
                      font-size: 11px;
                      padding: 0 0 5px 0;
                    }
    
                    .configBonusText {
                      padding: 5px 25px 0 25px;
                      gap: 5px;
                      font-size: 11px;
    
                      ul {
                        li {
                          font-size: 9px;
                        }  
                      }
                    }
                  }
    
                  .buttonArea {
                    button {
                      padding: 10px 18px;
                      font-size: 9px;
                    }
                  }
                }
              }
            }
          }
        }
        
        .right {
          /* Theme */
          --textColor: #2F2F2F;
          --uncomplicatedButtonColor: var(--primaryColor);
          --uncomplicatedButtonText: var(--secondaryColor);
          --uncomplicatedButtonIcon: var(--secondaryColor);
  
          background-color: var(--page2RightBackDesk);
          position: relative;
          gap: 25px;
  
          /* ORDER SUMMARY TEXT */
          .content-right-order-summary-text {
            display: none;
          }
        
          /* FIRST SUMMARY */
          .content-right-first-summary {
            margin: 0;
  
            .first-summary {
              gap: 10px;
  
              .titleArea {
                gap: 7px;
  
                .iconsArea {
                  svg {
                    width: 18px;
  
                    rect {
                      stroke: var(--primaryColor)
                    }
  
                    path {
                      stroke: var(--primaryColor)
                    }
                  }
                }
  
                .textArea {
                  color: var(--primaryColor);
  
                  h3 {
                    font-size: 9px;
                  }
        
                  p {
                    font-size: 7px;
                    opacity: 0.5;
                  }
                }
              }
  
              .ItemsArea {
                display: flex;
                flex-direction: column;
                gap: 10px;
                padding-left: 10%;
  
                .item {
                  width: 100%;
  
                  .choosePortability {
                    .choosePortabilityText {
                      h3 {
                        font-size: 8px;
                      }
          
                      p {
                        font-size: 7px;
                      }
                    }
              
                    .choosePortabilityDetail {
                      background-color: var(--primaryColor);
                      padding: 3px 6px;
                      width: unset;
                      height: unset;
              
                      p {
                        font-size: 7px;
                        color: var(--secondaryColor);
                      }
                    }
                  }
                }
              }
            }
          }
        
          /* SECOND SUMMARY */
          .content-right-second-summary {
            display: flex !important;
            margin: 0 0 -15px 0;
  
            .second-summary {
              gap: 10px;
  
              .titleArea {
                gap: 7px;
  
                .iconsArea {
                  svg {
                    width: 18px;
  
                    rect {
                      stroke: var(--primaryColor);
                    }
  
                    path {
                      fill: var(--primaryColor);
                    }
                  }
                }
  
                .textArea {
                  color: var(--primaryColor);
  
                  h3 {
                    font-size: 9px;
                  }
        
                  p {
                    font-size: 7px;
                    opacity: 0.5;
                  }
                }
              }
            }
          }
        
          /* CHOSEN PLAN CHIP */
          .content-right-chosen-plan-chip {
            display: flex !important;
            padding-left: 10%;
  
            .chosen-plan-chip {
              margin-bottom: 0;
              gap: 10px;
  
              .chosenPlanChip {              
                .textArea {
                  h3 {
                    font-size: 8px;
                  }
  
                  p {            
                    font-size: 7px;
                  }
                }
  
                .priceArea {
                  h3 {
                    font-size: 9px;
                  }
                }
              }
            }
          }
  
          /* PURCHASE SUMMARY */
          .content-right-purchase-summary {
            border-top: solid 1px var(--primaryColor);
            width: 100%;
            margin-left: 0;
            position: absolute;
            bottom: 0;
  
            .purchase-summary {
              padding: 7vh 0 0 0;
  
              .priceArea {
                h3 {
                  font-size: 11px;
                }
  
                p {
                  font-size: 8px;
                }
              }
  
              .buttonArea {
                button {
                  padding: 7px 10px;
                  font-size: 6px;
                  background-color: var(--uncomplicatedButtonColor);
                  color: var(--uncomplicatedButtonText);
                  gap: 5px;
          
                  svg {
                    width: 10px;
                    height: 10px;
  
                    path {
                      stroke: var(--uncomplicatedButtonIcon);
                    }
                  }
                }
  
                .MuiCircularProgress-root svg circle {
                  stroke: var(--primaryColor);
                }
              }
            }
          }
        }
      }
    
      .footer {
        display: none;
      }
    }
  
    
    /******************** MEDIA QUERY 1200 PIXELS ********************/
  
    @media (min-width: 1200px) {
      .content {
        .container-left {
          padding: 2.5rem 3.5rem;
        }
  
        .container-right {
          padding: 2.75rem 3.25rem;
        }
  
        .left {
          gap: 20px;
  
          /* HEADING */
          .content-left-heading {
            .heading {
              img {
                width: 165px;
              }
            }
          }
  
          /* PLAN CONFIG TEXT */
          .content-left-plan-config-text {
            .plan-config-text {
              .iconsArea svg {
                width: 20px;
              }
            
              h3 {
                font-size: 11px;
              }
        
              p {
                font-size: 10px;
              }
            }
          }
  
          /* STEPPER PLAN */
          .content-left-stepper-plan {
            .stepper-plan {
              .MuiStepLabel-root .MuiStepLabel-iconContainer .QontoStepIcon-completedIcon {
                width: 14px;
                height: 14px;
              }
              
              .MuiStepLabel-root .MuiStepLabel-iconContainer .QontoStepIcon-circle {
                width: 21px;
                height: 21px;
              }
              
              .Mui-active .QontoStepIcon-circle label {
                font-size: 9px;
              }
    
              .Mui-active .QontoStepIcon-circle::after {
                width: 33%;
                height: 5px;
                left: 52%;
                transform: translateY(1px);
              }
              
              .Mui-disabled .Mui-disabled .QontoStepIcon-circle label {
                font-size: 9px;
              }
              
              .MuiStepConnector-root.MuiStepConnector-horizontal.MuiStepConnector-alternativeLabel {
                top: 9px;
                left: calc(-50% + 6px);
                right: calc(50% + 6px);
              
                .MuiStepConnector-line.MuiStepConnector-lineHorizontal {
                  border-top-width: 5px;
                }
              }
              
              .css-1vyamtt-MuiStepLabel-labelContainer {
                .css-1hv8oq8-MuiStepLabel-label.MuiStepLabel-alternativeLabel {
                  font-size: 10px;
                }
              }
            }
          }
  
          /* STEPS */
          .content-left-steps {
            .steps {
              .step {
                gap: 15px;
  
                .choose-plan-text {
                  h3 {
                    font-size: 11px;
                  }
            
                  p {
                    font-size: 10px;
                  }
                }
  
                .form {
                  gap: 10px;
  
                  .choose-plan-text {
                    h3 {
                      font-size: 10px;
                    }
              
                    p {
                      font-size: 9px;
                    }
                  }
  
                  .formArea {
                    gap: 12px;
  
                    .fractArea {
                      gap: 12px;
                    }
  
                    .MuiFormControl-root .MuiInputLabel-root.MuiInputLabel-sizeSmall {
                      font-size: 13px;
                    }
  
                    .MuiFormControl-root .MuiInputBase-sizeSmall input.MuiInputBase-inputSizeSmall {
                      font-size: 13px;
                    }
  
                    .MuiFormLabel-root.MuiInputLabel-shrink.MuiInputLabel-sizeSmall.MuiInputLabel-outlined {
                      transform: translate(14px, -5px) scale(0.75);
                    }
  
                    .MuiInputBase-root.MuiInputBase-sizeSmall span {
                      font-size: 10px;
                    }
  
                    .MuiFormHelperText-root.MuiFormHelperText-sizeSmall {
                      font-size: 10px;
                    }
  
                    .MuiButtonBase-root.MuiAutocomplete-clearIndicator svg {
                      font-size: 0.9rem;
                    }
  
                    .MuiButtonBase-root.MuiAutocomplete-popupIndicator {
                      padding-top: 2px;
  
                      svg {
                        font-size: 1.4rem;
                      }
                    }
  
                    .MuiButtonBase-root.clearButton svg {
                      font-size: 0.9rem;
                    }
  
                    .MuiAutocomplete-popper .MuiAutocomplete-option {
                      font-size: 12px;
                    }
                  }
                }
              }
  
              .step-1 {
                /* SELECT PORTABILITY */
                .select-portability {
                  .choosePortability {
                    .choosePortabilitySwitch {
                      .MuiSwitch-root {
                        width: 29px;
                        height: 17px;
          
                        .MuiSwitch-thumb {
                          width: 9px;
                          height: 9px;
                          margin-left: 3px;
                          margin-top: 3px;
                        }
          
                        .MuiSwitch-switchBase.Mui-checked {
                          transform: translate(10px);
                        }
                      }
                    }
              
                    .choosePortabilityText {
                      margin: 0 12px;
              
                      h3 {
                        font-size: 10px;
                      }
              
                      p {
                        font-size: 9px;
                      }
                    }
              
                    .choosePortabilityDetail {
                      padding: 4px 9px;
              
                      p {
                        font-size: 9px;
                      }
                    }
                  }
                }
    
                /* SELECT SMART CONTROL */
                .select-smart-control {
                  .choosePortability {
                    .choosePortabilitySwitch {
                      .MuiSwitch-root {
                        width: 29px;
                        height: 17px;
          
                        .MuiSwitch-thumb {
                          width: 9px;
                          height: 9px;
                          margin-left: 3px;
                          margin-top: 3px;
                        }
          
                        .MuiSwitch-switchBase.Mui-checked {
                          transform: translate(10px);
                        }
                      }
                    }
              
                    .choosePortabilityText {
                      margin: 0 12px;
              
                      h3 {
                        font-size: 10px;
                      }
              
                      p {
                        font-size: 9px;
                      }
                    }
              
                    .choosePortabilityDetail {
                      padding: 4px 9px;
              
                      p {
                        font-size: 9px;
                      }
                    }
                  }
                }
  
                /* SELECT PICK UP CHIP */
                .chip-only-pick-up {
                  .pickUpChoose .pickUpChoice {
                    .checkArea {
                      .MuiButtonBase-root.MuiCheckbox-root {
                        padding: 0 11px;
                      }
  
                      .MuiButtonBase-root.MuiCheckbox-root svg {
                        width: 16px;
                        height: 16px;
                      }
  
                      .textAreaChipInfo {
                        h3 {
                          font-size: 10px;
                        }
    
                        p {
                          font-size: 9px;
                        }
                      }
                    }
          
                    .priceAreaChipInfo {
                      h3 {
                        font-size: 11px;
                      }
                    }
                  }
                }
    
                /* SELECT CHIP INFO */
                .select-chip-info {
                  .chip, .pickUpChoice {
                    .checkArea {
                      .MuiButtonBase-root.MuiCheckbox-root {
                        padding: 0 11px;
                      }
  
                      .MuiButtonBase-root.MuiCheckbox-root svg {
                        width: 16px;
                        height: 16px;
                      }
  
                      .textAreaChipInfo {
                        h3 {
                          font-size: 10px;
                        }
    
                        p {
                          font-size: 9px;
                        }
                      }
                    }
          
                    .priceAreaChipInfo {
                      h3 {
                        font-size: 11px;
                      }
                    }
                  }
                }
              }
  
              .step-4 .step-5 {
                .payment-step {
                  padding: 15px 15% 0 15%;
                  gap: 30px;
                  
                  .textArea {
                    gap: 10px;
                    
                    h3 {
                      font-size: 19px;
                    }
                    
                    h4 {
                      font-size: 14px;
                    }
    
                    .configBonusText {
                      padding: 10px 25px 5px 25px;
                      gap: 10px;
                      font-size: 15px;
    
                      ul {
                        li {
                          font-size: 11px;
                        }  
                      }
                    }
                  }
    
                  .buttonArea {
                    button {
                      padding: 11px 18px;
                      font-size: 10px;
                    }
                  }
                }
              }
            }
          }
        }
  
        .right {
          gap: 35px;
  
          /* FIRST SUMMARY */
          .content-right-first-summary {
            .first-summary {
              gap: 15px;
  
              .titleArea {
                gap: 12px;
  
                .iconsArea {
                  svg {
                    width: 24px;
                  }
                }
  
                .textArea {
                  h3 {
                    font-size: 11px;
                  }
        
                  p {
                    font-size: 10px;
                  }
                }
              }
  
              .ItemsArea {
                gap: 15px;
                padding-left: 10%;
  
                .item {
                  .choosePortability {
                    .choosePortabilityText {
                      h3 {
                        font-size: 10px;
                      }
          
                      p {
                        font-size: 9px;
                      }
                    }
              
                    .choosePortabilityDetail {
                      padding: 3px 9px;
              
                      p {
                        font-size: 10px;
                      }
                    }
                  }
                }
              }
            }
          }
        
          /* SECOND SUMMARY */
          .content-right-second-summary {
            margin: 0 0 -20px 0;
  
            .second-summary {
              gap: 10px;
  
              .titleArea {
                gap: 12px;
  
                .iconsArea {
                  svg {
                    width: 24px;
                  }
                }
  
                .textArea {
                  h3 {
                    font-size: 11px;
                  }
        
                  p {
                    font-size: 10px;
                  }
                }
              }
            }
          }
        
          /* CHOSEN PLAN CHIP */
          .content-right-chosen-plan-chip {
            .chosen-plan-chip {
              gap: 15px;
  
              .chosenPlanChip {
                .textArea {
                  h3 {
                    font-size: 10px;
                  }
  
                  p {            
                    font-size: 9px;
                  }
                }
  
                .priceArea {
                  h3 {
                    font-size: 11px;
                  }
                }
              }
            }
          }
  
          /* PURCHASE SUMMARY */
          .content-right-purchase-summary {
            .purchase-summary {
              padding: 8vh 0 0 0;
  
              .priceArea {
                h3 {
                  font-size: 15px;
                }
  
                p {
                  font-size: 12px;
                }
              }
  
              .buttonArea {
                button {
                  padding: 10px 18px;
                  font-size: 10px;
          
                  svg {
                    width: 14px;
                    height: 14px;
                  }
                }
              }
            }
          }
        }
      }
    }
    
  
    /******************** MEDIA QUERY 1440 PIXELS ********************/
    
    @media (min-width: 1440px) {
      .content {
        .container-left {
          padding: 3.5rem 4rem;
        }
  
        .container-right {
          padding: 3.5rem 3rem;
        }
    
        .left {
          /* HEADING */
          .content-left-heading {
            .heading {
              img {
                width: 170px;
              }
            }
          }
  
          /* PLAN CONFIG TEXT */
          .content-left-plan-config-text {
            .plan-config-text {
              .iconsArea svg {
                width: 23px;
              }
            
              h3 {
                font-size: 12px;
              }
        
              p {
                font-size: 11px;
              }
            }
          }
  
          /* STEPPER PLAN */
          .content-left-stepper-plan {
            .stepper-plan {
              .MuiStepLabel-root .MuiStepLabel-iconContainer .QontoStepIcon-completedIcon {
                width: 15px;
                height: 15px;
              }
              
              .MuiStepLabel-root .MuiStepLabel-iconContainer .QontoStepIcon-circle {
                width: 21px;
                height: 21px;
              }
              
              .Mui-active .QontoStepIcon-circle label {
                font-size: 10px;
              }
              
              .Mui-disabled .Mui-disabled .QontoStepIcon-circle label {
                font-size: 10px;
              }
              
              .css-1vyamtt-MuiStepLabel-labelContainer {
                .css-1hv8oq8-MuiStepLabel-label.MuiStepLabel-alternativeLabel {
                  font-size: 11px;
                }
              }
            }
          }
  
          /* STEPS */
          .content-left-steps {
            .steps {
              .step {
                margin-top: 10px;
                gap: 20px;
  
                .choose-plan-text {
                  h3 {
                    font-size: 12px;
                  }
            
                  p {
                    font-size: 11px;
                  }
                }
  
                .form {
                  gap: 15px;
  
                  .choose-plan-text {
                    h3 {
                      font-size: 11px;
                    }
              
                    p {
                      font-size: 10px;
                    }
                  }
  
                  .formArea {
                    gap: 14px;
  
                    .fractArea {
                      gap: 14px;
                    }
  
                    .MuiFormControl-root .MuiInputLabel-root.MuiInputLabel-sizeSmall {
                      font-size: 15px;
                    }
  
                    .MuiFormControl-root .MuiInputBase-sizeSmall input.MuiInputBase-inputSizeSmall {
                      font-size: 15px;
                    }
  
                    .MuiFormLabel-root.MuiInputLabel-shrink.MuiInputLabel-sizeSmall.MuiInputLabel-outlined {
                      transform: translate(14px, -8px) scale(0.75);
                    }
  
                    .MuiInputBase-root.MuiInputBase-sizeSmall span {
                      font-size: 11px;
                    }
  
                    .MuiFormHelperText-root.MuiFormHelperText-sizeSmall {
                      font-size: 11px;
                    }
  
                    .MuiButtonBase-root.MuiAutocomplete-clearIndicator svg {
                      font-size: 1.1rem;
                    }
  
                    .MuiButtonBase-root.MuiAutocomplete-popupIndicator {
                      padding-top: 2px;
  
                      svg {
                        font-size: 1.5rem;
                      }
                    }
  
                    .MuiButtonBase-root.clearButton svg {
                      font-size: 1.1rem;
                    }
  
                    .MuiAutocomplete-popper .MuiAutocomplete-option {
                      font-size: 12px;
                    }
                  }
                }
              }
  
              .step-1 {
                /* SELECT PORTABILITY */
                .select-portability {
                  .choosePortability {
                    .choosePortabilityText {
                      h3 {
                        font-size: 11px;
                      }
              
                      p {
                        font-size: 10px;
                      }
                    }
              
                    .choosePortabilityDetail {
                      p {
                        font-size: 10px;
                      }
                    }
                  }
                }
    
                /* SELECT SMART CONTROL */
                .select-smart-control {
                  .choosePortability {
                    .choosePortabilityText {
                      h3 {
                        font-size: 11px;
                      }
              
                      p {
                        font-size: 10px;
                      }
                    }
              
                    .choosePortabilityDetail {
                      p {
                        font-size: 10px;
                      }
                    }
                  }
                }
  
                /* SELECT PICK UP CHIP */
                .chip-only-pick-up {
                  .pickUpChoose .pickUpChoice {
                    .checkArea {
                      .MuiButtonBase-root.MuiCheckbox-root {
                        padding: 0 10px;
                      }
  
                      .MuiButtonBase-root.MuiCheckbox-root svg {
                        width: 17px;
                        height: 17px;
                      }
  
                      .textAreaChipInfo {
                        h3 {
                          font-size: 11px;
                        }
    
                        p {
                          font-size: 10px;
                        }
                      }
                    }
          
                    .priceAreaChipInfo {
                      h3 {
                        font-size: 12px;
                      }
                    }
                  }
                }
    
                /* SELECT CHIP INFO */
                .select-chip-info {
                  .chip, .pickUpChoice {
                    .checkArea {
                      .MuiButtonBase-root.MuiCheckbox-root {
                        padding: 0 10px;
                      }
  
                      .MuiButtonBase-root.MuiCheckbox-root svg {
                        width: 17px;
                        height: 17px;
                      }
  
                      .textAreaChipInfo {
                        h3 {
                          font-size: 11px;
                        }
    
                        p {
                          font-size: 10px;
                        }
                      }
                    }
          
                    .priceAreaChipInfo {
                      h3 {
                        font-size: 12px;
                      }
                    }
                  }
                }
              }
  
              .step-4 .step-5 {
                .payment-step {                
                  .textArea {
                    gap: 10px;
                    
                    h3 {
                      font-size: 20px;
                    }
    
                    .configBonusText {
                      ul {
                        li {
                          font-size: 12px;
                        }  
                      }
                    }
                  }
    
                  .buttonArea {
                    button {
                      font-size: 12px;
                    }
                  }
                }
              }
            }
          }
        }
        
        .right {
          gap: 40px;
  
          /* FIRST SUMMARY */
          .content-right-first-summary {
            .first-summary {
              gap: 25px;
  
              .titleArea {
                .iconsArea {
                  svg {
                    width: 26px;
                  }
                }
  
                .textArea {
                  h3 {
                    font-size: 12px;
                  }
        
                  p {
                    font-size: 11px;
                  }
                }
              }
  
              .ItemsArea {
                gap: 18px;
                padding-left: 9%;
  
                .item {
                  .choosePortability {
                    .choosePortabilityText {
                      h3 {
                        font-size: 11px;
                      }
          
                      p {
                        font-size: 10px;
                      }
                    }
              
                    .choosePortabilityDetail {
                      padding: 3px 10px;
              
                      p {
                        font-size: 11px;
                      }
                    }
                  }
                }
              }
            }
          }
        
          /* SECOND SUMMARY */
          .content-right-second-summary {
            margin: 0 0 -15px 0;
  
            .second-summary {
              .titleArea {
                .iconsArea {
                  svg {
                    width: 26px;
                  }
                }
  
                .textArea {
                  h3 {
                    font-size: 12px;
                  }
        
                  p {
                    font-size: 11px;
                  }
                }
              }
            }
          }
        
          /* CHOSEN PLAN CHIP */
          .content-right-chosen-plan-chip {
            padding-left: 9%;
  
            .chosen-plan-chip {
              gap: 18px;
  
              .chosenPlanChip {
                .textArea {
                  h3 {
                    font-size: 11px;
                  }
  
                  p {
                    font-size: 10px;
                  }
                }
  
                .priceArea {
                  h3 {
                    font-size: 12px;
                  }
                }
              }
            }
          }
  
          /* PURCHASE SUMMARY */
          .content-right-purchase-summary {
            .purchase-summary {
              padding: 7.5vh 0 0 0;
  
              .priceArea {
                h3 {
                  font-size: 18px;
                }
  
                p {
                  font-size: 14px;
                }
              }
  
              .buttonArea {
                button {
                  padding: 11px 20px;
                  font-size: 12px;
                  gap: 6px;
          
                  svg {
                    width: 16px;
                    height: 16px;
                  }
                }
              }
            }
          }
        }
      }
    }
    
    
    /******************** MEDIA QUERY 1860 PIXELS ********************/
    
    @media (min-width: 1860px) {
      .content {
        .container-left {
          padding: 5rem 5.75rem;
        }
  
        .container-right {
          padding: 5rem;
        }
    
        .left {
          gap: 30px;
  
          /* HEADING */
          .content-left-heading {
            .heading {
              img {
                width: 220px;
              }
            }
          }
  
          /* PLAN CONFIG TEXT */
          .content-left-plan-config-text {
            margin-top: 20px;
            
            .plan-config-text {
              .iconsArea svg {
                width: auto;
              }
            
              h3 {
                font-size: 15px;
              }
        
              p {
                font-size: 13px;
              }
            }
          }
  
          /* STEPPER PLAN */
          .content-left-stepper-plan {
            .stepper-plan {
              .MuiStepLabel-root .MuiStepLabel-iconContainer .QontoStepIcon-completedIcon {
                width: 19px;
                height: 19px;
              }
              
              .MuiStepLabel-root .MuiStepLabel-iconContainer .QontoStepIcon-circle {
                width: 29px;
                height: 29px;
              }
              
              .Mui-active .QontoStepIcon-circle label {
                font-size: 12px;
              }
    
              .Mui-active .QontoStepIcon-circle::after {
                height: 6px;
                transform: translateY(1px);
              }
              
              .Mui-disabled .Mui-disabled .QontoStepIcon-circle label {
                font-size: 12px;
              }
              
              .MuiStepConnector-root.MuiStepConnector-horizontal.MuiStepConnector-alternativeLabel {
                top: 12px;
              
                .MuiStepConnector-line.MuiStepConnector-lineHorizontal {
                  border-top-width: 6px;
                }
              }
              
              .css-1vyamtt-MuiStepLabel-labelContainer {
                .css-1hv8oq8-MuiStepLabel-label.MuiStepLabel-alternativeLabel {
                  margin-top: 10px;
                  font-size: 13px;
                }
              }
            }
          }
  
          /* STEPS */
          .content-left-steps {
            .steps {
              .step {
                margin-top: 15px;
                gap: 30px;
  
                .choose-plan-text {
                  h3 {
                    font-size: 14px;
                  }
            
                  p {
                    font-size: 12px;
                  }
                }
  
                .form {
                  gap: 25px;
  
                  .choose-plan-text {
                    h3 {
                      font-size: 14px;
                    }
              
                    p {
                      font-size: 12px;
                    }
                  }
  
                  .formArea {
                    gap: 18px;
  
                    .fractArea {
                      gap: 18px;
                    }
  
                    .MuiButtonBase-root.MuiAutocomplete-clearIndicator svg {
                      font-size: 1.3rem;
                    }
  
                    .MuiButtonBase-root.MuiAutocomplete-popupIndicator {
                      padding-top: 0;
  
                      svg {
                        font-size: 1.7rem;
                      }
                    }
  
                    .MuiButtonBase-root.clearButton svg {
                      font-size: 1.3rem;
                    }
  
                    .MuiAutocomplete-popper .MuiAutocomplete-option {
                      font-size: 16px;
                    }
                  }
                }
              }
  
              .step-1 {
                /* SELECT PORTABILITY */
                .select-portability {
                  .choosePortability {
                    .choosePortabilitySwitch {
                      .MuiSwitch-root {
                        width: 38px;
                        height: 22px;
          
                        .MuiSwitch-thumb {
                          width: 13px;
                          height: 13px;
                          margin-left: 4px;
                          margin-top: 3px;
                        }
          
                        .MuiSwitch-switchBase.Mui-checked {
                          transform: translate(13px);
                        }
                      }
                    }
  
                    .choosePortabilityText {
                      h3 {
                        font-size: 14px;
                      }
              
                      p {
                        font-size: 12px;
                      }
                    }
              
                    .choosePortabilityDetail {
                      p {
                        font-size: 12px;
                      }
                    }
                  }
                }
    
                /* SELECT SMART CONTROL */
                .select-smart-control {
                  .choosePortability {
                    .choosePortabilitySwitch {
                      .MuiSwitch-root {
                        width: 38px;
                        height: 22px;
          
                        .MuiSwitch-thumb {
                          width: 13px;
                          height: 13px;
                          margin-left: 4px;
                          margin-top: 3px;
                        }
          
                        .MuiSwitch-switchBase.Mui-checked {
                          transform: translate(13px);
                        }
                      }
                    }
  
                    .choosePortabilityText {
                      h3 {
                        font-size: 14px;
                      }
              
                      p {
                        font-size: 12px;
                      }
                    }
              
                    .choosePortabilityDetail {
                      p {
                        font-size: 12px;
                      }
                    }
                  }
                }
  
                /* SELECT PICK UP CHIP */
                .chip-only-pick-up {
                  .pickUpChoose .pickUpChoice {
                    .checkArea {
                      .MuiButtonBase-root.MuiCheckbox-root {
                        padding: 0 12px;
                      }
  
                      .MuiButtonBase-root.MuiCheckbox-root svg {
                        width: 22px;
                        height: 22px;
                      }
  
                      .textAreaChipInfo {
                        h3 {
                          font-size: 14px;
                        }
    
                        p {
                          font-size: 12px;
                        }
                      }
                    }
          
                    .priceAreaChipInfo {
                      h3 {
                        font-size: 15px;
                      }
                    }
                  }
                }
    
                /* SELECT CHIP INFO */
                .select-chip-info {
                  .chip, .pickUpChoice {
                    .checkArea {
                      .MuiButtonBase-root.MuiCheckbox-root {
                        padding: 0 12px;
                      }
  
                      .MuiButtonBase-root.MuiCheckbox-root svg {
                        width: 22px;
                        height: 22px;
                      }
  
                      .textAreaChipInfo {
                        h3 {
                          font-size: 14px;
                        }
    
                        p {
                          font-size: 12px;
                        }
                      }
                    }
          
                    .priceAreaChipInfo {
                      h3 {
                        font-size: 15px;
                      }
                    }
                  }
                }
              }
  
              .step-4 .step-5 {
                .payment-step {
                  .textArea {
                    gap: 10px;
                    
                    h3 {
                      font-size: 24px;
                    }
                    
                    h4 {
                      font-size: 16px;
                    }
    
                    .configBonusText {
                      font-size: 17px;
                      
                      ul {
                        li {
                          font-size: 14px;
                        }  
                      }
                    }
                  }
    
                  .buttonArea {
                    button {
                      font-size: 14px;
                    }
                  }
                }
              }
            }
          }
        }
        
        .right {
          /* FIRST SUMMARY */
          .content-right-first-summary {
            .first-summary {
              gap: 25px;
  
              .titleArea {
                .iconsArea {
                  svg {
                    width: 30px;
                  }
                }
  
                .textArea {
                  h3 {
                    font-size: 16px;
                  }
        
                  p {
                    font-size: 13px;
                  }
                }
              }
  
              .ItemsArea {
                padding-left: 8%;
  
                .item {
                  .choosePortability {
                    .choosePortabilityText {
                      h3 {
                        font-size: 14px;
                      }
          
                      p {
                        font-size: 12px;
                      }
                    }
              
                    .choosePortabilityDetail {
                      p {
                        font-size: 13px;
                      }
                    }
                  }
                }
              }
            }
          }
        
          /* SECOND SUMMARY */
          .content-right-second-summary {
            margin: 0 0 -15px 0;
  
            .second-summary {
              .titleArea {
                .iconsArea {
                  svg {
                    width: 30px;
                  }
                }
  
                .textArea {
                  h3 {
                    font-size: 16px;
                  }
        
                  p {
                    font-size: 13px;
                  }
                }
              }
            }
          }
        
          /* CHOSEN PLAN CHIP */
          .content-right-chosen-plan-chip {
            padding-left: 8%;
  
            .chosen-plan-chip {
              .chosenPlanChip {
                .textArea {
                  h3 {
                    font-size: 14px;
                  }
  
                  p {
                    font-size: 12px;
                  }
                }
  
                .priceArea {
                  h3 {
                    font-size: 15px;
                  }
                }
              }
            }
          }
  
          /* PURCHASE SUMMARY */
          .content-right-purchase-summary {
            .purchase-summary {
              padding: 7.5vh 0 0 0;
  
              .priceArea {
                h3 {
                  font-size: 18px;
                }
  
                p {
                  font-size: 14px;
                }
              }
  
              .buttonArea {
                button {
                  font-size: 16px;
          
                  svg {
                    width: 20px;
                    height: 20px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  /********** PAGE 3 **********/
  &.configure-sua-conta {
    background-color: var(--focusColor);
  
    .content {
      display: flex;
      height: 100vh;
  
      .container-left {
        display: flex;
        background-color: #FFFFFF;
        padding: 80px 30px 40px 30px;
      }
  
      .container-right {
        display: flex;
        padding: 50px 30px 40px 30px;
        background-color: var(--focusColor);
      }
  
      .left {
        --titleColor: #4E4E4E;
        --textColor: #5F5F5F;
  
        display: flex;
        gap: 25px;
        
        /* HEADING */
        .content-left-heading {
          width: 100vw;
    
          .heading {
            display: flex;
            justify-content: center;
            width: 100%;
    
            h3 {
              display: none;
            }
    
            img {
              width: 55%;
            }
          }
        }
        
        /* SLOGAN PAGE 3 */
        .content-left-slogan-pg-3 {
          margin-top: 20px;
  
          .slogan-pg-3 {
            max-width: 300px;
  
            .textArea {
              width: 100%;
  
              .skltn-slogan {
                width: 100%;
    
                .skltn-h3 {
                  font-size: 30px;
                  font-weight: 500;
  
                  &.skltn-1 {
                    width: 65%;
                  }
  
                  &.skltn-2 {
                    width: 85%;
                  }
                }
              }
    
              h3 {
                font-size: 30px;
                font-weight: 500;
                color: var(--titleColor);
      
                span {
                  color: var(--spanLabel);
                }
              }
            }
          }
        }
        
        /* DESCRIPTION */
        .content-left-description {
          .description {
            max-width: 260px;
  
            P {
              font-size: 14px;
              font-weight: 400;
              color: var(--textColor);
            }
          }
        }
        
        /* PLAN SUMMARY PAGE 3 */
        .content-left-plan-summary-pg-3 {
          margin-top: 15px;
          
          .plan-summary-pg-3 {
            gap: 10px;
  
            .chosenPlan {
              font-size: 20px;
              font-weight: 600;
              color: var(--primaryColor);
            }
  
            .planDetailsText {
              h3, .skltn-h3 {
                font-size: 12px;
                font-weight: 600;
                color: var(--titleColor);
              }
  
              p {
                font-size: 11px;
                font-weight: 400;
                color: var(--textColor);
              }
            }
  
            .planDetailsItems {
              margin-top: 10px;
              gap: 5px;
  
              .planDetailsItem {
                font-size: 9px;
                font-weight: 600;
                color: var(--primaryColor);
                background-color: var(--focusColor);
                border-radius: 21px;
                padding: 6px 10px;
              }
            }
  
            .skltn-1 {
              width: 31%;
            }
            
            .skltn-2 {
              width: 32%;
            }
            
            .skltn-3 {
              width: 16%;
            }
          }
        }
      }
  
      .right {
        /* Theme */
        --textColor: #FFFFFF;
  
        background-color: var(--focusColor);
        display: flex;
        flex-direction: column;
        gap: 25px;
  
        /* NEXT STEPS TEXT */
        .content-right-next-steps-text {
          h3 {
            font-size: 16px;
            font-weight: 500;
            color: var(--titleColor);
          }
          
          p {
            font-size: 13px;
            color: var(--titleColor);
          }
        }
        
        /* STEPPER CONFIG */
        .content-right-stepper-config {
          .stepper-config {
            gap: 15px;
  
            .step {
              justify-content: space-between;
              align-items: center;
              border-radius: 100px;
              padding: 20px 25px;
              cursor: pointer;
  
              .stepIcon {
                display: flex;
                align-items: center;
                justify-content: center;
  
                .iconTable {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  width: 30px;
                  height: 30px;
                  border-radius: 100%;
  
                  svg {
                    width: 16px;
                    height: 16px;
                  }
                }
              }
  
              .stepName {
                display: flex;
                flex-direction: column;
                justify-content: center;
                flex: 1 1;
                margin: 0 15px;
  
                h3 {
                  font-size: 12px;
                  font-weight: 500;
                }
  
                p {
                  font-size: 10px;
                }
              }
  
              .statusIcon {
                display: flex;
                align-items: center;
                justify-content: center;
  
                svg {
                  width: 20px;
                  height: 20px;
                }
              }
            }
            
            .step.step-1 {
              cursor: not-allowed;
            }
  
            .completed {
              background-color: var(--primaryColor);
              
              .stepIcon svg path {
                stroke: var(--primaryColor);
              }
  
              .iconTable {
                background-color: #FFF;
              }
  
              .stepName {
                h3 {
                  color: var(--textColor);
                }
  
                p {
                  color: var(--textColor);
                }
              }
  
              .statusIcon svg path {
                stroke: #FFF;
              }
            }
  
            .active, .disabled {
              border: 1px solid var(--primaryColor);
  
              .stepIcon svg path {
                stroke: var(--primaryColor);
              }
  
              .iconTable {
                border: 1px solid var(--primaryColor);
              }
  
              .stepName {
                h3 {
                  color: var(--titleColor);
                }
  
                p {
                  color: var(--titleColor);
                }
              }
  
              .statusIcon svg path {
                stroke: var(--primaryColor);
              }
            }
  
            .disabled {
              opacity: 0.3;
            }
  
            .active:hover {
              background-color: #FFFFFF10;
            }
  
            .blackShadow {
              position: fixed;
              top: 0;
              left: 0;
              width: 100vw;
              height: 100vh;
              background-color: #00000065;
              overflow-y: hidden;
              z-index: 1;
            }
  
            .modal {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              padding: 5.5vh 20px 4.5vh 20px;
              left: 0;
              right: 0;
              bottom: 0;
              position: fixed;
              background-color: white;
              border-radius: 20px 20px 0 0;
              gap: 20px;
  
              p {
                text-align: center;
                font-size: 11px;
                font-weight: 400;
                color: #A2A2A2;
              }
  
              .detailArea {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 5px;
                background-color: var(--focusColor);
                border-radius: 22px;
                padding: 8px 13px;
                color: var(--primaryColor);
                font-size: 12px;
                font-weight: 500;
  
                .eSimIcon, .portabilityIcon, .smartControlIcon {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                }
  
                svg {
                  width: 14px;
                  height: 14px;
                  
                  path {
                    stroke: var(--primaryColor);
                  }
                }
              }
  
              .textArea {
                text-align: center;
  
                h3 {
                  font-size: 19px;
                  font-weight: 500;
                }
  
                p {
                  max-width: 255px;
                }
  
                svg {
                  height: 60px;
                  width: 60px;
                }
              }
  
              .mainSectionModal {
                display: flex;
                align-items: center;
                justify-content: center;
              }
  
              .helpArea {
                display: flex;
                align-items: center;
                gap: 5px;
                text-decoration: none;
                font-size: 11px;
                color: #878787;
              }
  
              .helpArea:hover {
                color: #000000c5
              }
  
              .btnArea {
                button {
                  padding: 10px 40px;
                  border-radius: 43px;
                  font-size: 14px;
                  font-weight: 500;
                  color: var(--textColor);
                  background-color: var(--primaryColor);
                  text-transform: none;
                }
  
                button:hover {
                  opacity: 85%;
                }
              }
  
              // MODAL ESIM
              &.esim-modal {
                .mainSectionModal {
                  padding: 30px 0;
                  
                  .pdfBtnArea {
                    a {
                      display: flex;
                      align-items: center;
                      gap: 5px;
                      color: var(--primaryColor);
                      text-decoration: none;
                    }
    
                    a:hover {
                      opacity: 85%;
                    }
                  }
                }
              }
              
              // MODAL PORTABILITY
              &.portability-modal {
                .iconArea {
                  display: flex;
                  position: absolute;
                  left: 40px;
                  top: 5.4vh;
                }
    
                .mainSectionModal {
                  .stepper-portability {
                    display: flex;
                    align-items: center;
                    justify-content: center;
      
                    .current-step {
                      display: flex;
                      flex-direction: column;
                      align-items: center;
                      justify-content: center;
                      gap: 15px;
                      padding-bottom: 20px;
    
                      .MuiFormHelperText-root.Mui-error {
                        color: #d32f2f;
                        text-align: left;
                      }
      
                      .circularLabel {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 28px;
                        width: 28px;
                        background-color: #A6A6A61A;
                        color: black;
                        font-size: 11px;
                        border-radius: 50%;
                      }
      
                      .labelStepArea {
                        max-width: 200px;
                        margin-bottom: 10px;
                      }
  
                      &.step-3 {
                        padding-bottom: 0;
      
                        .transferArea {
                          display: flex;
                          justify-content: space-between;
                          padding: 0 3%;
      
                          .rightArrow {
                            transform: scaleX(-1);
                          }
      
                          .prevNextCompany {
                            display: flex;
                            flex-direction: column;
                            gap: 5px;
                            font-size: 13px;
      
                            .prevNextCompanyLabel {
                              display: flex;
                              flex-direction: row;
                              align-items: center;
                              background-color: #A6A6A61A;
                              padding: 5px 14px;
                              border-radius: 22px;
                              gap: 5px;
                            }
                            
                            .prevNextCompanyLabel.nextCompany {
                              background-color: #00D66A;
                              color: white;
                            }
                          }
                        }
      
                        .currentStepText {
                          display: flex;
                          flex-direction: column;
                          gap: 5px;
                          margin-bottom: 5px;
      
                          .attentionLabel {
                            font-weight: 500;
                            color: black;
                          }
        
                          .textArea {
                            max-width: 260px;
                          }
                        }
                      }
  
                      &.step-4 {
                        gap: 0;
      
                        p {
                          color: white;
                          font-size: 12px;
                        }
      
                        .titleArea {
                          font-size: 20px;
                        }
      
                        .labelTextArea {
                          max-width: 260px;
                        }
                        
                        .stepsAfterSubmit {
                          display: flex;
                          flex-direction: row;
                          justify-content: space-around;
                          width: 100%;
                          margin-top: 35px;
      
                          .stepAfterSubmit {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            gap: 15px;
      
                            .circularLabel {
                              background-color: #FFFFFF10;
                              color: white;
                            }
      
                            .circularTextLabel {
                              max-width: 120px;
                            }
                          }
                        }
                      }
                    }
                  }
                }
  
                &.submitClass {
                  background-color: var(--primaryColor);
      
                  .leftArrow {
                    svg path {
                      stroke: white;
                    }
                  }
      
                  .detailArea {
                    background-color: #FFFFFF10;
                    color: white;
      
                    .portabilityIcon svg path {
                      stroke: white;
                    }
                  }
      
                  .btnArea button {
                    background-color: white;
                    color: var(--primaryColor);
                  }
                }
              }
            }
          }
        }
      }
    }
    
    .footer {
      display: none;
      height: 32vh;
      background-color: blue;
      padding: 60px 20px;
    }
  
    /******************** MEDIA QUERY 900 PIXELS ********************/
    
    @media (min-width: 900px) {
      display: flex;
      align-items: center;
      height: 100vh;
  
      .content {
        flex-wrap: nowrap;
        position: relative;
  
        .container-left {
          display: flex;
          width: 120%;
          padding: 1.75rem 0 1.75rem 6.5rem;
          background-color: var(--focusColor);
        }
    
        .container-right {
          display: flex;
          background-color: var(--focusColor);
          position: unset;
          bottom: unset;
          padding: 1.75rem 6.5rem 1.75rem 1.5rem;
          overflow: hidden;
          z-index: unset;
        }
    
        .left {
          display: flex;
          flex-direction: column;
          justify-content: center;
          gap: 15px;
        
          /* HEADING */
          .content-left-heading {
            align-self: flex-start;
            width: auto;
            position: absolute;
            top: 1.75rem;
            left: 2.75rem;
      
            .heading {
              justify-content: unset;
      
              img {
                width: 150px;
              }
            }
          }
        
          /* SLOGAN PAGE 3 */
          .content-left-slogan-pg-3 {
            margin-top: 30px;
  
            .slogan-pg-3 {
              max-width: 270px;
          
              .textArea {
                .skltn-slogan .skltn-h3 {
                  font-size: 28px;
  
                  &.skltn-1 {
                    width: 68%;
                  }
  
                  &.skltn-2 {
                    width: 89%;
                  }
                }
  
                h3 {
                  font-size: 28px;
                }
              }
            }
          }
        
          /* DESCRIPTION */
          .content-left-description {
            .description {
              max-width: 320px;
    
              P {
                font-size: 12px;
              }
            }
          }
        
          /* PLAN SUMMARY PAGE 3 */
          .content-left-plan-summary-pg-3 {
            margin-top: 50px;
            
            .plan-summary-pg-3 {
              gap: 5px;
    
              .chosenPlan {
                font-size: 16px;
              }
    
              .planDetailsText {
                h3, .skltn-h3 {
                  font-size: 10px;
                }
    
                p {
                  font-size: 9px;
                }
              }
    
              .planDetailsItems {
                .planDetailsItem {
                  font-size: 8px;
                  border: 1px solid var(--primaryColor);
                }
              }
  
              .skltn-1 {
                width: 22%;
              }
              
              .skltn-2 {
                width: 23%;
              }
              
              .skltn-3 {
                width: 13%;
              }
            }
          }
        }
        
        .right {
          justify-content: center;
          gap: 15px;
  
          /* NEXT STEPS TEXT */
          .content-right-next-steps-text {
            h3 {
              font-size: 14px;
            }
            
            p {
              font-size: 11px;
            }
          }
        
          /* STEPPER CONFIG */
          .content-right-stepper-config {
            .stepper-config {
              gap: 10px;
  
              .step {
                padding: 18px 25px;
  
                .stepIcon .iconTable {
                  width: 26px;
                  height: 26px;
                }
  
                .stepName {
                  h3 {
                    font-size: 11px;
                  }
    
                  p {
                    font-size: 9px;
                  }
                }
              }
  
              .blackShadow {
                display: flex;
                align-items: center;
                justify-content: center;
              }
  
              .modal {
                width: 351px;
                height: 400px;     
                position: relative;
                border-radius: 20px;
                gap: 10px;
    
                p {
                  font-size: 10px;
                }
    
                .detailArea {
                  font-size: 10px;
    
                  svg {
                    width: 12px;
                    height: 12px;
                  }
                }
    
                .textArea {
                  h3 {
                    font-size: 16px;
                  }
    
                  svg {
                    height: 48px;
                    width: 48px;
                  }
                }
    
                .helpArea {
                  font-size: 10px;
                }
    
                .btnArea {
                  button {
                    padding: 10px 40px;
                    border-radius: 43px;
                    font-size: 12px;
                    font-weight: 500;
                    color: var(--textColor);
                    background-color: var(--primaryColor);
                    text-transform: none;
                  }
    
                  button:hover {
                    opacity: 85%;
                  }
                }
  
                &.esim-modal {
                  gap: 18px;
                }
  
                &.portability-modal .mainSectionModal .stepper-portability .current-step {
                  gap: 10px;
                  padding: 5px 0;
  
                  &.step-3 .transferArea {
                    padding: 0 2%;
                
                  }
    
                  &.step-4 .stepsAfterSubmit {
                    margin-top: 15px;
                  }
                }
              }
            }
          }
        }
      }
    }
  
    
    /******************** MEDIA QUERY 1200 PIXELS ********************/
  
    @media (min-width: 1200px) {
      .content {
        .container-left {
          padding: 1.75rem 0 1.75rem 9.5rem;
        }
  
        .container-right {
          padding: 1.75rem 9.5rem 1.75rem 2rem;
        }
  
        .left {
          gap: 10px;
        
          /* HEADING */
          .content-left-heading {
            top: 3.25rem;
            left: 5rem;
      
            .heading {
              img {
                width: 180px;
              }
            }
          }
        
          /* SLOGAN PAGE 3 */
          .content-left-slogan-pg-3 {
            .slogan-pg-3 {
              max-width: 300px;
          
              .textArea {
                .skltn-slogan .skltn-h3 {
                  font-size: 34px;
  
                  &.skltn-1 {
                    width: 74%;
                  }
  
                  &.skltn-2 {
                    width: 97%;
                  }
                }
  
                h3 {
                  font-size: 34px;
                }
              }
            }
          }
        
          /* DESCRIPTION */
          .content-left-description {
            .description {
              max-width: 370px;
    
              P {
                font-size: 14px;
              }
            }
          }
        
          /* PLAN SUMMARY PAGE 3 */
          .content-left-plan-summary-pg-3 {
            margin-top: 55px;
            
            .plan-summary-pg-3 {
              .chosenPlan {
                font-size: 20px;
              }
    
              .planDetailsText {
                h3, .skltn-h3 {
                  font-size: 14px;
                }
    
                p {
                  font-size: 12px;
                }
              }
    
              .planDetailsItems {
                .planDetailsItem {
                  font-size: 10px;
                }
              }
  
              .skltn-1 {
                width: 21%;
              }
              
              .skltn-2 {
                width: 26%;
              }
            }
          }
        }
  
        .right {
          /* NEXT STEPS TEXT */
          .content-right-next-steps-text {
            h3 {
              font-size: 16px;
            }
            
            p {
              font-size: 13px;
            }
          }
      
          /* STEPPER CONFIG */
          .content-right-stepper-config {
            .stepper-config {
              gap: 15px;
  
              .step {
                .stepIcon {
                  .iconTable {
                    width: 30px;
                    height: 30px;
                    
                    svg {
                      width: 18px;
                      height: 18px;
                    }
                  }
                }
                  
                .stepName {
                  h3 {
                    font-size: 13px;
                  }
    
                  p {
                    font-size: 11px;
                  }
                }
  
                .statusIcon svg {
                  width: 22px;
                  height: 22px;
                }
              }
  
              .modal {
                width: 400px;
                height: 490px;
  
                p {
                  font-size: 12px;
                }
  
                .detailArea {
                  font-size: 14px;
    
                  svg {
                    width: 16px;
                    height: 16px;
                  }
                }
    
                .textArea {
                  h3 {
                    font-size: 18px;
                  }
    
                  svg {
                    height: 48px;
                    width: 48px;
                  }
                }
    
                .helpArea {
                  font-size: 10px;
                }
    
                .btnArea {
                  button {
                    padding: 8px 36px;
                    font-size: 16px;
                  }
                }
  
                &.esim-modal {
                  height: 470px;
                  gap: 20px;
          
                  .textArea p {
                    max-width: 270px;
                  }
  
                  .mainSectionModal {
                    padding: 50px 0;
  
                    .pdfBtnArea a {
                      font-size: 18px;
                      gap: 8px;
                    }
                  }
                }
  
                &.portability-modal {
                  height: 515px;
                  gap: 20px;
          
                  .textArea p {
                    max-width: 280px;
                  }
  
                  .mainSectionModal .stepper-portability .current-step {
                    gap: 15px;
                    padding: unset;
                    padding-bottom: 20px;
  
                    &.step-3 .transferArea {
                      padding: 0 8%;
                    }
      
                    &.step-4 .stepsAfterSubmit {
                      margin-top: 15px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  
  
    /******************** MEDIA QUERY 1440 PIXELS ********************/
  
    @media (min-width: 1440px) {
      .content {
        .container-left {
          padding: 1.75rem 0 1.75rem 11.5rem;
        }
  
        .container-right {
          padding: 1.75rem 11.5rem 1.75rem 3rem;
        }
  
        .left {
          /* HEADING */
          .content-left-heading {
            .heading img {
              width: 200px;
            }
          }
        
          /* SLOGAN PAGE 3 */
          .content-left-slogan-pg-3 {
            margin-top: 25px;
  
            .slogan-pg-3 {
              max-width: 350px;
          
              .textArea {
                .skltn-slogan .skltn-h3 {
                  font-size: 40px;
  
                  &.skltn-1 {
                    width: 75%;
                  }
  
                  &.skltn-2 {
                    width: 98%;
                  }
                }
  
                h3 {
                  font-size: 40px;
                }
              }
            }
          }
        
          /* DESCRIPTION */
          .content-left-description {
            .description {
              max-width: 400px;
    
              P {
                font-size: 15px;
              }
            }
          }
  
          /* PLAN SUMMARY PAGE 3 */
          .content-left-plan-summary-pg-3 {
            margin-top: 115px;
  
            .plan-summary-pg-3 {
              .planDetailsText {
                h3, .skltn-h3 {
                  font-size: 13px;
                }
    
                p {
                  font-size: 12px;
                }
              }
  
              .planDetailsItems {
                margin-top: 15px;
    
                .planDetailsItem {
                  font-size: 11px;
                }
              }
  
              .skltn-1 {
                width: 18%;
              }
              
              .skltn-2 {
                width: 20%;
              }
              
              .skltn-3 {
                width: 12%;
              }
            }
          }
        }
  
        .right {
          /* NEXT STEPS TEXT */
          .content-right-next-steps-text {
            h3 {
              font-size: 16px;
            }
            
            p {
              font-size: 13px;
            }
          }
      
          /* STEPPER CONFIG */
          .content-right-stepper-config {
            .stepper-config {
              gap: 15px;
  
              .step {
                padding: 20px 26px;
                
                .stepIcon {
                  .iconTable {
                    width: 34px;
                    height: 34px;
                    
                    svg {
                      width: 20px;
                      height: 20px;
                    }
                  }
                }
                
                .stepName {
                  margin: 0 18px;
  
                  h3 {
                    font-size: 14px;
                  }
                  
                  p {
                    font-size: 12px;
                  }
                }
  
                .statusIcon svg {
                  width: 24px;
                  height: 24px;
                }
              }
              
              .modal {
                p {
                  font-size: 12px;
                }
                
                .detailArea {
                  font-size: 14px;
    
                  svg {
                    width: 16px;
                    height: 16px;
                  }
                }
                
                .textArea {
                  h3 {
                    font-size: 18px;
                  }
                  
                  svg {
                    height: 48px;
                    width: 48px;
                  }
                }
                
                .helpArea {
                  font-size: 10px;
                }
  
                .btnArea button {
                  padding: 8px 36px;
                  font-size: 16px;
                }
  
                &.esim-modal {
                  gap: 20px;
          
                  .textArea p {
                    max-width: 270px;
                  }
  
                  .mainSectionModal {
                    padding: 50px 0;
  
                    .pdfBtnArea a {
                      font-size: 18px;
                      gap: 8px;
                    }
                  }
                }
  
                &.portability-modal {
                  gap: 20px;
          
                  .textArea p {
                    max-width: 280px;
                  }
  
                  .mainSectionModal .stepper-portability .current-step {
                    gap: 15px;
                    padding: unset;
                    padding-bottom: 20px;
      
                    &.step-4 .stepsAfterSubmit {
                      margin-top: 15px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  
  
    /******************** MEDIA QUERY 1860 PIXELS ********************/
    
    @media (min-width: 1860px) {
      .content {
        .container-left {
          padding: 1.75rem 0 1.75rem 17.5rem;
        }
  
        .container-right {
          padding: 1.75rem 17.5rem 1.75rem 4rem;
        }
  
        .left {
          /* HEADING */
          .content-left-heading {
            .heading {
              top: 4rem;
              left: 6rem;
  
              img {
                width: 260px;
              }
            }
          }
        
          /* SLOGAN PAGE 3 */
          .content-left-slogan-pg-3 {
            margin-top: 10px;
  
            .slogan-pg-3 {
              max-width: 440px;
          
              .textArea {
                .skltn-slogan .skltn-h3 {
                  font-size: 50px;
                }
  
                h3 {
                  font-size: 50px;
                }
              }
            }
          }
        
          /* DESCRIPTION */
          .content-left-description {
            .description {
              max-width: 480px;
    
              P {
                font-size: 18px;
              }
            }
          }
  
          /* PLAN SUMMARY PAGE 3 */
          .content-left-plan-summary-pg-3 {
            margin-top: 150px;
  
            .plan-summary-pg-3 {
              .chosenPlan {
                font-size: 24px;
              }
  
              .planDetailsText {
                h3, .skltn-h3 {
                  font-size: 16px;
                }
    
                p {
                  font-size: 14px;
                }
              }
  
              .planDetailsItems {
                .planDetailsItem {
                  font-size: 14px;
                }
              }
  
              .skltn-1 {
                width: 17%;
              }
              
              .skltn-2 {
                width: 19%;
              }
            }
          }
        }
  
        .right {
          /* NEXT STEPS TEXT */
          .content-right-next-steps-text {
            h3 {
              font-size: 20px;
            }
            
            p {
              font-size: 16px;
            }
          }
      
          /* STEPPER CONFIG */
          .content-right-stepper-config {
            .stepper-config {
              gap: 25px;
  
              .step {
                padding: 22px 32px;
                
                .stepIcon {
                  .iconTable {
                    width: 38px;
                    height: 38px;
  
                    svg {
                      width: 22px;
                      height: 22px;
                    }
                  }
                }
                
                .stepName {
                  margin: 0 20px;
  
                  h3 {
                    font-size: 16px;
                  }
                  
                  p {
                    font-size: 14px;
                  }
                }
  
                .statusIcon svg {
                  width: 28px;
                  height: 28px;
                }
              }
              
              .modal {
                width: 490px;
                height: 580px;      
  
                p {
                  font-size: 15px;
                }
                
                .detailArea {
                  font-size: 18px;
                  gap: 8px;
    
                  svg {
                    width: 20px;
                    height: 20px;
                  }
                }
                
                .textArea {
                  h3 {
                    font-size: 26px;
                  }
                  
                  svg {
                    height: 78px;
                    width: 78px;
                  }
                }
                
                .helpArea {
                  font-size: 14px;
                  margin-bottom: -10px;
                }
  
                .btnArea button {
                  padding: 8px 44px;
                  font-size: 20px;
                }
  
                &.esim-modal {
                  width: 490px;
                  height: 580px;        
                  gap: 25px;
          
                  .textArea p {
                    max-width: 355px;
                    font-size: 16px;
                  }
  
                  .mainSectionModal {
                    padding: 55px 0;
  
                    .pdfBtnArea a {
                      font-size: 24px;
                      gap: 8px;
                    }
                  }
                }
  
                &.portability-modal {
                  width: 510px;
                  height: 640px;
                  gap: 25px;
  
                  .iconArea svg {
                    width: 32px;
                    height: 32px;
                  }
          
                  .textArea p {
                    max-width: 370px;
                  }
  
                  .mainSectionModal .stepper-portability .current-step {
                    gap: 20px;
                    padding: unset;
                    padding-bottom: 20px;
  
                    .circularLabel {
                      height: 30px;
                      width: 30px;
                      font-size: 16px;
                    }
  
                    .labelStepArea {
                      max-width: 270px;
                    }
  
                    &.step-3 {
                      .transferArea {
                        padding: 0 15%;
                
                        .prevNextCompany .prevNextCompanyLabel {
                          padding: 10px 14px;
                          font-size: 14px;
                        }
                      }
  
                      .currentStepText .textArea {
                        max-width: 345px;
                      }
                    }
      
                    &.step-4 {
                      .titleArea {
                        font-size: 24px;
                      }
  
                      .labelTextArea {
                        max-width: 320px;
                      }
  
                      p {
                        font-size: 16px;
                      }
  
                      .stepsAfterSubmit {
                        margin-top: 15px;
  
                        .stepAfterSubmit .circularTextLabel {
                          max-width: 160px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  /********** PAGE 4 **********/
  &.pedido-finalizado {
    .content {
      display: block;
      height: 100vh;
      background-color: var(--primaryColor);
  
      .container-left {
        padding: 80px 30px 0 30px;
        background-color: var(--primaryColor);
      }
  
      .container-right {
        padding: 35px 30px 30px 30px;
        background-color: var(--primaryColor);
      }
  
      .left {
        /* Theme */
        --titleColor: #FFFFFF;
        --textColor: #FFFFFF;
  
        display: flex;
        gap: 25px;
        
        /* HEADING */
        .content-left-heading {
          width: 100vw;
    
          .heading {
            display: flex;
            justify-content: center;
            width: 100%;
    
            h3 {
              display: none;
            }
    
            img {
              width: 55%;
            }
          }
        }
        
        /* SLOGAN PAGE 4 */
        .content-left-slogan-pg-4 {
          margin-top: 35px;
  
          .slogan-pg-4 {
            max-width: 300px;
  
            h1 {
              font-size: 30px;
              font-weight: 500;
              color: var(--titleColor);
            }
          }
        }
        
        /* DESCRIPTION */
        .content-left-description {
          margin-top: -10px;
  
          .description {
            max-width: 260px;
  
            P {
              font-size: 14px;
              font-weight: 400;
              color: var(--textColor);
            }
          }
        }
  
        /* NEXT STEPS TEXT */
        .content-left-download-app-text {
          margin-top: 15px;
  
          .textArea {
            max-width: 200px;
  
            h3 {
              font-size: 16px;
              font-weight: 500;
              color: var(--titleColor);
            }
          }
        }
  
        /* APP LINK */
        .content-left-app-link {
          display: flex;
          justify-content: center;
          margin-top: 5px;
          
          .app-link {
            width: 100%;
            max-width: 460px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
            gap: 10px;
  
            svg rect {
              fill: var(--appLinkBackColor)
            }
          }
        }
      }
  
      .right {
        /* Theme */
        --titleColor: #FFFFFF;
        --textColor: #FFFFFF;
  
        background-color: var(--primaryColor);
        display: flex;
        flex-direction: column;
        gap: 25px;
        
        /* STEPPER CONFIG */
        .content-right-stepper-config {
          .stepper-config {
            gap: 20px;
  
            .step {
              justify-content: space-between;
              align-items: center;
              border-radius: 100px;
              padding: 20px 25px;
              cursor: pointer;
  
              .stepIcon {
                display: flex;
                align-items: center;
                justify-content: center;
  
                svg {
                  width: 16px;
                  height: 16px;
                }
  
                .iconTable {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  width: 30px;
                  height: 30px;
                  border-radius: 100%;
                }
              }
  
              .stepName {
                display: flex;
                flex-direction: column;
                justify-content: center;
                flex: 1 1;
                margin: 0 15px;
  
                h3 {
                  font-size: 12px;
                  font-weight: 500;
                }
  
                p {
                  font-size: 10px;
                }
              }
  
              .statusIcon {
                display: flex;
                align-items: center;
                justify-content: center;
  
                svg {
                  width: 20px;
                  height: 20px;
                }
              }
            }
            
            .step.step-1 {
              cursor: not-allowed;
            }
  
            .completed {
              background-color: #FFFFFF25;
  
              .stepIcon svg path {
                stroke: var(--primaryColor);
              }
  
              .iconTable {
                background-color: #FFF;
              }
  
              .stepName {
                h3 {
                  color: var(--textColor);
                }
  
                p {
                  color: var(--textColor);
                }
              }
  
              .statusIcon svg path {
                stroke: #FFF;
              }
            }
  
            .active, .disabled {
              border: 1px solid #FFFFFF;
              cursor: pointer;
  
              .stepIcon svg {
                width: 20px;
                height: 20px;
  
                path {
                  stroke: var(--primaryColor);
                }
              }
  
              .iconTable {
                width: 36px;
                height: 36px;
                background-color: #FFF;
                padding: 0 0 2px 1px;
              }
  
              .stepName {
                h3 {
                  color: var(--titleColor);
                }
  
                p {
                  color: var(--titleColor);
                }
              }
  
              .statusIcon svg {
                width: 26px;
                height: 26px;
  
                path {
                  stroke: #FFFFFF;
                }
              }
            }
  
            .disabled {
              opacity: 0.3;
            }
  
            .active:hover {
              background-color: #FFFFFF10;
            }
  
            .active.step-5 {
              border: 1px solid var(--wppStepColor);
              width: 100%;
              text-decoration: none;
              
              .stepIcon svg {
                path {
                  fill: var(--primaryColor);
                  stroke: unset;
                }
              }
  
              .iconTable {
                background-color: var(--wppStepColor);
              }
  
              .stepName {
                h3 {
                  color: var(--wppStepColor);
                }
  
                p {
                  color: var(--wppStepColor);
                }
              }
  
              .statusIcon svg {
                path {
                  stroke: var(--wppStepColor);
                }
              }
            }
  
            .blackShadow {
              position: fixed;
              top: 0;
              left: 0;
              width: 100vw;
              height: 100vh;
              background-color: #00000065;
              overflow-y: hidden;
              z-index: 1;
            }
  
            .modal {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              padding: 4.5vh 20px;
              left: 0;
              right: 0;
              bottom: 0;
              position: fixed;
              background-color: white;
              border-radius: 20px 20px 0 0;
              gap: 20px;
  
              p {
                text-align: center;
                font-size: 11px;
                font-weight: 400;
                color: #A2A2A2;
              }
  
              .detailArea {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 5px;
                background-color: var(--focusColor);
                border-radius: 22px;
                padding: 8px 13px;
                color: var(--primaryColor);
                font-size: 12px;
                font-weight: 500;
  
                .eSimIcon, .portabilityIcon, .smartControlIcon {
                  width: 14px;
                  height: 14px;
                }
  
                svg {
                  width: 14px;
                  height: 14px;
                }
              }
  
              .textArea {
                text-align: center;
  
                h3 {
                  font-size: 19px;
                  font-weight: 500;
                }
  
                p {
                  max-width: 255px;
                }
  
                svg {
                  height: 60px;
                  width: 60px;
                }
              }
  
              .mainSectionModal {
                display: flex;
                align-items: center;
                justify-content: center;
              }
  
              .helpArea {
                display: flex;
                align-items: center;
                gap: 5px;
                text-decoration: none;
                font-size: 11px;
                color: #878787;
              }
  
              .helpArea:hover {
                color: #000000c5
              }
  
              .btnArea {
                button {
                  padding: 10px 40px;
                  border-radius: 43px;
                  font-size: 14px;
                  font-weight: 500;
                  color: var(--textColor);
                  background-color: var(--primaryColor);
                  text-transform: none;
                }
  
                button:hover {
                  opacity: 85%;
                }
              }
            }
  
            // MODAL ESIM
            .modal.esim-modal {
              .mainSectionModal {
                padding: 30px 0;
                
                .pdfBtnArea {
                  a {
                    display: flex;
                    align-items: center;
                    gap: 5px;
                    color: var(--primaryColor);
                    text-decoration: none;
                  }
  
                  a:hover {
                    opacity: 85%;
                  }
                }
              }
            }
  
            // MODAL PORTABILITY
            .modal.portability-modal {
              .iconArea {
                display: flex;
                position: absolute;
                left: 40px;
                top: 5.4vh;
              }
  
              .mainSectionModal {
                .stepper-portability {
                  display: flex;
                  align-items: center;
                  justify-content: center;
    
                  .current-step {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    gap: 15px;
                    padding-bottom: 20px;
  
                    .MuiFormHelperText-root.Mui-error {
                      color: #d32f2f;
                      text-align: left;
                    }
    
                    .circularLabel {
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      height: 28px;
                      width: 28px;
                      background-color: #A6A6A61A;
                      color: black;
                      font-size: 11px;
                      border-radius: 50%;
                    }
    
                    .labelStepArea {
                      max-width: 200px;
                      margin-bottom: 10px;
                    }
                  }
  
                  .current-step.step-3 {
                    padding-bottom: 0;
  
                    .transferArea {
                      display: flex;
                      justify-content: space-between;
                      padding: 0 15%;
  
                      .rightArrow {
                        transform: scaleX(-1);
                      }
  
                      .prevNextCompany {
                        display: flex;
                        flex-direction: column;
                        gap: 5px;
                        font-size: 13px;
  
                        .prevNextCompanyLabel {
                          display: flex;
                          flex-direction: row;
                          align-items: center;
                          background-color: #A6A6A61A;
                          padding: 5px 14px;
                          border-radius: 22px;
                          gap: 5px;
                        }
                        
                        .prevNextCompanyLabel.nextCompany {
                          background-color: #00D66A;
                          color: white;
                        }
                      }
                    }
  
                    .currentStepText {
                      display: flex;
                      flex-direction: column;
                      gap: 5px;
                      margin-bottom: 5px;
  
                      .attentionLabel {
                        font-weight: 500;
                        color: black;
                      }
    
                      .textArea {
                        max-width: 260px;
                      }
                    }
                  }
  
                  .current-step.step-4 {
                    gap: 0;
  
                    p {
                      color: white;
                      font-size: 12px;
                    }
  
                    .titleArea {
                      font-size: 20px;
                    }
  
                    .labelTextArea {
                      max-width: 260px;
                    }
                    
                    .stepsAfterSubmit {
                      display: flex;
                      flex-direction: row;
                      justify-content: space-around;
                      width: 100%;
                      margin-top: 35px;
  
                      .stepAfterSubmit {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        gap: 15px;
  
                        .circularLabel {
                          background-color: #FFFFFF10;
                          color: white;
                        }
  
                        .circularTextLabel {
                          max-width: 120px;
                        }
                      }
                    }
                  }
                }
              }
            }
  
            .modal.portability-modal.submitClass {
              background-color: var(--primaryColor);
  
              .leftArrow {
                svg path {
                  stroke: white;
                }
              }
  
              .detailArea {
                background-color: #FFFFFF10;
                color: white;
  
                .portabilityIcon svg path {
                  stroke: white;
                }
              }
  
              .btnArea button {
                background-color: white;
                color: var(--primaryColor);
              }
            }
          }
        }
      }
    }
    
    .footer {
      display: none;
      height: 32vh;
      background-color: blue;
      padding: 60px 20px;
    }
  
    /******************** MEDIA QUERY 900 PIXELS ********************/
    
    @media (min-width: 900px) {
      display: flex;
      align-items: center;
      height: 100vh; 
      background-color: var(--primaryColor);
  
      .content {
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row-reverse;
  
        .container-left {
          display: flex;
          width: 100%;
          padding: 1.75rem 2.75rem 1.75rem 0;
        }
    
        .container-right {
          padding: 1.75rem 2.75rem;
          position: unset;
          background-color: var(--primaryColor);
        }
    
        .left {
          /* Theme */
          --titleColor: #FFFFFF;
          --textColor: #FFFFFF;
  
          display: flex;
          flex-direction: column;
          justify-content: center;
          position: relative;
          gap: 15px;
        
          /* HEADING */
          .content-left-heading {
            align-self: flex-start;
            width: auto;
            position: fixed;
            top: 1.75rem;
            left: 2.75rem;
      
            .heading {
              justify-content: unset;
      
              img {
                width: 150px;
              }
            }
          }
        
          /* SLOGAN PAGE 4 */
          .content-left-slogan-pg-4 {
            margin-top: 0;
    
            .slogan-pg-4 {
              max-width: 266px;
    
              h3 {
                font-size: 32px;
              }
            }
          }
        
          /* DESCRIPTION */
          .content-left-description {
            display: none;
          }
  
          /* NEXT STEPS TEXT */
          .content-left-download-app-text {
            margin-top: 0;
    
            .textArea {
              max-width: unset;
    
              h3 {
                font-size: 14px;
                font-weight: 500;
                color: var(--titleColor);
              }
            }
          }
  
          /* APP LINK */
          .content-left-app-link {
            margin-top: 0;
            justify-content: start;
            
            .app-link {
              justify-content: start;
            }
          }
        }
        
        .right {
          /* Theme */
          --titleColor: #FFFFFF;
          --textColor: #FFFFFF;
  
          justify-content: center;
          padding: 38px;
        
          /* STEPPER CONFIG */
          .content-right-stepper-config {
            .stepper-config {
              .step {
                padding: 16px 25px;
              }
    
              .completed {
                .stepIcon svg {
                  width: 16px;
                  height: 16px;
                }
    
                .iconTable {
                  width: 30px;
                  height: 30px;
                }
              }
    
              .active {
                .stepIcon svg {
                  width: 16px;
                  height: 16px;
                }
    
                .iconTable {
                  width: 30px;
                  height: 30px;
                  padding: 0 0 1px 0;
                }
  
                .stepName {
                  h3 {
                    font-size: 12px;
                  }
    
                  p {
                    font-size: 10px;
                  }
                }
  
                .statusIcon svg {
                  width: auto;
                  height: auto;
                }
              }
  
              .blackShadow {
                display: flex;
                align-items: center;
                justify-content: center;
              }
  
              .modal {
                width: 351px;
                height: 400px;     
                position: relative;
                border-radius: 20px;
                gap: 10px;
    
                p {
                  font-size: 10px;
                }
    
                .detailArea {
                  font-size: 10px;
    
                  svg {
                    width: 12px;
                    height: 12px;
                  }
                }
    
                .textArea {
                  h3 {
                    font-size: 16px;
                  }
    
                  svg {
                    height: 48px;
                    width: 48px;
                  }
                }
    
                .helpArea {
                  font-size: 10px;
                }
    
                .btnArea {
                  button {
                    padding: 10px 40px;
                    border-radius: 43px;
                    font-size: 12px;
                    font-weight: 500;
                    color: var(--textColor);
                    background-color: var(--primaryColor);
                    text-transform: none;
                  }
    
                  button:hover {
                    opacity: 85%;
                  }
                }
  
                &.esim-modal {
                  gap: 18px;
                }
  
                &.portability-modal .mainSectionModal .stepper-portability .current-step {
                  gap: 10px;
                  padding: 5px 0;
  
                  &.step-3 .transferArea {
                    padding: 0 2%;
                
                  }
    
                  &.step-4 .stepsAfterSubmit {
                    margin-top: 15px;
                  }
                }
              }
            }
          }
        }
      }
    
      .footer {
        display: none;
      }
    }
  
    
    /******************** MEDIA QUERY 1200 PIXELS ********************/
  
    @media (min-width: 1200px) {
      .content {
        .container-left {
          padding: 2.75rem 3.5rem 2.75rem 0;
        }
  
        .container-right {
          padding: 2.75rem 6.25rem;
        }
  
        .left {
          gap: 20px;
  
          /* HEADING */
          .content-left-heading {
            top: 2.75rem;
            left: 3.5rem;
  
            .heading {
              img {
                width: 165px;
              }
            }
          }
  
          /* NEXT STEPS TEXT */
          .content-left-download-app-text {
            .textArea {
              h3 {
                font-size: 15px;
              }
            }
          }
  
          /* APP LINK */
          .content-left-app-link {
            .app-link {
              gap: 20px;
            }
          }
        }
  
        .right {
          gap: 35px;
  
          /* STEPPER CONFIG */
          .content-right-stepper-config {
            .stepper-config {
  
              .step {
                padding: 20px 25px;
    
                .stepName {
                  h3 {
                    font-size: 12px;
                  }
    
                  p {
                    font-size: 10px;
                  }
                }
              }
  
              .completed {
                .stepIcon svg {
                  width: 16px;
                  height: 16px;
                }
    
                .iconTable {
                  width: 30px;
                  height: 30px;
                }
              }
  
              .active {
                .stepIcon svg {
                  width: 16px;
                  height: 16px;
                }
  
                .stepName {
                  h3 {
                    font-size: 12px;
                    color: var(--titleColor);
                  }
    
                  p {
                    font-size: 10px;
                    color: var(--titleColor);
                  }
                }
    
                .iconTable {
                  width: 30px;
                  height: 30px;
                  padding: 0 0 1px 0;
                }
  
                .statusIcon svg {
                  width: auto;
                  height: auto;
                }
              }
  
              .modal {
                width: 400px;
                height: 490px;
  
                p {
                  font-size: 12px;
                }
  
                .detailArea {
                  font-size: 14px;
    
                  svg {
                    width: 16px;
                    height: 16px;
                  }
                }
    
                .textArea {
                  h3 {
                    font-size: 18px;
                  }
    
                  svg {
                    height: 48px;
                    width: 48px;
                  }
                }
    
                .helpArea {
                  font-size: 10px;
                }
    
                .btnArea {
                  button {
                    padding: 8px 36px;
                    font-size: 16px;
                  }
                }
  
                &.esim-modal {
                  height: 470px;
                  gap: 20px;
          
                  .textArea p {
                    max-width: 270px;
                  }
  
                  .mainSectionModal {
                    padding: 50px 0;
  
                    .pdfBtnArea a {
                      font-size: 18px;
                      gap: 8px;
                    }
                  }
                }
  
                &.portability-modal {
                  height: 515px;
                  gap: 20px;
          
                  .textArea p {
                    max-width: 280px;
                  }
  
                  .mainSectionModal .stepper-portability .current-step {
                    gap: 15px;
                    padding: unset;
                    padding-bottom: 20px;
  
                    &.step-3 .transferArea {
                      padding: 0 8%;
                    }
      
                    &.step-4 .stepsAfterSubmit {
                      margin-top: 15px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    
    
    /******************** MEDIA QUERY 1440 PIXELS ********************/
    
    @media (min-width: 1440px) {
      .content {
        .container-left {
          padding: 3.5rem 4rem 3.5rem 0;
        }
  
        .container-right {
          padding: 3.5rem 7.5rem;
        }
    
        .left {
          /* HEADING */
          .content-left-heading {
            top: 3.5rem;
            left: 4rem;
  
            .heading {
              img {
                width: 195px;
              }
            }
          }
        
          /* SLOGAN PAGE 3 */
          .content-left-slogan-pg-4 {
            .slogan-pg-4 {
              max-width: 315px;
  
              .iconArea svg {
                width: 48px;
                height: 48px;
              }
    
              h3 {
                font-size: 38px;
              }
            }
          }
        }
        
        .right {
          gap: 40px;
          padding: 50px;
      
          /* STEPPER CONFIG */
          .content-right-stepper-config {
            .stepper-config {
              
              .modal {
                p {
                  font-size: 12px;
                }
                
                .detailArea {
                  font-size: 14px;
    
                  svg {
                    width: 16px;
                    height: 16px;
                  }
                }
                
                .textArea {
                  h3 {
                    font-size: 18px;
                  }
                  
                  svg {
                    height: 48px;
                    width: 48px;
                  }
                }
                
                .helpArea {
                  font-size: 10px;
                }
  
                .btnArea button {
                  padding: 8px 36px;
                  font-size: 16px;
                }
  
                &.esim-modal {
                  gap: 20px;
          
                  .textArea p {
                    max-width: 270px;
                  }
  
                  .mainSectionModal {
                    padding: 50px 0;
  
                    .pdfBtnArea a {
                      font-size: 18px;
                      gap: 8px;
                    }
                  }
                }
  
                &.portability-modal {
                  gap: 20px;
          
                  .textArea p {
                    max-width: 280px;
                  }
  
                  .mainSectionModal .stepper-portability .current-step {
                    gap: 15px;
                    padding: unset;
                    padding-bottom: 20px;
      
                    &.step-4 .stepsAfterSubmit {
                      margin-top: 15px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    
    
    /******************** MEDIA QUERY 1860 PIXELS ********************/
    
    @media (min-width: 1860px) {
      .content {
        .container-left {
          padding: 5rem 5.75rem 5rem 0;
        }
  
        .container-right {
          padding: 5rem 8rem;
        }
    
        .left {
          gap: 15px;
  
          /* HEADING */
          .content-left-heading {
            top: 5rem;
            left: 5.75rem;
  
            .heading {
              img {
                width: 240px;
              }
            }
          }
        
          /* SLOGAN PAGE 3 */
          .content-left-slogan-pg-4 {
            .slogan-pg-4 {
              max-width: 470px;
  
              .iconArea svg {
                width: 75px;
                height: 75px;
              }
    
              h3 {
                font-size: 56px;
                line-height: 1.35;
              }
            }
          }
  
          /* NEXT STEPS TEXT */
          .content-left-download-app-text {
            .textArea {
              h3 {
                font-size: 19px;
              }
            }
          }
  
          /* APP LINK */
          .content-left-app-link {
            .app-link {
              gap: 20px;
  
              .appIcon {
                svg {
                  width: 100%;
                  height: 100%;
                }
              }
            }
          }
        }
        
        .right {
          padding: 90px;
  
          /* STEPPER CONFIG */
          .content-right-stepper-config {
            .stepper-config {
              gap: 25px;
  
              .step {
                padding: 22px 32px;
                
                .stepIcon {
                  .iconTable {
                    width: 38px;
                    height: 38px;
  
                    svg {
                      width: 22px;
                      height: 22px;
                    }
                  }
                }
                
                .stepName {
                  margin: 0 20px;
  
                  h3 {
                    font-size: 16px;
                  }
                  
                  p {
                    font-size: 14px;
                  }
                }
  
                .statusIcon svg {
                  width: 28px;
                  height: 28px;
                }
              }
    
              .completed {
                .stepIcon svg {
                  width: 20px;
                  height: 20px;
                }
    
                .iconTable {
                  width: 42px;
                  height: 42px;
                }
              }
    
              .active {
                .stepIcon svg {
                  width: 20px;
                  height: 20px;
                }
    
                .iconTable {
                  width: 42px;
                  height: 42px;
                  padding: 0 0 1px 0;
                }
  
                .statusIcon svg {
                  width: 30px;
                  height: 30px;
                }
              }
              
              .modal {
                width: 490px;
                height: 580px;      
  
                p {
                  font-size: 15px;
                }
                
                .detailArea {
                  font-size: 18px;
                  gap: 8px;
    
                  svg {
                    width: 20px;
                    height: 20px;
                  }
                }
                
                .textArea {
                  h3 {
                    font-size: 26px;
                  }
                  
                  svg {
                    height: 78px;
                    width: 78px;
                  }
                }
                
                .helpArea {
                  font-size: 14px;
                  margin-bottom: -10px;
                }
  
                .btnArea button {
                  padding: 8px 44px;
                  font-size: 20px;
                }
  
                &.esim-modal {
                  width: 490px;
                  height: 580px;        
                  gap: 25px;
          
                  .textArea p {
                    max-width: 355px;
                    font-size: 16px;
                  }
  
                  .mainSectionModal {
                    padding: 55px 0;
  
                    .pdfBtnArea a {
                      font-size: 24px;
                      gap: 8px;
                    }
                  }
                }
  
                &.portability-modal {
                  width: 510px;
                  height: 640px;
                  gap: 25px;
  
                  .iconArea svg {
                    width: 32px;
                    height: 32px;
                  }
          
                  .textArea p {
                    max-width: 370px;
                  }
  
                  .mainSectionModal .stepper-portability .current-step {
                    gap: 20px;
                    padding: unset;
                    padding-bottom: 20px;
  
                    .circularLabel {
                      height: 30px;
                      width: 30px;
                      font-size: 16px;
                    }
  
                    .labelStepArea {
                      max-width: 270px;
                    }
  
                    &.step-3 {
                      .transferArea {
                        padding: 0 15%;
                
                        .prevNextCompany .prevNextCompanyLabel {
                          padding: 10px 14px;
                          font-size: 14px;
                        }
                      }
  
                      .currentStepText .textArea {
                        max-width: 345px;
                      }
                    }
      
                    &.step-4 {
                      .titleArea {
                        font-size: 24px;
                      }
  
                      .labelTextArea {
                        max-width: 320px;
                      }
  
                      p {
                        font-size: 16px;
                      }
  
                      .stepsAfterSubmit {
                        margin-top: 15px;
  
                        .stepAfterSubmit .circularTextLabel {
                          max-width: 160px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}