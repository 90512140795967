/* GLOBAL */
:root {
  /* default */
  --primaryColor: #FF7A00;
  --secondaryColor: #FFF500;
  --titleColor: var(--primaryColor);
  --textColor: #878787;
  --textLowOpacity: #727272E6;
  --focusColor: #FFF0E3;
  --borderColor: #DCDCDC;
  --sloganTextColor: #FFFFFF;
  --page2RightBackMob: var(--primaryColor);
  --page2RightBackDesk: var(--focusColor);
  /* timer */
  --backgroundTimer: var(--primaryColor);
  --titleTimerColor: #FFFFFF;
  --textTimerColor: #FFFFFF;
  --numberTimerColor: var(--titleTimerColor);
  /* uncomplicate button */
  --uncomplicatedButtonColor: var(--focusColor);
  --uncomplicatedButtonText: var(--primaryColor);
  --uncomplicatedButtonIcon: var(--primaryColor);
  /* select plan */
  --planButtonActiveBack: var(--primaryColor);
  --planButtonActive: #FFFFFF;
  --planButtonText: #000000;
  --borderButtonColor: var(--borderColor);
  /* activated benefits */
  --detailBackColor: var(--primaryColor);
  --detailTextColor: #FFFFFF;
  /* select plan details */
  --primarySwitchColor: var(--primaryColor);
  --secondarySwitchColor: #FFFFFF;
  --monthlyBackColor: var(--focusColor);
  --monthlyColorBase: var(--primaryColor);
  --monthlyDetailsBackColor: var(--monthlyColorBase);
  --monthlyDetailsColor: #FFFFFF;
  --annualTextColor: var(--textColor);
  --annualBorderColor: #D7D7D780;
  --counterBorder: #cccccc;
  --counterTextBorder: #000000;
  /* stepper */
  --stepperBorderColor: #FFF4EA;
  --stepperDesabledCircleColor: #FFF4EA;
  /* payment method */
  --paymetCardBorder: #DCDCDC;
  /* select chip info */
  --dualSwitchColor: var(--primaryColor);
  /* config page */
  --spanLabel: var(--primaryColor);
  /* app link */
  --wppStepColor: #FFFFFF;
  --appLinkBackColor: var(--primaryColor);
}

* {
  margin: 0;
}

.container {
  /********** PAGE 1 **********/
  /********** PAGE 2 **********/
  /********** PAGE 3 **********/
  /********** PAGE 4 **********/
}
.container .content {
  max-width: 1920px;
  max-height: 993px;
}
.container.home, .container.vip-id {
  background-color: var(--primaryColor);
  /******************** MEDIA QUERY FOR GALAXY FOLD ********************/
  /******************** MDIEA QUERY 900 PIXELS ********************/
  /******************** MEDIA QUERY 1200 PIXELS ********************/
  /******************** MEDIA QUERY 1440 PIXELS ********************/
  /******************** MEDIA QUERY 1860 PIXELS ********************/
}
.container.home .content, .container.vip-id .content {
  background-color: var(--primaryColor);
}
.container.home .content .container-left, .container.vip-id .content .container-left {
  background-color: var(--primaryColor);
  padding: 50px 20px 0 20px;
  max-height: 80vh;
  overflow: hidden;
}
.container.home .content .container-right, .container.vip-id .content .container-right {
  padding: 50px 20px;
  background-color: #FFFFFF;
  border-radius: 20px 20px 0 0;
}
.container.home .content .left, .container.vip-id .content .left {
  /* HEADING */
  /* SLOGAN */
  /* BACK IMAGE */
  /* DETAILS */
  /* UNCOMPLICATE BUTTON */
}
.container.home .content .left .content-left-heading, .container.vip-id .content .left .content-left-heading {
  width: 100vw;
}
.container.home .content .left .content-left-heading .heading, .container.vip-id .content .left .content-left-heading .heading {
  display: flex;
  justify-content: center;
  width: 100%;
}
.container.home .content .left .content-left-heading .heading h3, .container.vip-id .content .left .content-left-heading .heading h3 {
  display: none;
}
.container.home .content .left .content-left-heading .heading img, .container.vip-id .content .left .content-left-heading .heading img {
  width: 55%;
}
.container.home .content .left .content-left-slogan, .container.vip-id .content .left .content-left-slogan {
  justify-content: center;
  text-align: center;
  padding: 25px 0;
  color: var(--sloganTextColor);
}
.container.home .content .left .content-left-slogan .slogan, .container.vip-id .content .left .content-left-slogan .slogan {
  max-width: 300px;
}
.container.home .content .left .content-left-slogan h3, .container.vip-id .content .left .content-left-slogan h3 {
  font-size: 31px;
  font-weight: 600;
}
.container.home .content .left .content-left-slogan h3 span, .container.vip-id .content .left .content-left-slogan h3 span {
  color: var(--secondaryColor);
}
.container.home .content .left .content-left-back-image, .container.vip-id .content .left .content-left-back-image {
  width: calc(100vw + 80px);
  overflow: hidden;
  margin: -55px -20px 0 -20px;
}
.container.home .content .left .content-left-back-image .back-image, .container.vip-id .content .left .content-left-back-image .back-image {
  width: 100%;
}
.container.home .content .left .content-left-back-image img, .container.vip-id .content .left .content-left-back-image img {
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  width: 100%;
}
.container.home .content .left .content-left-details .details, .container.vip-id .content .left .content-left-details .details {
  display: none;
  justify-content: center;
  flex-direction: row;
}
.container.home .content .left .content-left-details img, .container.vip-id .content .left .content-left-details img {
  width: 15%;
}
.container.home .content .left .content-left-details .detailsTextArea, .container.vip-id .content .left .content-left-details .detailsTextArea {
  max-width: 500px;
}
.container.home .content .left .content-left-details .detailsTextArea h3, .container.vip-id .content .left .content-left-details .detailsTextArea h3 {
  font-size: 18px;
  font-weight: 500;
  color: var(--titleColor);
}
.container.home .content .left .content-left-details .detailsTextArea p, .container.vip-id .content .left .content-left-details .detailsTextArea p {
  font-size: 12px;
  font-weight: 400;
  color: var(--textColor);
}
.container.home .content .left .content-left-uncomplicate-button, .container.vip-id .content .left .content-left-uncomplicate-button {
  justify-content: center;
  margin-bottom: 50px;
}
.container.home .content .left .content-left-uncomplicate-button button, .container.vip-id .content .left .content-left-uncomplicate-button button {
  padding: 15px 35px;
  font-size: 14px;
  font-weight: 600;
  border-radius: 100px;
  background-color: var(--uncomplicatedButtonColor);
  color: var(--uncomplicatedButtonText);
  gap: 10px;
}
.container.home .content .left .content-left-uncomplicate-button button svg path, .container.vip-id .content .left .content-left-uncomplicate-button button svg path {
  stroke: var(--uncomplicatedButtonIcon);
}
.container.home .content .right, .container.vip-id .content .right {
  /* TIMER */
  /* CHOOSE PLAN TEXT */
  /* SELECT PLAN */
  /* PLAN BENEFITS APPS */
  /* ACTIVATED BENEFITS */
  /* PLAN DETAILS TEXT */
  /* SELECT PLAN DETAILS */
}
.container.home .content .right .content-right-timer .timer, .container.vip-id .content .right .content-right-timer .timer {
  flex-direction: column;
  align-items: end;
  position: absolute;
  top: 60px;
  right: 20px;
  gap: 5px;
}
.container.home .content .right .content-right-timer .timerArea, .container.vip-id .content .right .content-right-timer .timerArea {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  color: var(--titleTimerColor);
  gap: 3px;
}
.container.home .content .right .content-right-timer .timerArea h3, .container.vip-id .content .right .content-right-timer .timerArea h3 {
  font-size: 12px;
  font-weight: 500;
}
.container.home .content .right .content-right-timer .timerArea p, .container.vip-id .content .right .content-right-timer .timerArea p {
  display: none;
  color: var(--textTimerColor);
}
.container.home .content .right .content-right-timer .timerArea .squareTime, .container.vip-id .content .right .content-right-timer .timerArea .squareTime {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  border-radius: 8px;
  background-color: var(--borderColor);
  color: var(--numberTimerColor);
  font-size: 15px;
  font-weight: 400;
}
.container.home .content .right .content-right-timer .timerTextArea, .container.vip-id .content .right .content-right-timer .timerTextArea {
  flex-direction: column;
  justify-content: center;
}
.container.home .content .right .content-right-choose-plan-text, .container.vip-id .content .right .content-right-choose-plan-text {
  margin-bottom: 25px;
}
.container.home .content .right .content-right-choose-plan-text h3, .container.vip-id .content .right .content-right-choose-plan-text h3 {
  font-size: 20px;
  font-weight: 500;
  color: var(--titleColor);
}
.container.home .content .right .content-right-choose-plan-text p, .container.vip-id .content .right .content-right-choose-plan-text p {
  font-size: 14px;
  font-weight: 400;
  color: var(--textColor);
  opacity: 0.75;
}
.container.home .content .right .content-right-select-plan, .container.vip-id .content .right .content-right-select-plan {
  margin-bottom: 30px;
}
.container.home .content .right .content-right-select-plan .selectArea, .container.vip-id .content .right .content-right-select-plan .selectArea {
  display: flex;
  margin: 0px 0 5px -20px;
  color: var(--primaryColor);
}
.container.home .content .right .content-right-select-plan .selectArea .buttonLabel, .container.vip-id .content .right .content-right-select-plan .selectArea .buttonLabel {
  align-items: center;
  background-color: var(--primaryColor);
  color: #FFF;
  padding: 0 8px;
  border: solid 2px #FFF;
  border-radius: 38px;
  font-size: 12px;
  font-weight: 500;
  height: 20px;
  transform: translate(0, 46px);
  margin-left: -74px;
}
.container.home .content .right .content-right-select-plan .containerCarousel, .container.vip-id .content .right .content-right-select-plan .containerCarousel {
  width: 100vw;
  padding: 15px 20px;
  margin-right: -40px;
  gap: 15px;
  overflow-x: auto;
  flex-wrap: nowrap;
}
.container.home .content .right .content-right-select-plan .containerCarousel::-webkit-scrollbar-track, .container.vip-id .content .right .content-right-select-plan .containerCarousel::-webkit-scrollbar-track {
  background: var(--primaryColor); /* cor de fundo da barra de rolagem */
}
.container.home .content .right .content-right-select-plan .select-plan .skltn-button, .container.vip-id .content .right .content-right-select-plan .select-plan .skltn-button {
  min-width: 76px;
  min-height: 58px;
  max-width: 76px;
  max-height: 58px;
  border-radius: 38px;
}
.container.home .content .right .content-right-select-plan .select-plan button, .container.vip-id .content .right .content-right-select-plan .select-plan button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-width: 76px;
  min-height: 58px;
  max-width: 76px;
  max-height: 58px;
  border-radius: 38px;
  border: solid 1.5px var(--borderButtonColor);
  background-color: #FFFFFF;
  color: var(--planButtonText);
  cursor: pointer;
  gap: 4px;
}
.container.home .content .right .content-right-select-plan .select-plan button svg path, .container.vip-id .content .right .content-right-select-plan .select-plan button svg path {
  stroke: var(--planButtonText);
}
.container.home .content .right .content-right-select-plan .select-plan button h3, .container.vip-id .content .right .content-right-select-plan .select-plan button h3 {
  font-size: 23px;
  font-weight: 400;
}
.container.home .content .right .content-right-select-plan .select-plan button p, .container.vip-id .content .right .content-right-select-plan .select-plan button p {
  font-size: 12px;
  font-weight: 500;
}
.container.home .content .right .content-right-select-plan .select-plan button.active-button, .container.home .content .right .content-right-select-plan .select-plan button:hover, .container.vip-id .content .right .content-right-select-plan .select-plan button.active-button, .container.vip-id .content .right .content-right-select-plan .select-plan button:hover {
  background-color: var(--planButtonActiveBack);
  color: var(--planButtonActive);
  border: none;
  transition: 0.3s;
}
.container.home .content .right .content-right-select-plan .select-plan button.active-button svg path, .container.home .content .right .content-right-select-plan .select-plan button:hover svg path, .container.vip-id .content .right .content-right-select-plan .select-plan button.active-button svg path, .container.vip-id .content .right .content-right-select-plan .select-plan button:hover svg path {
  stroke: var(--planButtonActive);
}
.container.home .content .right .content-right-select-plan .select-plan button.disabledButton:disabled, .container.vip-id .content .right .content-right-select-plan .select-plan button.disabledButton:disabled {
  display: none;
  opacity: 35%;
}
.container.home .content .right .content-right-select-plan .select-plan button.disabledButton:hover, .container.vip-id .content .right .content-right-select-plan .select-plan button.disabledButton:hover {
  background-color: var(--planButtonActive);
  border: solid 1.5px var(--borderButtonColor);
  color: var(--borderButtonColor);
  cursor: auto;
}
.container.home .content .right .content-right-select-plan .select-plan .plansDivider, .container.vip-id .content .right .content-right-select-plan .select-plan .plansDivider {
  min-height: 50%;
  max-height: 50%;
  min-width: 9px;
  border-radius: 25px;
  background-color: var(--planButtonActiveBack);
  align-self: center;
}
.container.home .content .right .content-right-select-plan .select-plan .planTextArea, .container.vip-id .content .right .content-right-select-plan .select-plan .planTextArea {
  display: flex;
  gap: 15px;
  color: var(--primaryColor);
}
.container.home .content .right .content-right-select-plan .select-plan .planTextArea .textAreaChipOnly, .container.vip-id .content .right .content-right-select-plan .select-plan .planTextArea .textAreaChipOnly {
  max-width: 90vw;
}
.container.home .content .right .content-right-select-plan .select-plan .planTextArea h1, .container.home .content .right .content-right-select-plan .select-plan .planTextArea h3, .container.home .content .right .content-right-select-plan .select-plan .planTextArea .skltn-labelPlan, .container.vip-id .content .right .content-right-select-plan .select-plan .planTextArea h1, .container.vip-id .content .right .content-right-select-plan .select-plan .planTextArea h3, .container.vip-id .content .right .content-right-select-plan .select-plan .planTextArea .skltn-labelPlan {
  font-weight: 500;
}
.container.home .content .right .content-right-select-plan .select-plan .planTextArea .labelPlan, .container.home .content .right .content-right-select-plan .select-plan .planTextArea .skltn-labelPlan, .container.vip-id .content .right .content-right-select-plan .select-plan .planTextArea .labelPlan, .container.vip-id .content .right .content-right-select-plan .select-plan .planTextArea .skltn-labelPlan {
  font-size: clamp(20px, 13vw, 60px);
}
.container.home .content .right .content-right-select-plan .select-plan .planTextArea .labelChipOnly, .container.vip-id .content .right .content-right-select-plan .select-plan .planTextArea .labelChipOnly {
  width: -moz-fit-content;
  width: fit-content;
}
.container.home .content .right .content-right-select-plan .select-plan .planTextArea p, .container.vip-id .content .right .content-right-select-plan .select-plan .planTextArea p {
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;
  max-width: 100%;
  color: var(--textColor);
}
.container.home .content .right .content-right-plan-bnfts-apps .plan-bnfts-apps, .container.vip-id .content .right .content-right-plan-bnfts-apps .plan-bnfts-apps {
  gap: 10px;
  margin-bottom: 20px;
}
.container.home .content .right .content-right-plan-bnfts-apps .plan-bnfts-apps .planBnftsAppsTitle, .container.vip-id .content .right .content-right-plan-bnfts-apps .plan-bnfts-apps .planBnftsAppsTitle {
  font-size: 20px;
  font-weight: 500;
  color: var(--titleColor);
}
.container.home .content .right .content-right-plan-bnfts-apps .plan-bnfts-apps .bnftsApps .bnftsApp, .container.vip-id .content .right .content-right-plan-bnfts-apps .plan-bnfts-apps .bnftsApps .bnftsApp {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 0 5px;
}
.container.home .content .right .content-right-plan-bnfts-apps .plan-bnfts-apps .bnftsApps .bnftsApp p, .container.vip-id .content .right .content-right-plan-bnfts-apps .plan-bnfts-apps .bnftsApps .bnftsApp p {
  font-size: clamp(13px, 1.25vw, 16px);
  color: var(--textColor);
}
.container.home .content .right .content-right-activated-bnfts .activated-bnfts, .container.home .content .right .content-right-activated-bnfts .activated-bnfts-flw, .container.home .content .right .content-right-activated-bnfts-flw .activated-bnfts, .container.home .content .right .content-right-activated-bnfts-flw .activated-bnfts-flw, .container.vip-id .content .right .content-right-activated-bnfts .activated-bnfts, .container.vip-id .content .right .content-right-activated-bnfts .activated-bnfts-flw, .container.vip-id .content .right .content-right-activated-bnfts-flw .activated-bnfts, .container.vip-id .content .right .content-right-activated-bnfts-flw .activated-bnfts-flw {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
  color: var(--planButtonText);
}
.container.home .content .right .content-right-activated-bnfts .activated-bnfts .actvBnftsTitle, .container.home .content .right .content-right-activated-bnfts .activated-bnfts-flw .actvBnftsTitle, .container.home .content .right .content-right-activated-bnfts-flw .activated-bnfts .actvBnftsTitle, .container.home .content .right .content-right-activated-bnfts-flw .activated-bnfts-flw .actvBnftsTitle, .container.vip-id .content .right .content-right-activated-bnfts .activated-bnfts .actvBnftsTitle, .container.vip-id .content .right .content-right-activated-bnfts .activated-bnfts-flw .actvBnftsTitle, .container.vip-id .content .right .content-right-activated-bnfts-flw .activated-bnfts .actvBnftsTitle, .container.vip-id .content .right .content-right-activated-bnfts-flw .activated-bnfts-flw .actvBnftsTitle {
  font-size: 20px;
  font-weight: 500;
  color: var(--titleColor);
}
.container.home .content .right .content-right-activated-bnfts .activated-bnfts .actvPortability, .container.home .content .right .content-right-activated-bnfts .activated-bnfts-flw .actvPortability, .container.home .content .right .content-right-activated-bnfts-flw .activated-bnfts .actvPortability, .container.home .content .right .content-right-activated-bnfts-flw .activated-bnfts-flw .actvPortability, .container.vip-id .content .right .content-right-activated-bnfts .activated-bnfts .actvPortability, .container.vip-id .content .right .content-right-activated-bnfts .activated-bnfts-flw .actvPortability, .container.vip-id .content .right .content-right-activated-bnfts-flw .activated-bnfts .actvPortability, .container.vip-id .content .right .content-right-activated-bnfts-flw .activated-bnfts-flw .actvPortability {
  display: flex;
  align-items: center;
}
.container.home .content .right .content-right-activated-bnfts .activated-bnfts .actvPortability .actvBnftsIcon, .container.home .content .right .content-right-activated-bnfts .activated-bnfts-flw .actvPortability .actvBnftsIcon, .container.home .content .right .content-right-activated-bnfts-flw .activated-bnfts .actvPortability .actvBnftsIcon, .container.home .content .right .content-right-activated-bnfts-flw .activated-bnfts-flw .actvPortability .actvBnftsIcon, .container.vip-id .content .right .content-right-activated-bnfts .activated-bnfts .actvPortability .actvBnftsIcon, .container.vip-id .content .right .content-right-activated-bnfts .activated-bnfts-flw .actvPortability .actvBnftsIcon, .container.vip-id .content .right .content-right-activated-bnfts-flw .activated-bnfts .actvPortability .actvBnftsIcon, .container.vip-id .content .right .content-right-activated-bnfts-flw .activated-bnfts-flw .actvPortability .actvBnftsIcon {
  display: flex;
  align-items: center;
  justify-content: center;
}
.container.home .content .right .content-right-activated-bnfts .activated-bnfts .actvPortability .actvBnftsIcon svg, .container.home .content .right .content-right-activated-bnfts .activated-bnfts-flw .actvPortability .actvBnftsIcon svg, .container.home .content .right .content-right-activated-bnfts-flw .activated-bnfts .actvPortability .actvBnftsIcon svg, .container.home .content .right .content-right-activated-bnfts-flw .activated-bnfts-flw .actvPortability .actvBnftsIcon svg, .container.vip-id .content .right .content-right-activated-bnfts .activated-bnfts .actvPortability .actvBnftsIcon svg, .container.vip-id .content .right .content-right-activated-bnfts .activated-bnfts-flw .actvPortability .actvBnftsIcon svg, .container.vip-id .content .right .content-right-activated-bnfts-flw .activated-bnfts .actvPortability .actvBnftsIcon svg, .container.vip-id .content .right .content-right-activated-bnfts-flw .activated-bnfts-flw .actvPortability .actvBnftsIcon svg {
  width: 35px;
  height: 35px;
}
.container.home .content .right .content-right-activated-bnfts .activated-bnfts .actvPortability .actvBnftsIcon svg rect, .container.home .content .right .content-right-activated-bnfts .activated-bnfts-flw .actvPortability .actvBnftsIcon svg rect, .container.home .content .right .content-right-activated-bnfts-flw .activated-bnfts .actvPortability .actvBnftsIcon svg rect, .container.home .content .right .content-right-activated-bnfts-flw .activated-bnfts-flw .actvPortability .actvBnftsIcon svg rect, .container.vip-id .content .right .content-right-activated-bnfts .activated-bnfts .actvPortability .actvBnftsIcon svg rect, .container.vip-id .content .right .content-right-activated-bnfts .activated-bnfts-flw .actvPortability .actvBnftsIcon svg rect, .container.vip-id .content .right .content-right-activated-bnfts-flw .activated-bnfts .actvPortability .actvBnftsIcon svg rect, .container.vip-id .content .right .content-right-activated-bnfts-flw .activated-bnfts-flw .actvPortability .actvBnftsIcon svg rect {
  fill: var(--detailBackColor);
}
.container.home .content .right .content-right-activated-bnfts .activated-bnfts .actvPortability .actvBnftsIcon svg path, .container.home .content .right .content-right-activated-bnfts .activated-bnfts-flw .actvPortability .actvBnftsIcon svg path, .container.home .content .right .content-right-activated-bnfts-flw .activated-bnfts .actvPortability .actvBnftsIcon svg path, .container.home .content .right .content-right-activated-bnfts-flw .activated-bnfts-flw .actvPortability .actvBnftsIcon svg path, .container.vip-id .content .right .content-right-activated-bnfts .activated-bnfts .actvPortability .actvBnftsIcon svg path, .container.vip-id .content .right .content-right-activated-bnfts .activated-bnfts-flw .actvPortability .actvBnftsIcon svg path, .container.vip-id .content .right .content-right-activated-bnfts-flw .activated-bnfts .actvPortability .actvBnftsIcon svg path, .container.vip-id .content .right .content-right-activated-bnfts-flw .activated-bnfts-flw .actvPortability .actvBnftsIcon svg path {
  stroke: var(--detailTextColor);
}
.container.home .content .right .content-right-activated-bnfts .activated-bnfts .actvPortability .actvBnftsText, .container.home .content .right .content-right-activated-bnfts .activated-bnfts-flw .actvPortability .actvBnftsText, .container.home .content .right .content-right-activated-bnfts-flw .activated-bnfts .actvPortability .actvBnftsText, .container.home .content .right .content-right-activated-bnfts-flw .activated-bnfts-flw .actvPortability .actvBnftsText, .container.vip-id .content .right .content-right-activated-bnfts .activated-bnfts .actvPortability .actvBnftsText, .container.vip-id .content .right .content-right-activated-bnfts .activated-bnfts-flw .actvPortability .actvBnftsText, .container.vip-id .content .right .content-right-activated-bnfts-flw .activated-bnfts .actvPortability .actvBnftsText, .container.vip-id .content .right .content-right-activated-bnfts-flw .activated-bnfts-flw .actvPortability .actvBnftsText {
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex: 1;
  margin: 0 15px;
}
.container.home .content .right .content-right-activated-bnfts .activated-bnfts .actvPortability .actvBnftsText h3, .container.home .content .right .content-right-activated-bnfts .activated-bnfts-flw .actvPortability .actvBnftsText h3, .container.home .content .right .content-right-activated-bnfts-flw .activated-bnfts .actvPortability .actvBnftsText h3, .container.home .content .right .content-right-activated-bnfts-flw .activated-bnfts-flw .actvPortability .actvBnftsText h3, .container.vip-id .content .right .content-right-activated-bnfts .activated-bnfts .actvPortability .actvBnftsText h3, .container.vip-id .content .right .content-right-activated-bnfts .activated-bnfts-flw .actvPortability .actvBnftsText h3, .container.vip-id .content .right .content-right-activated-bnfts-flw .activated-bnfts .actvPortability .actvBnftsText h3, .container.vip-id .content .right .content-right-activated-bnfts-flw .activated-bnfts-flw .actvPortability .actvBnftsText h3 {
  font-size: 14px;
  font-weight: 500;
  color: var(--titleColor);
}
.container.home .content .right .content-right-activated-bnfts .activated-bnfts .actvPortability .actvBnftsText p, .container.home .content .right .content-right-activated-bnfts .activated-bnfts-flw .actvPortability .actvBnftsText p, .container.home .content .right .content-right-activated-bnfts-flw .activated-bnfts .actvPortability .actvBnftsText p, .container.home .content .right .content-right-activated-bnfts-flw .activated-bnfts-flw .actvPortability .actvBnftsText p, .container.vip-id .content .right .content-right-activated-bnfts .activated-bnfts .actvPortability .actvBnftsText p, .container.vip-id .content .right .content-right-activated-bnfts .activated-bnfts-flw .actvPortability .actvBnftsText p, .container.vip-id .content .right .content-right-activated-bnfts-flw .activated-bnfts .actvPortability .actvBnftsText p, .container.vip-id .content .right .content-right-activated-bnfts-flw .activated-bnfts-flw .actvPortability .actvBnftsText p {
  font-size: 12px;
  color: var(--annualTextColor);
}
.container.home .content .right .content-right-activated-bnfts .activated-bnfts .actvPortability .actvBnftsDetail, .container.home .content .right .content-right-activated-bnfts .activated-bnfts-flw .actvPortability .actvBnftsDetail, .container.home .content .right .content-right-activated-bnfts-flw .activated-bnfts .actvPortability .actvBnftsDetail, .container.home .content .right .content-right-activated-bnfts-flw .activated-bnfts-flw .actvPortability .actvBnftsDetail, .container.vip-id .content .right .content-right-activated-bnfts .activated-bnfts .actvPortability .actvBnftsDetail, .container.vip-id .content .right .content-right-activated-bnfts .activated-bnfts-flw .actvPortability .actvBnftsDetail, .container.vip-id .content .right .content-right-activated-bnfts-flw .activated-bnfts .actvPortability .actvBnftsDetail, .container.vip-id .content .right .content-right-activated-bnfts-flw .activated-bnfts-flw .actvPortability .actvBnftsDetail {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--detailBackColor);
  border-radius: 27px;
  padding: 0 10px;
  height: 31px;
}
.container.home .content .right .content-right-activated-bnfts .activated-bnfts .actvPortability .actvBnftsDetail p, .container.home .content .right .content-right-activated-bnfts .activated-bnfts-flw .actvPortability .actvBnftsDetail p, .container.home .content .right .content-right-activated-bnfts-flw .activated-bnfts .actvPortability .actvBnftsDetail p, .container.home .content .right .content-right-activated-bnfts-flw .activated-bnfts-flw .actvPortability .actvBnftsDetail p, .container.vip-id .content .right .content-right-activated-bnfts .activated-bnfts .actvPortability .actvBnftsDetail p, .container.vip-id .content .right .content-right-activated-bnfts .activated-bnfts-flw .actvPortability .actvBnftsDetail p, .container.vip-id .content .right .content-right-activated-bnfts-flw .activated-bnfts .actvPortability .actvBnftsDetail p, .container.vip-id .content .right .content-right-activated-bnfts-flw .activated-bnfts-flw .actvPortability .actvBnftsDetail p {
  font-size: 14px;
  font-weight: 600;
  color: var(--detailTextColor);
}
.container.home .content .right .content-right-activated-bnfts .activated-bnfts .divider, .container.home .content .right .content-right-activated-bnfts .activated-bnfts-flw .divider, .container.home .content .right .content-right-activated-bnfts-flw .activated-bnfts .divider, .container.home .content .right .content-right-activated-bnfts-flw .activated-bnfts-flw .divider, .container.vip-id .content .right .content-right-activated-bnfts .activated-bnfts .divider, .container.vip-id .content .right .content-right-activated-bnfts .activated-bnfts-flw .divider, .container.vip-id .content .right .content-right-activated-bnfts-flw .activated-bnfts .divider, .container.vip-id .content .right .content-right-activated-bnfts-flw .activated-bnfts-flw .divider {
  width: 100%;
  height: 2px;
  border-color: var(--annualTextColor);
  opacity: 0.4;
}
.container.home .content .right .content-right-plan-details-text, .container.vip-id .content .right .content-right-plan-details-text {
  width: 100%;
  margin-bottom: 42px;
}
.container.home .content .right .content-right-plan-details-text .plan-details-text, .container.vip-id .content .right .content-right-plan-details-text .plan-details-text {
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 20px;
}
.container.home .content .right .content-right-plan-details-text .plan-details-text h3, .container.vip-id .content .right .content-right-plan-details-text .plan-details-text h3 {
  font-size: 20px;
  font-weight: 500;
  text-align: start;
  color: var(--primaryColor);
}
.container.home .content .right .content-right-plan-details-text .plan-details-text ul, .container.vip-id .content .right .content-right-plan-details-text .plan-details-text ul {
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: space-between;
  max-width: 480px;
}
.container.home .content .right .content-right-plan-details-text .plan-details-text ul li, .container.vip-id .content .right .content-right-plan-details-text .plan-details-text ul li {
  text-align: start;
}
.container.home .content .right .content-right-plan-details-text .plan-details-text ul li.plan-details-text-bonus, .container.vip-id .content .right .content-right-plan-details-text .plan-details-text ul li.plan-details-text-bonus {
  display: none;
}
.container.home .content .right .content-right-plan-details-text .plan-details-text ul h3, .container.vip-id .content .right .content-right-plan-details-text .plan-details-text ul h3 {
  font-size: 14px;
  font-weight: 500;
}
.container.home .content .right .content-right-plan-details-text .plan-details-text ul p, .container.vip-id .content .right .content-right-plan-details-text .plan-details-text ul p {
  font-size: 12px;
  font-weight: 400;
  color: var(--textColor);
  opacity: 1;
}
.container.home .content .right .choosePortability, .container.vip-id .content .right .choosePortability {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 35px;
  white-space: nowrap;
  flex-wrap: nowrap;
  max-width: 100%;
}
.container.home .content .right .choosePortability .choosePortabilitySwitch, .container.vip-id .content .right .choosePortability .choosePortabilitySwitch {
  display: flex;
  align-items: center;
}
.container.home .content .right .choosePortability .choosePortabilitySwitch .MuiSwitch-root, .container.vip-id .content .right .choosePortability .choosePortabilitySwitch .MuiSwitch-root {
  width: 48px;
  height: 27px;
  padding: 2px;
}
.container.home .content .right .choosePortability .choosePortabilitySwitch .MuiSwitch-root .MuiSwitch-thumb, .container.vip-id .content .right .choosePortability .choosePortabilitySwitch .MuiSwitch-root .MuiSwitch-thumb {
  box-sizing: border-box;
  width: 18px;
  height: 18px;
  margin-top: 3px;
  margin-left: 4px;
  transition: background-color 300ms;
  background-color: var(--primarySwitchColor);
}
.container.home .content .right .choosePortability .choosePortabilitySwitch .MuiSwitch-root .MuiSwitch-track, .container.vip-id .content .right .choosePortability .choosePortabilitySwitch .MuiSwitch-root .MuiSwitch-track {
  background-color: transparent;
  border-radius: 13px;
  border: solid 1px var(--primarySwitchColor);
  opacity: 1;
  transition: background-color 300ms, border-color 300ms;
}
.container.home .content .right .choosePortability .choosePortabilitySwitch .MuiSwitch-root .MuiSwitch-switchBase, .container.vip-id .content .right .choosePortability .choosePortabilitySwitch .MuiSwitch-root .MuiSwitch-switchBase {
  padding: 0;
  margin: 2px;
  transition-duration: 300ms;
}
.container.home .content .right .choosePortability .choosePortabilitySwitch .MuiSwitch-root .MuiSwitch-switchBase.Mui-checked, .container.vip-id .content .right .choosePortability .choosePortabilitySwitch .MuiSwitch-root .MuiSwitch-switchBase.Mui-checked {
  transform: translateX(19px);
}
.container.home .content .right .choosePortability .choosePortabilitySwitch .MuiSwitch-root .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track, .container.vip-id .content .right .choosePortability .choosePortabilitySwitch .MuiSwitch-root .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
  opacity: 1;
  background-color: var(--primarySwitchColor);
  border: solid 1px var(--primarySwitchColor);
}
.container.home .content .right .choosePortability .choosePortabilitySwitch .MuiSwitch-root .MuiSwitch-switchBase.Mui-checked .MuiSwitch-thumb, .container.vip-id .content .right .choosePortability .choosePortabilitySwitch .MuiSwitch-root .MuiSwitch-switchBase.Mui-checked .MuiSwitch-thumb {
  background-color: var(--secondarySwitchColor);
}
.container.home .content .right .choosePortability .choosePortabilitySwitch .MuiSwitch-root .MuiSwitch-switchBase.Mui-disabled + .MuiSwitch-track, .container.vip-id .content .right .choosePortability .choosePortabilitySwitch .MuiSwitch-root .MuiSwitch-switchBase.Mui-disabled + .MuiSwitch-track {
  opacity: 1;
}
.container.home .content .right .choosePortability .choosePortabilityText, .container.vip-id .content .right .choosePortability .choosePortabilityText {
  flex-direction: column;
  display: flex;
  justify-content: center;
  flex: 1;
  margin: 0 15px;
}
.container.home .content .right .choosePortability .choosePortabilityText h3, .container.vip-id .content .right .choosePortability .choosePortabilityText h3 {
  font-size: 14px;
  font-weight: 500;
  color: var(--titleColor);
  white-space: normal;
}
.container.home .content .right .choosePortability .choosePortabilityText p, .container.vip-id .content .right .choosePortability .choosePortabilityText p {
  font-size: 12px;
  font-weight: 400;
  color: var(--annualTextColor);
  opacity: 0.9;
  white-space: normal;
}
.container.home .content .right .choosePortability .choosePortabilityDetail, .container.vip-id .content .right .choosePortability .choosePortabilityDetail {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primaryColor);
  border-radius: 27px;
  padding: 0 10px;
  height: 31px;
}
.container.home .content .right .choosePortability .choosePortabilityDetail p, .container.vip-id .content .right .choosePortability .choosePortabilityDetail p {
  font-size: 14px;
  font-weight: 600;
  color: #FFFFFF;
}
.container.home .content .right .choosePortability .choosePortabilityDetail span, .container.vip-id .content .right .choosePortability .choosePortabilityDetail span {
  display: none;
}
.container.home .content .right .monthlyAnnualCards, .container.vip-id .content .right .monthlyAnnualCards {
  gap: 15px;
}
.container.home .content .right .monthlyAnnualCards .skltn-monthlyAnnualCard, .container.vip-id .content .right .monthlyAnnualCards .skltn-monthlyAnnualCard {
  height: 94px;
  border-radius: 10px;
}
.container.home .content .right .monthlyAnnualCards .monthlyAnnualCard, .container.vip-id .content .right .monthlyAnnualCards .monthlyAnnualCard {
  padding: 22px 25px;
  border: solid 1px var(--annualBorderColor);
  border-radius: 10px;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.container.home .content .right .monthlyAnnualCards .monthlyAnnualCard .monthlyAnnualTitle, .container.vip-id .content .right .monthlyAnnualCards .monthlyAnnualCard .monthlyAnnualTitle {
  align-items: center;
  gap: 10px;
  position: relative;
}
.container.home .content .right .monthlyAnnualCards .monthlyAnnualCard .monthlyAnnualTitle h3, .container.vip-id .content .right .monthlyAnnualCards .monthlyAnnualCard .monthlyAnnualTitle h3 {
  font-size: 16px;
  font-weight: 600;
  color: var(--annualTextColor);
}
.container.home .content .right .monthlyAnnualCards .monthlyAnnualCard .monthlyAnnualTitle .monthlyAnnualDetails, .container.vip-id .content .right .monthlyAnnualCards .monthlyAnnualCard .monthlyAnnualTitle .monthlyAnnualDetails {
  padding: 3px 5px 1px 5px;
  background-color: var(--primaryColor);
  color: #FFFFFF;
  border-radius: 4px;
  font-size: 8px;
  font-weight: 600;
  position: absolute;
  transform: translate(65px);
}
.container.home .content .right .monthlyAnnualCards .monthlyAnnualCard .monthlyAnnualTitle .monthlyAnnualDetails.annualDetails, .container.vip-id .content .right .monthlyAnnualCards .monthlyAnnualCard .monthlyAnnualTitle .monthlyAnnualDetails.annualDetails {
  display: flex;
  transform: translate(54px);
}
.container.home .content .right .monthlyAnnualCards .monthlyAnnualCard .monthlyAnnualTextArea, .container.vip-id .content .right .monthlyAnnualCards .monthlyAnnualCard .monthlyAnnualTextArea {
  gap: 5px;
}
.container.home .content .right .monthlyAnnualCards .monthlyAnnualCard .monthlyAnnualTextArea p, .container.vip-id .content .right .monthlyAnnualCards .monthlyAnnualCard .monthlyAnnualTextArea p {
  font-size: 12px;
  font-weight: 400;
  color: var(--annualTextColor);
}
.container.home .content .right .monthlyAnnualCards .monthlyAnnualCard .monthlyAnnualPriceArea, .container.vip-id .content .right .monthlyAnnualCards .monthlyAnnualCard .monthlyAnnualPriceArea {
  display: flex;
  align-items: center;
  justify-content: right;
  flex-wrap: nowrap;
  gap: 5px;
}
.container.home .content .right .monthlyAnnualCards .monthlyAnnualCard .monthlyAnnualPriceArea h3, .container.vip-id .content .right .monthlyAnnualCards .monthlyAnnualCard .monthlyAnnualPriceArea h3 {
  font-size: 17px;
  font-weight: 500;
  color: var(--annualTextColor);
}
.container.home .content .right .monthlyAnnualCards .monthlyAnnualCard .monthlyAnnualPriceArea h3.oldPrice, .container.vip-id .content .right .monthlyAnnualCards .monthlyAnnualCard .monthlyAnnualPriceArea h3.oldPrice {
  font-size: 14px;
  font-weight: 500;
  color: var(--annualTextColor);
  opacity: 0.75;
  text-decoration: line-through;
}
.container.home .content .right .monthlyAnnualCards .monthlyAnnualCard .monthlyAnnualPriceArea svg, .container.vip-id .content .right .monthlyAnnualCards .monthlyAnnualCard .monthlyAnnualPriceArea svg {
  width: 18px;
}
.container.home .content .right .monthlyAnnualCards .monthlyAnnualCard .monthlyAnnualPriceArea svg path, .container.vip-id .content .right .monthlyAnnualCards .monthlyAnnualCard .monthlyAnnualPriceArea svg path {
  stroke: var(--monthlyColorBase);
}
.container.home .content .right .monthlyAnnualCards .monthlyAnnualCard.monthly, .container.vip-id .content .right .monthlyAnnualCards .monthlyAnnualCard.monthly {
  background-color: var(--monthlyBackColor);
  border: none;
}
.container.home .content .right .monthlyAnnualCards .monthlyAnnualCard.monthly .monthlyAnnualPriceArea .annualPriceArea, .container.vip-id .content .right .monthlyAnnualCards .monthlyAnnualCard.monthly .monthlyAnnualPriceArea .annualPriceArea {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.container.home .content .right .monthlyAnnualCards .monthlyAnnualCard.monthly .monthlyAnnualTitle h3, .container.vip-id .content .right .monthlyAnnualCards .monthlyAnnualCard.monthly .monthlyAnnualTitle h3 {
  color: var(--monthlyColorBase);
}
.container.home .content .right .monthlyAnnualCards .monthlyAnnualCard.monthly .monthlyAnnualTitle .monthlyAnnualDetails, .container.vip-id .content .right .monthlyAnnualCards .monthlyAnnualCard.monthly .monthlyAnnualTitle .monthlyAnnualDetails {
  background-color: var(--monthlyDetailsBackColor);
  color: var(--monthlyDetailsColor);
}
.container.home .content .right .monthlyAnnualCards .monthlyAnnualCard.monthly .monthlyAnnualTextArea p, .container.vip-id .content .right .monthlyAnnualCards .monthlyAnnualCard.monthly .monthlyAnnualTextArea p {
  font-size: 13px;
  color: var(--monthlyColorBase);
}
.container.home .content .right .monthlyAnnualCards .monthlyAnnualCard.monthly .monthlyAnnualPriceArea h3, .container.vip-id .content .right .monthlyAnnualCards .monthlyAnnualCard.monthly .monthlyAnnualPriceArea h3 {
  color: var(--monthlyColorBase);
  font-weight: 600;
}
.container.home .content .right .monthlyAnnualCards .monthlyAnnualCard.chipOnly, .container.vip-id .content .right .monthlyAnnualCards .monthlyAnnualCard.chipOnly {
  background-color: var(--monthlyBackColor);
  border: none;
}
.container.home .content .right .monthlyAnnualCards .monthlyAnnualCard.chipOnly .monthlyAnnualChipCounter .numberCounter, .container.vip-id .content .right .monthlyAnnualCards .monthlyAnnualCard.chipOnly .monthlyAnnualChipCounter .numberCounter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid var(--monthlyColorBase);
  border-radius: 10px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  color: var(--monthlyColorBase);
}
.container.home .content .right .monthlyAnnualCards .monthlyAnnualCard.chipOnly .monthlyAnnualChipCounter .numberCounter .counterIcon, .container.vip-id .content .right .monthlyAnnualCards .monthlyAnnualCard.chipOnly .monthlyAnnualChipCounter .numberCounter .counterIcon {
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  height: 100%;
  padding: 3px;
}
.container.home .content .right .monthlyAnnualCards .monthlyAnnualCard.chipOnly .monthlyAnnualChipCounter .numberCounter .counterIcon svg, .container.vip-id .content .right .monthlyAnnualCards .monthlyAnnualCard.chipOnly .monthlyAnnualChipCounter .numberCounter .counterIcon svg {
  width: 80%;
  height: 80%;
}
.container.home .content .right .monthlyAnnualCards .monthlyAnnualCard.chipOnly .monthlyAnnualChipCounter .numberCounter .counterIcon svg path, .container.vip-id .content .right .monthlyAnnualCards .monthlyAnnualCard.chipOnly .monthlyAnnualChipCounter .numberCounter .counterIcon svg path {
  fill: var(--monthlyColorBase);
}
.container.home .content .right .monthlyAnnualCards .monthlyAnnualCard.chipOnly .monthlyAnnualChipCounter .numberCounter .counterText, .container.vip-id .content .right .monthlyAnnualCards .monthlyAnnualCard.chipOnly .monthlyAnnualChipCounter .numberCounter .counterText {
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-width: 0;
}
.container.home .content .right .monthlyAnnualCards .monthlyAnnualCard.chipOnly .monthlyAnnualTitle h3, .container.vip-id .content .right .monthlyAnnualCards .monthlyAnnualCard.chipOnly .monthlyAnnualTitle h3 {
  color: var(--monthlyColorBase);
}
.container.home .content .right .monthlyAnnualCards .monthlyAnnualCard.chipOnly .monthlyAnnualTitle .monthlyAnnualDetails, .container.vip-id .content .right .monthlyAnnualCards .monthlyAnnualCard.chipOnly .monthlyAnnualTitle .monthlyAnnualDetails {
  transform: translate(90px);
}
.container.home .content .right .monthlyAnnualCards .monthlyAnnualCard.chipOnly .monthlyAnnualTextArea, .container.vip-id .content .right .monthlyAnnualCards .monthlyAnnualCard.chipOnly .monthlyAnnualTextArea {
  padding: 0 4% 0 4%;
}
.container.home .content .right .monthlyAnnualCards .monthlyAnnualCard.chipOnly .monthlyAnnualTextArea p, .container.vip-id .content .right .monthlyAnnualCards .monthlyAnnualCard.chipOnly .monthlyAnnualTextArea p {
  font-size: 13px;
  color: var(--monthlyColorBase);
}
.container.home .content .right .monthlyAnnualCards .monthlyAnnualCard.chipOnly .monthlyAnnualPriceArea h3, .container.vip-id .content .right .monthlyAnnualCards .monthlyAnnualCard.chipOnly .monthlyAnnualPriceArea h3 {
  color: var(--monthlyColorBase);
  font-weight: 600;
}
.container.home .footer, .container.vip-id .footer {
  display: flex;
  height: 32vh;
  background-color: white;
  padding: 60px 20px;
}
@media (max-width: 325px) {
  .container.home .content .right, .container.vip-id .content .right {
    /* SELECT PLAN */
  }
  .container.home .content .right .content-right-select-plan .select-plan .planTextArea h1, .container.home .content .right .content-right-select-plan .select-plan .planTextArea h3, .container.home .content .right .content-right-select-plan .select-plan .planTextArea .skltn-labelPlan, .container.vip-id .content .right .content-right-select-plan .select-plan .planTextArea h1, .container.vip-id .content .right .content-right-select-plan .select-plan .planTextArea h3, .container.vip-id .content .right .content-right-select-plan .select-plan .planTextArea .skltn-labelPlan {
    font-size: 45px;
  }
}
@media (min-width: 900px) {
  .container.home, .container.vip-id {
    display: flex;
    align-items: center;
    height: 100vh;
    --titleColor: #686868;
  }
  .container.home .content, .container.vip-id .content {
    display: flex;
    flex-wrap: nowrap;
    height: 100%;
    max-height: 605px;
    padding: 1.5rem 2rem;
    overflow: hidden;
  }
  .container.home .content .container-left, .container.vip-id .content .container-left {
    display: flex;
    width: 170%;
    padding: 0;
    background-color: var(--primaryColor);
    max-height: unset;
    overflow: unset;
  }
  .container.home .content .container-right, .container.vip-id .content .container-right {
    display: flex;
    padding: 0 0 0 1rem;
    background-color: var(--primaryColor);
  }
  .container.home .content .left, .container.vip-id .content .left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-self: flex-start;
    height: calc(100vh - 2.5rem);
    padding: 1.25rem 0.75rem;
    position: relative;
    /* HEADING */
    /* SLOGAN */
    /* BACK IMAGE */
    /* DETAILS */
    /* UNCOMPLICATE BUTTON */
  }
  .container.home .content .left .content-left-heading, .container.vip-id .content .left .content-left-heading {
    align-self: flex-start;
    width: auto;
  }
  .container.home .content .left .content-left-heading .heading img, .container.vip-id .content .left .content-left-heading .heading img {
    width: 130px;
  }
  .container.home .content .left .content-left-slogan, .container.vip-id .content .left .content-left-slogan {
    justify-content: start;
    text-align: initial;
    padding: 0;
    margin-top: -190px;
  }
  .container.home .content .left .content-left-slogan .slogan, .container.vip-id .content .left .content-left-slogan .slogan {
    max-width: 170px;
  }
  .container.home .content .left .content-left-slogan .slogan h3, .container.vip-id .content .left .content-left-slogan .slogan h3 {
    font-size: 18px;
  }
  .container.home .content .left .content-left-back-image, .container.vip-id .content .left .content-left-back-image {
    display: flex;
    margin: 0;
    width: 100vw;
    height: 100vh;
    top: -1.5rem;
    left: -2rem;
    position: absolute;
  }
  .container.home .content .left .content-left-back-image .back-image, .container.vip-id .content .left .content-left-back-image .back-image {
    height: 100%;
    position: relative;
  }
  .container.home .content .left .content-left-back-image img, .container.vip-id .content .left .content-left-back-image img {
    -o-object-fit: unset;
       object-fit: unset;
    -o-object-position: unset;
       object-position: unset;
    width: 65%;
    position: absolute;
    top: -28px;
    left: 0px;
  }
  .container.home .content .left .content-left-details .details, .container.vip-id .content .left .content-left-details .details {
    display: none;
  }
  .container.home .content .right, .container.vip-id .content .right {
    flex-direction: column;
    justify-content: space-between;
    padding: 1.5rem;
    background-color: #FFF;
    border-radius: 1.75rem;
    z-index: 100;
    /* TIMER */
    /* CHOOSE PLAN TEXT */
    /* SELECT PLAN */
    /* PLAN BENEFITS APPS */
    /* ACTIVATED BENEFITS */
    /* PLAN DETAILS TEXT */
    /* SELECT PLAN DETAILS */
  }
  .container.home .content .right .content-right-choose-plan-text, .container.vip-id .content .right .content-right-choose-plan-text {
    margin-bottom: 0;
  }
  .container.home .content .right .content-right-choose-plan-text h3, .container.vip-id .content .right .content-right-choose-plan-text h3 {
    font-size: 11px;
  }
  .container.home .content .right .content-right-choose-plan-text p, .container.vip-id .content .right .content-right-choose-plan-text p {
    font-size: 9px;
  }
  .container.home .content .right .content-right-select-plan, .container.vip-id .content .right .content-right-select-plan {
    margin-bottom: 0;
  }
  .container.home .content .right .content-right-select-plan .selectArea, .container.vip-id .content .right .content-right-select-plan .selectArea {
    margin: 0;
  }
  .container.home .content .right .content-right-select-plan .selectArea .buttonLabel, .container.vip-id .content .right .content-right-select-plan .selectArea .buttonLabel {
    padding: 1px 6px 0 6px;
    border: solid 1px #FFF;
    font-size: 7px;
    height: 13px;
    position: absolute;
    transform: translate(0, 13px);
    margin-left: 0;
    right: 5px;
  }
  .container.home .content .right .content-right-select-plan .containerCarousel, .container.vip-id .content .right .content-right-select-plan .containerCarousel {
    width: 100%;
    padding: 5px 0 0 2px;
    margin-right: 0;
    gap: 0;
    justify-content: space-between;
    align-items: center;
    overflow-x: unset;
    position: relative;
  }
  .container.home .content .right .content-right-select-plan .select-plan .skltn-button, .container.vip-id .content .right .content-right-select-plan .select-plan .skltn-button {
    min-width: 38px;
    min-height: 28px;
    max-width: 76px;
    max-height: 58px;
  }
  .container.home .content .right .content-right-select-plan .select-plan button, .container.vip-id .content .right .content-right-select-plan .select-plan button {
    min-width: 38px;
    min-height: 28px;
    gap: 2px;
  }
  .container.home .content .right .content-right-select-plan .select-plan button h3, .container.vip-id .content .right .content-right-select-plan .select-plan button h3 {
    font-size: 12px;
  }
  .container.home .content .right .content-right-select-plan .select-plan button p, .container.vip-id .content .right .content-right-select-plan .select-plan button p {
    font-size: 6px;
  }
  .container.home .content .right .content-right-select-plan .select-plan .planTextArea, .container.vip-id .content .right .content-right-select-plan .select-plan .planTextArea {
    gap: 8px;
  }
  .container.home .content .right .content-right-select-plan .select-plan .planTextArea h1, .container.home .content .right .content-right-select-plan .select-plan .planTextArea h3, .container.home .content .right .content-right-select-plan .select-plan .planTextArea .skltn-labelPlan, .container.home .content .right .content-right-select-plan .select-plan .planTextArea .labelChipOnly, .container.home .content .right .content-right-select-plan .select-plan .planTextArea .labelPlan, .container.vip-id .content .right .content-right-select-plan .select-plan .planTextArea h1, .container.vip-id .content .right .content-right-select-plan .select-plan .planTextArea h3, .container.vip-id .content .right .content-right-select-plan .select-plan .planTextArea .skltn-labelPlan, .container.vip-id .content .right .content-right-select-plan .select-plan .planTextArea .labelChipOnly, .container.vip-id .content .right .content-right-select-plan .select-plan .planTextArea .labelPlan {
    font-size: 32px;
  }
  .container.home .content .right .content-right-select-plan .select-plan .planTextArea p, .container.vip-id .content .right .content-right-select-plan .select-plan .planTextArea p {
    font-size: 10px;
  }
  .container.home .content .right .content-right-plan-bnfts-apps .plan-bnfts-apps .planBnftsAppsTitle, .container.vip-id .content .right .content-right-plan-bnfts-apps .plan-bnfts-apps .planBnftsAppsTitle {
    font-size: 11px;
  }
  .container.home .content .right .content-right-plan-bnfts-apps .plan-bnfts-apps .bnftsApps .bnftsApp p, .container.vip-id .content .right .content-right-plan-bnfts-apps .plan-bnfts-apps .bnftsApps .bnftsApp p {
    font-size: clamp(9px, 1.15vw, 16px);
  }
  .container.home .content .right .content-right-activated-bnfts .activated-bnfts, .container.home .content .right .content-right-activated-bnfts .activated-bnfts-flw, .container.home .content .right .content-right-activated-bnfts-flw .activated-bnfts, .container.home .content .right .content-right-activated-bnfts-flw .activated-bnfts-flw, .container.vip-id .content .right .content-right-activated-bnfts .activated-bnfts, .container.vip-id .content .right .content-right-activated-bnfts .activated-bnfts-flw, .container.vip-id .content .right .content-right-activated-bnfts-flw .activated-bnfts, .container.vip-id .content .right .content-right-activated-bnfts-flw .activated-bnfts-flw {
    gap: 10px;
    margin-bottom: 10px;
  }
  .container.home .content .right .content-right-activated-bnfts .activated-bnfts .actvBnftsTitle, .container.home .content .right .content-right-activated-bnfts .activated-bnfts-flw .actvBnftsTitle, .container.home .content .right .content-right-activated-bnfts-flw .activated-bnfts .actvBnftsTitle, .container.home .content .right .content-right-activated-bnfts-flw .activated-bnfts-flw .actvBnftsTitle, .container.vip-id .content .right .content-right-activated-bnfts .activated-bnfts .actvBnftsTitle, .container.vip-id .content .right .content-right-activated-bnfts .activated-bnfts-flw .actvBnftsTitle, .container.vip-id .content .right .content-right-activated-bnfts-flw .activated-bnfts .actvBnftsTitle, .container.vip-id .content .right .content-right-activated-bnfts-flw .activated-bnfts-flw .actvBnftsTitle {
    font-size: 11px;
  }
  .container.home .content .right .content-right-activated-bnfts .activated-bnfts .actvPortability .actvBnftsIcon svg, .container.home .content .right .content-right-activated-bnfts .activated-bnfts-flw .actvPortability .actvBnftsIcon svg, .container.home .content .right .content-right-activated-bnfts-flw .activated-bnfts .actvPortability .actvBnftsIcon svg, .container.home .content .right .content-right-activated-bnfts-flw .activated-bnfts-flw .actvPortability .actvBnftsIcon svg, .container.vip-id .content .right .content-right-activated-bnfts .activated-bnfts .actvPortability .actvBnftsIcon svg, .container.vip-id .content .right .content-right-activated-bnfts .activated-bnfts-flw .actvPortability .actvBnftsIcon svg, .container.vip-id .content .right .content-right-activated-bnfts-flw .activated-bnfts .actvPortability .actvBnftsIcon svg, .container.vip-id .content .right .content-right-activated-bnfts-flw .activated-bnfts-flw .actvPortability .actvBnftsIcon svg {
    width: 25px;
    height: 25px;
  }
  .container.home .content .right .content-right-activated-bnfts .activated-bnfts .actvPortability .actvBnftsText h3, .container.home .content .right .content-right-activated-bnfts .activated-bnfts-flw .actvPortability .actvBnftsText h3, .container.home .content .right .content-right-activated-bnfts-flw .activated-bnfts .actvPortability .actvBnftsText h3, .container.home .content .right .content-right-activated-bnfts-flw .activated-bnfts-flw .actvPortability .actvBnftsText h3, .container.vip-id .content .right .content-right-activated-bnfts .activated-bnfts .actvPortability .actvBnftsText h3, .container.vip-id .content .right .content-right-activated-bnfts .activated-bnfts-flw .actvPortability .actvBnftsText h3, .container.vip-id .content .right .content-right-activated-bnfts-flw .activated-bnfts .actvPortability .actvBnftsText h3, .container.vip-id .content .right .content-right-activated-bnfts-flw .activated-bnfts-flw .actvPortability .actvBnftsText h3 {
    font-size: 10px;
  }
  .container.home .content .right .content-right-activated-bnfts .activated-bnfts .actvPortability .actvBnftsText p, .container.home .content .right .content-right-activated-bnfts .activated-bnfts-flw .actvPortability .actvBnftsText p, .container.home .content .right .content-right-activated-bnfts-flw .activated-bnfts .actvPortability .actvBnftsText p, .container.home .content .right .content-right-activated-bnfts-flw .activated-bnfts-flw .actvPortability .actvBnftsText p, .container.vip-id .content .right .content-right-activated-bnfts .activated-bnfts .actvPortability .actvBnftsText p, .container.vip-id .content .right .content-right-activated-bnfts .activated-bnfts-flw .actvPortability .actvBnftsText p, .container.vip-id .content .right .content-right-activated-bnfts-flw .activated-bnfts .actvPortability .actvBnftsText p, .container.vip-id .content .right .content-right-activated-bnfts-flw .activated-bnfts-flw .actvPortability .actvBnftsText p {
    font-size: 8px;
  }
  .container.home .content .right .content-right-activated-bnfts .activated-bnfts .actvPortability .actvBnftsDetail, .container.home .content .right .content-right-activated-bnfts .activated-bnfts-flw .actvPortability .actvBnftsDetail, .container.home .content .right .content-right-activated-bnfts-flw .activated-bnfts .actvPortability .actvBnftsDetail, .container.home .content .right .content-right-activated-bnfts-flw .activated-bnfts-flw .actvPortability .actvBnftsDetail, .container.vip-id .content .right .content-right-activated-bnfts .activated-bnfts .actvPortability .actvBnftsDetail, .container.vip-id .content .right .content-right-activated-bnfts .activated-bnfts-flw .actvPortability .actvBnftsDetail, .container.vip-id .content .right .content-right-activated-bnfts-flw .activated-bnfts .actvPortability .actvBnftsDetail, .container.vip-id .content .right .content-right-activated-bnfts-flw .activated-bnfts-flw .actvPortability .actvBnftsDetail {
    width: auto;
    height: auto;
    padding: 4px 11px 3px 11px;
  }
  .container.home .content .right .content-right-activated-bnfts .activated-bnfts .actvPortability .actvBnftsDetail p, .container.home .content .right .content-right-activated-bnfts .activated-bnfts-flw .actvPortability .actvBnftsDetail p, .container.home .content .right .content-right-activated-bnfts-flw .activated-bnfts .actvPortability .actvBnftsDetail p, .container.home .content .right .content-right-activated-bnfts-flw .activated-bnfts-flw .actvPortability .actvBnftsDetail p, .container.vip-id .content .right .content-right-activated-bnfts .activated-bnfts .actvPortability .actvBnftsDetail p, .container.vip-id .content .right .content-right-activated-bnfts .activated-bnfts-flw .actvPortability .actvBnftsDetail p, .container.vip-id .content .right .content-right-activated-bnfts-flw .activated-bnfts .actvPortability .actvBnftsDetail p, .container.vip-id .content .right .content-right-activated-bnfts-flw .activated-bnfts-flw .actvPortability .actvBnftsDetail p {
    font-size: 8px;
  }
  .container.home .content .right .content-right-plan-details-text, .container.vip-id .content .right .content-right-plan-details-text {
    margin-bottom: 0;
    align-items: center;
    padding: 0px 0 2px 0;
  }
  .container.home .content .right .content-right-plan-details-text .plan-details-text, .container.vip-id .content .right .content-right-plan-details-text .plan-details-text {
    flex-direction: row;
    gap: 0;
  }
  .container.home .content .right .content-right-plan-details-text .plan-details-text .planDetailsTextColumn, .container.vip-id .content .right .content-right-plan-details-text .plan-details-text .planDetailsTextColumn {
    display: flex;
    flex-direction: column;
    justify-content: initial;
    gap: 2px;
  }
  .container.home .content .right .content-right-plan-details-text .plan-details-text .planDetailsTextDetail, .container.vip-id .content .right .content-right-plan-details-text .plan-details-text .planDetailsTextDetail {
    display: flex;
    align-items: center;
    gap: 5px;
  }
  .container.home .content .right .content-right-plan-details-text .plan-details-text .planDetailsTextDetail p, .container.vip-id .content .right .content-right-plan-details-text .plan-details-text .planDetailsTextDetail p {
    font-size: 8px;
    font-weight: 500;
    color: var(--textColor);
    opacity: 80%;
  }
  .container.home .content .right .content-right-plan-details-text .plan-details-text .planDetailsTextDetail svg, .container.vip-id .content .right .content-right-plan-details-text .plan-details-text .planDetailsTextDetail svg {
    width: 8px;
  }
  .container.home .content .right .content-right-plan-details-text .plan-details-text .planDetailsTextDetail.icon svg path, .container.vip-id .content .right .content-right-plan-details-text .plan-details-text .planDetailsTextDetail.icon svg path {
    fill: var(--primaryColor);
  }
  .container.home .content .right .content-right-plan-details-text .plan-details-text .planDetailsTextDetail.checkIcon svg path, .container.vip-id .content .right .content-right-plan-details-text .plan-details-text .planDetailsTextDetail.checkIcon svg path {
    stroke: var(--primaryColor);
  }
  .container.home .content .right .content-right-plan-details-text .plan-details-text .planDetailsTextDetail.cellTowerIcon svg path, .container.vip-id .content .right .content-right-plan-details-text .plan-details-text .planDetailsTextDetail.cellTowerIcon svg path {
    fill: var(--primaryColor);
  }
  .container.home .content .right .choosePortability, .container.vip-id .content .right .choosePortability {
    margin-bottom: 5px;
  }
  .container.home .content .right .choosePortability .choosePortabilitySwitch .MuiSwitch-root, .container.vip-id .content .right .choosePortability .choosePortabilitySwitch .MuiSwitch-root {
    width: 24px;
    height: 14px;
  }
  .container.home .content .right .choosePortability .choosePortabilitySwitch .MuiSwitch-root .MuiSwitch-thumb, .container.vip-id .content .right .choosePortability .choosePortabilitySwitch .MuiSwitch-root .MuiSwitch-thumb {
    width: 8px;
    height: 8px;
    margin-left: 2px;
    margin-top: 2px;
  }
  .container.home .content .right .choosePortability .choosePortabilitySwitch .MuiSwitch-root .MuiSwitch-switchBase.Mui-checked, .container.vip-id .content .right .choosePortability .choosePortabilitySwitch .MuiSwitch-root .MuiSwitch-switchBase.Mui-checked {
    transform: translate(8px);
  }
  .container.home .content .right .choosePortability .choosePortabilityText, .container.vip-id .content .right .choosePortability .choosePortabilityText {
    margin: 0 10px;
  }
  .container.home .content .right .choosePortability .choosePortabilityText h3, .container.vip-id .content .right .choosePortability .choosePortabilityText h3 {
    color: var(--primaryColor);
    font-size: 9px;
  }
  .container.home .content .right .choosePortability .choosePortabilityText p, .container.vip-id .content .right .choosePortability .choosePortabilityText p {
    font-size: 8px;
  }
  .container.home .content .right .choosePortability .choosePortabilityDetail, .container.vip-id .content .right .choosePortability .choosePortabilityDetail {
    width: auto;
    height: auto;
    padding: 4px 11px 3px 11px;
    background-color: var(--focusColor);
  }
  .container.home .content .right .choosePortability .choosePortabilityDetail p, .container.vip-id .content .right .choosePortability .choosePortabilityDetail p {
    display: flex;
    gap: 2px;
    font-size: 8px;
    color: var(--primaryColor);
  }
  .container.home .content .right .monthlyAnnualCards, .container.vip-id .content .right .monthlyAnnualCards {
    gap: 8px;
  }
  .container.home .content .right .monthlyAnnualCards .skltn-monthlyAnnualCard, .container.vip-id .content .right .monthlyAnnualCards .skltn-monthlyAnnualCard {
    height: 55px;
  }
  .container.home .content .right .monthlyAnnualCards .monthlyAnnualCard, .container.vip-id .content .right .monthlyAnnualCards .monthlyAnnualCard {
    padding: 12px 18px;
  }
  .container.home .content .right .monthlyAnnualCards .monthlyAnnualCard .monthlyAnnualTitle, .container.vip-id .content .right .monthlyAnnualCards .monthlyAnnualCard .monthlyAnnualTitle {
    gap: 8px;
  }
  .container.home .content .right .monthlyAnnualCards .monthlyAnnualCard .monthlyAnnualTitle h3, .container.vip-id .content .right .monthlyAnnualCards .monthlyAnnualCard .monthlyAnnualTitle h3 {
    font-size: 10px;
  }
  .container.home .content .right .monthlyAnnualCards .monthlyAnnualCard .monthlyAnnualTitle .monthlyAnnualDetails, .container.vip-id .content .right .monthlyAnnualCards .monthlyAnnualCard .monthlyAnnualTitle .monthlyAnnualDetails {
    padding: 2px 5px 1px 5px;
    font-size: 6px;
    transform: translate(42px, -1px);
  }
  .container.home .content .right .monthlyAnnualCards .monthlyAnnualCard .monthlyAnnualTitle .monthlyAnnualDetails.annualDetails, .container.vip-id .content .right .monthlyAnnualCards .monthlyAnnualCard .monthlyAnnualTitle .monthlyAnnualDetails.annualDetails {
    transform: translate(35px, -1px);
  }
  .container.home .content .right .monthlyAnnualCards .monthlyAnnualCard .monthlyAnnualTextArea, .container.vip-id .content .right .monthlyAnnualCards .monthlyAnnualCard .monthlyAnnualTextArea {
    transition: 0.5s;
    gap: 3px;
  }
  .container.home .content .right .monthlyAnnualCards .monthlyAnnualCard .monthlyAnnualTextArea p, .container.vip-id .content .right .monthlyAnnualCards .monthlyAnnualCard .monthlyAnnualTextArea p {
    font-size: 8px;
  }
  .container.home .content .right .monthlyAnnualCards .monthlyAnnualCard .monthlyAnnualPriceArea h3, .container.vip-id .content .right .monthlyAnnualCards .monthlyAnnualCard .monthlyAnnualPriceArea h3 {
    font-size: 10px;
  }
  .container.home .content .right .monthlyAnnualCards .monthlyAnnualCard .monthlyAnnualPriceArea h3.oldPrice, .container.vip-id .content .right .monthlyAnnualCards .monthlyAnnualCard .monthlyAnnualPriceArea h3.oldPrice {
    display: none;
  }
  .container.home .content .right .monthlyAnnualCards .monthlyAnnualCard .monthlyAnnualPriceArea svg, .container.vip-id .content .right .monthlyAnnualCards .monthlyAnnualCard .monthlyAnnualPriceArea svg {
    width: 12px;
  }
  .container.home .content .right .monthlyAnnualCards .monthlyAnnualCard:hover .monthlyAnnualTextArea, .container.vip-id .content .right .monthlyAnnualCards .monthlyAnnualCard:hover .monthlyAnnualTextArea {
    padding-left: 12px;
    transition: 0.5s;
  }
  .container.home .content .right .monthlyAnnualCards .monthlyAnnualCard.monthly .monthlyAnnualTextArea p, .container.vip-id .content .right .monthlyAnnualCards .monthlyAnnualCard.monthly .monthlyAnnualTextArea p {
    font-size: 8px;
    color: var(--monthlyColorBase);
  }
}
@media (min-width: 1200px) {
  .container.home .content, .container.vip-id .content {
    padding: 1.75rem 3.25rem;
    max-height: 695px;
  }
  .container.home .content .left, .container.vip-id .content .left {
    padding: 1.25rem 0;
    /* HEADING */
    /* SLOGAN */
    /* BACK IMAGE */
    /* DETAILS */
    /* UNCOMPLICATE BUTTON */
  }
  .container.home .content .left .content-left-heading .heading img, .container.vip-id .content .left .content-left-heading .heading img {
    width: 170px;
  }
  .container.home .content .left .content-left-slogan, .container.vip-id .content .left .content-left-slogan {
    margin-top: -250px;
  }
  .container.home .content .left .content-left-slogan .slogan, .container.vip-id .content .left .content-left-slogan .slogan {
    max-width: 240px;
  }
  .container.home .content .left .content-left-slogan .slogan h3, .container.vip-id .content .left .content-left-slogan .slogan h3 {
    font-size: 26px;
  }
  .container.home .content .left .content-left-back-image, .container.vip-id .content .left .content-left-back-image {
    top: 0;
    left: -3.5rem;
  }
  .container.home .content .left .content-left-back-image img, .container.vip-id .content .left .content-left-back-image img {
    width: 65%;
    top: -59px;
    left: 5px;
  }
  .container.home .content .right, .container.vip-id .content .right {
    padding: 1.5rem 2rem;
    /* CHOOSE PLAN TEXT */
    /* SELECT PLAN */
    /* PLAN BENEFITS APPS */
    /* ACTIVATED BENEFITS */
    /* PLAN DETAILS TEXT */
    /* SELECT PLAN DETAILS */
  }
  .container.home .content .right .content-right-choose-plan-text, .container.vip-id .content .right .content-right-choose-plan-text {
    margin-bottom: 0;
  }
  .container.home .content .right .content-right-choose-plan-text h3, .container.vip-id .content .right .content-right-choose-plan-text h3 {
    font-size: 12px;
  }
  .container.home .content .right .content-right-choose-plan-text p, .container.vip-id .content .right .content-right-choose-plan-text p {
    font-size: 11px;
  }
  .container.home .content .right .content-right-select-plan, .container.vip-id .content .right .content-right-select-plan {
    margin-bottom: 0;
  }
  .container.home .content .right .content-right-select-plan .selectArea .buttonLabel, .container.vip-id .content .right .content-right-select-plan .selectArea .buttonLabel {
    padding: 0px 7px;
    font-size: 9px;
    height: 16px;
    transform: translate(0, 18px);
    right: 8px;
  }
  .container.home .content .right .content-right-select-plan .containerCarousel, .container.vip-id .content .right .content-right-select-plan .containerCarousel {
    padding: 5px 0;
  }
  .container.home .content .right .content-right-select-plan .select-plan .skltn-button, .container.vip-id .content .right .content-right-select-plan .select-plan .skltn-button {
    min-width: 52px;
    min-height: 38px;
    max-width: 76px;
    max-height: 58px;
  }
  .container.home .content .right .content-right-select-plan .select-plan button, .container.vip-id .content .right .content-right-select-plan .select-plan button {
    min-width: 50px;
    min-height: 34px;
    gap: 4px;
  }
  .container.home .content .right .content-right-select-plan .select-plan button h3, .container.vip-id .content .right .content-right-select-plan .select-plan button h3 {
    font-size: 14px;
  }
  .container.home .content .right .content-right-select-plan .select-plan button p, .container.vip-id .content .right .content-right-select-plan .select-plan button p {
    font-size: 10px;
  }
  .container.home .content .right .content-right-select-plan .select-plan .planTextArea h1, .container.home .content .right .content-right-select-plan .select-plan .planTextArea h3, .container.home .content .right .content-right-select-plan .select-plan .planTextArea .skltn-labelPlan, .container.home .content .right .content-right-select-plan .select-plan .planTextArea .labelChipOnly, .container.home .content .right .content-right-select-plan .select-plan .planTextArea .labelPlan, .container.vip-id .content .right .content-right-select-plan .select-plan .planTextArea h1, .container.vip-id .content .right .content-right-select-plan .select-plan .planTextArea h3, .container.vip-id .content .right .content-right-select-plan .select-plan .planTextArea .skltn-labelPlan, .container.vip-id .content .right .content-right-select-plan .select-plan .planTextArea .labelChipOnly, .container.vip-id .content .right .content-right-select-plan .select-plan .planTextArea .labelPlan {
    font-size: 34px;
  }
  .container.home .content .right .content-right-select-plan .select-plan .planTextArea p, .container.vip-id .content .right .content-right-select-plan .select-plan .planTextArea p {
    font-size: 12px;
  }
  .container.home .content .right .content-right-plan-bnfts-apps .plan-bnfts-apps .planBnftsAppsTitle, .container.vip-id .content .right .content-right-plan-bnfts-apps .plan-bnfts-apps .planBnftsAppsTitle {
    font-size: 12px;
  }
  .container.home .content .right .content-right-plan-bnfts-apps .plan-bnfts-apps .bnftsApps .bnftsApp, .container.vip-id .content .right .content-right-plan-bnfts-apps .plan-bnfts-apps .bnftsApps .bnftsApp {
    padding: 0 12px;
  }
  .container.home .content .right .content-right-plan-bnfts-apps .plan-bnfts-apps .bnftsApps .bnftsApp p, .container.vip-id .content .right .content-right-plan-bnfts-apps .plan-bnfts-apps .bnftsApps .bnftsApp p {
    font-size: clamp(9px, 0.9vw, 16px);
  }
  .container.home .content .right .content-right-activated-bnfts .activated-bnfts, .container.home .content .right .content-right-activated-bnfts .activated-bnfts-flw, .container.home .content .right .content-right-activated-bnfts-flw .activated-bnfts, .container.home .content .right .content-right-activated-bnfts-flw .activated-bnfts-flw, .container.vip-id .content .right .content-right-activated-bnfts .activated-bnfts, .container.vip-id .content .right .content-right-activated-bnfts .activated-bnfts-flw, .container.vip-id .content .right .content-right-activated-bnfts-flw .activated-bnfts, .container.vip-id .content .right .content-right-activated-bnfts-flw .activated-bnfts-flw {
    gap: 10px;
    margin-bottom: 10px;
  }
  .container.home .content .right .content-right-activated-bnfts .activated-bnfts .actvBnftsTitle, .container.home .content .right .content-right-activated-bnfts .activated-bnfts-flw .actvBnftsTitle, .container.home .content .right .content-right-activated-bnfts-flw .activated-bnfts .actvBnftsTitle, .container.home .content .right .content-right-activated-bnfts-flw .activated-bnfts-flw .actvBnftsTitle, .container.vip-id .content .right .content-right-activated-bnfts .activated-bnfts .actvBnftsTitle, .container.vip-id .content .right .content-right-activated-bnfts .activated-bnfts-flw .actvBnftsTitle, .container.vip-id .content .right .content-right-activated-bnfts-flw .activated-bnfts .actvBnftsTitle, .container.vip-id .content .right .content-right-activated-bnfts-flw .activated-bnfts-flw .actvBnftsTitle {
    font-size: 12px;
  }
  .container.home .content .right .content-right-activated-bnfts .activated-bnfts .actvPortability .actvBnftsIcon svg, .container.home .content .right .content-right-activated-bnfts .activated-bnfts-flw .actvPortability .actvBnftsIcon svg, .container.home .content .right .content-right-activated-bnfts-flw .activated-bnfts .actvPortability .actvBnftsIcon svg, .container.home .content .right .content-right-activated-bnfts-flw .activated-bnfts-flw .actvPortability .actvBnftsIcon svg, .container.vip-id .content .right .content-right-activated-bnfts .activated-bnfts .actvPortability .actvBnftsIcon svg, .container.vip-id .content .right .content-right-activated-bnfts .activated-bnfts-flw .actvPortability .actvBnftsIcon svg, .container.vip-id .content .right .content-right-activated-bnfts-flw .activated-bnfts .actvPortability .actvBnftsIcon svg, .container.vip-id .content .right .content-right-activated-bnfts-flw .activated-bnfts-flw .actvPortability .actvBnftsIcon svg {
    width: 30px;
    height: 30px;
  }
  .container.home .content .right .content-right-activated-bnfts .activated-bnfts .actvPortability .actvBnftsText h3, .container.home .content .right .content-right-activated-bnfts .activated-bnfts-flw .actvPortability .actvBnftsText h3, .container.home .content .right .content-right-activated-bnfts-flw .activated-bnfts .actvPortability .actvBnftsText h3, .container.home .content .right .content-right-activated-bnfts-flw .activated-bnfts-flw .actvPortability .actvBnftsText h3, .container.vip-id .content .right .content-right-activated-bnfts .activated-bnfts .actvPortability .actvBnftsText h3, .container.vip-id .content .right .content-right-activated-bnfts .activated-bnfts-flw .actvPortability .actvBnftsText h3, .container.vip-id .content .right .content-right-activated-bnfts-flw .activated-bnfts .actvPortability .actvBnftsText h3, .container.vip-id .content .right .content-right-activated-bnfts-flw .activated-bnfts-flw .actvPortability .actvBnftsText h3 {
    font-size: 11px;
  }
  .container.home .content .right .content-right-activated-bnfts .activated-bnfts .actvPortability .actvBnftsText p, .container.home .content .right .content-right-activated-bnfts .activated-bnfts-flw .actvPortability .actvBnftsText p, .container.home .content .right .content-right-activated-bnfts-flw .activated-bnfts .actvPortability .actvBnftsText p, .container.home .content .right .content-right-activated-bnfts-flw .activated-bnfts-flw .actvPortability .actvBnftsText p, .container.vip-id .content .right .content-right-activated-bnfts .activated-bnfts .actvPortability .actvBnftsText p, .container.vip-id .content .right .content-right-activated-bnfts .activated-bnfts-flw .actvPortability .actvBnftsText p, .container.vip-id .content .right .content-right-activated-bnfts-flw .activated-bnfts .actvPortability .actvBnftsText p, .container.vip-id .content .right .content-right-activated-bnfts-flw .activated-bnfts-flw .actvPortability .actvBnftsText p {
    font-size: 10px;
  }
  .container.home .content .right .content-right-activated-bnfts .activated-bnfts .actvPortability .actvBnftsDetail, .container.home .content .right .content-right-activated-bnfts .activated-bnfts-flw .actvPortability .actvBnftsDetail, .container.home .content .right .content-right-activated-bnfts-flw .activated-bnfts .actvPortability .actvBnftsDetail, .container.home .content .right .content-right-activated-bnfts-flw .activated-bnfts-flw .actvPortability .actvBnftsDetail, .container.vip-id .content .right .content-right-activated-bnfts .activated-bnfts .actvPortability .actvBnftsDetail, .container.vip-id .content .right .content-right-activated-bnfts .activated-bnfts-flw .actvPortability .actvBnftsDetail, .container.vip-id .content .right .content-right-activated-bnfts-flw .activated-bnfts .actvPortability .actvBnftsDetail, .container.vip-id .content .right .content-right-activated-bnfts-flw .activated-bnfts-flw .actvPortability .actvBnftsDetail {
    padding: 6px 11px 5px 11px;
  }
  .container.home .content .right .content-right-activated-bnfts .activated-bnfts .actvPortability .actvBnftsDetail p, .container.home .content .right .content-right-activated-bnfts .activated-bnfts-flw .actvPortability .actvBnftsDetail p, .container.home .content .right .content-right-activated-bnfts-flw .activated-bnfts .actvPortability .actvBnftsDetail p, .container.home .content .right .content-right-activated-bnfts-flw .activated-bnfts-flw .actvPortability .actvBnftsDetail p, .container.vip-id .content .right .content-right-activated-bnfts .activated-bnfts .actvPortability .actvBnftsDetail p, .container.vip-id .content .right .content-right-activated-bnfts .activated-bnfts-flw .actvPortability .actvBnftsDetail p, .container.vip-id .content .right .content-right-activated-bnfts-flw .activated-bnfts .actvPortability .actvBnftsDetail p, .container.vip-id .content .right .content-right-activated-bnfts-flw .activated-bnfts-flw .actvPortability .actvBnftsDetail p {
    font-size: 11px;
  }
  .container.home .content .right .content-right-plan-details-text, .container.vip-id .content .right .content-right-plan-details-text {
    padding: 5px 0 10px 0;
  }
  .container.home .content .right .content-right-plan-details-text .plan-details-text .planDetailsTextColumn, .container.vip-id .content .right .content-right-plan-details-text .plan-details-text .planDetailsTextColumn {
    gap: 5px;
  }
  .container.home .content .right .content-right-plan-details-text .plan-details-text .planDetailsTextDetail p, .container.vip-id .content .right .content-right-plan-details-text .plan-details-text .planDetailsTextDetail p {
    font-size: 11px;
  }
  .container.home .content .right .content-right-plan-details-text .plan-details-text .planDetailsTextDetail svg, .container.vip-id .content .right .content-right-plan-details-text .plan-details-text .planDetailsTextDetail svg {
    width: 13px;
  }
  .container.home .content .right .choosePortability .choosePortabilityText, .container.vip-id .content .right .choosePortability .choosePortabilityText {
    margin: 0 12px;
  }
  .container.home .content .right .choosePortability .choosePortabilityText h3, .container.vip-id .content .right .choosePortability .choosePortabilityText h3 {
    font-size: 11px;
  }
  .container.home .content .right .choosePortability .choosePortabilityText p, .container.vip-id .content .right .choosePortability .choosePortabilityText p {
    font-size: 10px;
  }
  .container.home .content .right .choosePortability .choosePortabilityDetail, .container.vip-id .content .right .choosePortability .choosePortabilityDetail {
    padding: 6px 11px 5px 11px;
  }
  .container.home .content .right .choosePortability .choosePortabilityDetail p, .container.vip-id .content .right .choosePortability .choosePortabilityDetail p {
    gap: 3px;
    font-size: 11px;
  }
  .container.home .content .right .choosePortability .choosePortabilityDetail span, .container.vip-id .content .right .choosePortability .choosePortabilityDetail span {
    display: block;
  }
  .container.home .content .right .monthlyAnnualCards .skltn-monthlyAnnualCard, .container.vip-id .content .right .monthlyAnnualCards .skltn-monthlyAnnualCard {
    height: 75px;
  }
  .container.home .content .right .monthlyAnnualCards .monthlyAnnualCard, .container.vip-id .content .right .monthlyAnnualCards .monthlyAnnualCard {
    padding: 18px;
  }
  .container.home .content .right .monthlyAnnualCards .monthlyAnnualCard .monthlyAnnualTitle h3, .container.vip-id .content .right .monthlyAnnualCards .monthlyAnnualCard .monthlyAnnualTitle h3 {
    font-size: 13px;
  }
  .container.home .content .right .monthlyAnnualCards .monthlyAnnualCard .monthlyAnnualTitle .monthlyAnnualDetails, .container.vip-id .content .right .monthlyAnnualCards .monthlyAnnualCard .monthlyAnnualTitle .monthlyAnnualDetails {
    font-size: 8px;
    transform: translate(54px, -1px);
  }
  .container.home .content .right .monthlyAnnualCards .monthlyAnnualCard .monthlyAnnualTitle .monthlyAnnualDetails.annualDetails, .container.vip-id .content .right .monthlyAnnualCards .monthlyAnnualCard .monthlyAnnualTitle .monthlyAnnualDetails.annualDetails {
    transform: translate(45px, -1px);
  }
  .container.home .content .right .monthlyAnnualCards .monthlyAnnualCard .monthlyAnnualTextArea p, .container.vip-id .content .right .monthlyAnnualCards .monthlyAnnualCard .monthlyAnnualTextArea p {
    font-size: 10px;
  }
  .container.home .content .right .monthlyAnnualCards .monthlyAnnualCard .monthlyAnnualPriceArea h3, .container.vip-id .content .right .monthlyAnnualCards .monthlyAnnualCard .monthlyAnnualPriceArea h3 {
    font-size: 14px;
  }
  .container.home .content .right .monthlyAnnualCards .monthlyAnnualCard .monthlyAnnualPriceArea svg, .container.vip-id .content .right .monthlyAnnualCards .monthlyAnnualCard .monthlyAnnualPriceArea svg {
    width: 17px;
  }
  .container.home .content .right .monthlyAnnualCards .monthlyAnnualCard.monthly .monthlyAnnualTextArea p, .container.vip-id .content .right .monthlyAnnualCards .monthlyAnnualCard.monthly .monthlyAnnualTextArea p {
    font-size: 10px;
  }
}
@media (min-width: 1440px) {
  .container.home .content, .container.vip-id .content {
    padding: 2rem 3.5rem;
    max-height: 920px;
  }
  .container.home .content .container-right, .container.vip-id .content .container-right {
    padding: 0 0 0 2rem;
  }
  .container.home .content .left, .container.vip-id .content .left {
    padding: 1.25rem 0 0 0;
    height: calc(100vh - 5.25rem);
    /* HEADING */
    /* SLOGAN */
    /* BACK IMAGE */
    /* DETAILS */
    /* UNCOMPLICATE BUTTON */
  }
  .container.home .content .left .content-left-heading .heading img, .container.vip-id .content .left .content-left-heading .heading img {
    width: 200px;
  }
  .container.home .content .left .content-left-slogan, .container.vip-id .content .left .content-left-slogan {
    margin-top: -270px;
  }
  .container.home .content .left .content-left-slogan .slogan, .container.vip-id .content .left .content-left-slogan .slogan {
    max-width: 275px;
  }
  .container.home .content .left .content-left-slogan .slogan h3, .container.vip-id .content .left .content-left-slogan .slogan h3 {
    font-size: 29px;
  }
  .container.home .content .left .content-left-back-image img, .container.vip-id .content .left .content-left-back-image img {
    width: 63%;
    top: -105px;
    left: 0px;
  }
  .container.home .content .left .content-left-details, .container.vip-id .content .left .content-left-details {
    z-index: 1;
    align-items: end;
  }
  .container.home .content .left .content-left-details .details, .container.vip-id .content .left .content-left-details .details {
    display: flex;
    align-self: auto;
    width: auto;
    justify-content: flex-start;
    gap: 9px;
  }
  .container.home .content .left .content-left-details img, .container.vip-id .content .left .content-left-details img {
    width: 15%;
  }
  .container.home .content .left .content-left-details .detailsTextArea h3, .container.vip-id .content .left .content-left-details .detailsTextArea h3 {
    font-size: 12px;
    color: #FFFFFF;
  }
  .container.home .content .left .content-left-details .detailsTextArea p, .container.vip-id .content .left .content-left-details .detailsTextArea p {
    font-size: 11px;
    color: #FFFFFF;
  }
  .container.home .content .right, .container.vip-id .content .right {
    padding: 2rem 2.75rem;
    /* TIMER */
    /* CHOOSE PLAN TEXT */
    /* SELECT PLAN */
    /* PLAN BENEFITS APPS */
    /* ACTIVATED BENEFITS */
    /* PLAN DETAILS TEXT */
    /* SELECT PLAN DETAILS */
  }
  .container.home .content .right .content-right-choose-plan-text, .container.vip-id .content .right .content-right-choose-plan-text {
    margin-bottom: 0;
  }
  .container.home .content .right .content-right-choose-plan-text h3, .container.vip-id .content .right .content-right-choose-plan-text h3 {
    font-size: 15px;
  }
  .container.home .content .right .content-right-choose-plan-text p, .container.vip-id .content .right .content-right-choose-plan-text p {
    font-size: 12px;
  }
  .container.home .content .right .content-right-select-plan, .container.vip-id .content .right .content-right-select-plan {
    margin-bottom: 0;
  }
  .container.home .content .right .content-right-select-plan .selectArea .buttonLabel, .container.vip-id .content .right .content-right-select-plan .selectArea .buttonLabel {
    font-size: 10px;
    height: 18px;
    transform: translate(0, 22px);
    right: 14px;
  }
  .container.home .content .right .content-right-select-plan .select-plan .skltn-button, .container.vip-id .content .right .content-right-select-plan .select-plan .skltn-button {
    min-width: 64px;
    min-height: 47px;
    max-width: 76px;
    max-height: 58px;
  }
  .container.home .content .right .content-right-select-plan .select-plan button, .container.vip-id .content .right .content-right-select-plan .select-plan button {
    min-width: 60px;
    min-height: 43px;
  }
  .container.home .content .right .content-right-select-plan .select-plan button h3, .container.vip-id .content .right .content-right-select-plan .select-plan button h3 {
    font-size: 20px;
  }
  .container.home .content .right .content-right-select-plan .select-plan .planTextArea h1, .container.home .content .right .content-right-select-plan .select-plan .planTextArea h3, .container.home .content .right .content-right-select-plan .select-plan .planTextArea .skltn-labelPlan, .container.home .content .right .content-right-select-plan .select-plan .planTextArea .labelChipOnly, .container.home .content .right .content-right-select-plan .select-plan .planTextArea .labelPlan, .container.vip-id .content .right .content-right-select-plan .select-plan .planTextArea h1, .container.vip-id .content .right .content-right-select-plan .select-plan .planTextArea h3, .container.vip-id .content .right .content-right-select-plan .select-plan .planTextArea .skltn-labelPlan, .container.vip-id .content .right .content-right-select-plan .select-plan .planTextArea .labelChipOnly, .container.vip-id .content .right .content-right-select-plan .select-plan .planTextArea .labelPlan {
    font-size: 44px;
  }
  .container.home .content .right .content-right-select-plan .select-plan .planTextArea p, .container.vip-id .content .right .content-right-select-plan .select-plan .planTextArea p {
    font-size: 14px;
  }
  .container.home .content .right .content-right-plan-bnfts-apps .plan-bnfts-apps .planBnftsAppsTitle, .container.vip-id .content .right .content-right-plan-bnfts-apps .plan-bnfts-apps .planBnftsAppsTitle {
    font-size: 15px;
  }
  .container.home .content .right .content-right-plan-bnfts-apps .plan-bnfts-apps .bnftsApps .bnftsApp p, .container.vip-id .content .right .content-right-plan-bnfts-apps .plan-bnfts-apps .bnftsApps .bnftsApp p {
    font-size: clamp(9px, 0.95vw, 16px);
  }
  .container.home .content .right .content-right-activated-bnfts .activated-bnfts, .container.home .content .right .content-right-activated-bnfts .activated-bnfts-flw, .container.home .content .right .content-right-activated-bnfts-flw .activated-bnfts, .container.home .content .right .content-right-activated-bnfts-flw .activated-bnfts-flw, .container.vip-id .content .right .content-right-activated-bnfts .activated-bnfts, .container.vip-id .content .right .content-right-activated-bnfts .activated-bnfts-flw, .container.vip-id .content .right .content-right-activated-bnfts-flw .activated-bnfts, .container.vip-id .content .right .content-right-activated-bnfts-flw .activated-bnfts-flw {
    gap: 14px;
    margin-bottom: 14px;
  }
  .container.home .content .right .content-right-activated-bnfts .activated-bnfts .actvBnftsTitle, .container.home .content .right .content-right-activated-bnfts .activated-bnfts-flw .actvBnftsTitle, .container.home .content .right .content-right-activated-bnfts-flw .activated-bnfts .actvBnftsTitle, .container.home .content .right .content-right-activated-bnfts-flw .activated-bnfts-flw .actvBnftsTitle, .container.vip-id .content .right .content-right-activated-bnfts .activated-bnfts .actvBnftsTitle, .container.vip-id .content .right .content-right-activated-bnfts .activated-bnfts-flw .actvBnftsTitle, .container.vip-id .content .right .content-right-activated-bnfts-flw .activated-bnfts .actvBnftsTitle, .container.vip-id .content .right .content-right-activated-bnfts-flw .activated-bnfts-flw .actvBnftsTitle {
    font-size: 15px;
  }
  .container.home .content .right .content-right-activated-bnfts .activated-bnfts .actvPortability .actvBnftsIcon svg, .container.home .content .right .content-right-activated-bnfts .activated-bnfts-flw .actvPortability .actvBnftsIcon svg, .container.home .content .right .content-right-activated-bnfts-flw .activated-bnfts .actvPortability .actvBnftsIcon svg, .container.home .content .right .content-right-activated-bnfts-flw .activated-bnfts-flw .actvPortability .actvBnftsIcon svg, .container.vip-id .content .right .content-right-activated-bnfts .activated-bnfts .actvPortability .actvBnftsIcon svg, .container.vip-id .content .right .content-right-activated-bnfts .activated-bnfts-flw .actvPortability .actvBnftsIcon svg, .container.vip-id .content .right .content-right-activated-bnfts-flw .activated-bnfts .actvPortability .actvBnftsIcon svg, .container.vip-id .content .right .content-right-activated-bnfts-flw .activated-bnfts-flw .actvPortability .actvBnftsIcon svg {
    width: 30px;
    height: 30px;
  }
  .container.home .content .right .content-right-activated-bnfts .activated-bnfts .actvPortability .actvBnftsText h3, .container.home .content .right .content-right-activated-bnfts .activated-bnfts-flw .actvPortability .actvBnftsText h3, .container.home .content .right .content-right-activated-bnfts-flw .activated-bnfts .actvPortability .actvBnftsText h3, .container.home .content .right .content-right-activated-bnfts-flw .activated-bnfts-flw .actvPortability .actvBnftsText h3, .container.vip-id .content .right .content-right-activated-bnfts .activated-bnfts .actvPortability .actvBnftsText h3, .container.vip-id .content .right .content-right-activated-bnfts .activated-bnfts-flw .actvPortability .actvBnftsText h3, .container.vip-id .content .right .content-right-activated-bnfts-flw .activated-bnfts .actvPortability .actvBnftsText h3, .container.vip-id .content .right .content-right-activated-bnfts-flw .activated-bnfts-flw .actvPortability .actvBnftsText h3 {
    font-size: 13px;
  }
  .container.home .content .right .content-right-activated-bnfts .activated-bnfts .actvPortability .actvBnftsText p, .container.home .content .right .content-right-activated-bnfts .activated-bnfts-flw .actvPortability .actvBnftsText p, .container.home .content .right .content-right-activated-bnfts-flw .activated-bnfts .actvPortability .actvBnftsText p, .container.home .content .right .content-right-activated-bnfts-flw .activated-bnfts-flw .actvPortability .actvBnftsText p, .container.vip-id .content .right .content-right-activated-bnfts .activated-bnfts .actvPortability .actvBnftsText p, .container.vip-id .content .right .content-right-activated-bnfts .activated-bnfts-flw .actvPortability .actvBnftsText p, .container.vip-id .content .right .content-right-activated-bnfts-flw .activated-bnfts .actvPortability .actvBnftsText p, .container.vip-id .content .right .content-right-activated-bnfts-flw .activated-bnfts-flw .actvPortability .actvBnftsText p {
    font-size: 11px;
  }
  .container.home .content .right .content-right-activated-bnfts .activated-bnfts .actvPortability .actvBnftsDetail p, .container.home .content .right .content-right-activated-bnfts .activated-bnfts-flw .actvPortability .actvBnftsDetail p, .container.home .content .right .content-right-activated-bnfts-flw .activated-bnfts .actvPortability .actvBnftsDetail p, .container.home .content .right .content-right-activated-bnfts-flw .activated-bnfts-flw .actvPortability .actvBnftsDetail p, .container.vip-id .content .right .content-right-activated-bnfts .activated-bnfts .actvPortability .actvBnftsDetail p, .container.vip-id .content .right .content-right-activated-bnfts .activated-bnfts-flw .actvPortability .actvBnftsDetail p, .container.vip-id .content .right .content-right-activated-bnfts-flw .activated-bnfts .actvPortability .actvBnftsDetail p, .container.vip-id .content .right .content-right-activated-bnfts-flw .activated-bnfts-flw .actvPortability .actvBnftsDetail p {
    font-size: 12px;
  }
  .container.home .content .right .content-right-plan-details-text, .container.vip-id .content .right .content-right-plan-details-text {
    padding: 0 0 10px 0;
  }
  .container.home .content .right .content-right-plan-details-text .plan-details-text .planDetailsTextColumn, .container.vip-id .content .right .content-right-plan-details-text .plan-details-text .planDetailsTextColumn {
    gap: 6px;
  }
  .container.home .content .right .content-right-plan-details-text .plan-details-text .planDetailsTextDetail p, .container.vip-id .content .right .content-right-plan-details-text .plan-details-text .planDetailsTextDetail p {
    font-size: 12px;
  }
  .container.home .content .right .content-right-plan-details-text .plan-details-text .planDetailsTextDetail svg, .container.vip-id .content .right .content-right-plan-details-text .plan-details-text .planDetailsTextDetail svg {
    width: 14px;
  }
  .container.home .content .right .choosePortability, .container.vip-id .content .right .choosePortability {
    margin-bottom: 8px;
  }
  .container.home .content .right .choosePortability .choosePortabilitySwitch .MuiSwitch-root, .container.vip-id .content .right .choosePortability .choosePortabilitySwitch .MuiSwitch-root {
    width: 38px;
    height: 22px;
  }
  .container.home .content .right .choosePortability .choosePortabilitySwitch .MuiSwitch-root .MuiSwitch-thumb, .container.vip-id .content .right .choosePortability .choosePortabilitySwitch .MuiSwitch-root .MuiSwitch-thumb {
    width: 13px;
    height: 13px;
    margin-left: 4px;
    margin-top: 3px;
  }
  .container.home .content .right .choosePortability .choosePortabilitySwitch .MuiSwitch-root .MuiSwitch-switchBase.Mui-checked, .container.vip-id .content .right .choosePortability .choosePortabilitySwitch .MuiSwitch-root .MuiSwitch-switchBase.Mui-checked {
    transform: translate(13px);
  }
  .container.home .content .right .choosePortability .choosePortabilityText h3, .container.vip-id .content .right .choosePortability .choosePortabilityText h3 {
    font-size: 13px;
  }
  .container.home .content .right .choosePortability .choosePortabilityText p, .container.vip-id .content .right .choosePortability .choosePortabilityText p {
    font-size: 11px;
  }
  .container.home .content .right .choosePortability .choosePortabilityDetail p, .container.vip-id .content .right .choosePortability .choosePortabilityDetail p {
    gap: 4px;
    font-size: 12px;
  }
  .container.home .content .right .monthlyAnnualCards .skltn-monthlyAnnualCard, .container.vip-id .content .right .monthlyAnnualCards .skltn-monthlyAnnualCard {
    height: 86px;
  }
  .container.home .content .right .monthlyAnnualCards .monthlyAnnualCard, .container.vip-id .content .right .monthlyAnnualCards .monthlyAnnualCard {
    padding: 22px;
  }
  .container.home .content .right .monthlyAnnualCards .monthlyAnnualCard .monthlyAnnualTitle h3, .container.vip-id .content .right .monthlyAnnualCards .monthlyAnnualCard .monthlyAnnualTitle h3 {
    font-size: 14px;
  }
  .container.home .content .right .monthlyAnnualCards .monthlyAnnualCard .monthlyAnnualTitle .monthlyAnnualDetails, .container.vip-id .content .right .monthlyAnnualCards .monthlyAnnualCard .monthlyAnnualTitle .monthlyAnnualDetails {
    font-size: 9px;
    transform: translate(58px, -1px);
  }
  .container.home .content .right .monthlyAnnualCards .monthlyAnnualCard .monthlyAnnualTitle .monthlyAnnualDetails.annualDetails, .container.vip-id .content .right .monthlyAnnualCards .monthlyAnnualCard .monthlyAnnualTitle .monthlyAnnualDetails.annualDetails {
    transform: translate(48px, -1px);
  }
  .container.home .content .right .monthlyAnnualCards .monthlyAnnualCard .monthlyAnnualTextArea p, .container.vip-id .content .right .monthlyAnnualCards .monthlyAnnualCard .monthlyAnnualTextArea p {
    font-size: 12px;
  }
  .container.home .content .right .monthlyAnnualCards .monthlyAnnualCard .monthlyAnnualPriceArea h3, .container.vip-id .content .right .monthlyAnnualCards .monthlyAnnualCard .monthlyAnnualPriceArea h3 {
    font-size: 14px;
  }
  .container.home .content .right .monthlyAnnualCards .monthlyAnnualCard .monthlyAnnualPriceArea svg, .container.vip-id .content .right .monthlyAnnualCards .monthlyAnnualCard .monthlyAnnualPriceArea svg {
    width: 16px;
  }
  .container.home .content .right .monthlyAnnualCards .monthlyAnnualCard.monthly .monthlyAnnualTextArea p, .container.vip-id .content .right .monthlyAnnualCards .monthlyAnnualCard.monthly .monthlyAnnualTextArea p {
    font-size: 12px;
  }
}
@media (min-width: 1860px) {
  .container.home .content, .container.vip-id .content {
    padding: 2.5rem 6.25rem;
    max-height: 1080px;
  }
  .container.home .content .container-right, .container.vip-id .content .container-right {
    padding: 0 0 0 3rem;
  }
  .container.home .content .left, .container.vip-id .content .left {
    /* HEADING */
    /* SLOGAN */
    /* BACK IMAGE */
    /* DETAILS */
  }
  .container.home .content .left .content-left-heading .heading img, .container.vip-id .content .left .content-left-heading .heading img {
    width: 245px;
  }
  .container.home .content .left .content-left-slogan, .container.vip-id .content .left .content-left-slogan {
    margin-top: -420px;
  }
  .container.home .content .left .content-left-slogan .slogan, .container.vip-id .content .left .content-left-slogan .slogan {
    max-width: 310px;
  }
  .container.home .content .left .content-left-slogan .slogan h3, .container.vip-id .content .left .content-left-slogan .slogan h3 {
    font-size: 34px;
  }
  .container.home .content .left .content-left-back-image, .container.vip-id .content .left .content-left-back-image {
    left: -6.5rem;
  }
  .container.home .content .left .content-left-back-image img, .container.vip-id .content .left .content-left-back-image img {
    width: auto;
    top: -155px;
  }
  .container.home .content .left .content-left-details .details, .container.vip-id .content .left .content-left-details .details {
    gap: 10px;
  }
  .container.home .content .left .content-left-details img, .container.vip-id .content .left .content-left-details img {
    width: 17%;
  }
  .container.home .content .left .content-left-details .detailsTextArea h3, .container.vip-id .content .left .content-left-details .detailsTextArea h3 {
    font-size: 13px;
  }
  .container.home .content .left .content-left-details .detailsTextArea p, .container.vip-id .content .left .content-left-details .detailsTextArea p {
    font-size: 12px;
  }
  .container.home .content .right, .container.vip-id .content .right {
    padding: 4.5rem 4.25rem;
    /* CHOOSE PLAN TEXT */
    /* SELECT PLAN */
    /* PLAN BENEFITS APPS */
    /* ACTIVATED BENEFITS */
    /* PLAN DETAILS TEXT */
    /* SELECT PLAN DETAILS */
  }
  .container.home .content .right .content-right-choose-plan-text, .container.vip-id .content .right .content-right-choose-plan-text {
    margin-bottom: 0;
  }
  .container.home .content .right .content-right-choose-plan-text h3, .container.vip-id .content .right .content-right-choose-plan-text h3 {
    font-size: 18px;
  }
  .container.home .content .right .content-right-choose-plan-text p, .container.vip-id .content .right .content-right-choose-plan-text p {
    font-size: 16px;
  }
  .container.home .content .right .content-right-select-plan, .container.vip-id .content .right .content-right-select-plan {
    margin-bottom: 0;
  }
  .container.home .content .right .content-right-select-plan .selectArea .buttonLabel, .container.vip-id .content .right .content-right-select-plan .selectArea .buttonLabel {
    right: 12px;
  }
  .container.home .content .right .content-right-select-plan .select-plan .skltn-button, .container.vip-id .content .right .content-right-select-plan .select-plan .skltn-button {
    min-width: 62px;
    min-height: 47px;
  }
  .container.home .content .right .content-right-select-plan .select-plan button, .container.vip-id .content .right .content-right-select-plan .select-plan button {
    min-width: 62px;
    min-height: 47px;
  }
  .container.home .content .right .content-right-select-plan .select-plan .planTextArea h1, .container.home .content .right .content-right-select-plan .select-plan .planTextArea h3, .container.home .content .right .content-right-select-plan .select-plan .planTextArea .skltn-labelPlan, .container.home .content .right .content-right-select-plan .select-plan .planTextArea .labelChipOnly, .container.home .content .right .content-right-select-plan .select-plan .planTextArea .labelPlan, .container.vip-id .content .right .content-right-select-plan .select-plan .planTextArea h1, .container.vip-id .content .right .content-right-select-plan .select-plan .planTextArea h3, .container.vip-id .content .right .content-right-select-plan .select-plan .planTextArea .skltn-labelPlan, .container.vip-id .content .right .content-right-select-plan .select-plan .planTextArea .labelChipOnly, .container.vip-id .content .right .content-right-select-plan .select-plan .planTextArea .labelPlan {
    font-size: 58px;
  }
  .container.home .content .right .content-right-select-plan .select-plan .planTextArea p, .container.vip-id .content .right .content-right-select-plan .select-plan .planTextArea p {
    font-size: 16px;
  }
  .container.home .content .right .content-right-plan-bnfts-apps .plan-bnfts-apps .planBnftsAppsTitle, .container.vip-id .content .right .content-right-plan-bnfts-apps .plan-bnfts-apps .planBnftsAppsTitle {
    font-size: 18px;
  }
  .container.home .content .right .content-right-plan-bnfts-apps .plan-bnfts-apps .bnftsApps .bnftsApp, .container.vip-id .content .right .content-right-plan-bnfts-apps .plan-bnfts-apps .bnftsApps .bnftsApp {
    padding: 0 8px;
  }
  .container.home .content .right .content-right-plan-bnfts-apps .plan-bnfts-apps .bnftsApps .bnftsApp p, .container.vip-id .content .right .content-right-plan-bnfts-apps .plan-bnfts-apps .bnftsApps .bnftsApp p {
    font-size: clamp(9px, 1vw, 17px);
  }
  .container.home .content .right .content-right-activated-bnfts .activated-bnfts .actvBnftsTitle, .container.home .content .right .content-right-activated-bnfts .activated-bnfts-flw .actvBnftsTitle, .container.home .content .right .content-right-activated-bnfts-flw .activated-bnfts .actvBnftsTitle, .container.home .content .right .content-right-activated-bnfts-flw .activated-bnfts-flw .actvBnftsTitle, .container.vip-id .content .right .content-right-activated-bnfts .activated-bnfts .actvBnftsTitle, .container.vip-id .content .right .content-right-activated-bnfts .activated-bnfts-flw .actvBnftsTitle, .container.vip-id .content .right .content-right-activated-bnfts-flw .activated-bnfts .actvBnftsTitle, .container.vip-id .content .right .content-right-activated-bnfts-flw .activated-bnfts-flw .actvBnftsTitle {
    font-size: 18px;
  }
  .container.home .content .right .content-right-activated-bnfts .activated-bnfts .actvPortability .actvBnftsText h3, .container.home .content .right .content-right-activated-bnfts .activated-bnfts-flw .actvPortability .actvBnftsText h3, .container.home .content .right .content-right-activated-bnfts-flw .activated-bnfts .actvPortability .actvBnftsText h3, .container.home .content .right .content-right-activated-bnfts-flw .activated-bnfts-flw .actvPortability .actvBnftsText h3, .container.vip-id .content .right .content-right-activated-bnfts .activated-bnfts .actvPortability .actvBnftsText h3, .container.vip-id .content .right .content-right-activated-bnfts .activated-bnfts-flw .actvPortability .actvBnftsText h3, .container.vip-id .content .right .content-right-activated-bnfts-flw .activated-bnfts .actvPortability .actvBnftsText h3, .container.vip-id .content .right .content-right-activated-bnfts-flw .activated-bnfts-flw .actvPortability .actvBnftsText h3 {
    font-size: 15px;
  }
  .container.home .content .right .content-right-activated-bnfts .activated-bnfts .actvPortability .actvBnftsText p, .container.home .content .right .content-right-activated-bnfts .activated-bnfts-flw .actvPortability .actvBnftsText p, .container.home .content .right .content-right-activated-bnfts-flw .activated-bnfts .actvPortability .actvBnftsText p, .container.home .content .right .content-right-activated-bnfts-flw .activated-bnfts-flw .actvPortability .actvBnftsText p, .container.vip-id .content .right .content-right-activated-bnfts .activated-bnfts .actvPortability .actvBnftsText p, .container.vip-id .content .right .content-right-activated-bnfts .activated-bnfts-flw .actvPortability .actvBnftsText p, .container.vip-id .content .right .content-right-activated-bnfts-flw .activated-bnfts .actvPortability .actvBnftsText p, .container.vip-id .content .right .content-right-activated-bnfts-flw .activated-bnfts-flw .actvPortability .actvBnftsText p {
    font-size: 12px;
  }
  .container.home .content .right .content-right-activated-bnfts .activated-bnfts .actvPortability .actvBnftsDetail p, .container.home .content .right .content-right-activated-bnfts .activated-bnfts-flw .actvPortability .actvBnftsDetail p, .container.home .content .right .content-right-activated-bnfts-flw .activated-bnfts .actvPortability .actvBnftsDetail p, .container.home .content .right .content-right-activated-bnfts-flw .activated-bnfts-flw .actvPortability .actvBnftsDetail p, .container.vip-id .content .right .content-right-activated-bnfts .activated-bnfts .actvPortability .actvBnftsDetail p, .container.vip-id .content .right .content-right-activated-bnfts .activated-bnfts-flw .actvPortability .actvBnftsDetail p, .container.vip-id .content .right .content-right-activated-bnfts-flw .activated-bnfts .actvPortability .actvBnftsDetail p, .container.vip-id .content .right .content-right-activated-bnfts-flw .activated-bnfts-flw .actvPortability .actvBnftsDetail p {
    font-size: 14px;
  }
  .container.home .content .right .content-right-plan-details-text, .container.vip-id .content .right .content-right-plan-details-text {
    padding: 0 0 10px 0;
  }
  .container.home .content .right .content-right-plan-details-text .plan-details-text .planDetailsTextColumn, .container.vip-id .content .right .content-right-plan-details-text .plan-details-text .planDetailsTextColumn {
    gap: 20px;
  }
  .container.home .content .right .content-right-plan-details-text .plan-details-text .planDetailsTextDetail p, .container.vip-id .content .right .content-right-plan-details-text .plan-details-text .planDetailsTextDetail p {
    font-size: 14px;
  }
  .container.home .content .right .choosePortability, .container.vip-id .content .right .choosePortability {
    margin-bottom: 30px;
  }
  .container.home .content .right .choosePortability .choosePortabilityText h3, .container.vip-id .content .right .choosePortability .choosePortabilityText h3 {
    font-size: 14px;
  }
  .container.home .content .right .choosePortability .choosePortabilityText p, .container.vip-id .content .right .choosePortability .choosePortabilityText p {
    font-size: 12px;
  }
  .container.home .content .right .choosePortability .choosePortabilityDetail p, .container.vip-id .content .right .choosePortability .choosePortabilityDetail p {
    gap: 4px;
    font-size: 12px;
  }
  .container.home .content .right .monthlyAnnualCards .skltn-monthlyAnnualCard, .container.vip-id .content .right .monthlyAnnualCards .skltn-monthlyAnnualCard {
    height: 100px;
  }
  .container.home .content .right .monthlyAnnualCards .monthlyAnnualCard, .container.vip-id .content .right .monthlyAnnualCards .monthlyAnnualCard {
    padding: 28px;
  }
  .container.home .content .right .monthlyAnnualCards .monthlyAnnualCard .monthlyAnnualTitle h3, .container.vip-id .content .right .monthlyAnnualCards .monthlyAnnualCard .monthlyAnnualTitle h3 {
    font-size: 15px;
  }
  .container.home .content .right .monthlyAnnualCards .monthlyAnnualCard .monthlyAnnualTitle .monthlyAnnualDetails, .container.vip-id .content .right .monthlyAnnualCards .monthlyAnnualCard .monthlyAnnualTitle .monthlyAnnualDetails {
    transform: translate(64px, 0px);
  }
  .container.home .content .right .monthlyAnnualCards .monthlyAnnualCard .monthlyAnnualTitle .monthlyAnnualDetails.annualDetails, .container.vip-id .content .right .monthlyAnnualCards .monthlyAnnualCard .monthlyAnnualTitle .monthlyAnnualDetails.annualDetails {
    transform: translate(54px, 0px);
  }
  .container.home .content .right .monthlyAnnualCards .monthlyAnnualCard .monthlyAnnualPriceArea h3, .container.vip-id .content .right .monthlyAnnualCards .monthlyAnnualCard .monthlyAnnualPriceArea h3 {
    font-size: 16px;
  }
  .container.home .content .right .monthlyAnnualCards .monthlyAnnualCard .monthlyAnnualPriceArea, .container.vip-id .content .right .monthlyAnnualCards .monthlyAnnualCard .monthlyAnnualPriceArea {
    gap: 8px;
  }
  .container.home .content .right .monthlyAnnualCards .monthlyAnnualCard .monthlyAnnualPriceArea svg, .container.vip-id .content .right .monthlyAnnualCards .monthlyAnnualCard .monthlyAnnualPriceArea svg {
    width: 18px;
  }
  .container.home .content .right .monthlyAnnualCards .monthlyAnnualCard:hover .monthlyAnnualTextArea, .container.vip-id .content .right .monthlyAnnualCards .monthlyAnnualCard:hover .monthlyAnnualTextArea {
    padding-left: 18px;
  }
}
.container.configure-seu-plano {
  --secondaryColor: #FFF4EA;
  /******************** MEDIA QUERY 900 PIXELS ********************/
  /******************** MEDIA QUERY 1200 PIXELS ********************/
  /******************** MEDIA QUERY 1440 PIXELS ********************/
  /******************** MEDIA QUERY 1860 PIXELS ********************/
}
.container.configure-seu-plano .content .container-left {
  padding: 80px 20px 224px 20px;
}
.container.configure-seu-plano .content .container-right {
  padding: 20px 25px 0 25px;
  background-color: var(--page2RightBackMob);
  position: fixed;
  bottom: 0;
  z-index: 1;
}
.container.configure-seu-plano .content .left {
  display: flex;
  gap: 30px;
  padding-bottom: 60px;
  /* HEADING */
  /* PLAN CONFIG TEXT */
  /* STEPPER PLAN */
  /* STEPS */
}
.container.configure-seu-plano .content .left .content-left-heading {
  width: 100vw;
}
.container.configure-seu-plano .content .left .content-left-heading .heading {
  display: flex;
  justify-content: center;
  width: 100%;
}
.container.configure-seu-plano .content .left .content-left-heading .heading h3 {
  display: none;
}
.container.configure-seu-plano .content .left .content-left-heading .heading img {
  width: 55%;
}
.container.configure-seu-plano .content .left .content-left-plan-config-text .plan-config-text {
  display: flex;
  align-items: center;
  gap: 10px;
}
.container.configure-seu-plano .content .left .content-left-plan-config-text .plan-config-text .iconsArea {
  display: flex;
  align-items: center;
}
.container.configure-seu-plano .content .left .content-left-plan-config-text .plan-config-text h3 {
  font-size: 14px;
  font-weight: 600;
}
.container.configure-seu-plano .content .left .content-left-plan-config-text .plan-config-text p {
  font-size: 12px;
  font-weight: 400;
}
.container.configure-seu-plano .content .left .content-left-stepper-plan {
  width: 100%;
  overflow: hidden;
}
.container.configure-seu-plano .content .left .content-left-stepper-plan .stepper-plan {
  width: 100%;
}
.container.configure-seu-plano .content .left .content-left-stepper-plan .stepper-plan .css-10mg1vw-MuiStepper-root .css-n7tliy-MuiStep-root {
  padding-left: 0;
  padding-right: 0;
}
.container.configure-seu-plano .content .left .content-left-stepper-plan .stepper-plan .Mui-completed .Mui-completed .QontoStepIcon-circle {
  background-color: var(--primaryColor);
}
.container.configure-seu-plano .content .left .content-left-stepper-plan .stepper-plan .Mui-active .QontoStepIcon-circle {
  background-color: var(--primaryColor);
}
.container.configure-seu-plano .content .left .content-left-stepper-plan .stepper-plan .Mui-active .QontoStepIcon-circle label {
  color: white;
  font-size: 10px;
  font-weight: 600;
}
.container.configure-seu-plano .content .left .content-left-stepper-plan .stepper-plan .Mui-active .QontoStepIcon-circle::after {
  content: "";
  display: block;
  width: 30%;
  height: 6px;
  background-color: var(--primaryColor);
  position: absolute;
  left: 58%;
  z-index: 1;
  border-radius: 10px;
}
.container.configure-seu-plano .content .left .content-left-stepper-plan .stepper-plan .Mui-disabled .Mui-disabled .QontoStepIcon-circle {
  color: var(--stepperDesabledCircleColor);
}
.container.configure-seu-plano .content .left .content-left-stepper-plan .stepper-plan .Mui-disabled .Mui-disabled .QontoStepIcon-circle label {
  color: var(--primaryColor);
  font-size: 10px;
  font-weight: 600;
}
.container.configure-seu-plano .content .left .content-left-stepper-plan .stepper-plan .MuiStepConnector-root.MuiStepConnector-horizontal.MuiStepConnector-alternativeLabel {
  top: 10px;
  left: calc(-50% + 8px);
  right: calc(50% + 8px);
}
.container.configure-seu-plano .content .left .content-left-stepper-plan .stepper-plan .MuiStepConnector-root.MuiStepConnector-horizontal.MuiStepConnector-alternativeLabel .MuiStepConnector-line.MuiStepConnector-lineHorizontal {
  border-top-width: 6px;
  border-color: var(--stepperBorderColor);
  width: 93%;
  margin-left: 4%;
}
.container.configure-seu-plano .content .left .content-left-stepper-plan .stepper-plan .MuiStepConnector-root.MuiStepConnector-horizontal.MuiStepConnector-alternativeLabel.Mui-completed .MuiStepConnector-line.MuiStepConnector-lineHorizontal {
  border-color: var(--primaryColor);
}
.container.configure-seu-plano .content .left .content-left-stepper-plan .stepper-plan .MuiStepConnector-root.MuiStepConnector-horizontal.MuiStepConnector-alternativeLabel.Mui-active .MuiStepConnector-line.MuiStepConnector-lineHorizontal {
  border-color: var(--primaryColor);
}
.container.configure-seu-plano .content .left .content-left-stepper-plan .stepper-plan .css-vnkopk-MuiStepLabel-iconContainer.MuiStepLabel-alternativeLabel {
  z-index: 1;
}
.container.configure-seu-plano .content .left .content-left-stepper-plan .stepper-plan .css-1vyamtt-MuiStepLabel-labelContainer .css-1hv8oq8-MuiStepLabel-label.MuiStepLabel-alternativeLabel {
  margin-top: 5px;
  color: var(--primaryColor);
  font-size: 14px;
}
.container.configure-seu-plano .content .left .content-left-stepper-plan .stepper-plan .css-1vyamtt-MuiStepLabel-labelContainer .css-1hv8oq8-MuiStepLabel-label.MuiStepLabel-alternativeLabel.Mui-disabled {
  display: none;
}
.container.configure-seu-plano .content .left .content-left-stepper-plan .stepper-plan .css-1vyamtt-MuiStepLabel-labelContainer .css-1hv8oq8-MuiStepLabel-label.MuiStepLabel-alternativeLabel.Mui-completed {
  display: none;
}
.container.configure-seu-plano .content .left .content-left-stepper-plan .stepper-plan.step-5 .Mui-active .QontoStepIcon-circle::after {
  opacity: 0;
}
.container.configure-seu-plano .content .left .content-left-steps .steps .step {
  gap: 30px;
}
.container.configure-seu-plano .content .left .content-left-steps .steps .step .choose-plan-text h3 {
  font-size: 14px;
  font-weight: 600;
}
.container.configure-seu-plano .content .left .content-left-steps .steps .step .choose-plan-text p {
  font-size: 12px;
  font-weight: 400;
}
.container.configure-seu-plano .content .left .content-left-steps .steps .step-1 {
  /* BONUS TEXT */
  /* SELECT PORTABILITY */
  /* SELECT SMART CONTROL */
  /* ACCESS TECHNOLOGY TEXT */
  /* SELECT PICK UP CHIP */
  /* SELECT CHIP INFO */
}
.container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .bonus-text {
  margin-bottom: -15px;
}
.container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .bonus-text h3 {
  font-size: 14px;
  font-weight: 600;
}
.container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .bonus-text p {
  font-size: 12px;
  font-weight: 400;
}
.container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-portability .choosePortability {
  display: flex;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
  flex-wrap: nowrap;
  max-width: 100%;
}
.container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-portability .choosePortability .choosePortabilitySwitch {
  display: flex;
  align-items: center;
}
.container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-portability .choosePortability .choosePortabilitySwitch .MuiSwitch-root {
  width: 34px;
  height: 20px;
  padding: 2px;
}
.container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-portability .choosePortability .choosePortabilitySwitch .MuiSwitch-root .MuiSwitch-thumb {
  box-sizing: border-box;
  width: 12px;
  height: 12px;
  margin-left: 3px;
  margin-top: 3px;
  transition: background-color 300ms;
  background-color: var(--primarySwitchColor);
}
.container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-portability .choosePortability .choosePortabilitySwitch .MuiSwitch-root .MuiSwitch-track {
  background-color: transparent;
  border-radius: 13px;
  border: solid 1px var(--primarySwitchColor);
  opacity: 1;
  transition: background-color 300ms, border-color 300ms;
}
.container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-portability .choosePortability .choosePortabilitySwitch .MuiSwitch-root .MuiSwitch-switchBase {
  padding: 0;
  margin: 2px;
  transition-duration: 300ms;
}
.container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-portability .choosePortability .choosePortabilitySwitch .MuiSwitch-root .MuiSwitch-switchBase.Mui-checked {
  transform: translate(12px);
}
.container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-portability .choosePortability .choosePortabilitySwitch .MuiSwitch-root .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
  opacity: 1;
  background-color: var(--primarySwitchColor);
  border: solid 1px var(--primarySwitchColor);
}
.container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-portability .choosePortability .choosePortabilitySwitch .MuiSwitch-root .MuiSwitch-switchBase.Mui-checked .MuiSwitch-thumb {
  background-color: var(--secondarySwitchColor);
}
.container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-portability .choosePortability .choosePortabilitySwitch .MuiSwitch-root .MuiSwitch-switchBase.Mui-disabled + .MuiSwitch-track {
  opacity: 1;
}
.container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-portability .choosePortability .choosePortabilityText {
  flex-direction: column;
  display: flex;
  justify-content: center;
  flex: 1;
  margin: 0 15px;
}
.container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-portability .choosePortability .choosePortabilityText h3 {
  font-size: 12px;
  font-weight: 500;
  color: #282828;
  white-space: normal;
}
.container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-portability .choosePortability .choosePortabilityText p {
  font-size: 10px;
  font-weight: 400;
  color: var(--annualTextColor);
  opacity: 0.9;
  white-space: normal;
}
.container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-portability .choosePortability .choosePortabilityDetail {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primaryColor);
  border-radius: 27px;
  padding: 0 14px;
  height: 28px;
}
.container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-portability .choosePortability .choosePortabilityDetail p {
  font-size: 12px;
  font-weight: 600;
  color: #FFFFFF;
}
.container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-portability .choosePortability .choosePortabilityDetail span {
  display: none;
}
.container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-smart-control .choosePortability {
  display: flex;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
  flex-wrap: nowrap;
  max-width: 100%;
}
.container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-smart-control .choosePortability .choosePortabilitySwitch {
  display: flex;
  align-items: center;
}
.container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-smart-control .choosePortability .choosePortabilitySwitch .MuiSwitch-root {
  width: 34px;
  height: 20px;
  padding: 2px;
}
.container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-smart-control .choosePortability .choosePortabilitySwitch .MuiSwitch-root .MuiSwitch-thumb {
  box-sizing: border-box;
  width: 12px;
  height: 12px;
  margin-left: 3px;
  margin-top: 3px;
  transition: background-color 300ms;
  background-color: var(--primarySwitchColor);
}
.container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-smart-control .choosePortability .choosePortabilitySwitch .MuiSwitch-root .MuiSwitch-track {
  background-color: transparent;
  border-radius: 13px;
  border: solid 1px var(--primarySwitchColor);
  opacity: 1;
  transition: background-color 300ms, border-color 300ms;
}
.container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-smart-control .choosePortability .choosePortabilitySwitch .MuiSwitch-root .MuiSwitch-switchBase {
  padding: 0;
  margin: 2px;
  transition-duration: 300ms;
}
.container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-smart-control .choosePortability .choosePortabilitySwitch .MuiSwitch-root .MuiSwitch-switchBase.Mui-checked {
  transform: translate(12px);
}
.container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-smart-control .choosePortability .choosePortabilitySwitch .MuiSwitch-root .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
  opacity: 1;
  background-color: var(--primarySwitchColor);
  border: solid 1px var(--primarySwitchColor);
}
.container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-smart-control .choosePortability .choosePortabilitySwitch .MuiSwitch-root .MuiSwitch-switchBase.Mui-checked .MuiSwitch-thumb {
  background-color: var(--secondarySwitchColor);
}
.container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-smart-control .choosePortability .choosePortabilitySwitch .MuiSwitch-root .MuiSwitch-switchBase.Mui-disabled + .MuiSwitch-track {
  opacity: 1;
}
.container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-smart-control .choosePortability .choosePortabilityText {
  flex-direction: column;
  display: flex;
  justify-content: center;
  flex: 1;
  margin: 0 15px;
}
.container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-smart-control .choosePortability .choosePortabilityText h3 {
  font-size: 12px;
  font-weight: 500;
  color: #282828;
}
.container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-smart-control .choosePortability .choosePortabilityText p {
  font-size: 10px;
  font-weight: 400;
  color: var(--annualTextColor);
  opacity: 0.9;
  white-space: normal;
}
.container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-smart-control .choosePortability .choosePortabilityDetail {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--focusColor);
  border-radius: 27px;
  padding: 0 14px;
  height: 28px;
}
.container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-smart-control .choosePortability .choosePortabilityDetail p {
  font-size: 12px;
  font-weight: 600;
  color: var(--primaryColor);
}
.container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-smart-control .choosePortability .choosePortabilityDetail span {
  display: none;
}
.container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .access-technology-text {
  margin: 10px 0 -15px 0;
}
.container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .access-technology-text h3 {
  font-size: 14px;
  font-weight: 600;
}
.container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .access-technology-text p {
  font-size: 12px;
  font-weight: 400;
}
.container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .chip-only-pick-up {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-bottom: 20px;
  gap: 14px;
}
.container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .chip-only-pick-up .pickUpChoose {
  gap: 10px;
  margin-bottom: 10px;
}
.container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .chip-only-pick-up .pickUpChoose .pickUpChoice {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  width: 100%;
}
.container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .chip-only-pick-up .pickUpChoose .pickUpChoice .checkArea {
  gap: 5px;
  align-items: center;
}
.container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .chip-only-pick-up .pickUpChoose .pickUpChoice .checkArea .textAreaChipInfo h3 {
  font-size: 12px;
  font-weight: 500;
  color: #282828;
}
.container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .chip-only-pick-up .pickUpChoose .pickUpChoice .checkArea .textAreaChipInfo p {
  font-size: 10px;
  font-weight: 400;
  color: var(--textLowOpacity);
}
.container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .chip-only-pick-up .pickUpChoose .pickUpChoice .checkArea .textAreaChipInfo a {
  text-decoration-color: var(--primaryColor);
}
.container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .chip-only-pick-up .pickUpChoose .pickUpChoice .checkArea .textAreaChipInfo a p {
  color: var(--primaryColor);
}
.container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .chip-only-pick-up .pickUpChoose .pickUpChoice .checkArea svg path {
  fill: var(--dualSwitchColor);
}
.container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .chip-only-pick-up .pickUpChoose .pickUpChoice .checkArea .Mui-checked svg rect {
  fill: var(--dualSwitchColor);
}
.container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .chip-only-pick-up .pickUpChoose .pickUpChoice .priceAreaChipInfo {
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}
.container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .chip-only-pick-up .pickUpChoose .pickUpChoice .priceAreaChipInfo h3 {
  font-size: 12px;
  font-weight: 500;
  color: #6D6D6D;
}
.container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-chip-info {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-bottom: 20px;
  gap: 14px;
}
.container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-chip-info .chip, .container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-chip-info .pickUpChoice {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  width: 100%;
}
.container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-chip-info .chip .checkArea, .container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-chip-info .pickUpChoice .checkArea {
  gap: 5px;
  align-items: center;
}
.container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-chip-info .chip .checkArea .textAreaChipInfo h3, .container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-chip-info .pickUpChoice .checkArea .textAreaChipInfo h3 {
  font-size: 12px;
  font-weight: 500;
  color: #282828;
}
.container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-chip-info .chip .checkArea .textAreaChipInfo p, .container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-chip-info .pickUpChoice .checkArea .textAreaChipInfo p {
  font-size: 10px;
  font-weight: 400;
  color: var(--textLowOpacity);
}
.container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-chip-info .chip .checkArea svg path, .container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-chip-info .pickUpChoice .checkArea svg path {
  fill: var(--dualSwitchColor);
}
.container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-chip-info .chip .checkArea .Mui-checked svg rect, .container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-chip-info .pickUpChoice .checkArea .Mui-checked svg rect {
  fill: var(--dualSwitchColor);
}
.container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-chip-info .chip .priceAreaChipInfo, .container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-chip-info .pickUpChoice .priceAreaChipInfo {
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}
.container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-chip-info .chip .priceAreaChipInfo h3, .container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-chip-info .pickUpChoice .priceAreaChipInfo h3 {
  font-size: 12px;
  font-weight: 500;
  color: #6D6D6D;
}
.container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-chip-info .pickUpChoose {
  gap: 10px;
  margin-bottom: 10px;
}
.container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-chip-info .pickUpChoose .pickUpChoice {
  padding: 0 7%;
}
.container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-chip-info .pickUpChoose .pickUpChoice .checkArea .textAreaChipInfo a {
  text-decoration-color: var(--primaryColor);
}
.container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-chip-info .pickUpChoose .pickUpChoice .checkArea .textAreaChipInfo a p {
  color: var(--primaryColor);
}
.container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-chip-info .pickUpChoose .pickUpChoice .checkArea svg {
  width: 85% !important;
}
.container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-chip-info .pickUpChoose .pickUpChoice .checkArea svg path {
  fill: var(--dualSwitchColor);
}
.container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-chip-info .pickUpChoose .pickUpChoice .checkArea .Mui-checked svg rect {
  fill: var(--dualSwitchColor);
}
.container.configure-seu-plano .content .left .content-left-steps .steps .step-4 .payment-step .paymentCard, .container.configure-seu-plano .content .left .content-left-steps .steps .step-5 .payment-step .paymentCard {
  border: 1px solid var(--paymetCardBorder);
}
.container.configure-seu-plano .content .left .content-left-steps .steps .step-4 .payment-step .paymentCard.activated, .container.configure-seu-plano .content .left .content-left-steps .steps .step-5 .payment-step .paymentCard.activated {
  background-color: #F4F4F4;
}
.container.configure-seu-plano .content .left .content-left-steps .steps .step-4 .payment-step .paymentCard.activated .cardCheckbox svg circle:nth-of-type(1), .container.configure-seu-plano .content .left .content-left-steps .steps .step-5 .payment-step .paymentCard.activated .cardCheckbox svg circle:nth-of-type(1) {
  stroke: var(--primaryColor);
}
.container.configure-seu-plano .content .left .content-left-steps .steps .step-4 .payment-step .paymentCard.activated .cardCheckbox svg circle:nth-of-type(2), .container.configure-seu-plano .content .left .content-left-steps .steps .step-5 .payment-step .paymentCard.activated .cardCheckbox svg circle:nth-of-type(2) {
  fill: var(--primaryColor);
}
.container.configure-seu-plano .content .left .content-left-steps .steps .step-4 .payment-step .paymentCard .cardDetail, .container.configure-seu-plano .content .left .content-left-steps .steps .step-5 .payment-step .paymentCard .cardDetail {
  background-color: var(--primaryColor);
  color: white;
}
.container.configure-seu-plano .content .left .content-left-steps .steps .step-4 .payment-step .paymentCard .keepCard svg path, .container.configure-seu-plano .content .left .content-left-steps .steps .step-5 .payment-step .paymentCard .keepCard svg path {
  fill: var(--primaryColor);
}
.container.configure-seu-plano .content .left .content-left-steps .steps .step-4 .payment-step .collapses .pix-collapse .pixQrCodeBox, .container.configure-seu-plano .content .left .content-left-steps .steps .step-5 .payment-step .collapses .pix-collapse .pixQrCodeBox {
  border: 2px solid var(--paymetCardBorder);
}
.container.configure-seu-plano .content .left .content-left-steps .steps .step-4 .payment-step .collapses .pix-collapse .stepByStep .stepNumber, .container.configure-seu-plano .content .left .content-left-steps .steps .step-5 .payment-step .collapses .pix-collapse .stepByStep .stepNumber {
  background-color: var(--stepperDesabledCircleColor);
  color: var(--primaryColor);
}
.container.configure-seu-plano .content .left .content-left-steps .steps .step-4 .payment-step .collapses .pix-collapse .stepByStep .stepText p, .container.configure-seu-plano .content .left .content-left-steps .steps .step-5 .payment-step .collapses .pix-collapse .stepByStep .stepText p {
  color: var(--textLowOpacity);
}
.container.configure-seu-plano .content .left .content-left-steps .steps .step-4 .payment-step .collapses .boleto-collapse .boletoLinkBox, .container.configure-seu-plano .content .left .content-left-steps .steps .step-5 .payment-step .collapses .boleto-collapse .boletoLinkBox {
  border: 1px solid var(--paymetCardBorder);
}
.container.configure-seu-plano .content .left .content-left-steps .steps .step-4 .payment-step .collapses .boleto-collapse .stepByStep .stepNumber, .container.configure-seu-plano .content .left .content-left-steps .steps .step-5 .payment-step .collapses .boleto-collapse .stepByStep .stepNumber {
  background-color: var(--stepperDesabledCircleColor);
  color: var(--primaryColor);
}
.container.configure-seu-plano .content .left .content-left-steps .steps .step-4 .payment-step .collapses .boleto-collapse .stepByStep .stepText p, .container.configure-seu-plano .content .left .content-left-steps .steps .step-5 .payment-step .collapses .boleto-collapse .stepByStep .stepText p {
  color: var(--textLowOpacity);
}
.container.configure-seu-plano .content .left .content-left-steps .steps .step-4 .payment-step .collapses .boleto-collapse .downloadPDFBtn, .container.configure-seu-plano .content .left .content-left-steps .steps .step-5 .payment-step .collapses .boleto-collapse .downloadPDFBtn {
  border: 1px solid var(--textLowOpacity);
  color: var(--textLowOpacity);
}
.container.configure-seu-plano .content .right {
  /* Theme */
  --textColor: #FFFFFF;
  background-color: var(--page2RightBackMob);
  display: flex;
  flex-direction: column;
  gap: 20px;
  /* ORDER SUMMARY TEXT */
  /* FIRST SUMMARY */
  /* SECOND SUMMARY */
  /* CHOSEN PLAN CHIP */
  /* PURCHASE SUMMARY */
}
.container.configure-seu-plano .content .right .content-right-order-summary-text .order-summary-text {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  padding-top: 5px;
  margin-bottom: 5px;
  color: var(--textColor);
}
.container.configure-seu-plano .content .right .content-right-order-summary-text .order-summary-text h3 {
  font-size: 14px;
  font-weight: 500;
}
.container.configure-seu-plano .content .right .content-right-order-summary-text .order-summary-text p {
  font-size: 12px;
  font-weight: 400;
  opacity: 0.75;
}
.container.configure-seu-plano .content .right .content-right-order-summary-text .order-summary-text .iconsArea {
  display: flex;
}
.container.configure-seu-plano .content .right .content-right-order-summary-text .order-summary-text.closed {
  padding-top: 0;
  margin-bottom: 0;
}
.container.configure-seu-plano .content .right .content-right-first-summary {
  margin: 5px 0 20px 0;
}
.container.configure-seu-plano .content .right .content-right-first-summary .first-summary {
  gap: 15px;
}
.container.configure-seu-plano .content .right .content-right-first-summary .first-summary .titleArea {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
}
.container.configure-seu-plano .content .right .content-right-first-summary .first-summary .titleArea .iconsArea {
  display: flex;
  align-items: center;
}
.container.configure-seu-plano .content .right .content-right-first-summary .first-summary .titleArea .textArea {
  color: var(--textColor);
}
.container.configure-seu-plano .content .right .content-right-first-summary .first-summary .titleArea .textArea h3 {
  font-size: 14px;
  font-weight: 500;
}
.container.configure-seu-plano .content .right .content-right-first-summary .first-summary .titleArea .textArea p {
  font-size: 12px;
  font-weight: 400;
  opacity: 0.75;
}
.container.configure-seu-plano .content .right .content-right-first-summary .first-summary .ItemsArea {
  gap: 25px;
}
.container.configure-seu-plano .content .right .content-right-first-summary .first-summary .ItemsArea .item {
  width: 100%;
}
.container.configure-seu-plano .content .right .content-right-first-summary .first-summary .ItemsArea .item .choosePortability {
  display: flex;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
  flex-wrap: nowrap;
  max-width: 100%;
  gap: 15px;
}
.container.configure-seu-plano .content .right .content-right-first-summary .first-summary .ItemsArea .item .choosePortability .choosePortabilityText {
  flex-direction: column;
  display: flex;
  justify-content: center;
  flex: 1;
  color: var(--textColor);
}
.container.configure-seu-plano .content .right .content-right-first-summary .first-summary .ItemsArea .item .choosePortability .choosePortabilityText h3 {
  font-size: 14px;
  font-weight: 500;
}
.container.configure-seu-plano .content .right .content-right-first-summary .first-summary .ItemsArea .item .choosePortability .choosePortabilityText p {
  font-size: 10px;
  font-weight: 400;
  opacity: 0.75;
  white-space: normal;
}
.container.configure-seu-plano .content .right .content-right-first-summary .first-summary .ItemsArea .item .choosePortability .choosePortabilityDetail {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--focusColor);
  border-radius: 27px;
  padding: 0 14px;
  height: 28px;
}
.container.configure-seu-plano .content .right .content-right-first-summary .first-summary .ItemsArea .item .choosePortability .choosePortabilityDetail p {
  font-size: 12px;
  font-weight: 600;
  color: var(--primaryColor);
}
.container.configure-seu-plano .content .right .content-right-first-summary .first-summary .ItemsArea .item .choosePortability .choosePortabilityDetail span {
  display: none;
}
.container.configure-seu-plano .content .right .content-right-second-summary {
  display: none;
  margin: 5px 0 -10px 0;
}
.container.configure-seu-plano .content .right .content-right-second-summary .second-summary .titleArea {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
}
.container.configure-seu-plano .content .right .content-right-second-summary .second-summary .titleArea .iconsArea {
  display: flex;
  align-items: center;
}
.container.configure-seu-plano .content .right .content-right-second-summary .second-summary .titleArea .textArea {
  color: var(--textColor);
}
.container.configure-seu-plano .content .right .content-right-second-summary .second-summary .titleArea .textArea h3 {
  font-size: 14px;
  font-weight: 500;
}
.container.configure-seu-plano .content .right .content-right-second-summary .second-summary .titleArea .textArea p {
  font-size: 12px;
  font-weight: 400;
  opacity: 0.75;
}
.container.configure-seu-plano .content .right .content-right-chosen-plan-chip {
  display: none;
}
.container.configure-seu-plano .content .right .content-right-chosen-plan-chip .chosen-plan-chip {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  gap: 25px;
}
.container.configure-seu-plano .content .right .content-right-chosen-plan-chip .chosen-plan-chip .chosenPlanChip {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
  color: var(--textColor);
}
.container.configure-seu-plano .content .right .content-right-chosen-plan-chip .chosen-plan-chip .chosenPlanChip .textArea h3 {
  font-size: 14px;
  font-weight: 500;
}
.container.configure-seu-plano .content .right .content-right-chosen-plan-chip .chosen-plan-chip .chosenPlanChip .textArea p {
  font-size: 12px;
  font-weight: 400;
  opacity: 0.75;
}
.container.configure-seu-plano .content .right .content-right-chosen-plan-chip .chosen-plan-chip .chosenPlanChip .priceArea h3 {
  font-size: 14px;
  font-weight: 500;
}
.container.configure-seu-plano .content .right .content-right-chosen-plan-chip .chosen-plan-chip .chosenPlanChip .chip {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}
.container.configure-seu-plano .content .right .content-right-purchase-summary {
  border-top: solid 1px #FFFFFF;
  width: 100vw;
  margin-left: -25px;
}
.container.configure-seu-plano .content .right .content-right-purchase-summary .purchase-summary {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 3vh 20px;
}
.container.configure-seu-plano .content .right .content-right-purchase-summary .purchase-summary .priceArea {
  color: var(--textColor);
}
.container.configure-seu-plano .content .right .content-right-purchase-summary .purchase-summary .priceArea h3 {
  font-size: 20px;
  font-weight: 500;
}
.container.configure-seu-plano .content .right .content-right-purchase-summary .purchase-summary .priceArea p {
  font-size: 12px;
  font-weight: 400;
}
.container.configure-seu-plano .content .right .content-right-purchase-summary .purchase-summary .buttonArea .MuiCircularProgress-root svg circle {
  stroke: var(--textColor);
}
.container.configure-seu-plano .content .right .content-right-purchase-summary .purchase-summary .buttonArea button {
  padding: 13px 20px;
  font-size: 12px;
  font-weight: 600;
  border-radius: 100px;
  background-color: var(--uncomplicatedButtonColor);
  color: var(--uncomplicatedButtonText);
  gap: 8px;
}
.container.configure-seu-plano .content .right .content-right-purchase-summary .purchase-summary .buttonArea button svg {
  width: 16px;
}
.container.configure-seu-plano .content .right .content-right-purchase-summary .purchase-summary .buttonArea button svg path {
  stroke: var(--uncomplicatedButtonIcon);
}
.container.configure-seu-plano .footer {
  display: none;
  height: 32vh;
  background-color: blue;
  padding: 60px 20px;
}
@media (min-width: 900px) {
  .container.configure-seu-plano {
    display: flex;
    align-items: center;
    height: 100vh;
  }
  .container.configure-seu-plano .content {
    display: flex;
    flex-wrap: nowrap;
    height: 100vh;
  }
  .container.configure-seu-plano .content .container-left {
    display: flex;
    width: 170%;
    padding: 1.75rem 2.75rem;
  }
  .container.configure-seu-plano .content .container-right {
    display: flex;
    padding: 0;
    background-color: var(--page2RightBackDesk);
    position: unset;
    bottom: unset;
    padding: 1.75rem 2.5rem;
    overflow: hidden;
    z-index: unset;
  }
  .container.configure-seu-plano .content .left {
    display: flex;
    flex-direction: column;
    gap: 15px;
    /* HEADING */
    /* PLAN CONFIG TEXT */
    /* STEPPER PLAN */
    /* STEPS */
  }
  .container.configure-seu-plano .content .left .content-left-heading {
    align-self: flex-start;
    width: auto;
  }
  .container.configure-seu-plano .content .left .content-left-heading .heading {
    justify-content: unset;
  }
  .container.configure-seu-plano .content .left .content-left-heading .heading img {
    width: 120px;
  }
  .container.configure-seu-plano .content .left .content-left-plan-config-text {
    padding-left: 14%;
    margin-bottom: -5px;
  }
  .container.configure-seu-plano .content .left .content-left-plan-config-text .plan-config-text {
    gap: 8px;
  }
  .container.configure-seu-plano .content .left .content-left-plan-config-text .plan-config-text .iconsArea svg {
    width: 14px;
  }
  .container.configure-seu-plano .content .left .content-left-plan-config-text .plan-config-text h3 {
    font-size: 9px;
  }
  .container.configure-seu-plano .content .left .content-left-plan-config-text .plan-config-text p {
    font-size: 8px;
  }
  .container.configure-seu-plano .content .left .content-left-stepper-plan {
    justify-content: center;
    width: 100%;
    overflow: hidden;
  }
  .container.configure-seu-plano .content .left .content-left-stepper-plan .stepper-plan {
    width: 85%;
  }
  .container.configure-seu-plano .content .left .content-left-stepper-plan .stepper-plan .MuiStepLabel-root .MuiStepLabel-iconContainer .QontoStepIcon-completedIcon {
    width: 10px;
    height: 10px;
  }
  .container.configure-seu-plano .content .left .content-left-stepper-plan .stepper-plan .MuiStepLabel-root .MuiStepLabel-iconContainer .QontoStepIcon-circle {
    width: 15px;
    height: 15px;
  }
  .container.configure-seu-plano .content .left .content-left-stepper-plan .stepper-plan .Mui-active .QontoStepIcon-circle label {
    font-size: 7px;
  }
  .container.configure-seu-plano .content .left .content-left-stepper-plan .stepper-plan .Mui-active .QontoStepIcon-circle::after {
    width: 33%;
    height: 4px;
    left: 52%;
    transform: translateY(0px);
  }
  .container.configure-seu-plano .content .left .content-left-stepper-plan .stepper-plan .Mui-disabled .Mui-disabled .QontoStepIcon-circle label {
    font-size: 7px;
  }
  .container.configure-seu-plano .content .left .content-left-stepper-plan .stepper-plan .MuiStepConnector-root.MuiStepConnector-horizontal.MuiStepConnector-alternativeLabel {
    top: 6px;
    left: calc(-50% + 6px);
    right: calc(50% + 6px);
  }
  .container.configure-seu-plano .content .left .content-left-stepper-plan .stepper-plan .MuiStepConnector-root.MuiStepConnector-horizontal.MuiStepConnector-alternativeLabel .MuiStepConnector-line.MuiStepConnector-lineHorizontal {
    border-top-width: 4px;
  }
  .container.configure-seu-plano .content .left .content-left-stepper-plan .stepper-plan .css-1vyamtt-MuiStepLabel-labelContainer .css-1hv8oq8-MuiStepLabel-label.MuiStepLabel-alternativeLabel {
    font-size: 7px;
  }
  .container.configure-seu-plano .content .left .content-left-stepper-plan .stepper-plan .css-1vyamtt-MuiStepLabel-labelContainer .css-1hv8oq8-MuiStepLabel-label.MuiStepLabel-alternativeLabel.Mui-disabled {
    display: block;
    color: var(--textColor);
  }
  .container.configure-seu-plano .content .left .content-left-stepper-plan .stepper-plan .css-1vyamtt-MuiStepLabel-labelContainer .css-1hv8oq8-MuiStepLabel-label.MuiStepLabel-alternativeLabel.Mui-completed {
    display: block;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step {
    gap: 15px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step .textArea {
    width: 100%;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step .choose-plan-text {
    padding-left: 15%;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step .choose-plan-text h3 {
    font-size: 9px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step .choose-plan-text p {
    font-size: 8px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step .form {
    padding: 0 15%;
    gap: 5px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step .form .choose-plan-text {
    padding-left: 0;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step .form .choose-plan-text h3 {
    font-size: 8px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step .form .choose-plan-text p {
    font-size: 7px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step .form .formArea {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step .form .formArea .fractArea {
    display: flex;
    width: 100%;
    gap: 8px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step .form .formArea .MuiFormControl-root .MuiInputLabel-root.MuiInputLabel-sizeSmall {
    font-size: 10px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step .form .formArea .MuiFormControl-root .MuiInputBase-sizeSmall input.MuiInputBase-inputSizeSmall {
    font-size: 10px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step .form .formArea .MuiFormLabel-root.MuiInputLabel-shrink.MuiInputLabel-sizeSmall.MuiInputLabel-outlined {
    transform: translate(13px, -4px) scale(0.75);
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step .form .formArea .MuiInputBase-root.MuiInputBase-sizeSmall span {
    font-size: 7px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step .form .formArea .MuiFormHelperText-root.MuiFormHelperText-sizeSmall {
    font-size: 8px;
    margin-top: 2px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step .form .formArea .MuiButtonBase-root.MuiAutocomplete-clearIndicator svg {
    font-size: 0.8rem;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step .form .formArea .MuiButtonBase-root.MuiAutocomplete-popupIndicator {
    padding-top: 3px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step .form .formArea .MuiButtonBase-root.MuiAutocomplete-popupIndicator svg {
    font-size: 1.3rem;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step .form .formArea .MuiButtonBase-root.clearButton svg {
    font-size: 0.8rem;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step .form .formArea .MuiAutocomplete-popper .MuiAutocomplete-option {
    font-size: 10px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step-1 {
    /* BONUS TEXT */
    /* SELECT PORTABILITY */
    /* SELECT SMART CONTROL */
    /* ACCESS TECHNOLOGY TEXT */
    /* SELECT PICK UP CHIP */
    /* SELECT CHIP INFO */
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .bonus-text {
    margin-bottom: -5px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-portability {
    padding: 0 15%;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-portability .choosePortability .choosePortabilitySwitch .MuiSwitch-root {
    width: 24px;
    height: 14px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-portability .choosePortability .choosePortabilitySwitch .MuiSwitch-root .MuiSwitch-thumb {
    width: 8px;
    height: 8px;
    margin-left: 2px;
    margin-top: 2px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-portability .choosePortability .choosePortabilitySwitch .MuiSwitch-root .MuiSwitch-switchBase.Mui-checked {
    transform: translate(8px);
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-portability .choosePortability .choosePortabilityText {
    margin: 0 10px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-portability .choosePortability .choosePortabilityText h3 {
    font-size: 8px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-portability .choosePortability .choosePortabilityText p {
    font-size: 7px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-portability .choosePortability .choosePortabilityDetail {
    padding: 3px 8px;
    width: unset;
    height: unset;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-portability .choosePortability .choosePortabilityDetail p {
    display: flex;
    gap: 3px;
    font-size: 8px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-portability .choosePortability .choosePortabilityDetail span {
    display: block;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-smart-control {
    padding: 0 15%;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-smart-control .choosePortability .choosePortabilitySwitch .MuiSwitch-root {
    width: 24px;
    height: 14px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-smart-control .choosePortability .choosePortabilitySwitch .MuiSwitch-root .MuiSwitch-thumb {
    width: 8px;
    height: 8px;
    margin-left: 2px;
    margin-top: 2px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-smart-control .choosePortability .choosePortabilitySwitch .MuiSwitch-root .MuiSwitch-switchBase.Mui-checked {
    transform: translate(8px);
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-smart-control .choosePortability .choosePortabilityText {
    margin: 0 10px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-smart-control .choosePortability .choosePortabilityText h3 {
    font-size: 8px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-smart-control .choosePortability .choosePortabilityText p {
    font-size: 7px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-smart-control .choosePortability .choosePortabilityDetail {
    padding: 3px 8px;
    width: unset;
    height: unset;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-smart-control .choosePortability .choosePortabilityDetail p {
    display: flex;
    gap: 3px;
    font-size: 8px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-smart-control .choosePortability .choosePortabilityDetail span {
    display: block;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .access-technology-text {
    margin: 15px 0 -5px 0;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .chip-only-pick-up {
    padding: 0 15% 30px;
    gap: 10px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .chip-only-pick-up .pickUpChoose .pickUpChoice .checkArea {
    display: flex;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .chip-only-pick-up .pickUpChoose .pickUpChoice .checkArea .MuiButtonBase-root.MuiCheckbox-root {
    padding: 0 8px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .chip-only-pick-up .pickUpChoose .pickUpChoice .checkArea .MuiButtonBase-root.MuiCheckbox-root svg {
    width: 12px;
    height: 12px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .chip-only-pick-up .pickUpChoose .pickUpChoice .checkArea .textAreaChipInfo h3 {
    font-size: 8px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .chip-only-pick-up .pickUpChoose .pickUpChoice .checkArea .textAreaChipInfo p {
    font-size: 7px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .chip-only-pick-up .pickUpChoose .pickUpChoice .priceAreaChipInfo h3 {
    font-size: 9px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .chip-only-pick-up hr {
    margin-top: -10px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-chip-info {
    padding: 0 15% 30px;
    gap: 10px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-chip-info .chip .checkArea, .container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-chip-info .pickUpChoice .checkArea {
    display: flex;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-chip-info .chip .checkArea .MuiButtonBase-root.MuiCheckbox-root, .container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-chip-info .pickUpChoice .checkArea .MuiButtonBase-root.MuiCheckbox-root {
    padding: 0 8px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-chip-info .chip .checkArea .MuiButtonBase-root.MuiCheckbox-root svg, .container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-chip-info .pickUpChoice .checkArea .MuiButtonBase-root.MuiCheckbox-root svg {
    width: 12px;
    height: 12px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-chip-info .chip .checkArea .textAreaChipInfo h3, .container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-chip-info .pickUpChoice .checkArea .textAreaChipInfo h3 {
    font-size: 8px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-chip-info .chip .checkArea .textAreaChipInfo p, .container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-chip-info .pickUpChoice .checkArea .textAreaChipInfo p {
    font-size: 7px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-chip-info .chip .priceAreaChipInfo h3, .container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-chip-info .pickUpChoice .priceAreaChipInfo h3 {
    font-size: 9px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-chip-info hr {
    margin-top: -10px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step-4 .step-5 .payment-step {
    padding: 10px 15% 0 15%;
    gap: 20px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step-4 .step-5 .payment-step .textArea {
    gap: 5px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step-4 .step-5 .payment-step .textArea h3 {
    font-size: 15px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step-4 .step-5 .payment-step .textArea h4 {
    font-size: 11px;
    padding: 0 0 5px 0;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step-4 .step-5 .payment-step .textArea .configBonusText {
    padding: 5px 25px 0 25px;
    gap: 5px;
    font-size: 11px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step-4 .step-5 .payment-step .textArea .configBonusText ul li {
    font-size: 9px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step-4 .step-5 .payment-step .buttonArea button {
    padding: 10px 18px;
    font-size: 9px;
  }
  .container.configure-seu-plano .content .right {
    /* Theme */
    --textColor: #2F2F2F;
    --uncomplicatedButtonColor: var(--primaryColor);
    --uncomplicatedButtonText: var(--secondaryColor);
    --uncomplicatedButtonIcon: var(--secondaryColor);
    background-color: var(--page2RightBackDesk);
    position: relative;
    gap: 25px;
    /* ORDER SUMMARY TEXT */
    /* FIRST SUMMARY */
    /* SECOND SUMMARY */
    /* CHOSEN PLAN CHIP */
    /* PURCHASE SUMMARY */
  }
  .container.configure-seu-plano .content .right .content-right-order-summary-text {
    display: none;
  }
  .container.configure-seu-plano .content .right .content-right-first-summary {
    margin: 0;
  }
  .container.configure-seu-plano .content .right .content-right-first-summary .first-summary {
    gap: 10px;
  }
  .container.configure-seu-plano .content .right .content-right-first-summary .first-summary .titleArea {
    gap: 7px;
  }
  .container.configure-seu-plano .content .right .content-right-first-summary .first-summary .titleArea .iconsArea svg {
    width: 18px;
  }
  .container.configure-seu-plano .content .right .content-right-first-summary .first-summary .titleArea .iconsArea svg rect {
    stroke: var(--primaryColor);
  }
  .container.configure-seu-plano .content .right .content-right-first-summary .first-summary .titleArea .iconsArea svg path {
    stroke: var(--primaryColor);
  }
  .container.configure-seu-plano .content .right .content-right-first-summary .first-summary .titleArea .textArea {
    color: var(--primaryColor);
  }
  .container.configure-seu-plano .content .right .content-right-first-summary .first-summary .titleArea .textArea h3 {
    font-size: 9px;
  }
  .container.configure-seu-plano .content .right .content-right-first-summary .first-summary .titleArea .textArea p {
    font-size: 7px;
    opacity: 0.5;
  }
  .container.configure-seu-plano .content .right .content-right-first-summary .first-summary .ItemsArea {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-left: 10%;
  }
  .container.configure-seu-plano .content .right .content-right-first-summary .first-summary .ItemsArea .item {
    width: 100%;
  }
  .container.configure-seu-plano .content .right .content-right-first-summary .first-summary .ItemsArea .item .choosePortability .choosePortabilityText h3 {
    font-size: 8px;
  }
  .container.configure-seu-plano .content .right .content-right-first-summary .first-summary .ItemsArea .item .choosePortability .choosePortabilityText p {
    font-size: 7px;
  }
  .container.configure-seu-plano .content .right .content-right-first-summary .first-summary .ItemsArea .item .choosePortability .choosePortabilityDetail {
    background-color: var(--primaryColor);
    padding: 3px 6px;
    width: unset;
    height: unset;
  }
  .container.configure-seu-plano .content .right .content-right-first-summary .first-summary .ItemsArea .item .choosePortability .choosePortabilityDetail p {
    font-size: 7px;
    color: var(--secondaryColor);
  }
  .container.configure-seu-plano .content .right .content-right-second-summary {
    display: flex !important;
    margin: 0 0 -15px 0;
  }
  .container.configure-seu-plano .content .right .content-right-second-summary .second-summary {
    gap: 10px;
  }
  .container.configure-seu-plano .content .right .content-right-second-summary .second-summary .titleArea {
    gap: 7px;
  }
  .container.configure-seu-plano .content .right .content-right-second-summary .second-summary .titleArea .iconsArea svg {
    width: 18px;
  }
  .container.configure-seu-plano .content .right .content-right-second-summary .second-summary .titleArea .iconsArea svg rect {
    stroke: var(--primaryColor);
  }
  .container.configure-seu-plano .content .right .content-right-second-summary .second-summary .titleArea .iconsArea svg path {
    fill: var(--primaryColor);
  }
  .container.configure-seu-plano .content .right .content-right-second-summary .second-summary .titleArea .textArea {
    color: var(--primaryColor);
  }
  .container.configure-seu-plano .content .right .content-right-second-summary .second-summary .titleArea .textArea h3 {
    font-size: 9px;
  }
  .container.configure-seu-plano .content .right .content-right-second-summary .second-summary .titleArea .textArea p {
    font-size: 7px;
    opacity: 0.5;
  }
  .container.configure-seu-plano .content .right .content-right-chosen-plan-chip {
    display: flex !important;
    padding-left: 10%;
  }
  .container.configure-seu-plano .content .right .content-right-chosen-plan-chip .chosen-plan-chip {
    margin-bottom: 0;
    gap: 10px;
  }
  .container.configure-seu-plano .content .right .content-right-chosen-plan-chip .chosen-plan-chip .chosenPlanChip .textArea h3 {
    font-size: 8px;
  }
  .container.configure-seu-plano .content .right .content-right-chosen-plan-chip .chosen-plan-chip .chosenPlanChip .textArea p {
    font-size: 7px;
  }
  .container.configure-seu-plano .content .right .content-right-chosen-plan-chip .chosen-plan-chip .chosenPlanChip .priceArea h3 {
    font-size: 9px;
  }
  .container.configure-seu-plano .content .right .content-right-purchase-summary {
    border-top: solid 1px var(--primaryColor);
    width: 100%;
    margin-left: 0;
    position: absolute;
    bottom: 0;
  }
  .container.configure-seu-plano .content .right .content-right-purchase-summary .purchase-summary {
    padding: 7vh 0 0 0;
  }
  .container.configure-seu-plano .content .right .content-right-purchase-summary .purchase-summary .priceArea h3 {
    font-size: 11px;
  }
  .container.configure-seu-plano .content .right .content-right-purchase-summary .purchase-summary .priceArea p {
    font-size: 8px;
  }
  .container.configure-seu-plano .content .right .content-right-purchase-summary .purchase-summary .buttonArea button {
    padding: 7px 10px;
    font-size: 6px;
    background-color: var(--uncomplicatedButtonColor);
    color: var(--uncomplicatedButtonText);
    gap: 5px;
  }
  .container.configure-seu-plano .content .right .content-right-purchase-summary .purchase-summary .buttonArea button svg {
    width: 10px;
    height: 10px;
  }
  .container.configure-seu-plano .content .right .content-right-purchase-summary .purchase-summary .buttonArea button svg path {
    stroke: var(--uncomplicatedButtonIcon);
  }
  .container.configure-seu-plano .content .right .content-right-purchase-summary .purchase-summary .buttonArea .MuiCircularProgress-root svg circle {
    stroke: var(--primaryColor);
  }
  .container.configure-seu-plano .footer {
    display: none;
  }
}
@media (min-width: 1200px) {
  .container.configure-seu-plano .content .container-left {
    padding: 2.5rem 3.5rem;
  }
  .container.configure-seu-plano .content .container-right {
    padding: 2.75rem 3.25rem;
  }
  .container.configure-seu-plano .content .left {
    gap: 20px;
    /* HEADING */
    /* PLAN CONFIG TEXT */
    /* STEPPER PLAN */
    /* STEPS */
  }
  .container.configure-seu-plano .content .left .content-left-heading .heading img {
    width: 165px;
  }
  .container.configure-seu-plano .content .left .content-left-plan-config-text .plan-config-text .iconsArea svg {
    width: 20px;
  }
  .container.configure-seu-plano .content .left .content-left-plan-config-text .plan-config-text h3 {
    font-size: 11px;
  }
  .container.configure-seu-plano .content .left .content-left-plan-config-text .plan-config-text p {
    font-size: 10px;
  }
  .container.configure-seu-plano .content .left .content-left-stepper-plan .stepper-plan .MuiStepLabel-root .MuiStepLabel-iconContainer .QontoStepIcon-completedIcon {
    width: 14px;
    height: 14px;
  }
  .container.configure-seu-plano .content .left .content-left-stepper-plan .stepper-plan .MuiStepLabel-root .MuiStepLabel-iconContainer .QontoStepIcon-circle {
    width: 21px;
    height: 21px;
  }
  .container.configure-seu-plano .content .left .content-left-stepper-plan .stepper-plan .Mui-active .QontoStepIcon-circle label {
    font-size: 9px;
  }
  .container.configure-seu-plano .content .left .content-left-stepper-plan .stepper-plan .Mui-active .QontoStepIcon-circle::after {
    width: 33%;
    height: 5px;
    left: 52%;
    transform: translateY(1px);
  }
  .container.configure-seu-plano .content .left .content-left-stepper-plan .stepper-plan .Mui-disabled .Mui-disabled .QontoStepIcon-circle label {
    font-size: 9px;
  }
  .container.configure-seu-plano .content .left .content-left-stepper-plan .stepper-plan .MuiStepConnector-root.MuiStepConnector-horizontal.MuiStepConnector-alternativeLabel {
    top: 9px;
    left: calc(-50% + 6px);
    right: calc(50% + 6px);
  }
  .container.configure-seu-plano .content .left .content-left-stepper-plan .stepper-plan .MuiStepConnector-root.MuiStepConnector-horizontal.MuiStepConnector-alternativeLabel .MuiStepConnector-line.MuiStepConnector-lineHorizontal {
    border-top-width: 5px;
  }
  .container.configure-seu-plano .content .left .content-left-stepper-plan .stepper-plan .css-1vyamtt-MuiStepLabel-labelContainer .css-1hv8oq8-MuiStepLabel-label.MuiStepLabel-alternativeLabel {
    font-size: 10px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step {
    gap: 15px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step .choose-plan-text h3 {
    font-size: 11px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step .choose-plan-text p {
    font-size: 10px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step .form {
    gap: 10px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step .form .choose-plan-text h3 {
    font-size: 10px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step .form .choose-plan-text p {
    font-size: 9px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step .form .formArea {
    gap: 12px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step .form .formArea .fractArea {
    gap: 12px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step .form .formArea .MuiFormControl-root .MuiInputLabel-root.MuiInputLabel-sizeSmall {
    font-size: 13px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step .form .formArea .MuiFormControl-root .MuiInputBase-sizeSmall input.MuiInputBase-inputSizeSmall {
    font-size: 13px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step .form .formArea .MuiFormLabel-root.MuiInputLabel-shrink.MuiInputLabel-sizeSmall.MuiInputLabel-outlined {
    transform: translate(14px, -5px) scale(0.75);
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step .form .formArea .MuiInputBase-root.MuiInputBase-sizeSmall span {
    font-size: 10px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step .form .formArea .MuiFormHelperText-root.MuiFormHelperText-sizeSmall {
    font-size: 10px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step .form .formArea .MuiButtonBase-root.MuiAutocomplete-clearIndicator svg {
    font-size: 0.9rem;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step .form .formArea .MuiButtonBase-root.MuiAutocomplete-popupIndicator {
    padding-top: 2px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step .form .formArea .MuiButtonBase-root.MuiAutocomplete-popupIndicator svg {
    font-size: 1.4rem;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step .form .formArea .MuiButtonBase-root.clearButton svg {
    font-size: 0.9rem;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step .form .formArea .MuiAutocomplete-popper .MuiAutocomplete-option {
    font-size: 12px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step-1 {
    /* SELECT PORTABILITY */
    /* SELECT SMART CONTROL */
    /* SELECT PICK UP CHIP */
    /* SELECT CHIP INFO */
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-portability .choosePortability .choosePortabilitySwitch .MuiSwitch-root {
    width: 29px;
    height: 17px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-portability .choosePortability .choosePortabilitySwitch .MuiSwitch-root .MuiSwitch-thumb {
    width: 9px;
    height: 9px;
    margin-left: 3px;
    margin-top: 3px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-portability .choosePortability .choosePortabilitySwitch .MuiSwitch-root .MuiSwitch-switchBase.Mui-checked {
    transform: translate(10px);
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-portability .choosePortability .choosePortabilityText {
    margin: 0 12px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-portability .choosePortability .choosePortabilityText h3 {
    font-size: 10px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-portability .choosePortability .choosePortabilityText p {
    font-size: 9px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-portability .choosePortability .choosePortabilityDetail {
    padding: 4px 9px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-portability .choosePortability .choosePortabilityDetail p {
    font-size: 9px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-smart-control .choosePortability .choosePortabilitySwitch .MuiSwitch-root {
    width: 29px;
    height: 17px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-smart-control .choosePortability .choosePortabilitySwitch .MuiSwitch-root .MuiSwitch-thumb {
    width: 9px;
    height: 9px;
    margin-left: 3px;
    margin-top: 3px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-smart-control .choosePortability .choosePortabilitySwitch .MuiSwitch-root .MuiSwitch-switchBase.Mui-checked {
    transform: translate(10px);
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-smart-control .choosePortability .choosePortabilityText {
    margin: 0 12px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-smart-control .choosePortability .choosePortabilityText h3 {
    font-size: 10px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-smart-control .choosePortability .choosePortabilityText p {
    font-size: 9px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-smart-control .choosePortability .choosePortabilityDetail {
    padding: 4px 9px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-smart-control .choosePortability .choosePortabilityDetail p {
    font-size: 9px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .chip-only-pick-up .pickUpChoose .pickUpChoice .checkArea .MuiButtonBase-root.MuiCheckbox-root {
    padding: 0 11px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .chip-only-pick-up .pickUpChoose .pickUpChoice .checkArea .MuiButtonBase-root.MuiCheckbox-root svg {
    width: 16px;
    height: 16px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .chip-only-pick-up .pickUpChoose .pickUpChoice .checkArea .textAreaChipInfo h3 {
    font-size: 10px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .chip-only-pick-up .pickUpChoose .pickUpChoice .checkArea .textAreaChipInfo p {
    font-size: 9px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .chip-only-pick-up .pickUpChoose .pickUpChoice .priceAreaChipInfo h3 {
    font-size: 11px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-chip-info .chip .checkArea .MuiButtonBase-root.MuiCheckbox-root, .container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-chip-info .pickUpChoice .checkArea .MuiButtonBase-root.MuiCheckbox-root {
    padding: 0 11px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-chip-info .chip .checkArea .MuiButtonBase-root.MuiCheckbox-root svg, .container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-chip-info .pickUpChoice .checkArea .MuiButtonBase-root.MuiCheckbox-root svg {
    width: 16px;
    height: 16px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-chip-info .chip .checkArea .textAreaChipInfo h3, .container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-chip-info .pickUpChoice .checkArea .textAreaChipInfo h3 {
    font-size: 10px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-chip-info .chip .checkArea .textAreaChipInfo p, .container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-chip-info .pickUpChoice .checkArea .textAreaChipInfo p {
    font-size: 9px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-chip-info .chip .priceAreaChipInfo h3, .container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-chip-info .pickUpChoice .priceAreaChipInfo h3 {
    font-size: 11px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step-4 .step-5 .payment-step {
    padding: 15px 15% 0 15%;
    gap: 30px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step-4 .step-5 .payment-step .textArea {
    gap: 10px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step-4 .step-5 .payment-step .textArea h3 {
    font-size: 19px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step-4 .step-5 .payment-step .textArea h4 {
    font-size: 14px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step-4 .step-5 .payment-step .textArea .configBonusText {
    padding: 10px 25px 5px 25px;
    gap: 10px;
    font-size: 15px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step-4 .step-5 .payment-step .textArea .configBonusText ul li {
    font-size: 11px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step-4 .step-5 .payment-step .buttonArea button {
    padding: 11px 18px;
    font-size: 10px;
  }
  .container.configure-seu-plano .content .right {
    gap: 35px;
    /* FIRST SUMMARY */
    /* SECOND SUMMARY */
    /* CHOSEN PLAN CHIP */
    /* PURCHASE SUMMARY */
  }
  .container.configure-seu-plano .content .right .content-right-first-summary .first-summary {
    gap: 15px;
  }
  .container.configure-seu-plano .content .right .content-right-first-summary .first-summary .titleArea {
    gap: 12px;
  }
  .container.configure-seu-plano .content .right .content-right-first-summary .first-summary .titleArea .iconsArea svg {
    width: 24px;
  }
  .container.configure-seu-plano .content .right .content-right-first-summary .first-summary .titleArea .textArea h3 {
    font-size: 11px;
  }
  .container.configure-seu-plano .content .right .content-right-first-summary .first-summary .titleArea .textArea p {
    font-size: 10px;
  }
  .container.configure-seu-plano .content .right .content-right-first-summary .first-summary .ItemsArea {
    gap: 15px;
    padding-left: 10%;
  }
  .container.configure-seu-plano .content .right .content-right-first-summary .first-summary .ItemsArea .item .choosePortability .choosePortabilityText h3 {
    font-size: 10px;
  }
  .container.configure-seu-plano .content .right .content-right-first-summary .first-summary .ItemsArea .item .choosePortability .choosePortabilityText p {
    font-size: 9px;
  }
  .container.configure-seu-plano .content .right .content-right-first-summary .first-summary .ItemsArea .item .choosePortability .choosePortabilityDetail {
    padding: 3px 9px;
  }
  .container.configure-seu-plano .content .right .content-right-first-summary .first-summary .ItemsArea .item .choosePortability .choosePortabilityDetail p {
    font-size: 10px;
  }
  .container.configure-seu-plano .content .right .content-right-second-summary {
    margin: 0 0 -20px 0;
  }
  .container.configure-seu-plano .content .right .content-right-second-summary .second-summary {
    gap: 10px;
  }
  .container.configure-seu-plano .content .right .content-right-second-summary .second-summary .titleArea {
    gap: 12px;
  }
  .container.configure-seu-plano .content .right .content-right-second-summary .second-summary .titleArea .iconsArea svg {
    width: 24px;
  }
  .container.configure-seu-plano .content .right .content-right-second-summary .second-summary .titleArea .textArea h3 {
    font-size: 11px;
  }
  .container.configure-seu-plano .content .right .content-right-second-summary .second-summary .titleArea .textArea p {
    font-size: 10px;
  }
  .container.configure-seu-plano .content .right .content-right-chosen-plan-chip .chosen-plan-chip {
    gap: 15px;
  }
  .container.configure-seu-plano .content .right .content-right-chosen-plan-chip .chosen-plan-chip .chosenPlanChip .textArea h3 {
    font-size: 10px;
  }
  .container.configure-seu-plano .content .right .content-right-chosen-plan-chip .chosen-plan-chip .chosenPlanChip .textArea p {
    font-size: 9px;
  }
  .container.configure-seu-plano .content .right .content-right-chosen-plan-chip .chosen-plan-chip .chosenPlanChip .priceArea h3 {
    font-size: 11px;
  }
  .container.configure-seu-plano .content .right .content-right-purchase-summary .purchase-summary {
    padding: 8vh 0 0 0;
  }
  .container.configure-seu-plano .content .right .content-right-purchase-summary .purchase-summary .priceArea h3 {
    font-size: 15px;
  }
  .container.configure-seu-plano .content .right .content-right-purchase-summary .purchase-summary .priceArea p {
    font-size: 12px;
  }
  .container.configure-seu-plano .content .right .content-right-purchase-summary .purchase-summary .buttonArea button {
    padding: 10px 18px;
    font-size: 10px;
  }
  .container.configure-seu-plano .content .right .content-right-purchase-summary .purchase-summary .buttonArea button svg {
    width: 14px;
    height: 14px;
  }
}
@media (min-width: 1440px) {
  .container.configure-seu-plano .content .container-left {
    padding: 3.5rem 4rem;
  }
  .container.configure-seu-plano .content .container-right {
    padding: 3.5rem 3rem;
  }
  .container.configure-seu-plano .content .left {
    /* HEADING */
    /* PLAN CONFIG TEXT */
    /* STEPPER PLAN */
    /* STEPS */
  }
  .container.configure-seu-plano .content .left .content-left-heading .heading img {
    width: 170px;
  }
  .container.configure-seu-plano .content .left .content-left-plan-config-text .plan-config-text .iconsArea svg {
    width: 23px;
  }
  .container.configure-seu-plano .content .left .content-left-plan-config-text .plan-config-text h3 {
    font-size: 12px;
  }
  .container.configure-seu-plano .content .left .content-left-plan-config-text .plan-config-text p {
    font-size: 11px;
  }
  .container.configure-seu-plano .content .left .content-left-stepper-plan .stepper-plan .MuiStepLabel-root .MuiStepLabel-iconContainer .QontoStepIcon-completedIcon {
    width: 15px;
    height: 15px;
  }
  .container.configure-seu-plano .content .left .content-left-stepper-plan .stepper-plan .MuiStepLabel-root .MuiStepLabel-iconContainer .QontoStepIcon-circle {
    width: 21px;
    height: 21px;
  }
  .container.configure-seu-plano .content .left .content-left-stepper-plan .stepper-plan .Mui-active .QontoStepIcon-circle label {
    font-size: 10px;
  }
  .container.configure-seu-plano .content .left .content-left-stepper-plan .stepper-plan .Mui-disabled .Mui-disabled .QontoStepIcon-circle label {
    font-size: 10px;
  }
  .container.configure-seu-plano .content .left .content-left-stepper-plan .stepper-plan .css-1vyamtt-MuiStepLabel-labelContainer .css-1hv8oq8-MuiStepLabel-label.MuiStepLabel-alternativeLabel {
    font-size: 11px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step {
    margin-top: 10px;
    gap: 20px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step .choose-plan-text h3 {
    font-size: 12px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step .choose-plan-text p {
    font-size: 11px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step .form {
    gap: 15px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step .form .choose-plan-text h3 {
    font-size: 11px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step .form .choose-plan-text p {
    font-size: 10px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step .form .formArea {
    gap: 14px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step .form .formArea .fractArea {
    gap: 14px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step .form .formArea .MuiFormControl-root .MuiInputLabel-root.MuiInputLabel-sizeSmall {
    font-size: 15px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step .form .formArea .MuiFormControl-root .MuiInputBase-sizeSmall input.MuiInputBase-inputSizeSmall {
    font-size: 15px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step .form .formArea .MuiFormLabel-root.MuiInputLabel-shrink.MuiInputLabel-sizeSmall.MuiInputLabel-outlined {
    transform: translate(14px, -8px) scale(0.75);
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step .form .formArea .MuiInputBase-root.MuiInputBase-sizeSmall span {
    font-size: 11px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step .form .formArea .MuiFormHelperText-root.MuiFormHelperText-sizeSmall {
    font-size: 11px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step .form .formArea .MuiButtonBase-root.MuiAutocomplete-clearIndicator svg {
    font-size: 1.1rem;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step .form .formArea .MuiButtonBase-root.MuiAutocomplete-popupIndicator {
    padding-top: 2px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step .form .formArea .MuiButtonBase-root.MuiAutocomplete-popupIndicator svg {
    font-size: 1.5rem;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step .form .formArea .MuiButtonBase-root.clearButton svg {
    font-size: 1.1rem;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step .form .formArea .MuiAutocomplete-popper .MuiAutocomplete-option {
    font-size: 12px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step-1 {
    /* SELECT PORTABILITY */
    /* SELECT SMART CONTROL */
    /* SELECT PICK UP CHIP */
    /* SELECT CHIP INFO */
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-portability .choosePortability .choosePortabilityText h3 {
    font-size: 11px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-portability .choosePortability .choosePortabilityText p {
    font-size: 10px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-portability .choosePortability .choosePortabilityDetail p {
    font-size: 10px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-smart-control .choosePortability .choosePortabilityText h3 {
    font-size: 11px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-smart-control .choosePortability .choosePortabilityText p {
    font-size: 10px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-smart-control .choosePortability .choosePortabilityDetail p {
    font-size: 10px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .chip-only-pick-up .pickUpChoose .pickUpChoice .checkArea .MuiButtonBase-root.MuiCheckbox-root {
    padding: 0 10px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .chip-only-pick-up .pickUpChoose .pickUpChoice .checkArea .MuiButtonBase-root.MuiCheckbox-root svg {
    width: 17px;
    height: 17px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .chip-only-pick-up .pickUpChoose .pickUpChoice .checkArea .textAreaChipInfo h3 {
    font-size: 11px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .chip-only-pick-up .pickUpChoose .pickUpChoice .checkArea .textAreaChipInfo p {
    font-size: 10px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .chip-only-pick-up .pickUpChoose .pickUpChoice .priceAreaChipInfo h3 {
    font-size: 12px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-chip-info .chip .checkArea .MuiButtonBase-root.MuiCheckbox-root, .container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-chip-info .pickUpChoice .checkArea .MuiButtonBase-root.MuiCheckbox-root {
    padding: 0 10px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-chip-info .chip .checkArea .MuiButtonBase-root.MuiCheckbox-root svg, .container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-chip-info .pickUpChoice .checkArea .MuiButtonBase-root.MuiCheckbox-root svg {
    width: 17px;
    height: 17px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-chip-info .chip .checkArea .textAreaChipInfo h3, .container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-chip-info .pickUpChoice .checkArea .textAreaChipInfo h3 {
    font-size: 11px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-chip-info .chip .checkArea .textAreaChipInfo p, .container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-chip-info .pickUpChoice .checkArea .textAreaChipInfo p {
    font-size: 10px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-chip-info .chip .priceAreaChipInfo h3, .container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-chip-info .pickUpChoice .priceAreaChipInfo h3 {
    font-size: 12px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step-4 .step-5 .payment-step .textArea {
    gap: 10px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step-4 .step-5 .payment-step .textArea h3 {
    font-size: 20px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step-4 .step-5 .payment-step .textArea .configBonusText ul li {
    font-size: 12px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step-4 .step-5 .payment-step .buttonArea button {
    font-size: 12px;
  }
  .container.configure-seu-plano .content .right {
    gap: 40px;
    /* FIRST SUMMARY */
    /* SECOND SUMMARY */
    /* CHOSEN PLAN CHIP */
    /* PURCHASE SUMMARY */
  }
  .container.configure-seu-plano .content .right .content-right-first-summary .first-summary {
    gap: 25px;
  }
  .container.configure-seu-plano .content .right .content-right-first-summary .first-summary .titleArea .iconsArea svg {
    width: 26px;
  }
  .container.configure-seu-plano .content .right .content-right-first-summary .first-summary .titleArea .textArea h3 {
    font-size: 12px;
  }
  .container.configure-seu-plano .content .right .content-right-first-summary .first-summary .titleArea .textArea p {
    font-size: 11px;
  }
  .container.configure-seu-plano .content .right .content-right-first-summary .first-summary .ItemsArea {
    gap: 18px;
    padding-left: 9%;
  }
  .container.configure-seu-plano .content .right .content-right-first-summary .first-summary .ItemsArea .item .choosePortability .choosePortabilityText h3 {
    font-size: 11px;
  }
  .container.configure-seu-plano .content .right .content-right-first-summary .first-summary .ItemsArea .item .choosePortability .choosePortabilityText p {
    font-size: 10px;
  }
  .container.configure-seu-plano .content .right .content-right-first-summary .first-summary .ItemsArea .item .choosePortability .choosePortabilityDetail {
    padding: 3px 10px;
  }
  .container.configure-seu-plano .content .right .content-right-first-summary .first-summary .ItemsArea .item .choosePortability .choosePortabilityDetail p {
    font-size: 11px;
  }
  .container.configure-seu-plano .content .right .content-right-second-summary {
    margin: 0 0 -15px 0;
  }
  .container.configure-seu-plano .content .right .content-right-second-summary .second-summary .titleArea .iconsArea svg {
    width: 26px;
  }
  .container.configure-seu-plano .content .right .content-right-second-summary .second-summary .titleArea .textArea h3 {
    font-size: 12px;
  }
  .container.configure-seu-plano .content .right .content-right-second-summary .second-summary .titleArea .textArea p {
    font-size: 11px;
  }
  .container.configure-seu-plano .content .right .content-right-chosen-plan-chip {
    padding-left: 9%;
  }
  .container.configure-seu-plano .content .right .content-right-chosen-plan-chip .chosen-plan-chip {
    gap: 18px;
  }
  .container.configure-seu-plano .content .right .content-right-chosen-plan-chip .chosen-plan-chip .chosenPlanChip .textArea h3 {
    font-size: 11px;
  }
  .container.configure-seu-plano .content .right .content-right-chosen-plan-chip .chosen-plan-chip .chosenPlanChip .textArea p {
    font-size: 10px;
  }
  .container.configure-seu-plano .content .right .content-right-chosen-plan-chip .chosen-plan-chip .chosenPlanChip .priceArea h3 {
    font-size: 12px;
  }
  .container.configure-seu-plano .content .right .content-right-purchase-summary .purchase-summary {
    padding: 7.5vh 0 0 0;
  }
  .container.configure-seu-plano .content .right .content-right-purchase-summary .purchase-summary .priceArea h3 {
    font-size: 18px;
  }
  .container.configure-seu-plano .content .right .content-right-purchase-summary .purchase-summary .priceArea p {
    font-size: 14px;
  }
  .container.configure-seu-plano .content .right .content-right-purchase-summary .purchase-summary .buttonArea button {
    padding: 11px 20px;
    font-size: 12px;
    gap: 6px;
  }
  .container.configure-seu-plano .content .right .content-right-purchase-summary .purchase-summary .buttonArea button svg {
    width: 16px;
    height: 16px;
  }
}
@media (min-width: 1860px) {
  .container.configure-seu-plano .content .container-left {
    padding: 5rem 5.75rem;
  }
  .container.configure-seu-plano .content .container-right {
    padding: 5rem;
  }
  .container.configure-seu-plano .content .left {
    gap: 30px;
    /* HEADING */
    /* PLAN CONFIG TEXT */
    /* STEPPER PLAN */
    /* STEPS */
  }
  .container.configure-seu-plano .content .left .content-left-heading .heading img {
    width: 220px;
  }
  .container.configure-seu-plano .content .left .content-left-plan-config-text {
    margin-top: 20px;
  }
  .container.configure-seu-plano .content .left .content-left-plan-config-text .plan-config-text .iconsArea svg {
    width: auto;
  }
  .container.configure-seu-plano .content .left .content-left-plan-config-text .plan-config-text h3 {
    font-size: 15px;
  }
  .container.configure-seu-plano .content .left .content-left-plan-config-text .plan-config-text p {
    font-size: 13px;
  }
  .container.configure-seu-plano .content .left .content-left-stepper-plan .stepper-plan .MuiStepLabel-root .MuiStepLabel-iconContainer .QontoStepIcon-completedIcon {
    width: 19px;
    height: 19px;
  }
  .container.configure-seu-plano .content .left .content-left-stepper-plan .stepper-plan .MuiStepLabel-root .MuiStepLabel-iconContainer .QontoStepIcon-circle {
    width: 29px;
    height: 29px;
  }
  .container.configure-seu-plano .content .left .content-left-stepper-plan .stepper-plan .Mui-active .QontoStepIcon-circle label {
    font-size: 12px;
  }
  .container.configure-seu-plano .content .left .content-left-stepper-plan .stepper-plan .Mui-active .QontoStepIcon-circle::after {
    height: 6px;
    transform: translateY(1px);
  }
  .container.configure-seu-plano .content .left .content-left-stepper-plan .stepper-plan .Mui-disabled .Mui-disabled .QontoStepIcon-circle label {
    font-size: 12px;
  }
  .container.configure-seu-plano .content .left .content-left-stepper-plan .stepper-plan .MuiStepConnector-root.MuiStepConnector-horizontal.MuiStepConnector-alternativeLabel {
    top: 12px;
  }
  .container.configure-seu-plano .content .left .content-left-stepper-plan .stepper-plan .MuiStepConnector-root.MuiStepConnector-horizontal.MuiStepConnector-alternativeLabel .MuiStepConnector-line.MuiStepConnector-lineHorizontal {
    border-top-width: 6px;
  }
  .container.configure-seu-plano .content .left .content-left-stepper-plan .stepper-plan .css-1vyamtt-MuiStepLabel-labelContainer .css-1hv8oq8-MuiStepLabel-label.MuiStepLabel-alternativeLabel {
    margin-top: 10px;
    font-size: 13px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step {
    margin-top: 15px;
    gap: 30px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step .choose-plan-text h3 {
    font-size: 14px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step .choose-plan-text p {
    font-size: 12px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step .form {
    gap: 25px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step .form .choose-plan-text h3 {
    font-size: 14px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step .form .choose-plan-text p {
    font-size: 12px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step .form .formArea {
    gap: 18px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step .form .formArea .fractArea {
    gap: 18px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step .form .formArea .MuiButtonBase-root.MuiAutocomplete-clearIndicator svg {
    font-size: 1.3rem;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step .form .formArea .MuiButtonBase-root.MuiAutocomplete-popupIndicator {
    padding-top: 0;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step .form .formArea .MuiButtonBase-root.MuiAutocomplete-popupIndicator svg {
    font-size: 1.7rem;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step .form .formArea .MuiButtonBase-root.clearButton svg {
    font-size: 1.3rem;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step .form .formArea .MuiAutocomplete-popper .MuiAutocomplete-option {
    font-size: 16px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step-1 {
    /* SELECT PORTABILITY */
    /* SELECT SMART CONTROL */
    /* SELECT PICK UP CHIP */
    /* SELECT CHIP INFO */
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-portability .choosePortability .choosePortabilitySwitch .MuiSwitch-root {
    width: 38px;
    height: 22px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-portability .choosePortability .choosePortabilitySwitch .MuiSwitch-root .MuiSwitch-thumb {
    width: 13px;
    height: 13px;
    margin-left: 4px;
    margin-top: 3px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-portability .choosePortability .choosePortabilitySwitch .MuiSwitch-root .MuiSwitch-switchBase.Mui-checked {
    transform: translate(13px);
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-portability .choosePortability .choosePortabilityText h3 {
    font-size: 14px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-portability .choosePortability .choosePortabilityText p {
    font-size: 12px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-portability .choosePortability .choosePortabilityDetail p {
    font-size: 12px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-smart-control .choosePortability .choosePortabilitySwitch .MuiSwitch-root {
    width: 38px;
    height: 22px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-smart-control .choosePortability .choosePortabilitySwitch .MuiSwitch-root .MuiSwitch-thumb {
    width: 13px;
    height: 13px;
    margin-left: 4px;
    margin-top: 3px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-smart-control .choosePortability .choosePortabilitySwitch .MuiSwitch-root .MuiSwitch-switchBase.Mui-checked {
    transform: translate(13px);
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-smart-control .choosePortability .choosePortabilityText h3 {
    font-size: 14px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-smart-control .choosePortability .choosePortabilityText p {
    font-size: 12px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-smart-control .choosePortability .choosePortabilityDetail p {
    font-size: 12px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .chip-only-pick-up .pickUpChoose .pickUpChoice .checkArea .MuiButtonBase-root.MuiCheckbox-root {
    padding: 0 12px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .chip-only-pick-up .pickUpChoose .pickUpChoice .checkArea .MuiButtonBase-root.MuiCheckbox-root svg {
    width: 22px;
    height: 22px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .chip-only-pick-up .pickUpChoose .pickUpChoice .checkArea .textAreaChipInfo h3 {
    font-size: 14px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .chip-only-pick-up .pickUpChoose .pickUpChoice .checkArea .textAreaChipInfo p {
    font-size: 12px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .chip-only-pick-up .pickUpChoose .pickUpChoice .priceAreaChipInfo h3 {
    font-size: 15px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-chip-info .chip .checkArea .MuiButtonBase-root.MuiCheckbox-root, .container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-chip-info .pickUpChoice .checkArea .MuiButtonBase-root.MuiCheckbox-root {
    padding: 0 12px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-chip-info .chip .checkArea .MuiButtonBase-root.MuiCheckbox-root svg, .container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-chip-info .pickUpChoice .checkArea .MuiButtonBase-root.MuiCheckbox-root svg {
    width: 22px;
    height: 22px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-chip-info .chip .checkArea .textAreaChipInfo h3, .container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-chip-info .pickUpChoice .checkArea .textAreaChipInfo h3 {
    font-size: 14px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-chip-info .chip .checkArea .textAreaChipInfo p, .container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-chip-info .pickUpChoice .checkArea .textAreaChipInfo p {
    font-size: 12px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-chip-info .chip .priceAreaChipInfo h3, .container.configure-seu-plano .content .left .content-left-steps .steps .step-1 .select-chip-info .pickUpChoice .priceAreaChipInfo h3 {
    font-size: 15px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step-4 .step-5 .payment-step .textArea {
    gap: 10px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step-4 .step-5 .payment-step .textArea h3 {
    font-size: 24px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step-4 .step-5 .payment-step .textArea h4 {
    font-size: 16px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step-4 .step-5 .payment-step .textArea .configBonusText {
    font-size: 17px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step-4 .step-5 .payment-step .textArea .configBonusText ul li {
    font-size: 14px;
  }
  .container.configure-seu-plano .content .left .content-left-steps .steps .step-4 .step-5 .payment-step .buttonArea button {
    font-size: 14px;
  }
  .container.configure-seu-plano .content .right {
    /* FIRST SUMMARY */
    /* SECOND SUMMARY */
    /* CHOSEN PLAN CHIP */
    /* PURCHASE SUMMARY */
  }
  .container.configure-seu-plano .content .right .content-right-first-summary .first-summary {
    gap: 25px;
  }
  .container.configure-seu-plano .content .right .content-right-first-summary .first-summary .titleArea .iconsArea svg {
    width: 30px;
  }
  .container.configure-seu-plano .content .right .content-right-first-summary .first-summary .titleArea .textArea h3 {
    font-size: 16px;
  }
  .container.configure-seu-plano .content .right .content-right-first-summary .first-summary .titleArea .textArea p {
    font-size: 13px;
  }
  .container.configure-seu-plano .content .right .content-right-first-summary .first-summary .ItemsArea {
    padding-left: 8%;
  }
  .container.configure-seu-plano .content .right .content-right-first-summary .first-summary .ItemsArea .item .choosePortability .choosePortabilityText h3 {
    font-size: 14px;
  }
  .container.configure-seu-plano .content .right .content-right-first-summary .first-summary .ItemsArea .item .choosePortability .choosePortabilityText p {
    font-size: 12px;
  }
  .container.configure-seu-plano .content .right .content-right-first-summary .first-summary .ItemsArea .item .choosePortability .choosePortabilityDetail p {
    font-size: 13px;
  }
  .container.configure-seu-plano .content .right .content-right-second-summary {
    margin: 0 0 -15px 0;
  }
  .container.configure-seu-plano .content .right .content-right-second-summary .second-summary .titleArea .iconsArea svg {
    width: 30px;
  }
  .container.configure-seu-plano .content .right .content-right-second-summary .second-summary .titleArea .textArea h3 {
    font-size: 16px;
  }
  .container.configure-seu-plano .content .right .content-right-second-summary .second-summary .titleArea .textArea p {
    font-size: 13px;
  }
  .container.configure-seu-plano .content .right .content-right-chosen-plan-chip {
    padding-left: 8%;
  }
  .container.configure-seu-plano .content .right .content-right-chosen-plan-chip .chosen-plan-chip .chosenPlanChip .textArea h3 {
    font-size: 14px;
  }
  .container.configure-seu-plano .content .right .content-right-chosen-plan-chip .chosen-plan-chip .chosenPlanChip .textArea p {
    font-size: 12px;
  }
  .container.configure-seu-plano .content .right .content-right-chosen-plan-chip .chosen-plan-chip .chosenPlanChip .priceArea h3 {
    font-size: 15px;
  }
  .container.configure-seu-plano .content .right .content-right-purchase-summary .purchase-summary {
    padding: 7.5vh 0 0 0;
  }
  .container.configure-seu-plano .content .right .content-right-purchase-summary .purchase-summary .priceArea h3 {
    font-size: 18px;
  }
  .container.configure-seu-plano .content .right .content-right-purchase-summary .purchase-summary .priceArea p {
    font-size: 14px;
  }
  .container.configure-seu-plano .content .right .content-right-purchase-summary .purchase-summary .buttonArea button {
    font-size: 16px;
  }
  .container.configure-seu-plano .content .right .content-right-purchase-summary .purchase-summary .buttonArea button svg {
    width: 20px;
    height: 20px;
  }
}
.container.configure-sua-conta {
  background-color: var(--focusColor);
  /******************** MEDIA QUERY 900 PIXELS ********************/
  /******************** MEDIA QUERY 1200 PIXELS ********************/
  /******************** MEDIA QUERY 1440 PIXELS ********************/
  /******************** MEDIA QUERY 1860 PIXELS ********************/
}
.container.configure-sua-conta .content {
  display: flex;
  height: 100vh;
}
.container.configure-sua-conta .content .container-left {
  display: flex;
  background-color: #FFFFFF;
  padding: 80px 30px 40px 30px;
}
.container.configure-sua-conta .content .container-right {
  display: flex;
  padding: 50px 30px 40px 30px;
  background-color: var(--focusColor);
}
.container.configure-sua-conta .content .left {
  --titleColor: #4E4E4E;
  --textColor: #5F5F5F;
  display: flex;
  gap: 25px;
  /* HEADING */
  /* SLOGAN PAGE 3 */
  /* DESCRIPTION */
  /* PLAN SUMMARY PAGE 3 */
}
.container.configure-sua-conta .content .left .content-left-heading {
  width: 100vw;
}
.container.configure-sua-conta .content .left .content-left-heading .heading {
  display: flex;
  justify-content: center;
  width: 100%;
}
.container.configure-sua-conta .content .left .content-left-heading .heading h3 {
  display: none;
}
.container.configure-sua-conta .content .left .content-left-heading .heading img {
  width: 55%;
}
.container.configure-sua-conta .content .left .content-left-slogan-pg-3 {
  margin-top: 20px;
}
.container.configure-sua-conta .content .left .content-left-slogan-pg-3 .slogan-pg-3 {
  max-width: 300px;
}
.container.configure-sua-conta .content .left .content-left-slogan-pg-3 .slogan-pg-3 .textArea {
  width: 100%;
}
.container.configure-sua-conta .content .left .content-left-slogan-pg-3 .slogan-pg-3 .textArea .skltn-slogan {
  width: 100%;
}
.container.configure-sua-conta .content .left .content-left-slogan-pg-3 .slogan-pg-3 .textArea .skltn-slogan .skltn-h3 {
  font-size: 30px;
  font-weight: 500;
}
.container.configure-sua-conta .content .left .content-left-slogan-pg-3 .slogan-pg-3 .textArea .skltn-slogan .skltn-h3.skltn-1 {
  width: 65%;
}
.container.configure-sua-conta .content .left .content-left-slogan-pg-3 .slogan-pg-3 .textArea .skltn-slogan .skltn-h3.skltn-2 {
  width: 85%;
}
.container.configure-sua-conta .content .left .content-left-slogan-pg-3 .slogan-pg-3 .textArea h3 {
  font-size: 30px;
  font-weight: 500;
  color: var(--titleColor);
}
.container.configure-sua-conta .content .left .content-left-slogan-pg-3 .slogan-pg-3 .textArea h3 span {
  color: var(--spanLabel);
}
.container.configure-sua-conta .content .left .content-left-description .description {
  max-width: 260px;
}
.container.configure-sua-conta .content .left .content-left-description .description P {
  font-size: 14px;
  font-weight: 400;
  color: var(--textColor);
}
.container.configure-sua-conta .content .left .content-left-plan-summary-pg-3 {
  margin-top: 15px;
}
.container.configure-sua-conta .content .left .content-left-plan-summary-pg-3 .plan-summary-pg-3 {
  gap: 10px;
}
.container.configure-sua-conta .content .left .content-left-plan-summary-pg-3 .plan-summary-pg-3 .chosenPlan {
  font-size: 20px;
  font-weight: 600;
  color: var(--primaryColor);
}
.container.configure-sua-conta .content .left .content-left-plan-summary-pg-3 .plan-summary-pg-3 .planDetailsText h3, .container.configure-sua-conta .content .left .content-left-plan-summary-pg-3 .plan-summary-pg-3 .planDetailsText .skltn-h3 {
  font-size: 12px;
  font-weight: 600;
  color: var(--titleColor);
}
.container.configure-sua-conta .content .left .content-left-plan-summary-pg-3 .plan-summary-pg-3 .planDetailsText p {
  font-size: 11px;
  font-weight: 400;
  color: var(--textColor);
}
.container.configure-sua-conta .content .left .content-left-plan-summary-pg-3 .plan-summary-pg-3 .planDetailsItems {
  margin-top: 10px;
  gap: 5px;
}
.container.configure-sua-conta .content .left .content-left-plan-summary-pg-3 .plan-summary-pg-3 .planDetailsItems .planDetailsItem {
  font-size: 9px;
  font-weight: 600;
  color: var(--primaryColor);
  background-color: var(--focusColor);
  border-radius: 21px;
  padding: 6px 10px;
}
.container.configure-sua-conta .content .left .content-left-plan-summary-pg-3 .plan-summary-pg-3 .skltn-1 {
  width: 31%;
}
.container.configure-sua-conta .content .left .content-left-plan-summary-pg-3 .plan-summary-pg-3 .skltn-2 {
  width: 32%;
}
.container.configure-sua-conta .content .left .content-left-plan-summary-pg-3 .plan-summary-pg-3 .skltn-3 {
  width: 16%;
}
.container.configure-sua-conta .content .right {
  /* Theme */
  --textColor: #FFFFFF;
  background-color: var(--focusColor);
  display: flex;
  flex-direction: column;
  gap: 25px;
  /* NEXT STEPS TEXT */
  /* STEPPER CONFIG */
}
.container.configure-sua-conta .content .right .content-right-next-steps-text h3 {
  font-size: 16px;
  font-weight: 500;
  color: var(--titleColor);
}
.container.configure-sua-conta .content .right .content-right-next-steps-text p {
  font-size: 13px;
  color: var(--titleColor);
}
.container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config {
  gap: 15px;
}
.container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .step {
  justify-content: space-between;
  align-items: center;
  border-radius: 100px;
  padding: 20px 25px;
  cursor: pointer;
}
.container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .step .stepIcon {
  display: flex;
  align-items: center;
  justify-content: center;
}
.container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .step .stepIcon .iconTable {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 100%;
}
.container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .step .stepIcon .iconTable svg {
  width: 16px;
  height: 16px;
}
.container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .step .stepName {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1 1;
  margin: 0 15px;
}
.container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .step .stepName h3 {
  font-size: 12px;
  font-weight: 500;
}
.container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .step .stepName p {
  font-size: 10px;
}
.container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .step .statusIcon {
  display: flex;
  align-items: center;
  justify-content: center;
}
.container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .step .statusIcon svg {
  width: 20px;
  height: 20px;
}
.container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .step.step-1 {
  cursor: not-allowed;
}
.container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .completed {
  background-color: var(--primaryColor);
}
.container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .completed .stepIcon svg path {
  stroke: var(--primaryColor);
}
.container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .completed .iconTable {
  background-color: #FFF;
}
.container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .completed .stepName h3 {
  color: var(--textColor);
}
.container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .completed .stepName p {
  color: var(--textColor);
}
.container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .completed .statusIcon svg path {
  stroke: #FFF;
}
.container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .active, .container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .disabled {
  border: 1px solid var(--primaryColor);
}
.container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .active .stepIcon svg path, .container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .disabled .stepIcon svg path {
  stroke: var(--primaryColor);
}
.container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .active .iconTable, .container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .disabled .iconTable {
  border: 1px solid var(--primaryColor);
}
.container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .active .stepName h3, .container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .disabled .stepName h3 {
  color: var(--titleColor);
}
.container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .active .stepName p, .container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .disabled .stepName p {
  color: var(--titleColor);
}
.container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .active .statusIcon svg path, .container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .disabled .statusIcon svg path {
  stroke: var(--primaryColor);
}
.container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .disabled {
  opacity: 0.3;
}
.container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .active:hover {
  background-color: rgba(255, 255, 255, 0.062745098);
}
.container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .blackShadow {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3960784314);
  overflow-y: hidden;
  z-index: 1;
}
.container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5.5vh 20px 4.5vh 20px;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  background-color: white;
  border-radius: 20px 20px 0 0;
  gap: 20px;
}
.container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .modal p {
  text-align: center;
  font-size: 11px;
  font-weight: 400;
  color: #A2A2A2;
}
.container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .modal .detailArea {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  background-color: var(--focusColor);
  border-radius: 22px;
  padding: 8px 13px;
  color: var(--primaryColor);
  font-size: 12px;
  font-weight: 500;
}
.container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .modal .detailArea .eSimIcon, .container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .modal .detailArea .portabilityIcon, .container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .modal .detailArea .smartControlIcon {
  display: flex;
  align-items: center;
  justify-content: center;
}
.container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .modal .detailArea svg {
  width: 14px;
  height: 14px;
}
.container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .modal .detailArea svg path {
  stroke: var(--primaryColor);
}
.container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .modal .textArea {
  text-align: center;
}
.container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .modal .textArea h3 {
  font-size: 19px;
  font-weight: 500;
}
.container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .modal .textArea p {
  max-width: 255px;
}
.container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .modal .textArea svg {
  height: 60px;
  width: 60px;
}
.container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .modal .mainSectionModal {
  display: flex;
  align-items: center;
  justify-content: center;
}
.container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .modal .helpArea {
  display: flex;
  align-items: center;
  gap: 5px;
  text-decoration: none;
  font-size: 11px;
  color: #878787;
}
.container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .modal .helpArea:hover {
  color: rgba(0, 0, 0, 0.7725490196);
}
.container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .modal .btnArea button {
  padding: 10px 40px;
  border-radius: 43px;
  font-size: 14px;
  font-weight: 500;
  color: var(--textColor);
  background-color: var(--primaryColor);
  text-transform: none;
}
.container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .modal .btnArea button:hover {
  opacity: 85%;
}
.container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .modal.esim-modal .mainSectionModal {
  padding: 30px 0;
}
.container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .modal.esim-modal .mainSectionModal .pdfBtnArea a {
  display: flex;
  align-items: center;
  gap: 5px;
  color: var(--primaryColor);
  text-decoration: none;
}
.container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .modal.esim-modal .mainSectionModal .pdfBtnArea a:hover {
  opacity: 85%;
}
.container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .modal.portability-modal .iconArea {
  display: flex;
  position: absolute;
  left: 40px;
  top: 5.4vh;
}
.container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .modal.portability-modal .mainSectionModal .stepper-portability {
  display: flex;
  align-items: center;
  justify-content: center;
}
.container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .modal.portability-modal .mainSectionModal .stepper-portability .current-step {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
  padding-bottom: 20px;
}
.container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .modal.portability-modal .mainSectionModal .stepper-portability .current-step .MuiFormHelperText-root.Mui-error {
  color: #d32f2f;
  text-align: left;
}
.container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .modal.portability-modal .mainSectionModal .stepper-portability .current-step .circularLabel {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 28px;
  width: 28px;
  background-color: rgba(166, 166, 166, 0.1019607843);
  color: black;
  font-size: 11px;
  border-radius: 50%;
}
.container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .modal.portability-modal .mainSectionModal .stepper-portability .current-step .labelStepArea {
  max-width: 200px;
  margin-bottom: 10px;
}
.container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .modal.portability-modal .mainSectionModal .stepper-portability .current-step.step-3 {
  padding-bottom: 0;
}
.container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .modal.portability-modal .mainSectionModal .stepper-portability .current-step.step-3 .transferArea {
  display: flex;
  justify-content: space-between;
  padding: 0 3%;
}
.container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .modal.portability-modal .mainSectionModal .stepper-portability .current-step.step-3 .transferArea .rightArrow {
  transform: scaleX(-1);
}
.container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .modal.portability-modal .mainSectionModal .stepper-portability .current-step.step-3 .transferArea .prevNextCompany {
  display: flex;
  flex-direction: column;
  gap: 5px;
  font-size: 13px;
}
.container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .modal.portability-modal .mainSectionModal .stepper-portability .current-step.step-3 .transferArea .prevNextCompany .prevNextCompanyLabel {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: rgba(166, 166, 166, 0.1019607843);
  padding: 5px 14px;
  border-radius: 22px;
  gap: 5px;
}
.container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .modal.portability-modal .mainSectionModal .stepper-portability .current-step.step-3 .transferArea .prevNextCompany .prevNextCompanyLabel.nextCompany {
  background-color: #00D66A;
  color: white;
}
.container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .modal.portability-modal .mainSectionModal .stepper-portability .current-step.step-3 .currentStepText {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 5px;
}
.container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .modal.portability-modal .mainSectionModal .stepper-portability .current-step.step-3 .currentStepText .attentionLabel {
  font-weight: 500;
  color: black;
}
.container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .modal.portability-modal .mainSectionModal .stepper-portability .current-step.step-3 .currentStepText .textArea {
  max-width: 260px;
}
.container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .modal.portability-modal .mainSectionModal .stepper-portability .current-step.step-4 {
  gap: 0;
}
.container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .modal.portability-modal .mainSectionModal .stepper-portability .current-step.step-4 p {
  color: white;
  font-size: 12px;
}
.container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .modal.portability-modal .mainSectionModal .stepper-portability .current-step.step-4 .titleArea {
  font-size: 20px;
}
.container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .modal.portability-modal .mainSectionModal .stepper-portability .current-step.step-4 .labelTextArea {
  max-width: 260px;
}
.container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .modal.portability-modal .mainSectionModal .stepper-portability .current-step.step-4 .stepsAfterSubmit {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  margin-top: 35px;
}
.container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .modal.portability-modal .mainSectionModal .stepper-portability .current-step.step-4 .stepsAfterSubmit .stepAfterSubmit {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
}
.container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .modal.portability-modal .mainSectionModal .stepper-portability .current-step.step-4 .stepsAfterSubmit .stepAfterSubmit .circularLabel {
  background-color: rgba(255, 255, 255, 0.062745098);
  color: white;
}
.container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .modal.portability-modal .mainSectionModal .stepper-portability .current-step.step-4 .stepsAfterSubmit .stepAfterSubmit .circularTextLabel {
  max-width: 120px;
}
.container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .modal.portability-modal.submitClass {
  background-color: var(--primaryColor);
}
.container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .modal.portability-modal.submitClass .leftArrow svg path {
  stroke: white;
}
.container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .modal.portability-modal.submitClass .detailArea {
  background-color: rgba(255, 255, 255, 0.062745098);
  color: white;
}
.container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .modal.portability-modal.submitClass .detailArea .portabilityIcon svg path {
  stroke: white;
}
.container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .modal.portability-modal.submitClass .btnArea button {
  background-color: white;
  color: var(--primaryColor);
}
.container.configure-sua-conta .footer {
  display: none;
  height: 32vh;
  background-color: blue;
  padding: 60px 20px;
}
@media (min-width: 900px) {
  .container.configure-sua-conta {
    display: flex;
    align-items: center;
    height: 100vh;
  }
  .container.configure-sua-conta .content {
    flex-wrap: nowrap;
    position: relative;
  }
  .container.configure-sua-conta .content .container-left {
    display: flex;
    width: 120%;
    padding: 1.75rem 0 1.75rem 6.5rem;
    background-color: var(--focusColor);
  }
  .container.configure-sua-conta .content .container-right {
    display: flex;
    background-color: var(--focusColor);
    position: unset;
    bottom: unset;
    padding: 1.75rem 6.5rem 1.75rem 1.5rem;
    overflow: hidden;
    z-index: unset;
  }
  .container.configure-sua-conta .content .left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 15px;
    /* HEADING */
    /* SLOGAN PAGE 3 */
    /* DESCRIPTION */
    /* PLAN SUMMARY PAGE 3 */
  }
  .container.configure-sua-conta .content .left .content-left-heading {
    align-self: flex-start;
    width: auto;
    position: absolute;
    top: 1.75rem;
    left: 2.75rem;
  }
  .container.configure-sua-conta .content .left .content-left-heading .heading {
    justify-content: unset;
  }
  .container.configure-sua-conta .content .left .content-left-heading .heading img {
    width: 150px;
  }
  .container.configure-sua-conta .content .left .content-left-slogan-pg-3 {
    margin-top: 30px;
  }
  .container.configure-sua-conta .content .left .content-left-slogan-pg-3 .slogan-pg-3 {
    max-width: 270px;
  }
  .container.configure-sua-conta .content .left .content-left-slogan-pg-3 .slogan-pg-3 .textArea .skltn-slogan .skltn-h3 {
    font-size: 28px;
  }
  .container.configure-sua-conta .content .left .content-left-slogan-pg-3 .slogan-pg-3 .textArea .skltn-slogan .skltn-h3.skltn-1 {
    width: 68%;
  }
  .container.configure-sua-conta .content .left .content-left-slogan-pg-3 .slogan-pg-3 .textArea .skltn-slogan .skltn-h3.skltn-2 {
    width: 89%;
  }
  .container.configure-sua-conta .content .left .content-left-slogan-pg-3 .slogan-pg-3 .textArea h3 {
    font-size: 28px;
  }
  .container.configure-sua-conta .content .left .content-left-description .description {
    max-width: 320px;
  }
  .container.configure-sua-conta .content .left .content-left-description .description P {
    font-size: 12px;
  }
  .container.configure-sua-conta .content .left .content-left-plan-summary-pg-3 {
    margin-top: 50px;
  }
  .container.configure-sua-conta .content .left .content-left-plan-summary-pg-3 .plan-summary-pg-3 {
    gap: 5px;
  }
  .container.configure-sua-conta .content .left .content-left-plan-summary-pg-3 .plan-summary-pg-3 .chosenPlan {
    font-size: 16px;
  }
  .container.configure-sua-conta .content .left .content-left-plan-summary-pg-3 .plan-summary-pg-3 .planDetailsText h3, .container.configure-sua-conta .content .left .content-left-plan-summary-pg-3 .plan-summary-pg-3 .planDetailsText .skltn-h3 {
    font-size: 10px;
  }
  .container.configure-sua-conta .content .left .content-left-plan-summary-pg-3 .plan-summary-pg-3 .planDetailsText p {
    font-size: 9px;
  }
  .container.configure-sua-conta .content .left .content-left-plan-summary-pg-3 .plan-summary-pg-3 .planDetailsItems .planDetailsItem {
    font-size: 8px;
    border: 1px solid var(--primaryColor);
  }
  .container.configure-sua-conta .content .left .content-left-plan-summary-pg-3 .plan-summary-pg-3 .skltn-1 {
    width: 22%;
  }
  .container.configure-sua-conta .content .left .content-left-plan-summary-pg-3 .plan-summary-pg-3 .skltn-2 {
    width: 23%;
  }
  .container.configure-sua-conta .content .left .content-left-plan-summary-pg-3 .plan-summary-pg-3 .skltn-3 {
    width: 13%;
  }
  .container.configure-sua-conta .content .right {
    justify-content: center;
    gap: 15px;
    /* NEXT STEPS TEXT */
    /* STEPPER CONFIG */
  }
  .container.configure-sua-conta .content .right .content-right-next-steps-text h3 {
    font-size: 14px;
  }
  .container.configure-sua-conta .content .right .content-right-next-steps-text p {
    font-size: 11px;
  }
  .container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config {
    gap: 10px;
  }
  .container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .step {
    padding: 18px 25px;
  }
  .container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .step .stepIcon .iconTable {
    width: 26px;
    height: 26px;
  }
  .container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .step .stepName h3 {
    font-size: 11px;
  }
  .container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .step .stepName p {
    font-size: 9px;
  }
  .container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .blackShadow {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .modal {
    width: 351px;
    height: 400px;
    position: relative;
    border-radius: 20px;
    gap: 10px;
  }
  .container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .modal p {
    font-size: 10px;
  }
  .container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .modal .detailArea {
    font-size: 10px;
  }
  .container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .modal .detailArea svg {
    width: 12px;
    height: 12px;
  }
  .container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .modal .textArea h3 {
    font-size: 16px;
  }
  .container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .modal .textArea svg {
    height: 48px;
    width: 48px;
  }
  .container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .modal .helpArea {
    font-size: 10px;
  }
  .container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .modal .btnArea button {
    padding: 10px 40px;
    border-radius: 43px;
    font-size: 12px;
    font-weight: 500;
    color: var(--textColor);
    background-color: var(--primaryColor);
    text-transform: none;
  }
  .container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .modal .btnArea button:hover {
    opacity: 85%;
  }
  .container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .modal.esim-modal {
    gap: 18px;
  }
  .container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .modal.portability-modal .mainSectionModal .stepper-portability .current-step {
    gap: 10px;
    padding: 5px 0;
  }
  .container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .modal.portability-modal .mainSectionModal .stepper-portability .current-step.step-3 .transferArea {
    padding: 0 2%;
  }
  .container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .modal.portability-modal .mainSectionModal .stepper-portability .current-step.step-4 .stepsAfterSubmit {
    margin-top: 15px;
  }
}
@media (min-width: 1200px) {
  .container.configure-sua-conta .content .container-left {
    padding: 1.75rem 0 1.75rem 9.5rem;
  }
  .container.configure-sua-conta .content .container-right {
    padding: 1.75rem 9.5rem 1.75rem 2rem;
  }
  .container.configure-sua-conta .content .left {
    gap: 10px;
    /* HEADING */
    /* SLOGAN PAGE 3 */
    /* DESCRIPTION */
    /* PLAN SUMMARY PAGE 3 */
  }
  .container.configure-sua-conta .content .left .content-left-heading {
    top: 3.25rem;
    left: 5rem;
  }
  .container.configure-sua-conta .content .left .content-left-heading .heading img {
    width: 180px;
  }
  .container.configure-sua-conta .content .left .content-left-slogan-pg-3 .slogan-pg-3 {
    max-width: 300px;
  }
  .container.configure-sua-conta .content .left .content-left-slogan-pg-3 .slogan-pg-3 .textArea .skltn-slogan .skltn-h3 {
    font-size: 34px;
  }
  .container.configure-sua-conta .content .left .content-left-slogan-pg-3 .slogan-pg-3 .textArea .skltn-slogan .skltn-h3.skltn-1 {
    width: 74%;
  }
  .container.configure-sua-conta .content .left .content-left-slogan-pg-3 .slogan-pg-3 .textArea .skltn-slogan .skltn-h3.skltn-2 {
    width: 97%;
  }
  .container.configure-sua-conta .content .left .content-left-slogan-pg-3 .slogan-pg-3 .textArea h3 {
    font-size: 34px;
  }
  .container.configure-sua-conta .content .left .content-left-description .description {
    max-width: 370px;
  }
  .container.configure-sua-conta .content .left .content-left-description .description P {
    font-size: 14px;
  }
  .container.configure-sua-conta .content .left .content-left-plan-summary-pg-3 {
    margin-top: 55px;
  }
  .container.configure-sua-conta .content .left .content-left-plan-summary-pg-3 .plan-summary-pg-3 .chosenPlan {
    font-size: 20px;
  }
  .container.configure-sua-conta .content .left .content-left-plan-summary-pg-3 .plan-summary-pg-3 .planDetailsText h3, .container.configure-sua-conta .content .left .content-left-plan-summary-pg-3 .plan-summary-pg-3 .planDetailsText .skltn-h3 {
    font-size: 14px;
  }
  .container.configure-sua-conta .content .left .content-left-plan-summary-pg-3 .plan-summary-pg-3 .planDetailsText p {
    font-size: 12px;
  }
  .container.configure-sua-conta .content .left .content-left-plan-summary-pg-3 .plan-summary-pg-3 .planDetailsItems .planDetailsItem {
    font-size: 10px;
  }
  .container.configure-sua-conta .content .left .content-left-plan-summary-pg-3 .plan-summary-pg-3 .skltn-1 {
    width: 21%;
  }
  .container.configure-sua-conta .content .left .content-left-plan-summary-pg-3 .plan-summary-pg-3 .skltn-2 {
    width: 26%;
  }
  .container.configure-sua-conta .content .right {
    /* NEXT STEPS TEXT */
    /* STEPPER CONFIG */
  }
  .container.configure-sua-conta .content .right .content-right-next-steps-text h3 {
    font-size: 16px;
  }
  .container.configure-sua-conta .content .right .content-right-next-steps-text p {
    font-size: 13px;
  }
  .container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config {
    gap: 15px;
  }
  .container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .step .stepIcon .iconTable {
    width: 30px;
    height: 30px;
  }
  .container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .step .stepIcon .iconTable svg {
    width: 18px;
    height: 18px;
  }
  .container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .step .stepName h3 {
    font-size: 13px;
  }
  .container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .step .stepName p {
    font-size: 11px;
  }
  .container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .step .statusIcon svg {
    width: 22px;
    height: 22px;
  }
  .container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .modal {
    width: 400px;
    height: 490px;
  }
  .container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .modal p {
    font-size: 12px;
  }
  .container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .modal .detailArea {
    font-size: 14px;
  }
  .container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .modal .detailArea svg {
    width: 16px;
    height: 16px;
  }
  .container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .modal .textArea h3 {
    font-size: 18px;
  }
  .container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .modal .textArea svg {
    height: 48px;
    width: 48px;
  }
  .container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .modal .helpArea {
    font-size: 10px;
  }
  .container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .modal .btnArea button {
    padding: 8px 36px;
    font-size: 16px;
  }
  .container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .modal.esim-modal {
    height: 470px;
    gap: 20px;
  }
  .container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .modal.esim-modal .textArea p {
    max-width: 270px;
  }
  .container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .modal.esim-modal .mainSectionModal {
    padding: 50px 0;
  }
  .container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .modal.esim-modal .mainSectionModal .pdfBtnArea a {
    font-size: 18px;
    gap: 8px;
  }
  .container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .modal.portability-modal {
    height: 515px;
    gap: 20px;
  }
  .container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .modal.portability-modal .textArea p {
    max-width: 280px;
  }
  .container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .modal.portability-modal .mainSectionModal .stepper-portability .current-step {
    gap: 15px;
    padding: unset;
    padding-bottom: 20px;
  }
  .container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .modal.portability-modal .mainSectionModal .stepper-portability .current-step.step-3 .transferArea {
    padding: 0 8%;
  }
  .container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .modal.portability-modal .mainSectionModal .stepper-portability .current-step.step-4 .stepsAfterSubmit {
    margin-top: 15px;
  }
}
@media (min-width: 1440px) {
  .container.configure-sua-conta .content .container-left {
    padding: 1.75rem 0 1.75rem 11.5rem;
  }
  .container.configure-sua-conta .content .container-right {
    padding: 1.75rem 11.5rem 1.75rem 3rem;
  }
  .container.configure-sua-conta .content .left {
    /* HEADING */
    /* SLOGAN PAGE 3 */
    /* DESCRIPTION */
    /* PLAN SUMMARY PAGE 3 */
  }
  .container.configure-sua-conta .content .left .content-left-heading .heading img {
    width: 200px;
  }
  .container.configure-sua-conta .content .left .content-left-slogan-pg-3 {
    margin-top: 25px;
  }
  .container.configure-sua-conta .content .left .content-left-slogan-pg-3 .slogan-pg-3 {
    max-width: 350px;
  }
  .container.configure-sua-conta .content .left .content-left-slogan-pg-3 .slogan-pg-3 .textArea .skltn-slogan .skltn-h3 {
    font-size: 40px;
  }
  .container.configure-sua-conta .content .left .content-left-slogan-pg-3 .slogan-pg-3 .textArea .skltn-slogan .skltn-h3.skltn-1 {
    width: 75%;
  }
  .container.configure-sua-conta .content .left .content-left-slogan-pg-3 .slogan-pg-3 .textArea .skltn-slogan .skltn-h3.skltn-2 {
    width: 98%;
  }
  .container.configure-sua-conta .content .left .content-left-slogan-pg-3 .slogan-pg-3 .textArea h3 {
    font-size: 40px;
  }
  .container.configure-sua-conta .content .left .content-left-description .description {
    max-width: 400px;
  }
  .container.configure-sua-conta .content .left .content-left-description .description P {
    font-size: 15px;
  }
  .container.configure-sua-conta .content .left .content-left-plan-summary-pg-3 {
    margin-top: 115px;
  }
  .container.configure-sua-conta .content .left .content-left-plan-summary-pg-3 .plan-summary-pg-3 .planDetailsText h3, .container.configure-sua-conta .content .left .content-left-plan-summary-pg-3 .plan-summary-pg-3 .planDetailsText .skltn-h3 {
    font-size: 13px;
  }
  .container.configure-sua-conta .content .left .content-left-plan-summary-pg-3 .plan-summary-pg-3 .planDetailsText p {
    font-size: 12px;
  }
  .container.configure-sua-conta .content .left .content-left-plan-summary-pg-3 .plan-summary-pg-3 .planDetailsItems {
    margin-top: 15px;
  }
  .container.configure-sua-conta .content .left .content-left-plan-summary-pg-3 .plan-summary-pg-3 .planDetailsItems .planDetailsItem {
    font-size: 11px;
  }
  .container.configure-sua-conta .content .left .content-left-plan-summary-pg-3 .plan-summary-pg-3 .skltn-1 {
    width: 18%;
  }
  .container.configure-sua-conta .content .left .content-left-plan-summary-pg-3 .plan-summary-pg-3 .skltn-2 {
    width: 20%;
  }
  .container.configure-sua-conta .content .left .content-left-plan-summary-pg-3 .plan-summary-pg-3 .skltn-3 {
    width: 12%;
  }
  .container.configure-sua-conta .content .right {
    /* NEXT STEPS TEXT */
    /* STEPPER CONFIG */
  }
  .container.configure-sua-conta .content .right .content-right-next-steps-text h3 {
    font-size: 16px;
  }
  .container.configure-sua-conta .content .right .content-right-next-steps-text p {
    font-size: 13px;
  }
  .container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config {
    gap: 15px;
  }
  .container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .step {
    padding: 20px 26px;
  }
  .container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .step .stepIcon .iconTable {
    width: 34px;
    height: 34px;
  }
  .container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .step .stepIcon .iconTable svg {
    width: 20px;
    height: 20px;
  }
  .container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .step .stepName {
    margin: 0 18px;
  }
  .container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .step .stepName h3 {
    font-size: 14px;
  }
  .container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .step .stepName p {
    font-size: 12px;
  }
  .container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .step .statusIcon svg {
    width: 24px;
    height: 24px;
  }
  .container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .modal p {
    font-size: 12px;
  }
  .container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .modal .detailArea {
    font-size: 14px;
  }
  .container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .modal .detailArea svg {
    width: 16px;
    height: 16px;
  }
  .container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .modal .textArea h3 {
    font-size: 18px;
  }
  .container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .modal .textArea svg {
    height: 48px;
    width: 48px;
  }
  .container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .modal .helpArea {
    font-size: 10px;
  }
  .container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .modal .btnArea button {
    padding: 8px 36px;
    font-size: 16px;
  }
  .container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .modal.esim-modal {
    gap: 20px;
  }
  .container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .modal.esim-modal .textArea p {
    max-width: 270px;
  }
  .container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .modal.esim-modal .mainSectionModal {
    padding: 50px 0;
  }
  .container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .modal.esim-modal .mainSectionModal .pdfBtnArea a {
    font-size: 18px;
    gap: 8px;
  }
  .container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .modal.portability-modal {
    gap: 20px;
  }
  .container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .modal.portability-modal .textArea p {
    max-width: 280px;
  }
  .container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .modal.portability-modal .mainSectionModal .stepper-portability .current-step {
    gap: 15px;
    padding: unset;
    padding-bottom: 20px;
  }
  .container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .modal.portability-modal .mainSectionModal .stepper-portability .current-step.step-4 .stepsAfterSubmit {
    margin-top: 15px;
  }
}
@media (min-width: 1860px) {
  .container.configure-sua-conta .content .container-left {
    padding: 1.75rem 0 1.75rem 17.5rem;
  }
  .container.configure-sua-conta .content .container-right {
    padding: 1.75rem 17.5rem 1.75rem 4rem;
  }
  .container.configure-sua-conta .content .left {
    /* HEADING */
    /* SLOGAN PAGE 3 */
    /* DESCRIPTION */
    /* PLAN SUMMARY PAGE 3 */
  }
  .container.configure-sua-conta .content .left .content-left-heading .heading {
    top: 4rem;
    left: 6rem;
  }
  .container.configure-sua-conta .content .left .content-left-heading .heading img {
    width: 260px;
  }
  .container.configure-sua-conta .content .left .content-left-slogan-pg-3 {
    margin-top: 10px;
  }
  .container.configure-sua-conta .content .left .content-left-slogan-pg-3 .slogan-pg-3 {
    max-width: 440px;
  }
  .container.configure-sua-conta .content .left .content-left-slogan-pg-3 .slogan-pg-3 .textArea .skltn-slogan .skltn-h3 {
    font-size: 50px;
  }
  .container.configure-sua-conta .content .left .content-left-slogan-pg-3 .slogan-pg-3 .textArea h3 {
    font-size: 50px;
  }
  .container.configure-sua-conta .content .left .content-left-description .description {
    max-width: 480px;
  }
  .container.configure-sua-conta .content .left .content-left-description .description P {
    font-size: 18px;
  }
  .container.configure-sua-conta .content .left .content-left-plan-summary-pg-3 {
    margin-top: 150px;
  }
  .container.configure-sua-conta .content .left .content-left-plan-summary-pg-3 .plan-summary-pg-3 .chosenPlan {
    font-size: 24px;
  }
  .container.configure-sua-conta .content .left .content-left-plan-summary-pg-3 .plan-summary-pg-3 .planDetailsText h3, .container.configure-sua-conta .content .left .content-left-plan-summary-pg-3 .plan-summary-pg-3 .planDetailsText .skltn-h3 {
    font-size: 16px;
  }
  .container.configure-sua-conta .content .left .content-left-plan-summary-pg-3 .plan-summary-pg-3 .planDetailsText p {
    font-size: 14px;
  }
  .container.configure-sua-conta .content .left .content-left-plan-summary-pg-3 .plan-summary-pg-3 .planDetailsItems .planDetailsItem {
    font-size: 14px;
  }
  .container.configure-sua-conta .content .left .content-left-plan-summary-pg-3 .plan-summary-pg-3 .skltn-1 {
    width: 17%;
  }
  .container.configure-sua-conta .content .left .content-left-plan-summary-pg-3 .plan-summary-pg-3 .skltn-2 {
    width: 19%;
  }
  .container.configure-sua-conta .content .right {
    /* NEXT STEPS TEXT */
    /* STEPPER CONFIG */
  }
  .container.configure-sua-conta .content .right .content-right-next-steps-text h3 {
    font-size: 20px;
  }
  .container.configure-sua-conta .content .right .content-right-next-steps-text p {
    font-size: 16px;
  }
  .container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config {
    gap: 25px;
  }
  .container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .step {
    padding: 22px 32px;
  }
  .container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .step .stepIcon .iconTable {
    width: 38px;
    height: 38px;
  }
  .container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .step .stepIcon .iconTable svg {
    width: 22px;
    height: 22px;
  }
  .container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .step .stepName {
    margin: 0 20px;
  }
  .container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .step .stepName h3 {
    font-size: 16px;
  }
  .container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .step .stepName p {
    font-size: 14px;
  }
  .container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .step .statusIcon svg {
    width: 28px;
    height: 28px;
  }
  .container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .modal {
    width: 490px;
    height: 580px;
  }
  .container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .modal p {
    font-size: 15px;
  }
  .container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .modal .detailArea {
    font-size: 18px;
    gap: 8px;
  }
  .container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .modal .detailArea svg {
    width: 20px;
    height: 20px;
  }
  .container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .modal .textArea h3 {
    font-size: 26px;
  }
  .container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .modal .textArea svg {
    height: 78px;
    width: 78px;
  }
  .container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .modal .helpArea {
    font-size: 14px;
    margin-bottom: -10px;
  }
  .container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .modal .btnArea button {
    padding: 8px 44px;
    font-size: 20px;
  }
  .container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .modal.esim-modal {
    width: 490px;
    height: 580px;
    gap: 25px;
  }
  .container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .modal.esim-modal .textArea p {
    max-width: 355px;
    font-size: 16px;
  }
  .container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .modal.esim-modal .mainSectionModal {
    padding: 55px 0;
  }
  .container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .modal.esim-modal .mainSectionModal .pdfBtnArea a {
    font-size: 24px;
    gap: 8px;
  }
  .container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .modal.portability-modal {
    width: 510px;
    height: 640px;
    gap: 25px;
  }
  .container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .modal.portability-modal .iconArea svg {
    width: 32px;
    height: 32px;
  }
  .container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .modal.portability-modal .textArea p {
    max-width: 370px;
  }
  .container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .modal.portability-modal .mainSectionModal .stepper-portability .current-step {
    gap: 20px;
    padding: unset;
    padding-bottom: 20px;
  }
  .container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .modal.portability-modal .mainSectionModal .stepper-portability .current-step .circularLabel {
    height: 30px;
    width: 30px;
    font-size: 16px;
  }
  .container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .modal.portability-modal .mainSectionModal .stepper-portability .current-step .labelStepArea {
    max-width: 270px;
  }
  .container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .modal.portability-modal .mainSectionModal .stepper-portability .current-step.step-3 .transferArea {
    padding: 0 15%;
  }
  .container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .modal.portability-modal .mainSectionModal .stepper-portability .current-step.step-3 .transferArea .prevNextCompany .prevNextCompanyLabel {
    padding: 10px 14px;
    font-size: 14px;
  }
  .container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .modal.portability-modal .mainSectionModal .stepper-portability .current-step.step-3 .currentStepText .textArea {
    max-width: 345px;
  }
  .container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .modal.portability-modal .mainSectionModal .stepper-portability .current-step.step-4 .titleArea {
    font-size: 24px;
  }
  .container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .modal.portability-modal .mainSectionModal .stepper-portability .current-step.step-4 .labelTextArea {
    max-width: 320px;
  }
  .container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .modal.portability-modal .mainSectionModal .stepper-portability .current-step.step-4 p {
    font-size: 16px;
  }
  .container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .modal.portability-modal .mainSectionModal .stepper-portability .current-step.step-4 .stepsAfterSubmit {
    margin-top: 15px;
  }
  .container.configure-sua-conta .content .right .content-right-stepper-config .stepper-config .modal.portability-modal .mainSectionModal .stepper-portability .current-step.step-4 .stepsAfterSubmit .stepAfterSubmit .circularTextLabel {
    max-width: 160px;
  }
}
.container.pedido-finalizado {
  /******************** MEDIA QUERY 900 PIXELS ********************/
  /******************** MEDIA QUERY 1200 PIXELS ********************/
  /******************** MEDIA QUERY 1440 PIXELS ********************/
  /******************** MEDIA QUERY 1860 PIXELS ********************/
}
.container.pedido-finalizado .content {
  display: block;
  height: 100vh;
  background-color: var(--primaryColor);
}
.container.pedido-finalizado .content .container-left {
  padding: 80px 30px 0 30px;
  background-color: var(--primaryColor);
}
.container.pedido-finalizado .content .container-right {
  padding: 35px 30px 30px 30px;
  background-color: var(--primaryColor);
}
.container.pedido-finalizado .content .left {
  /* Theme */
  --titleColor: #FFFFFF;
  --textColor: #FFFFFF;
  display: flex;
  gap: 25px;
  /* HEADING */
  /* SLOGAN PAGE 4 */
  /* DESCRIPTION */
  /* NEXT STEPS TEXT */
  /* APP LINK */
}
.container.pedido-finalizado .content .left .content-left-heading {
  width: 100vw;
}
.container.pedido-finalizado .content .left .content-left-heading .heading {
  display: flex;
  justify-content: center;
  width: 100%;
}
.container.pedido-finalizado .content .left .content-left-heading .heading h3 {
  display: none;
}
.container.pedido-finalizado .content .left .content-left-heading .heading img {
  width: 55%;
}
.container.pedido-finalizado .content .left .content-left-slogan-pg-4 {
  margin-top: 35px;
}
.container.pedido-finalizado .content .left .content-left-slogan-pg-4 .slogan-pg-4 {
  max-width: 300px;
}
.container.pedido-finalizado .content .left .content-left-slogan-pg-4 .slogan-pg-4 h1 {
  font-size: 30px;
  font-weight: 500;
  color: var(--titleColor);
}
.container.pedido-finalizado .content .left .content-left-description {
  margin-top: -10px;
}
.container.pedido-finalizado .content .left .content-left-description .description {
  max-width: 260px;
}
.container.pedido-finalizado .content .left .content-left-description .description P {
  font-size: 14px;
  font-weight: 400;
  color: var(--textColor);
}
.container.pedido-finalizado .content .left .content-left-download-app-text {
  margin-top: 15px;
}
.container.pedido-finalizado .content .left .content-left-download-app-text .textArea {
  max-width: 200px;
}
.container.pedido-finalizado .content .left .content-left-download-app-text .textArea h3 {
  font-size: 16px;
  font-weight: 500;
  color: var(--titleColor);
}
.container.pedido-finalizado .content .left .content-left-app-link {
  display: flex;
  justify-content: center;
  margin-top: 5px;
}
.container.pedido-finalizado .content .left .content-left-app-link .app-link {
  width: 100%;
  max-width: 460px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 10px;
}
.container.pedido-finalizado .content .left .content-left-app-link .app-link svg rect {
  fill: var(--appLinkBackColor);
}
.container.pedido-finalizado .content .right {
  /* Theme */
  --titleColor: #FFFFFF;
  --textColor: #FFFFFF;
  background-color: var(--primaryColor);
  display: flex;
  flex-direction: column;
  gap: 25px;
  /* STEPPER CONFIG */
}
.container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config {
  gap: 20px;
}
.container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .step {
  justify-content: space-between;
  align-items: center;
  border-radius: 100px;
  padding: 20px 25px;
  cursor: pointer;
}
.container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .step .stepIcon {
  display: flex;
  align-items: center;
  justify-content: center;
}
.container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .step .stepIcon svg {
  width: 16px;
  height: 16px;
}
.container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .step .stepIcon .iconTable {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 100%;
}
.container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .step .stepName {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1 1;
  margin: 0 15px;
}
.container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .step .stepName h3 {
  font-size: 12px;
  font-weight: 500;
}
.container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .step .stepName p {
  font-size: 10px;
}
.container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .step .statusIcon {
  display: flex;
  align-items: center;
  justify-content: center;
}
.container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .step .statusIcon svg {
  width: 20px;
  height: 20px;
}
.container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .step.step-1 {
  cursor: not-allowed;
}
.container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .completed {
  background-color: rgba(255, 255, 255, 0.1450980392);
}
.container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .completed .stepIcon svg path {
  stroke: var(--primaryColor);
}
.container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .completed .iconTable {
  background-color: #FFF;
}
.container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .completed .stepName h3 {
  color: var(--textColor);
}
.container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .completed .stepName p {
  color: var(--textColor);
}
.container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .completed .statusIcon svg path {
  stroke: #FFF;
}
.container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .active, .container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .disabled {
  border: 1px solid #FFFFFF;
  cursor: pointer;
}
.container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .active .stepIcon svg, .container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .disabled .stepIcon svg {
  width: 20px;
  height: 20px;
}
.container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .active .stepIcon svg path, .container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .disabled .stepIcon svg path {
  stroke: var(--primaryColor);
}
.container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .active .iconTable, .container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .disabled .iconTable {
  width: 36px;
  height: 36px;
  background-color: #FFF;
  padding: 0 0 2px 1px;
}
.container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .active .stepName h3, .container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .disabled .stepName h3 {
  color: var(--titleColor);
}
.container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .active .stepName p, .container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .disabled .stepName p {
  color: var(--titleColor);
}
.container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .active .statusIcon svg, .container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .disabled .statusIcon svg {
  width: 26px;
  height: 26px;
}
.container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .active .statusIcon svg path, .container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .disabled .statusIcon svg path {
  stroke: #FFFFFF;
}
.container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .disabled {
  opacity: 0.3;
}
.container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .active:hover {
  background-color: rgba(255, 255, 255, 0.062745098);
}
.container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .active.step-5 {
  border: 1px solid var(--wppStepColor);
  width: 100%;
  text-decoration: none;
}
.container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .active.step-5 .stepIcon svg path {
  fill: var(--primaryColor);
  stroke: unset;
}
.container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .active.step-5 .iconTable {
  background-color: var(--wppStepColor);
}
.container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .active.step-5 .stepName h3 {
  color: var(--wppStepColor);
}
.container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .active.step-5 .stepName p {
  color: var(--wppStepColor);
}
.container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .active.step-5 .statusIcon svg path {
  stroke: var(--wppStepColor);
}
.container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .blackShadow {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3960784314);
  overflow-y: hidden;
  z-index: 1;
}
.container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4.5vh 20px;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  background-color: white;
  border-radius: 20px 20px 0 0;
  gap: 20px;
}
.container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .modal p {
  text-align: center;
  font-size: 11px;
  font-weight: 400;
  color: #A2A2A2;
}
.container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .modal .detailArea {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  background-color: var(--focusColor);
  border-radius: 22px;
  padding: 8px 13px;
  color: var(--primaryColor);
  font-size: 12px;
  font-weight: 500;
}
.container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .modal .detailArea .eSimIcon, .container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .modal .detailArea .portabilityIcon, .container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .modal .detailArea .smartControlIcon {
  width: 14px;
  height: 14px;
}
.container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .modal .detailArea svg {
  width: 14px;
  height: 14px;
}
.container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .modal .textArea {
  text-align: center;
}
.container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .modal .textArea h3 {
  font-size: 19px;
  font-weight: 500;
}
.container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .modal .textArea p {
  max-width: 255px;
}
.container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .modal .textArea svg {
  height: 60px;
  width: 60px;
}
.container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .modal .mainSectionModal {
  display: flex;
  align-items: center;
  justify-content: center;
}
.container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .modal .helpArea {
  display: flex;
  align-items: center;
  gap: 5px;
  text-decoration: none;
  font-size: 11px;
  color: #878787;
}
.container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .modal .helpArea:hover {
  color: rgba(0, 0, 0, 0.7725490196);
}
.container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .modal .btnArea button {
  padding: 10px 40px;
  border-radius: 43px;
  font-size: 14px;
  font-weight: 500;
  color: var(--textColor);
  background-color: var(--primaryColor);
  text-transform: none;
}
.container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .modal .btnArea button:hover {
  opacity: 85%;
}
.container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .modal.esim-modal .mainSectionModal {
  padding: 30px 0;
}
.container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .modal.esim-modal .mainSectionModal .pdfBtnArea a {
  display: flex;
  align-items: center;
  gap: 5px;
  color: var(--primaryColor);
  text-decoration: none;
}
.container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .modal.esim-modal .mainSectionModal .pdfBtnArea a:hover {
  opacity: 85%;
}
.container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .modal.portability-modal .iconArea {
  display: flex;
  position: absolute;
  left: 40px;
  top: 5.4vh;
}
.container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .modal.portability-modal .mainSectionModal .stepper-portability {
  display: flex;
  align-items: center;
  justify-content: center;
}
.container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .modal.portability-modal .mainSectionModal .stepper-portability .current-step {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
  padding-bottom: 20px;
}
.container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .modal.portability-modal .mainSectionModal .stepper-portability .current-step .MuiFormHelperText-root.Mui-error {
  color: #d32f2f;
  text-align: left;
}
.container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .modal.portability-modal .mainSectionModal .stepper-portability .current-step .circularLabel {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 28px;
  width: 28px;
  background-color: rgba(166, 166, 166, 0.1019607843);
  color: black;
  font-size: 11px;
  border-radius: 50%;
}
.container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .modal.portability-modal .mainSectionModal .stepper-portability .current-step .labelStepArea {
  max-width: 200px;
  margin-bottom: 10px;
}
.container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .modal.portability-modal .mainSectionModal .stepper-portability .current-step.step-3 {
  padding-bottom: 0;
}
.container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .modal.portability-modal .mainSectionModal .stepper-portability .current-step.step-3 .transferArea {
  display: flex;
  justify-content: space-between;
  padding: 0 15%;
}
.container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .modal.portability-modal .mainSectionModal .stepper-portability .current-step.step-3 .transferArea .rightArrow {
  transform: scaleX(-1);
}
.container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .modal.portability-modal .mainSectionModal .stepper-portability .current-step.step-3 .transferArea .prevNextCompany {
  display: flex;
  flex-direction: column;
  gap: 5px;
  font-size: 13px;
}
.container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .modal.portability-modal .mainSectionModal .stepper-portability .current-step.step-3 .transferArea .prevNextCompany .prevNextCompanyLabel {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: rgba(166, 166, 166, 0.1019607843);
  padding: 5px 14px;
  border-radius: 22px;
  gap: 5px;
}
.container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .modal.portability-modal .mainSectionModal .stepper-portability .current-step.step-3 .transferArea .prevNextCompany .prevNextCompanyLabel.nextCompany {
  background-color: #00D66A;
  color: white;
}
.container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .modal.portability-modal .mainSectionModal .stepper-portability .current-step.step-3 .currentStepText {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 5px;
}
.container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .modal.portability-modal .mainSectionModal .stepper-portability .current-step.step-3 .currentStepText .attentionLabel {
  font-weight: 500;
  color: black;
}
.container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .modal.portability-modal .mainSectionModal .stepper-portability .current-step.step-3 .currentStepText .textArea {
  max-width: 260px;
}
.container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .modal.portability-modal .mainSectionModal .stepper-portability .current-step.step-4 {
  gap: 0;
}
.container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .modal.portability-modal .mainSectionModal .stepper-portability .current-step.step-4 p {
  color: white;
  font-size: 12px;
}
.container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .modal.portability-modal .mainSectionModal .stepper-portability .current-step.step-4 .titleArea {
  font-size: 20px;
}
.container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .modal.portability-modal .mainSectionModal .stepper-portability .current-step.step-4 .labelTextArea {
  max-width: 260px;
}
.container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .modal.portability-modal .mainSectionModal .stepper-portability .current-step.step-4 .stepsAfterSubmit {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  margin-top: 35px;
}
.container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .modal.portability-modal .mainSectionModal .stepper-portability .current-step.step-4 .stepsAfterSubmit .stepAfterSubmit {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
}
.container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .modal.portability-modal .mainSectionModal .stepper-portability .current-step.step-4 .stepsAfterSubmit .stepAfterSubmit .circularLabel {
  background-color: rgba(255, 255, 255, 0.062745098);
  color: white;
}
.container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .modal.portability-modal .mainSectionModal .stepper-portability .current-step.step-4 .stepsAfterSubmit .stepAfterSubmit .circularTextLabel {
  max-width: 120px;
}
.container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .modal.portability-modal.submitClass {
  background-color: var(--primaryColor);
}
.container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .modal.portability-modal.submitClass .leftArrow svg path {
  stroke: white;
}
.container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .modal.portability-modal.submitClass .detailArea {
  background-color: rgba(255, 255, 255, 0.062745098);
  color: white;
}
.container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .modal.portability-modal.submitClass .detailArea .portabilityIcon svg path {
  stroke: white;
}
.container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .modal.portability-modal.submitClass .btnArea button {
  background-color: white;
  color: var(--primaryColor);
}
.container.pedido-finalizado .footer {
  display: none;
  height: 32vh;
  background-color: blue;
  padding: 60px 20px;
}
@media (min-width: 900px) {
  .container.pedido-finalizado {
    display: flex;
    align-items: center;
    height: 100vh;
    background-color: var(--primaryColor);
  }
  .container.pedido-finalizado .content {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row-reverse;
  }
  .container.pedido-finalizado .content .container-left {
    display: flex;
    width: 100%;
    padding: 1.75rem 2.75rem 1.75rem 0;
  }
  .container.pedido-finalizado .content .container-right {
    padding: 1.75rem 2.75rem;
    position: unset;
    background-color: var(--primaryColor);
  }
  .container.pedido-finalizado .content .left {
    /* Theme */
    --titleColor: #FFFFFF;
    --textColor: #FFFFFF;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    gap: 15px;
    /* HEADING */
    /* SLOGAN PAGE 4 */
    /* DESCRIPTION */
    /* NEXT STEPS TEXT */
    /* APP LINK */
  }
  .container.pedido-finalizado .content .left .content-left-heading {
    align-self: flex-start;
    width: auto;
    position: fixed;
    top: 1.75rem;
    left: 2.75rem;
  }
  .container.pedido-finalizado .content .left .content-left-heading .heading {
    justify-content: unset;
  }
  .container.pedido-finalizado .content .left .content-left-heading .heading img {
    width: 150px;
  }
  .container.pedido-finalizado .content .left .content-left-slogan-pg-4 {
    margin-top: 0;
  }
  .container.pedido-finalizado .content .left .content-left-slogan-pg-4 .slogan-pg-4 {
    max-width: 266px;
  }
  .container.pedido-finalizado .content .left .content-left-slogan-pg-4 .slogan-pg-4 h3 {
    font-size: 32px;
  }
  .container.pedido-finalizado .content .left .content-left-description {
    display: none;
  }
  .container.pedido-finalizado .content .left .content-left-download-app-text {
    margin-top: 0;
  }
  .container.pedido-finalizado .content .left .content-left-download-app-text .textArea {
    max-width: unset;
  }
  .container.pedido-finalizado .content .left .content-left-download-app-text .textArea h3 {
    font-size: 14px;
    font-weight: 500;
    color: var(--titleColor);
  }
  .container.pedido-finalizado .content .left .content-left-app-link {
    margin-top: 0;
    justify-content: start;
  }
  .container.pedido-finalizado .content .left .content-left-app-link .app-link {
    justify-content: start;
  }
  .container.pedido-finalizado .content .right {
    /* Theme */
    --titleColor: #FFFFFF;
    --textColor: #FFFFFF;
    justify-content: center;
    padding: 38px;
    /* STEPPER CONFIG */
  }
  .container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .step {
    padding: 16px 25px;
  }
  .container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .completed .stepIcon svg {
    width: 16px;
    height: 16px;
  }
  .container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .completed .iconTable {
    width: 30px;
    height: 30px;
  }
  .container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .active .stepIcon svg {
    width: 16px;
    height: 16px;
  }
  .container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .active .iconTable {
    width: 30px;
    height: 30px;
    padding: 0 0 1px 0;
  }
  .container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .active .stepName h3 {
    font-size: 12px;
  }
  .container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .active .stepName p {
    font-size: 10px;
  }
  .container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .active .statusIcon svg {
    width: auto;
    height: auto;
  }
  .container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .blackShadow {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .modal {
    width: 351px;
    height: 400px;
    position: relative;
    border-radius: 20px;
    gap: 10px;
  }
  .container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .modal p {
    font-size: 10px;
  }
  .container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .modal .detailArea {
    font-size: 10px;
  }
  .container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .modal .detailArea svg {
    width: 12px;
    height: 12px;
  }
  .container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .modal .textArea h3 {
    font-size: 16px;
  }
  .container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .modal .textArea svg {
    height: 48px;
    width: 48px;
  }
  .container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .modal .helpArea {
    font-size: 10px;
  }
  .container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .modal .btnArea button {
    padding: 10px 40px;
    border-radius: 43px;
    font-size: 12px;
    font-weight: 500;
    color: var(--textColor);
    background-color: var(--primaryColor);
    text-transform: none;
  }
  .container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .modal .btnArea button:hover {
    opacity: 85%;
  }
  .container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .modal.esim-modal {
    gap: 18px;
  }
  .container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .modal.portability-modal .mainSectionModal .stepper-portability .current-step {
    gap: 10px;
    padding: 5px 0;
  }
  .container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .modal.portability-modal .mainSectionModal .stepper-portability .current-step.step-3 .transferArea {
    padding: 0 2%;
  }
  .container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .modal.portability-modal .mainSectionModal .stepper-portability .current-step.step-4 .stepsAfterSubmit {
    margin-top: 15px;
  }
  .container.pedido-finalizado .footer {
    display: none;
  }
}
@media (min-width: 1200px) {
  .container.pedido-finalizado .content .container-left {
    padding: 2.75rem 3.5rem 2.75rem 0;
  }
  .container.pedido-finalizado .content .container-right {
    padding: 2.75rem 6.25rem;
  }
  .container.pedido-finalizado .content .left {
    gap: 20px;
    /* HEADING */
    /* NEXT STEPS TEXT */
    /* APP LINK */
  }
  .container.pedido-finalizado .content .left .content-left-heading {
    top: 2.75rem;
    left: 3.5rem;
  }
  .container.pedido-finalizado .content .left .content-left-heading .heading img {
    width: 165px;
  }
  .container.pedido-finalizado .content .left .content-left-download-app-text .textArea h3 {
    font-size: 15px;
  }
  .container.pedido-finalizado .content .left .content-left-app-link .app-link {
    gap: 20px;
  }
  .container.pedido-finalizado .content .right {
    gap: 35px;
    /* STEPPER CONFIG */
  }
  .container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .step {
    padding: 20px 25px;
  }
  .container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .step .stepName h3 {
    font-size: 12px;
  }
  .container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .step .stepName p {
    font-size: 10px;
  }
  .container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .completed .stepIcon svg {
    width: 16px;
    height: 16px;
  }
  .container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .completed .iconTable {
    width: 30px;
    height: 30px;
  }
  .container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .active .stepIcon svg {
    width: 16px;
    height: 16px;
  }
  .container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .active .stepName h3 {
    font-size: 12px;
    color: var(--titleColor);
  }
  .container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .active .stepName p {
    font-size: 10px;
    color: var(--titleColor);
  }
  .container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .active .iconTable {
    width: 30px;
    height: 30px;
    padding: 0 0 1px 0;
  }
  .container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .active .statusIcon svg {
    width: auto;
    height: auto;
  }
  .container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .modal {
    width: 400px;
    height: 490px;
  }
  .container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .modal p {
    font-size: 12px;
  }
  .container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .modal .detailArea {
    font-size: 14px;
  }
  .container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .modal .detailArea svg {
    width: 16px;
    height: 16px;
  }
  .container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .modal .textArea h3 {
    font-size: 18px;
  }
  .container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .modal .textArea svg {
    height: 48px;
    width: 48px;
  }
  .container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .modal .helpArea {
    font-size: 10px;
  }
  .container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .modal .btnArea button {
    padding: 8px 36px;
    font-size: 16px;
  }
  .container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .modal.esim-modal {
    height: 470px;
    gap: 20px;
  }
  .container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .modal.esim-modal .textArea p {
    max-width: 270px;
  }
  .container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .modal.esim-modal .mainSectionModal {
    padding: 50px 0;
  }
  .container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .modal.esim-modal .mainSectionModal .pdfBtnArea a {
    font-size: 18px;
    gap: 8px;
  }
  .container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .modal.portability-modal {
    height: 515px;
    gap: 20px;
  }
  .container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .modal.portability-modal .textArea p {
    max-width: 280px;
  }
  .container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .modal.portability-modal .mainSectionModal .stepper-portability .current-step {
    gap: 15px;
    padding: unset;
    padding-bottom: 20px;
  }
  .container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .modal.portability-modal .mainSectionModal .stepper-portability .current-step.step-3 .transferArea {
    padding: 0 8%;
  }
  .container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .modal.portability-modal .mainSectionModal .stepper-portability .current-step.step-4 .stepsAfterSubmit {
    margin-top: 15px;
  }
}
@media (min-width: 1440px) {
  .container.pedido-finalizado .content .container-left {
    padding: 3.5rem 4rem 3.5rem 0;
  }
  .container.pedido-finalizado .content .container-right {
    padding: 3.5rem 7.5rem;
  }
  .container.pedido-finalizado .content .left {
    /* HEADING */
    /* SLOGAN PAGE 3 */
  }
  .container.pedido-finalizado .content .left .content-left-heading {
    top: 3.5rem;
    left: 4rem;
  }
  .container.pedido-finalizado .content .left .content-left-heading .heading img {
    width: 195px;
  }
  .container.pedido-finalizado .content .left .content-left-slogan-pg-4 .slogan-pg-4 {
    max-width: 315px;
  }
  .container.pedido-finalizado .content .left .content-left-slogan-pg-4 .slogan-pg-4 .iconArea svg {
    width: 48px;
    height: 48px;
  }
  .container.pedido-finalizado .content .left .content-left-slogan-pg-4 .slogan-pg-4 h3 {
    font-size: 38px;
  }
  .container.pedido-finalizado .content .right {
    gap: 40px;
    padding: 50px;
    /* STEPPER CONFIG */
  }
  .container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .modal p {
    font-size: 12px;
  }
  .container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .modal .detailArea {
    font-size: 14px;
  }
  .container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .modal .detailArea svg {
    width: 16px;
    height: 16px;
  }
  .container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .modal .textArea h3 {
    font-size: 18px;
  }
  .container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .modal .textArea svg {
    height: 48px;
    width: 48px;
  }
  .container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .modal .helpArea {
    font-size: 10px;
  }
  .container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .modal .btnArea button {
    padding: 8px 36px;
    font-size: 16px;
  }
  .container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .modal.esim-modal {
    gap: 20px;
  }
  .container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .modal.esim-modal .textArea p {
    max-width: 270px;
  }
  .container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .modal.esim-modal .mainSectionModal {
    padding: 50px 0;
  }
  .container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .modal.esim-modal .mainSectionModal .pdfBtnArea a {
    font-size: 18px;
    gap: 8px;
  }
  .container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .modal.portability-modal {
    gap: 20px;
  }
  .container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .modal.portability-modal .textArea p {
    max-width: 280px;
  }
  .container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .modal.portability-modal .mainSectionModal .stepper-portability .current-step {
    gap: 15px;
    padding: unset;
    padding-bottom: 20px;
  }
  .container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .modal.portability-modal .mainSectionModal .stepper-portability .current-step.step-4 .stepsAfterSubmit {
    margin-top: 15px;
  }
}
@media (min-width: 1860px) {
  .container.pedido-finalizado .content .container-left {
    padding: 5rem 5.75rem 5rem 0;
  }
  .container.pedido-finalizado .content .container-right {
    padding: 5rem 8rem;
  }
  .container.pedido-finalizado .content .left {
    gap: 15px;
    /* HEADING */
    /* SLOGAN PAGE 3 */
    /* NEXT STEPS TEXT */
    /* APP LINK */
  }
  .container.pedido-finalizado .content .left .content-left-heading {
    top: 5rem;
    left: 5.75rem;
  }
  .container.pedido-finalizado .content .left .content-left-heading .heading img {
    width: 240px;
  }
  .container.pedido-finalizado .content .left .content-left-slogan-pg-4 .slogan-pg-4 {
    max-width: 470px;
  }
  .container.pedido-finalizado .content .left .content-left-slogan-pg-4 .slogan-pg-4 .iconArea svg {
    width: 75px;
    height: 75px;
  }
  .container.pedido-finalizado .content .left .content-left-slogan-pg-4 .slogan-pg-4 h3 {
    font-size: 56px;
    line-height: 1.35;
  }
  .container.pedido-finalizado .content .left .content-left-download-app-text .textArea h3 {
    font-size: 19px;
  }
  .container.pedido-finalizado .content .left .content-left-app-link .app-link {
    gap: 20px;
  }
  .container.pedido-finalizado .content .left .content-left-app-link .app-link .appIcon svg {
    width: 100%;
    height: 100%;
  }
  .container.pedido-finalizado .content .right {
    padding: 90px;
    /* STEPPER CONFIG */
  }
  .container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config {
    gap: 25px;
  }
  .container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .step {
    padding: 22px 32px;
  }
  .container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .step .stepIcon .iconTable {
    width: 38px;
    height: 38px;
  }
  .container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .step .stepIcon .iconTable svg {
    width: 22px;
    height: 22px;
  }
  .container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .step .stepName {
    margin: 0 20px;
  }
  .container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .step .stepName h3 {
    font-size: 16px;
  }
  .container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .step .stepName p {
    font-size: 14px;
  }
  .container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .step .statusIcon svg {
    width: 28px;
    height: 28px;
  }
  .container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .completed .stepIcon svg {
    width: 20px;
    height: 20px;
  }
  .container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .completed .iconTable {
    width: 42px;
    height: 42px;
  }
  .container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .active .stepIcon svg {
    width: 20px;
    height: 20px;
  }
  .container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .active .iconTable {
    width: 42px;
    height: 42px;
    padding: 0 0 1px 0;
  }
  .container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .active .statusIcon svg {
    width: 30px;
    height: 30px;
  }
  .container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .modal {
    width: 490px;
    height: 580px;
  }
  .container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .modal p {
    font-size: 15px;
  }
  .container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .modal .detailArea {
    font-size: 18px;
    gap: 8px;
  }
  .container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .modal .detailArea svg {
    width: 20px;
    height: 20px;
  }
  .container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .modal .textArea h3 {
    font-size: 26px;
  }
  .container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .modal .textArea svg {
    height: 78px;
    width: 78px;
  }
  .container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .modal .helpArea {
    font-size: 14px;
    margin-bottom: -10px;
  }
  .container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .modal .btnArea button {
    padding: 8px 44px;
    font-size: 20px;
  }
  .container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .modal.esim-modal {
    width: 490px;
    height: 580px;
    gap: 25px;
  }
  .container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .modal.esim-modal .textArea p {
    max-width: 355px;
    font-size: 16px;
  }
  .container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .modal.esim-modal .mainSectionModal {
    padding: 55px 0;
  }
  .container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .modal.esim-modal .mainSectionModal .pdfBtnArea a {
    font-size: 24px;
    gap: 8px;
  }
  .container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .modal.portability-modal {
    width: 510px;
    height: 640px;
    gap: 25px;
  }
  .container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .modal.portability-modal .iconArea svg {
    width: 32px;
    height: 32px;
  }
  .container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .modal.portability-modal .textArea p {
    max-width: 370px;
  }
  .container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .modal.portability-modal .mainSectionModal .stepper-portability .current-step {
    gap: 20px;
    padding: unset;
    padding-bottom: 20px;
  }
  .container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .modal.portability-modal .mainSectionModal .stepper-portability .current-step .circularLabel {
    height: 30px;
    width: 30px;
    font-size: 16px;
  }
  .container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .modal.portability-modal .mainSectionModal .stepper-portability .current-step .labelStepArea {
    max-width: 270px;
  }
  .container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .modal.portability-modal .mainSectionModal .stepper-portability .current-step.step-3 .transferArea {
    padding: 0 15%;
  }
  .container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .modal.portability-modal .mainSectionModal .stepper-portability .current-step.step-3 .transferArea .prevNextCompany .prevNextCompanyLabel {
    padding: 10px 14px;
    font-size: 14px;
  }
  .container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .modal.portability-modal .mainSectionModal .stepper-portability .current-step.step-3 .currentStepText .textArea {
    max-width: 345px;
  }
  .container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .modal.portability-modal .mainSectionModal .stepper-portability .current-step.step-4 .titleArea {
    font-size: 24px;
  }
  .container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .modal.portability-modal .mainSectionModal .stepper-portability .current-step.step-4 .labelTextArea {
    max-width: 320px;
  }
  .container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .modal.portability-modal .mainSectionModal .stepper-portability .current-step.step-4 p {
    font-size: 16px;
  }
  .container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .modal.portability-modal .mainSectionModal .stepper-portability .current-step.step-4 .stepsAfterSubmit {
    margin-top: 15px;
  }
  .container.pedido-finalizado .content .right .content-right-stepper-config .stepper-config .modal.portability-modal .mainSectionModal .stepper-portability .current-step.step-4 .stepsAfterSubmit .stepAfterSubmit .circularTextLabel {
    max-width: 160px;
  }
}/*# sourceMappingURL=index.css.map */